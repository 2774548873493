import React from 'react';

import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import RePopLogo from '../../../assets/images/repop-logo-black.svg';
import RePopLogoWhite from '../../../assets/images/repop-logo-white.svg';

export const RePopLogoBlock = ({
  sx = [],
  isWhiteLogo = false,
}: {
  sx?: SxProps<Theme>;
  isWhiteLogo?: boolean;
}): JSX.Element => {
  return (
    <Box sx={[{ width: { xs: '88px', md: '170px' }, cursor: 'pointer' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <img
        onClick={() => {
          window.location.href = '/';
        }}
        className="responsive-img"
        src={isWhiteLogo ? RePopLogoWhite : RePopLogo}
        style={{ width: 'inherit' }}
        alt="repop-logo"
      />
    </Box>
  );
};
