import React, { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { DashboardStoreTranslationBox } from 'common/components/dashboard/StoreTranslationBox';
import StoreTranslateDTO from 'models/translations/store-translate-dto';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { BasicButton } from '../../../common/components/basic/Button';
import { Checkbox } from '../../../common/components/basic/Checkbox';
import { BasicInput } from '../../../common/components/basic/Input';
import { DashboardBasicContainer } from '../../../common/components/dashboard/BasicContainer';
import { DashboardCaption } from '../../../common/components/dashboard/Caption';
import { GoogleAutocompleteInput } from '../../../common/components/GoogleAutocomplete';
import { PhotoUploader } from '../../../common/components/PhotoUploader';
import { WorkingDayCheckboxes } from '../../../common/components/WorkingDays';
import { createAddressDtoFromGoogleObj } from '../../../common/helpers/google-heplers';
import { useAppDispatch } from '../../../common/hooks/redux-hooks';
import { useStateFormControlHandler } from '../../../common/hooks/use-state-from-control-handler';
import { DAYS_OF_WEEK } from '../../../constants/enums/days-of-week';
import ImageEditDTO from '../../../models/image-edit-dto';
import StoreEditDTO from '../../../models/store/store-edit-dto';
import { StoresManagerService } from '../../../services/stores-manager';
import { enqueueNotification as enqueueSnackbarAction } from '../../notifier/notifierSlice';

export const NewStore = (): JSX.Element => {
  const initialState = {
    workingDays: [],
    //unused fields
    establishmentTypeId: null,
    customerDescription: null,
    newExteriorImages: [],
    exteriorImages: [],
    newInteriorImages: [],
    interiorImages: [],
    availabilityDescription: null,
    environmentDescription: null,
    gettingAroundDescription: null,
    insurances: [],
    basicPropertyServices: [],
    customPropertyServices: [],
    newCustomPropertyServices: [],
    basicSafetyServices: [],
    customSafetyServices: [],
    newCustomSafetyServices: [],
  } as StoreEditDTO;
  const dispatch = useAppDispatch();
  const [newStoreData, setNewStoreData] = useState(initialState);
  const storesManagerService: StoresManagerService = new StoresManagerService();

  const { inputHandler } = useStateFormControlHandler(newStoreData, setNewStoreData);
  let {
    name,
    description,
    streetDescription,
    isHaveBusinessLicense,
    sizeInMeters,
    audiencePerDay,
    workingDays,
    contactName,
    contactPhoneNumber,
    contactEmail,
    newStreetImage,
    newDefaultImage,
    newFamousBrands,
    translates,
  } = newStoreData;

  const submitHandler = (e: any) => {
    e.preventDefault();
    storesManagerService
      .createStore(newStoreData)
      .execute()
      .then((data) => {
        if (data?.id) {
          dispatch(
            enqueueSnackbarAction({
              message: 'Store successfully created',
              options: { variant: 'success' },
            }),
          );
          resetFormState();
        }
      });
  };

  const loadedStreetImageHandler = (files: ImageEditDTO[]) => {
    setNewStoreData({
      ...newStoreData,
      newStreetImage: files[0],
    });
  };

  const loadedStoreImageHandler = (files: ImageEditDTO[]) => {
    setNewStoreData({
      ...newStoreData,
      newDefaultImage: files[0],
    });
  };

  const loadedFamousBrandsImageHandler = (files: ImageEditDTO[]) => {
    setNewStoreData({
      ...newStoreData,
      newFamousBrands: files,
    });
  };

  const changeAddressHandler = (place: any) => {
    const addressObj = createAddressDtoFromGoogleObj(place);
    setNewStoreData({ ...newStoreData, address: addressObj });
  };

  const changeWorkingDayHandler = (e: any) => {
    const dayIndex = parseInt(DAYS_OF_WEEK[e.target.name]);
    const isChecked = e.target.checked;

    //closeTimeHours on Friday (dayIndex 4) is 15PM
    let workingDaysArray = [
      ...workingDays,
      {
        day: dayIndex,
        openTimeHours: 9,
        openTimeMinutes: 0,
        closeTimeHours: dayIndex == 4 ? 15 : 19,
        closeTimeMinutes: 0,
      },
    ].filter((item) => (!isChecked ? dayIndex !== item.day : item));

    setNewStoreData({
      ...newStoreData,
      workingDays: workingDaysArray,
    });
  };

  const changeTranslationsHandler = (translations: StoreTranslateDTO[]) => {
    setNewStoreData({
      ...newStoreData,
      translates: translations,
    });
  };

  const resetFormState = () => {
    setNewStoreData(initialState);
  };

  return (
    <>
      <DashboardCaption>NEW STORE</DashboardCaption>

      <form>
        <DashboardBasicContainer maxWidthInPixels={'970px'}>
          <Grid container rowSpacing={10} columns={10} columnSpacing={15} sx={{ marginBottom: 20 }}>
            <Grid item xs={12} md={4}>
              <BasicInput
                id="store-name"
                value={name || ''}
                fullWidth
                inputProps={{
                  name: 'name',
                  placeholder: 'Store name',
                  onChange: inputHandler,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GoogleAutocompleteInput
                formattedAddress={newStoreData?.address?.formattedAddress}
                onPlaceSelectedHandler={changeAddressHandler}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BasicInput
                id="size-in-meter"
                value={sizeInMeters || ''}
                fullWidth
                inputProps={{
                  name: 'sizeInMeters',
                  type: 'number',
                  placeholder: 'Size (mts)',
                  onChange: inputHandler,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BasicInput
                id="audience-per-day"
                value={audiencePerDay || ''}
                fullWidth
                inputProps={{
                  name: 'audiencePerDay',
                  type: 'number',
                  placeholder: 'Audience (per day)',
                  onChange: inputHandler,
                }}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <BasicInput
                id="store-description"
                value={description || ''}
                fullWidth
                multiline={true}
                rows={11}
                inputProps={{
                  name: 'description',
                  placeholder: 'Store Description',
                  onChange: inputHandler,
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <PhotoUploader maxFiles={1} selectedFiles={newDefaultImage} setSelectedFiles={loadedStoreImageHandler}>
                <Typography>Store photo</Typography>
                <Typography>(upload)</Typography>
              </PhotoUploader>
            </Grid>

            <Grid item xs={12} md={8}>
              <BasicInput
                id="street-description"
                value={streetDescription || ''}
                fullWidth
                multiline={true}
                rows={11}
                inputProps={{
                  name: 'streetDescription',
                  placeholder: 'Street Description',
                  onChange: inputHandler,
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <PhotoUploader maxFiles={1} selectedFiles={newStreetImage} setSelectedFiles={loadedStreetImageHandler}>
                <Typography>Street photo</Typography>
                <Typography>(upload)</Typography>
              </PhotoUploader>
            </Grid>

            <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={12} md={10}>
              <Checkbox
                name={'isHaveBusinessLicense'}
                checked={!!isHaveBusinessLicense}
                label={<Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>Business license</Typography>}
                changeHandler={inputHandler}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 20 }}>
              <Typography variant="h3">Translations</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <DashboardStoreTranslationBox translations={translates} changeHandler={changeTranslationsHandler} />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 20 }}>
              <Typography variant="h3">Working days</Typography>
            </Grid>

            <WorkingDayCheckboxes workingDaysState={workingDays} changeWorkingDayHandler={changeWorkingDayHandler} />
          </Grid>
        </DashboardBasicContainer>

        <DashboardBasicContainer>
          <Grid container>
            <Grid item xs={12}>
              <Grid container rowSpacing={10} columnSpacing={15}>
                <Grid item xs={12} sx={{ marginTop: 10 }}>
                  <Typography variant="h3">Famous Brands from this Store</Typography>
                </Grid>
                <Grid item xs={12}>
                  <PhotoUploader
                    maxFiles={10}
                    selectedFiles={newFamousBrands}
                    setSelectedFiles={loadedFamousBrandsImageHandler}
                  >
                    <PlusIcon />
                  </PhotoUploader>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DashboardBasicContainer>

        <DashboardBasicContainer maxWidthInPixels={'970px'}>
          <Grid container rowSpacing={10} columns={10} columnSpacing={15} sx={{ maxWidth: '970px', marginBottom: 20 }}>
            <Grid item xs={12} sx={{ marginTop: 20 }}>
              <Typography variant="h3">Community manager</Typography>
            </Grid>
            <Grid item container rowSpacing={10} columnSpacing={15}>
              <Grid item xs={12} md={5}>
                <BasicInput
                  id="contact-name"
                  value={contactName || ''}
                  fullWidth
                  inputProps={{
                    name: 'contactName',
                    placeholder: 'Manager name',
                    onChange: inputHandler,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <BasicInput
                id="contact-phone-number"
                value={contactPhoneNumber || ''}
                fullWidth
                inputProps={{
                  name: 'contactPhoneNumber',
                  placeholder: 'Manager phone number',
                  onChange: inputHandler,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <BasicInput
                id="contact-email"
                value={contactEmail || ''}
                fullWidth
                inputProps={{
                  name: 'contactEmail',
                  type: 'email',
                  placeholder: 'Manager email',
                  onChange: inputHandler,
                }}
              />
            </Grid>
          </Grid>
        </DashboardBasicContainer>

        <DashboardBasicContainer>
          <Grid
            container
            justifyContent={{ xs: 'center', sm: 'end' }}
            rowSpacing={10}
            columnSpacing={15}
            sx={{ marginTop: 10 }}
          >
            <Grid item>
              <BasicButton
                onClick={() => {
                  resetFormState();
                }}
                className="btn-large"
                sx={{ minWidth: { xs: '140px', md: '170px' }, display: 'block' }}
              >
                Cancel
              </BasicButton>
            </Grid>

            <Grid item>
              <BasicButton
                className="btn-black btn-large"
                sx={{ minWidth: { xs: '140px', md: '170px' }, display: 'block' }}
                variant="contained"
                onClick={submitHandler}
              >
                <Typography variant="body1">NEXT</Typography>
              </BasicButton>
            </Grid>
          </Grid>
        </DashboardBasicContainer>
      </form>
    </>
  );
};
