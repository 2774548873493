import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import ImageEditDTO from '../models/image-edit-dto';
import PreCampaignDTO from '../models/pre-campaign/pre-campaign-dto';

export class PreCampaignBrandService {
  private readonly basePath: string = 'brand';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getPreCampaignById(id: string): ApiRequestWrapper<PreCampaignDTO> {
    return this.apiUrlProvider.get(this.basePath + `/precampaigns/${id}`);
  }

  public addBankAccountFile(id: string, file: { image: ImageEditDTO }): ApiRequestWrapper<PreCampaignDTO> {
    return this.apiUrlProvider.post(this.basePath + `/precampaigns/${id}/BankAccount`, file);
  }
}
