import React, { useState } from 'react';

import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Typography } from '@mui/material';

import { ReactComponent as ActionDirectionArrow } from '../../../../assets/icons/action-direction-arrow.svg';
import { isNotUndefinedOrNull } from '../../../helpers/utils';
import { actionDirectionArrowBox, selectLabelStyles, selectMenuStyles, StyledSelect } from './styles';

export function BasicSelect({
  id,
  value,
  name,
  placeholder = '',
  placeholderStyles = {},
  error = '',
  fullWidth = true,
  children,
  disabled = false,
  readOnly,
  onChange,
  noBorder = false,
  multiple = false,
  aheadIconUrl,
  className,
}: BasicSelectProps): JSX.Element {
  const MENU_TRANSITION_DELAY = 200;
  const [isOpen, setOpen] = useState(false);
  const [isSelectedValue, setIsSelectedValue] = useState(false);
  let closeWithDelay: any;

  const closeHandler = () => {
    if (closeWithDelay) {
      clearTimeout(closeWithDelay);
    }

    closeWithDelay = setTimeout(() => {
      setOpen(false);
    }, MENU_TRANSITION_DELAY);
  };

  const openHandler = () => {
    setOpen(true);
  };

  const changeHandler = (e: any) => {
    const targetValue = e.target.value;
    const isAvailableValue = Array.isArray(targetValue) ? !!targetValue.length : isNotUndefinedOrNull(targetValue);

    onChange(e);
    setIsSelectedValue(isAvailableValue);
  };

  return (
    <FormControl fullWidth={fullWidth} error={!!error}>
      {multiple && !isSelectedValue && (
        <InputLabel sx={selectLabelStyles} shrink={false}>
          {placeholder}
        </InputLabel>
      )}

      <StyledSelect
        multiple={multiple}
        displayEmpty
        className={`${isOpen ? 'open' : 'closed'} ${noBorder && 'no-border'} ${className}`}
        name={name}
        value={value}
        disabled={disabled}
        inputProps={{
          id,
          readOnly,
        }}
        MenuProps={{ transitionDuration: MENU_TRANSITION_DELAY, sx: selectMenuStyles }}
        onChange={changeHandler}
        onClose={closeHandler}
        onOpen={openHandler}
        IconComponent={() => <ActionDirectionArrowBox isDisabled={disabled} />}
        aheadIconUrl={aheadIconUrl}
      >
        <MenuItem sx={isOpen ? { display: 'none' } : { display: 'block' }} disabled value="">
          <Typography sx={{ ...placeholderStyles }}>{placeholder}</Typography>
        </MenuItem>

        {children}
      </StyledSelect>

      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
}

const ActionDirectionArrowBox = ({ isDisabled }: { isDisabled: boolean }): JSX.Element => {
  return (
    <Box className="arrow-box" sx={actionDirectionArrowBox}>
      <ActionDirectionArrow style={isDisabled ? { display: 'none' } : { flexShrink: 0 }} />
    </Box>
  );
};

interface BasicSelectProps {
  id?: string;
  value: string | number | Array<any>;
  name: string;
  placeholder?: string;
  placeholderStyles?: object;
  error?: string;
  fullWidth?: boolean;
  children?: JSX.Element | JSX.Element[] | (JSX.Element | undefined)[];
  disabled?: boolean;
  readOnly?: boolean;
  onChange: any;
  noBorder?: boolean;
  multiple?: boolean;
  aheadIconUrl?: string;
  className?: string;
}
