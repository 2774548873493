import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, Stack, Typography } from '@mui/material';

import { enqueueNotification as enqueueSnackbarAction } from '../../../../features/notifier/notifierSlice';
import { PreCampaignManagerService } from '../../../../services/pre-campaign-manager';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { BasicButton } from '../../basic/Button';
import { BasicInput } from '../../basic/Input';

export const SendBookingDialog = ({
  email,
  dialogProps = { open: false },
}: BookingRequestReadyDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [preCampaignUrl, setPreCampaignUrl] = useState('');
  const { onClose } = dialogProps;
  const preCampaignManagerService: PreCampaignManagerService = new PreCampaignManagerService();

  const submitEmailForm = () => {
    let preCampaignUrlObj = new URL(preCampaignUrl);
    let preCampaignParams = new URLSearchParams(preCampaignUrlObj.search);
    let preCampaignId = preCampaignParams.get('precampaign_id');

    if (preCampaignId && email) {
      preCampaignManagerService
        .sendBookingUrlToEmail(preCampaignId, email)
        .execute()
        .then(() => {
          dispatch(
            enqueueSnackbarAction({
              message: 'Email sent successfully',
              options: { variant: 'success' },
            }),
          );
          onClose && onClose();
        });
    }
  };

  useEffect(() => {
    !dialogProps.open && setPreCampaignUrl('');
  }, [dialogProps.open]);

  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <Stack
          sx={{
            maxWidth: '430px',
            margin: '0 auto',
            paddingY: 10,
            textAlign: 'center',
          }}
          alignItems="center"
        >
          <Typography sx={{ marginBottom: 6 }} variant="subtitle1">
            Send booking
          </Typography>

          <Typography sx={{ marginBottom: 15 }} variant="body1">
            Enter the booking URL to send it to the <br />
            <b>{email}</b>:
          </Typography>

          <BasicInput
            id="pre-campaign-url"
            value={preCampaignUrl}
            formControlProps={{ sx: { marginBottom: 15 } }}
            fullWidth
            inputProps={{
              name: 'preCampaignUrl',
              placeholder: 'Booking URL',
              onChange: (e: any) => setPreCampaignUrl(e.target.value),
            }}
          />
          <BasicButton className="btn-black" variant="contained" onClick={submitEmailForm}>
            Send
          </BasicButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

interface BookingRequestReadyDialogProps {
  dialogProps: {
    open: boolean;
    onClose?: () => void;
    fullWidth?: boolean;
    PaperProps?: object;
  };
  email: string;
}
