import { createSlice, current } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { LoaderDTO } from '../../models/ui/loader-dto';

const loaderState: LoaderDTO = {
  isLoadingData: false,
  loaderCount: 0,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: loaderState,
  reducers: {
    showLoader: (state) => {
      state.loaderCount++;
    },
    hideLoader: (state) => {
      state.loaderCount--;
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export const selectLoaderState = function (state: RootState): any {
  return state.loader;
};

export default loaderSlice.reducer;
