import React, { useEffect, useState } from 'react';

import { Avatar, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { ActionMenu } from 'common/components/ActionMenu';
import { BasicButton } from 'common/components/basic/Button';
import { BasicInput } from 'common/components/basic/Input';
import { DashboardBasicContainer } from 'common/components/dashboard/BasicContainer';
import { DashboardCaption } from 'common/components/dashboard/Caption';
import { SendBookingDialog } from 'common/components/Dialogs/SendBookingDialog';
import { findPartInAddressObjByType } from 'common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { useDebounce } from 'common/hooks/use-debounce';
import BrandDTO from 'models/brand/brand-dto';
import UserDTO from 'models/user/user-dto';
import { ImgService } from 'services/img-service';
import theme from 'theme-config/theme';

import {
  getBrandsByFilter,
  getBrandsBySearchValue,
  selectManagerDashboardState,
  setInitialStateForBrandsFilterResult,
} from '../managerDashboardSlice';

export const Brands = (): JSX.Element => {
  const initialPageState = { text: '', itemsOnPage: 10, page: 1 };
  const initialBookingDialogState = { email: '', isShown: false };
  const dispatch = useAppDispatch();
  const { brandsFilterResult } = useAppSelector(selectManagerDashboardState);
  const [pageState, setPageState] = useState(initialPageState);
  const [bookingDialogState, setBookingDialogState] = useState(initialBookingDialogState);
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const debouncedSearchValue = useDebounce(pageState.text, 400);

  const imgService: ImgService = new ImgService();

  const changeSearchBrandInputHandler = (e: any) => {
    setPageState({ ...pageState, text: e.target.value, page: 1 });
  };

  const clickOnViewMoreBtnHandler = () => {
    const nextPage = pageState.page + 1;
    const newPageState = { ...pageState, page: nextPage };
    setPageState(newPageState);
    dispatch(getBrandsByFilter(newPageState));
  };

  useEffect(() => {
    return () => {
      dispatch(setInitialStateForBrandsFilterResult());
    };
  }, []);

  useEffect(() => {
    dispatch(getBrandsBySearchValue(pageState));
  }, [debouncedSearchValue]);

  return (
    <>
      <DashboardCaption>Brands</DashboardCaption>

      <DashboardBasicContainer>
        <Grid container rowSpacing={2} columnSpacing={15} sx={{ marginBottom: 20 }}>
          <Grid item xs={6}>
            <BasicInput
              id="brand-search"
              value={pageState.text}
              formControlProps={{ sx: { marginBottom: 8 } }}
              fullWidth
              inputProps={{
                name: 'brandSearch',
                placeholder: 'Search Brand',
                onChange: changeSearchBrandInputHandler,
              }}
            />
          </Grid>
          {brandsFilterResult?.searchPage?.items.map((brand: BrandDTO, index: number) => {
            const avatarUrl =
              brand?.logo &&
              imgService.getImgPreviewUrl(brand.logo, {
                width: 100,
                height: 100,
              });
            const user = brandsFilterResult?.users?.find((user: UserDTO) => user.id === brand.userId);
            const brandCity = brand?.address?.parts
              ? findPartInAddressObjByType(brand.address.parts, 'locality')?.name
              : '';

            return (
              <Grid key={index} item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    padding: { xs: '12px 10px', md: '15px 20px' },
                    border: 'solid 1px black',
                    borderRadius: '20px',
                  }}
                >
                  <Grid container columns={16} columnSpacing={{ xs: 10, md: 15 }} rowSpacing={5} alignItems="center">
                    <Grid item xs={14} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{
                          width: '50px',
                          height: '50px',
                          marginRight: { xs: 5, md: 15 },
                          border: 'solid 2px white',
                        }}
                        src={avatarUrl}
                        alt="user avatars"
                      />

                      <Typography variant="subtitle2">{brand?.name}</Typography>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ marginLeft: { xs: 30, md: 0 } }}>
                      <Typography variant="body1">{user?.email}</Typography>
                    </Grid>

                    <Grid item xs={12} md={3} sx={{ marginLeft: { xs: 30, md: 0 } }}>
                      <Typography variant="body1">{user?.phoneNumber}</Typography>
                    </Grid>

                    <Grid item xs={12} md={3} sx={{ marginLeft: { xs: 30, md: 0 } }}>
                      <Typography variant="body1">{brandCity}</Typography>
                    </Grid>

                    {isUpMdBreakPoint && (
                      <Grid item xs={12} md={1} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
                        <ActionMenu
                          items={[
                            {
                              title: 'Send a Booking',
                              action: () => {
                                setBookingDialogState({ email: user?.email, isShown: true });
                              },
                            },
                          ]}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!isUpMdBreakPoint && (
                    <ActionMenu
                      items={[
                        {
                          title: 'Send a Booking',
                          action: () => {
                            setBookingDialogState({ email: user?.email, isShown: true });
                          },
                        },
                      ]}
                    />
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <BasicButton
              className="btn-large btn-yellow"
              variant="contained"
              onClick={clickOnViewMoreBtnHandler}
              disabled={
                brandsFilterResult?.searchPage?.items
                  ? brandsFilterResult.searchPage.items.length >= brandsFilterResult.searchPage.totalCount
                  : true
              }
            >
              <Typography variant="body1">View more</Typography>
            </BasicButton>
          </Grid>
        </Grid>
      </DashboardBasicContainer>
      <SendBookingDialog
        email={bookingDialogState.email}
        dialogProps={{
          open: bookingDialogState.isShown,
          onClose: () => {
            setBookingDialogState({ email: '', isShown: false });
          },
          fullWidth: true,
          PaperProps: { sx: { maxWidth: '570px' } },
        }}
      />
    </>
  );
};
