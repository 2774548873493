import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

import { BasicButton } from '../../../common/components/basic/Button';
import { BasicInput } from '../../../common/components/basic/Input';
import { Rating } from '../../../common/components/Rating';
import { findPartInAddressObjByType } from '../../../common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { useStateFormControlHandler } from '../../../common/hooks/use-state-from-control-handler';
import CampaignDTO from '../../../models/campaign/campaign-dto';
import FeedbackEditDTO from '../../../models/feedback/feedback-edit-dto';
import SpotDTO from '../../../models/spot/spot-dto';
import StoreDTO from '../../../models/store/store-dto';
import { FeedbackBrandService } from '../../../services/feedback-brand';
import { hideRightNavDrawer } from '../../drawer/drawerSlice';
import { selectBrandDashboardState } from '../brandDashboardSlice';

export const PastSpotAdditionalInfo = ({ campaign }: { campaign: CampaignDTO | undefined }): JSX.Element => {
  const dispatch = useAppDispatch();
  const campaignId = campaign?.id;
  const spotId = campaign?.spotId;
  const initialState = {
    spotId: spotId ? spotId : '',
    campaignId: campaignId ? campaignId : '',
  } as FeedbackEditDTO;
  const { allSpots, allStores } = useAppSelector(selectBrandDashboardState);
  const selectedSpot = allSpots?.find((spot: SpotDTO) => spot.id === spotId);
  const selectedStore = allStores?.find((store: StoreDTO) => store.id === selectedSpot?.storeId);
  const [feedbackFormState, setFeedbackFormState] = useState<FeedbackEditDTO>(initialState);
  const { inputHandler } = useStateFormControlHandler(feedbackFormState, setFeedbackFormState);

  const feedbackBrandService: FeedbackBrandService = new FeedbackBrandService();

  const { cleanness, customerSupport, overallExperience, description } = feedbackFormState;
  const spotStreetName =
    selectedStore?.address?.parts && findPartInAddressObjByType(selectedStore.address.parts, 'locality')?.name;

  const clickOnSendFeedbackBtnHandler = () => {
    feedbackBrandService
      .createFeedback(feedbackFormState)
      .execute()
      .then((data) => {
        dispatch(hideRightNavDrawer());
      });
  };

  return (
    <Box sx={{ marginTop: 15 }}>
      <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
        {selectedSpot?.name}
      </Typography>
      {spotStreetName && (
        <Typography variant="subtitle2" sx={{ marginBottom: 15 }}>
          {spotStreetName}
        </Typography>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 5, paddingBottom: { xs: 7, md: 5 } }}>
          <Typography variant="h3" sx={{ typography: { xs: 'h5', md: 'h3' }, mr: 4 }}>
            From:
          </Typography>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            {campaign?.startDate && format(new Date(campaign?.startDate), 'dd/MM/yyyy')}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: { xs: 12, md: 5 } }}>
          <Typography variant="h3" sx={{ typography: { xs: 'h5', md: 'h3' }, mr: 4 }}>
            To:
          </Typography>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            {campaign?.endDate && format(new Date(campaign?.endDate), 'dd/MM/yyyy')}{' '}
          </Typography>
        </Box>
      </Box>

      <Typography variant="h3" sx={{ marginBottom: 5 }}>
        Your feedback
      </Typography>

      <Grid container rowSpacing={10} columnSpacing={15} sx={{ marginBottom: 20 }}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Cleanness
          </Typography>
          <Rating
            name="feedback-score-controlled"
            value={cleanness || null}
            onChange={(event, newValue) =>
              setFeedbackFormState({
                ...feedbackFormState,
                cleanness: newValue,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            REPOP Customer support
          </Typography>
          <Rating
            name="feedback-score-controlled"
            value={customerSupport || null}
            onChange={(event, newValue) =>
              setFeedbackFormState({
                ...feedbackFormState,
                customerSupport: newValue,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Overall experience
          </Typography>
          <Rating
            name="feedback-score-controlled"
            value={overallExperience || null}
            onChange={(event, newValue) =>
              setFeedbackFormState({
                ...feedbackFormState,
                overallExperience: newValue,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="body1" sx={{ marginTop: 10, marginBottom: 5 }}>
            We would love to hear what you have to say about your experience with us!
          </Typography>
          <BasicInput
            id="feedback-description"
            value={description || ''}
            fullWidth
            multiline={true}
            rows={7}
            inputProps={{
              name: 'description',
              placeholder: 'Write your Review here',
              onChange: inputHandler,
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <BasicButton
            className="btn-small"
            sx={{ minWidth: '100px' }}
            variant="outlined"
            type="button"
            onClick={clickOnSendFeedbackBtnHandler}
          >
            SEND
          </BasicButton>
        </Grid>
      </Grid>
    </Box>
  );
};
