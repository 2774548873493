import React from 'react';

import { List } from '@mui/material';

import { markedListStyles } from './styles';

export const MarkedList = ({ children }: MarkedListProps): JSX.Element => {
  return <List sx={markedListStyles}>{children}</List>;
};

interface MarkedListProps {
  children: JSX.Element | JSX.Element[];
  listProps?: object;
}
