import React from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Header } from '../Header';
import { LeftNavDrawer } from '../LeftNavDrawer';

export const MainTemplate = (): JSX.Element => {
  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <LeftNavDrawer />
        <Box component="main">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
