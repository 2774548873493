import React from 'react';

import { Grid } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';

import { addNewCreditCard, setStripeIframeShowStatus } from '../../../features/brand-payments/brandPaymentsSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../../../features/notifier/notifierSlice';
import { PaymentsBrandService } from '../../../services/payments-brand';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { BasicButton } from '../basic/Button';
import { StyledStripeElementBlock } from './styles';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: 'black',
      fontWeight: '500',
      fontFamily: "'Blatant', sans-serif",
      fontSize: '14px',
      '::placeholder': {
        color: 'black',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
} as StripeCardElementOptions;

export const StripePaymentForm = (): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const paymentsBrandService: PaymentsBrandService = new PaymentsBrandService();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement('cardNumber');

    if (cardElement) {
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then(function (result) {
          result?.paymentMethod?.id &&
            paymentsBrandService
              .addStripePaymentMethod(result.paymentMethod.id)
              .execute()
              .then((data) => {
                dispatch(
                  enqueueSnackbarAction({
                    message: 'Credit card successfully added',
                    options: { variant: 'success' },
                  }),
                );
                elements.getElement('cardNumber')?.clear();
                elements.getElement('cardExpiry')?.clear();
                elements.getElement('cardCvc')?.clear();
                dispatch(setStripeIframeShowStatus(false));
                dispatch(addNewCreditCard(data));
              });
        });
    }
  };

  return (
    <form>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <StyledStripeElementBlock>
            <CardNumberElement
              options={{
                iconStyle: 'default',
                placeholder: 'Credit card number',
                ...CARD_ELEMENT_OPTIONS,
              }}
            ></CardNumberElement>
          </StyledStripeElementBlock>
        </Grid>
        <Grid item xs={6}>
          <StyledStripeElementBlock>
            <CardExpiryElement
              options={{ placeholder: 'Expiry date (MM/YY)', ...CARD_ELEMENT_OPTIONS }}
            ></CardExpiryElement>
          </StyledStripeElementBlock>
        </Grid>
        <Grid item xs={6}>
          <StyledStripeElementBlock>
            <CardCvcElement options={{ placeholder: 'CVV', ...CARD_ELEMENT_OPTIONS }}></CardCvcElement>
          </StyledStripeElementBlock>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: { xs: 'center', md: 'right', marginTop: 10 } }}>
          <BasicButton
            className="btn-large"
            sx={{ minWidth: '165px' }}
            variant="outlined"
            onClick={handleSubmit}
            disabled={!stripe}
          >
            Add credit card
          </BasicButton>
        </Grid>
      </Grid>
    </form>
  );
};
