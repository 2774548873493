import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSingleNavigationBox = styled(Box)(({ theme }) => ({
  width: '12px',
  height: '60px',
  position: 'absolute',
  right: '0',
  bottom: '0',
  borderRadius: '25px',
  backgroundColor: theme.palette.common.white,
  cursor: 'pointer',

  [theme.breakpoints.up('sm')]: {
    width: '46px',
    height: '46px',
  },

  [theme.breakpoints.up('md')]: {
    width: '50px',
    height: '50px',
  },

  svg: {
    position: 'relative',
    top: '50%',
    left: '50%',
    fill: 'none',
    transform: 'rotate(270deg) translate(calc(-50% + 24px), -50%)',
  },
}));
