import AddressDTO from '../../models/address/address-dto';
import AddressPartDTO from '../../models/address/address-part-dto';

export const createAddressDtoFromGoogleObj = (place: any): AddressDTO => {
  let country = place.address_components.find((x: any) => x.types.indexOf('country') != -1);
  let city = place.address_components.find((x: any) => x.types.indexOf('locality') != -1);
  let addressObj = {} as AddressDTO;

  addressObj.shortAddress =
    city?.long_name && country?.long_name ? city.long_name + ', ' + country.long_name : country.long_name;
  addressObj.formattedAddress = place.formatted_address;
  addressObj.lat = place.geometry.location.lat();
  addressObj.lng = place.geometry.location.lng();
  addressObj.parts = [];

  for (let i = 0; i < place.address_components.length; i++) {
    let address_component = place.address_components[i];
    let type = address_component.types.find((x: any) => x != 'political');
    let obj = {
      name: address_component.long_name,
      type: type,
    };
    addressObj.parts.push(obj);
  }

  return addressObj;
};

export function findPartInAddressObjByType(addressObj: Array<AddressPartDTO>, type: string): AddressPartDTO | null {
  let part = null;

  addressObj.some((partItem) => {
    if (type === partItem.type) {
      part = partItem;
      return true;
    }
  });

  return part;
}
