import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import { API_URL } from '../constants';
import { CURRENCY } from '../constants/enums/currency';
import UserDTO from '../models/user/user-auth-dto';
import UserEditDTO from '../models/user/user-edit-dto';

export default class AuthService {
  baseURL: string;

  constructor() {
    this.baseURL = `${API_URL}/user`;
  }

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getCurrentUserByToken(): ApiRequestWrapper<UserDTO> {
    return this.apiUrlProvider.get(this.baseURL + '/current');
  }

  public updateCurrentUser(userEditData: UserEditDTO): ApiRequestWrapper<UserDTO> {
    return this.apiUrlProvider.put(this.baseURL + '/current', userEditData);
  }

  public changeCurrency(currency: CURRENCY): ApiRequestWrapper<UserDTO> {
    return this.apiUrlProvider.put(this.baseURL + `/current/change-currency?currency=${currency}`, {});
  }
}
