import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { GridSpacing } from '@mui/material/Grid/Grid';
import { ResponsiveStyleValue } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { getFurniture, selectFurnitureState } from 'features/furniture/furnitureSlice';
import FurnituresDTO from 'models/furnitures-dto';
import SpotFurnitureDTO from 'models/spot/spot-furniture-dto';
import { ImgService } from 'services/img-service';

export const FurnitureList = ({
  spotFurnitureItems,
  gridSpacing = { xs: 5, lg: 15 },
}: {
  spotFurnitureItems: SpotFurnitureDTO[] | undefined;
  gridSpacing?: ResponsiveStyleValue<GridSpacing>;
}): JSX.Element => {
  const { furnitureList } = useAppSelector(selectFurnitureState);
  const dispatch = useAppDispatch();

  const imgService: ImgService = new ImgService();

  useEffect(() => {
    !furnitureList.length && dispatch(getFurniture());
  }, []);

  return (
    <Grid item container spacing={gridSpacing}>
      {spotFurnitureItems?.map((spotFurnitureItem: SpotFurnitureDTO, index: number) => {
        return furnitureList?.map((furniture: FurnituresDTO) => {
          if (furniture.id === spotFurnitureItem.furnitureId) {
            const furnitureImg = imgService.getImgPreviewUrl(furniture.image, { width: 100, height: 100 });

            return (
              <Grid key={index} item xs={2} sx={{ textAlign: 'center' }}>
                <Box sx={{ height: { xs: '20px', md: '40px' }, marginBottom: 3 }}>
                  <img src={furnitureImg} alt={`furniture-${furniture.name}`} style={{ height: 'inherit' }} />
                </Box>

                <Typography variant="h3" sx={{ typography: { xs: 'body1', md: 'subtitle1' } }}>
                  {spotFurnitureItem.count}
                </Typography>

                <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                  {furniture.name}
                </Typography>
              </Grid>
            );
          }
        });
      })}
    </Grid>
  );
};
