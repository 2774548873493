import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as OLetter } from '../../../assets/icons/letters/o-letter.svg';
import { BoxWithBackground } from '../../../common/components/BoxWithBackground';
import { DashboardBasicContainer } from '../../../common/components/dashboard/BasicContainer';
import { DashboardCaption } from '../../../common/components/dashboard/Caption';
import { DashboardRightDrawer } from '../../../common/components/dashboard/RightDrawer';
import { StoreCard } from '../../../common/components/StoreCard';
import { getDaysBetweenTwoDates } from '../../../common/helpers/date-heplers';
import { getFormattedDatesRange } from '../../../common/helpers/formatters';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import CampaignDTO from '../../../models/campaign/campaign-dto';
import SpotItemDTO from '../../../models/spot/spot-item-dto';
import { SpotsList } from '../../booking/SpotsList';
import { openRightNavDrawer } from '../../drawer/drawerSlice';
import {
  getAllSpots,
  getAllStores,
  getBrandCampaigns,
  getNewestSpots,
  selectBrandDashboardState,
} from '../brandDashboardSlice';
import { CampaignAdditionalInfo } from '../campaign-additional-info';

export const Home = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { upcomingCampaigns, activeCampaigns, newestSpots } = useAppSelector(selectBrandDashboardState);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignDTO>();

  const clickOnBookedSpotHandler = (selectedCampaign: CampaignDTO) => {
    setSelectedCampaign(selectedCampaign);
    dispatch(openRightNavDrawer('CampaignAdditionalInfo'));
  };

  const clickOnNewestStoreCardHandler = (spotId: number) => {
    navigate(`/booking/spots/${spotId}`);
  };

  useEffect(() => {
    dispatch(getBrandCampaigns());
    dispatch(getNewestSpots(3));
    dispatch(getAllSpots());
    dispatch(getAllStores());
  }, []);

  return (
    <>
      <DashboardCaption>
        H<OLetter />
        me
      </DashboardCaption>

      <DashboardBasicContainer>
        {upcomingCampaigns?.length ? (
          <>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              UPCOMING
            </Typography>
            <Grid container rowSpacing={10} columnSpacing={{ xs: 10, md: 15 }} sx={{ marginBottom: 10 }}>
              {upcomingCampaigns?.map((campaign: CampaignDTO, index: number) => {
                const daysToStart = getDaysBetweenTwoDates(new Date(), new Date(campaign.startDate));

                return (
                  <Grid key={index} item xs={12} md={6}>
                    <BoxWithBackground
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderRadius: '20px',
                        padding: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => clickOnBookedSpotHandler(campaign)}
                    >
                      <Box>
                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          REPOP at
                        </Typography>
                        <Typography variant="subtitle2" sx={{ marginBottom: 15 }}>
                          {campaign.name}
                        </Typography>

                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          Dates
                        </Typography>
                        <Typography variant="subtitle2">
                          {getFormattedDatesRange(new Date(campaign.startDate), new Date(campaign.endDate))}
                        </Typography>
                      </Box>

                      {daysToStart >= 1 ? (
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography variant="h5" sx={{ color: '#808080' }}>
                            Countdown
                          </Typography>
                          <Typography variant="subtitle1">{daysToStart}</Typography>
                          <Typography variant="h5" sx={{ color: '#808080' }}>
                            days
                          </Typography>
                        </Box>
                      ) : null}
                    </BoxWithBackground>
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}

        {activeCampaigns?.length ? (
          <>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              ACTIVE
            </Typography>
            <Grid container rowSpacing={10} columnSpacing={{ xs: 10, md: 15 }} sx={{ marginBottom: 10 }}>
              {activeCampaigns?.map((campaign: CampaignDTO, index: number) => {
                const leftDays = getDaysBetweenTwoDates(new Date(), new Date(campaign.endDate));

                return (
                  <Grid key={index} item xs={12} md={6}>
                    <BoxWithBackground
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderRadius: '20px',
                        padding: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => clickOnBookedSpotHandler(campaign)}
                    >
                      <Box>
                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          REPOP at
                        </Typography>
                        <Typography variant="subtitle2" sx={{ marginBottom: 15 }}>
                          {campaign.name}
                        </Typography>

                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          Dates
                        </Typography>
                        <Typography variant="subtitle2">
                          {getFormattedDatesRange(new Date(campaign.startDate), new Date(campaign.endDate))}
                        </Typography>
                      </Box>

                      {leftDays >= 1 ? (
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography variant="h5" sx={{ color: '#808080' }}>
                            Left
                          </Typography>
                          <Typography variant="subtitle1">{leftDays}</Typography>
                          <Typography variant="h5" sx={{ color: '#808080' }}>
                            days
                          </Typography>
                        </Box>
                      ) : null}
                    </BoxWithBackground>
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}

        <SpotsList isDashboardSpotList={true}></SpotsList>

        {newestSpots?.length ? (
          <>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              NEWEST STORES
            </Typography>
            <Grid container rowSpacing={10} columnSpacing={{ xs: 10, md: 15 }}>
              {newestSpots?.map((item: SpotItemDTO, index: number) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <StoreCard
                      className="square-view"
                      name={item.storeName}
                      spotPrice={item.spotPrice}
                      spotCurrency={item.spotCurrency}
                      shortAddress={item.shortAddress}
                      sizeInMeters={item.sizeInMeters}
                      imageId={item.storeImage}
                      clickHandler={() => clickOnNewestStoreCardHandler(item.spotId)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}
      </DashboardBasicContainer>

      <DashboardRightDrawer
        contentElements={[
          <CampaignAdditionalInfo data-name={'CampaignAdditionalInfo'} selectedCampaign={selectedCampaign} />,
        ]}
      />
    </>
  );
};
