import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { AddNewCreditCardBlock } from '../../../common/components/AddNewCreditCardBlock';
import { DefaultCreditCardBlock } from '../../../common/components/DefaultCreditCardBlock';
import { StripeElement } from '../../../common/components/StripeElement';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import PaymentMethodDTO from '../../../models/payment/payment-method-dto';
import { getAvailablePaymentMethods, selectBrandPaymentsState, setStripeIframeShowStatus } from '../brandPaymentsSlice';

export const Stripe = ({
  stripeKey,
  depositAmount,
  totalAmount,
}: {
  stripeKey: string;
  depositAmount?: string;
  totalAmount?: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { availablePaymentMethods, shownStripeIframe } = useAppSelector(selectBrandPaymentsState);
  const defaultCreditCard = availablePaymentMethods?.find((paymentMethod: PaymentMethodDTO) => paymentMethod.isDefault);

  const stripeElement = (
    <Grid item xs={12} sx={{ marginTop: 10 }}>
      <StripeElement stripeKey={stripeKey}></StripeElement>
    </Grid>
  );

  useEffect(() => {
    !availablePaymentMethods?.length && dispatch(getAvailablePaymentMethods());
    dispatch(setStripeIframeShowStatus(false));
  }, []);

  return (
    <Grid item container sx={{ marginBottom: 20 }}>
      <Grid item xs={12}>
        {(depositAmount || totalAmount) && (
          <Box
            sx={{
              width: '100%',
              maxWidth: '370px',
              marginTop: 8,
              marginBottom: 20,
              marginX: 'auto',
              paddingX: 15,
              paddingY: 8,
              borderRadius: '10px',
              backgroundColor: (theme: Theme) => theme.palette.common.black,
              color: (theme: Theme) => theme.palette.common.white,
            }}
          >
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              <Typography variant="caption" sx={{ typography: { xs: 'h5', md: 'h3' } }}>
                {depositAmount && 'Pay now'}
                {totalAmount && 'Total Price:'}
              </Typography>{' '}
              {depositAmount && (
                <Typography variant="caption" sx={{ typography: { xs: 'body1', md: 'subtitle2' } }}>
                  (deposit fee):
                </Typography>
              )}{' '}
              <Typography variant="caption" sx={{ typography: { xs: 'h5', md: 'h3' } }}>
                {depositAmount && depositAmount}
                {totalAmount && totalAmount}
              </Typography>
            </Typography>
          </Box>
        )}
      </Grid>

      <Grid item container spacing={10}>
        {defaultCreditCard?.id && (
          <Grid item xs={12} md={6}>
            <DefaultCreditCardBlock
              type={defaultCreditCard?.stripeData?.type || ''}
              lastDigit={defaultCreditCard?.stripeData?.lastDigit || defaultCreditCard?.payPlusData?.lastDigit}
              expiredDate={defaultCreditCard?.stripeData?.expriedAt || defaultCreditCard?.payPlusData?.expriedAt}
            />
          </Grid>
        )}
        {!shownStripeIframe && (
          <Grid item xs={12} md={6}>
            <AddNewCreditCardBlock
              title={'Add new Stripe credit card'}
              showIframeAction={() => {
                dispatch(setStripeIframeShowStatus(true));
              }}
            />
          </Grid>
        )}
      </Grid>

      {shownStripeIframe && stripeElement}
    </Grid>
  );
};
