import { USER_ROLES } from '../../constants/enums/user-roles';
import { selectAuthState } from '../../features/authentication/authSlice';
import { useAppSelector } from './redux-hooks';

export const useUserRole = (expectedUserRole: USER_ROLES): boolean => {
  const userState = useAppSelector(selectAuthState);
  const authorizedUserRole = userState.authorizedUser?.role;

  return authorizedUserRole === expectedUserRole.valueOf();
};
