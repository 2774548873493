import { Theme } from '@mui/material/styles';

export const markedListStyles = (theme: Theme) =>
  ({
    '&': {
      p: 0,
    },
    '.MuiListItem-root': {
      alignItems: 'start',
      pl: 3,
      pr: 0,
    },
    '.MuiListItem-root:before': {
      content: '"•"',
      fontSize: '20px',
      mr: '10px',
    },
    '.MuiListItemText-root': {
      m: 0,
    },
  } as const);
