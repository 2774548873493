import { useEffect } from 'react';

import { OptionsObject, useSnackbar } from 'notistack';

import { useAppDispatch, useAppSelector } from '../../common/hooks/redux-hooks';
import { removeNotification as removeNotificationAction, selectNotifierState } from './notifierSlice';
import './notifier-styles.scss';

let displayed: Array<any> = [];

interface Notification {
  key: number | string;
  message: string;
  options: {
    variant?: 'success' | 'error' | 'warning' | 'info';
    onClose?: (event: any, reason: string, key: number | string) => void;
  };
  dismissed?: boolean;
}

export const Notifier = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch();
  const notifierState = useAppSelector(selectNotifierState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: number | string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: number) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifierState.notifications.forEach(({ key, message, options, dismissed }: Notification) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return;
      }

      // display snackbar using notistack
      enqueueSnackbar(message, <OptionsObject>{
        key,
        ...options,
        onClose: (event: any, reason: any, key: any) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
        },
        onExited: (node: HTMLElement, key: number) => {
          // remove this snackbar from redux store
          dispatch(removeNotificationAction(key));
          removeDisplayed(key);
        },
      });
      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifierState.notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return children;
};
