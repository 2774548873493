import createPalette from '@mui/material/styles/createPalette';

export const defaultPalette = createPalette({
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: {
    main: '#000000',
  },
  secondary: {
    main: '#FFFF00',
  },
  text: {
    primary: '#000000',
  },
});
