import React from 'react';

import { Box, Link, ListItem, SvgIcon, Typography } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';

import { ReactComponent as LogOutIcon } from '../../../assets/icons/side-nav/logout.svg';
import { USER_ROLES } from '../../../constants/enums/user-roles';
import { logOut, selectAuthState } from '../../../features/authentication/authSlice';
import { hideLeftNavDrawer, selectDrawerState } from '../../../features/drawer/drawerSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useUserRole } from '../../hooks/use-user-role';
import { BasicButton } from '../basic/Button';
import { DrawerList } from '../DrawerList/DrawerList';
import { StyledLeftDrawer } from './styles';

export const LeftNavDrawer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authorizedUser } = useAppSelector(selectAuthState);
  const drawerState = useAppSelector(selectDrawerState);
  const isAdminRole = useUserRole(USER_ROLES.Admin);
  const isRetailRole = useUserRole(USER_ROLES.Retail);
  const dashboardRoute = isAdminRole || isRetailRole ? '/dashboard/manager' : '/dashboard/brand';

  const handleDrawerToggle = () => {
    dispatch(hideLeftNavDrawer());
  };

  const handleClickOnTheLogOutItem = () => {
    dispatch(logOut());
  };

  return (
    <Box component="nav">
      <StyledLeftDrawer
        className="mobile-left-drawer"
        variant="temporary"
        open={drawerState.isOpenLeftDrawer}
        onClose={handleDrawerToggle}
      >
        <DrawerList>
          <ListItem className="dashboard-list-item">
            <BasicButton
              className="btn-yellow btn-small"
              sx={{ minWidth: '170px' }}
              variant="contained"
              onClick={() => navigate(authorizedUser?.token ? dashboardRoute : '/sign-up')}
            >
              <Typography variant="subtitle2">{authorizedUser?.token ? 'My Dashboard' : 'Sign-up'}</Typography>
            </BasicButton>
          </ListItem>

          <ListItem>
            <NavLink className="nav-link" to="/booking/spots" end>
              Our Locations
            </NavLink>
          </ListItem>

          {authorizedUser?.token && (
            <>
              <ListItem>
                <Box className="nav-link nav-link--log-out" onClick={handleClickOnTheLogOutItem}>
                  <Box className="icon-box">
                    <SvgIcon component={LogOutIcon} viewBox={'0 0 20 20'} />
                  </Box>
                  <Typography className="link-text" variant="subtitle2">
                    Log out
                  </Typography>
                </Box>
              </ListItem>
            </>
          )}

          <ListItem className="contact-us-list-item">
            <Typography variant="body1" sx={{ marginBottom: 6 }}>
              Contact us:
            </Typography>

            <Box>
              <Link className="phone-link" href="https://wa.me/+972548200202" target="_blank" />
              <Link className="email-link" href="mailto:hello@repop.shop" />
            </Box>
          </ListItem>
        </DrawerList>
      </StyledLeftDrawer>
    </Box>
  );
};
