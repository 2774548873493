import ImageEditDTO from '../../models/image-edit-dto';

export function createImageDto(selectedFileInBase64: string, isDefault = false): ImageEditDTO {
  let ext = selectedFileInBase64.substring('data:image/'.length, selectedFileInBase64.indexOf(';base64'));

  return {
    base64: selectedFileInBase64,
    extension: ext ? ext : '',
    default: isDefault,
  };
}
