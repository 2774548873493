import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import PaymentMethodDTO from '../models/payment/payment-method-dto';

export class PaymentsBrandService {
  private readonly basePath: string = 'brand/PaymentMethods';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getAvailablePaymentMethods(): ApiRequestWrapper<Array<PaymentMethodDTO>> {
    return this.apiUrlProvider.get(this.basePath);
  }

  public getPaymentMethodById(paymentMethodId: string): ApiRequestWrapper<PaymentMethodDTO> {
    return this.apiUrlProvider.get(this.basePath + `/${paymentMethodId}`);
  }

  public setDefaultPaymentMethod(paymentMethodId: number): ApiRequestWrapper<boolean> {
    return this.apiUrlProvider.put(this.basePath + `/default/${paymentMethodId}`, {});
  }

  public deletePaymentMethodById(paymentMethodId: number): ApiRequestWrapper<boolean> {
    return this.apiUrlProvider.delete(this.basePath + `/${paymentMethodId}`);
  }

  public addStripePaymentMethod(paymentMethodId: string): ApiRequestWrapper<PaymentMethodDTO> {
    return this.apiUrlProvider.post(this.basePath + `/stripe?paymentMethodId=${paymentMethodId}`, {});
  }

  public getPayPlusIframeLink(): ApiRequestWrapper<{ link: string }> {
    return this.apiUrlProvider.get(this.basePath + '/payplus/iframe');
  }

  public getStripeKey(): ApiRequestWrapper<{ PublishableKey: string }> {
    return this.apiUrlProvider.get(this.basePath + '/stripe/key');
  }
}
