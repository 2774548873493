import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import CampaignDTO from '../../models/campaign/campaign-dto';
import CampaignEditDTO from '../../models/campaign/campaign-edit-dto';
import { CampaignBrandService } from '../../services/campaigns-brand';

const campaignBrandService = new CampaignBrandService();

export const createCampaign = createAsyncThunk('createCampaign', async (obj: CampaignEditDTO) => {
  return await campaignBrandService.createCampaign(obj).execute();
});

export const brandCampaignsSlice = createSlice({
  name: 'brandCampaigns',
  initialState: {} as CampaignDTO,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      if (action.payload) {
        // state = { ...action.payload };
      }
    });
  },
});

export const selectBrandCampaignsSlice = function (state: RootState): any {
  return state.brandCampaigns;
};

export default brandCampaignsSlice.reducer;
