const GOOGLE_CLIENT_ID = window.spaSettings.googleClientId;
const FACEBOOK_CLIENT_ID = window.spaSettings.facebookClientId;

export function getLinkForGoogleLogIn() {
  let redirectUri = window.origin + '/externalauth/log-in?type=sign-in&provider=google&';
  return `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20email%20profile&include_granted_scopes=true&state=state_parameter_passthrough_value&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=token&client_id=${GOOGLE_CLIENT_ID}`;
}

export function getLinkForFacebookLogIn() {
  let redirectUri = window.origin + '/externalauth/log-in?type=sign-in&provider=facebook&';
  return `https://www.facebook.com/v2.11/dialog/oauth?&response_type=token&display=popup&client_id=${FACEBOOK_CLIENT_ID}&display=popup&redirect_uri=${encodeURIComponent(redirectUri)}&scope=email`;
}
