import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import FeedbackEditManagerDTO from '../models/feedback/feedback-edit-manager-dto';
import FeedbackManagerDTO from '../models/feedback/feedback-manager-dto';

export class FeedbackManagerService {
  private readonly basePath: string = 'manager/feedbacks';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public createFeedback(feedback: FeedbackEditManagerDTO): ApiRequestWrapper<FeedbackManagerDTO> {
    return this.apiUrlProvider.post(this.basePath, feedback);
  }

  public setFeedbackActivity(id: string, activity: boolean): ApiRequestWrapper<FeedbackManagerDTO> {
    return this.apiUrlProvider.put(this.basePath + `/${id}?isEnabled=${activity}`, {});
  }

  public deleteFeedbackById(id: string) {
    return this.apiUrlProvider.delete(this.basePath + `/${id}`);
  }
}
