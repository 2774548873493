import React, { useEffect, useState } from 'react';

import { Avatar, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';

import { ActionMenu } from '../../../../common/components/ActionMenu';
import { BasicButton } from '../../../../common/components/basic/Button';
import { DashboardRightDrawer } from '../../../../common/components/dashboard/RightDrawer';
import { RejectReasonDialog } from '../../../../common/components/Dialogs/RejectReason/RejectReasonDialog';
import { getFormattedDatesRange } from '../../../../common/helpers/formatters';
import { getActionMenuItemsForCampaign } from '../../../../common/helpers/get-action-menu-items-for-campaign';
import { getRelatedDataToCampaign } from '../../../../common/helpers/get-related-data-to-campaign';
import { findPartInAddressObjByType } from '../../../../common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/redux-hooks';
import { CampaignStatus, getAllCampaignStatuses } from '../../../../constants/enums/campaign/campaign-status';
import CampaignBundleDTO from '../../../../models/campaign/campaign-bundle-dto';
import CampaignDTO from '../../../../models/campaign/campaign-dto';
import CampaignFilterDTO from '../../../../models/campaign/campaign-filter-dto';
import { ImgService } from '../../../../services/img-service';
import theme from '../../../../theme-config/theme';
import { hideRightNavDrawer, openRightNavDrawer } from '../../../drawer/drawerSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../../../notifier/notifierSlice';
import { BookingAdditionalInfo } from '../BookingAdditionalInfo';
import {
  approveCampaign,
  getCampaignsByFilter,
  selectBookingsManagerDashboardState,
  setInitialStateForBookingsBundleData,
  updateSelectedBookingId,
} from '../bookingsManagerDashboardSlice';

export const BookingsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const [rejectReasonDialogState, setRejectReasonDialogState] = useState<{
    campaign: CampaignDTO;
    isShowRejectReasonDialog: boolean;
  }>();

  const {
    bookingsBundleData,
    bookingsPaginationPage,
  }: { bookingsBundleData: CampaignBundleDTO; bookingsPaginationPage: number } = useAppSelector(
    selectBookingsManagerDashboardState,
  );

  const filter: CampaignFilterDTO = {
    preCampaignIds: [],
    brandIds: [],
    spotIds: [],
    itemsOnPage: 10,
    page: bookingsPaginationPage,
  };
  const bookingItems = bookingsBundleData?.searchPage?.items;
  const imgService: ImgService = new ImgService();

  const clickOnApproveHandler = (campaign: CampaignDTO) => {
    dispatch(approveCampaign(campaign.id)).then(() => {
      dispatch(
        enqueueSnackbarAction({
          message: 'Booking was successfully approved',
          options: { variant: 'success' },
        }),
      );
    });
  };

  const clickOnRejectHandler = (campaign: CampaignDTO) => {
    setRejectReasonDialogState({ campaign: campaign, isShowRejectReasonDialog: true });
  };

  const clickOnShowMoreHandler = () => {
    dispatch(getCampaignsByFilter(filter));
  };

  const clickOnBookingItemHandler = (bookingId: number) => {
    dispatch(updateSelectedBookingId(bookingId));
    dispatch(openRightNavDrawer('BookingAdditionalInfo'));
  };

  useEffect(() => {
    dispatch(getCampaignsByFilter(filter));

    return () => {
      dispatch(setInitialStateForBookingsBundleData());
      dispatch(hideRightNavDrawer());
    };
  }, []);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={15} sx={{ marginBottom: 20 }}>
        {bookingItems?.map((campaignsItem, index) => {
          const { campaign, spot, store, brand } = getRelatedDataToCampaign(campaignsItem.id, bookingsBundleData);

          const parsedStatus = getAllCampaignStatuses().find((x) => x.id == campaign?.status);
          const avatarUrl =
            brand?.logo &&
            imgService.getImgPreviewUrl(brand.logo, {
              width: 100,
              height: 100,
            });
          const spotCityName = store?.address?.parts
            ? findPartInAddressObjByType(store.address.parts, 'locality')?.name
            : '';

          return (
            <Grid key={index} item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  padding: { xs: '12px 10px', md: '15px 20px' },
                  border: 'solid 1px black',
                  borderRadius: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => clickOnBookingItemHandler(campaign?.id)}
              >
                <Grid container columns={16} columnSpacing={{ xs: 10, md: 15 }} rowSpacing={5} alignItems="center">
                  <Grid item xs={14} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      sx={{
                        width: { xs: '50px', md: '70px' },
                        height: { xs: '50px', md: '70px' },
                        marginRight: '10px',
                        border: 'solid 2px white',
                      }}
                      src={avatarUrl}
                      alt="user avatars"
                    />

                    <Box>
                      <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                        {brand?.name}
                      </Typography>
                      {campaign?.createdAt && (
                        <Typography variant="body1" sx={{ color: '#808080' }}>
                          Created on: {format(new Date(campaign.createdAt), 'dd/MM/yyyy')}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', textAlign: 'center' }}
                  >
                    <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                      Store
                    </Typography>
                    <Typography variant="body1">
                      {spot?.name}
                      {spotCityName && ', ' + spotCityName}
                    </Typography>
                  </Grid>

                  {campaign?.startDate && campaign?.endDate ? (
                    <Grid
                      item
                      md={3}
                      sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', textAlign: 'center' }}
                    >
                      <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                        Dates
                      </Typography>
                      <Typography variant="body1">
                        {getFormattedDatesRange(new Date(campaign.startDate), new Date(campaign.endDate))}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: { xs: '60px', md: '0' } }}>
                      <Box
                        sx={{
                          width: '12px',
                          height: '12px',
                          flexShrink: '0',
                          marginRight: 5,
                          borderRadius: '20px',
                          backgroundColor: parsedStatus?.color,
                        }}
                      ></Box>

                      <Typography variant="body1">{parsedStatus?.name}</Typography>
                    </Box>
                  </Grid>

                  {isUpMdBreakPoint && (
                    <Grid item xs={12} md={1} sx={{ textAlign: 'right' }}>
                      {CampaignStatus.Pending === campaign.status && (
                        <ActionMenu
                          items={getActionMenuItemsForCampaign(campaign, clickOnApproveHandler, clickOnRejectHandler)}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
                {!isUpMdBreakPoint && CampaignStatus.Pending === campaign.status && (
                  <ActionMenu
                    items={getActionMenuItemsForCampaign(campaign, clickOnApproveHandler, clickOnRejectHandler)}
                  />
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <BasicButton
            className="btn-large btn-yellow"
            variant="contained"
            onClick={() => clickOnShowMoreHandler()}
            disabled={bookingItems ? bookingItems.length >= bookingsBundleData?.searchPage.totalCount : true}
          >
            <Typography variant="body1">View more</Typography>
          </BasicButton>
        </Grid>
      </Grid>

      <DashboardRightDrawer
        contentElements={[
          <BookingAdditionalInfo
            data-name={'BookingAdditionalInfo'}
            clickOnApproveHandler={clickOnApproveHandler}
            clickOnRejectHandler={clickOnRejectHandler}
          />,
        ]}
      />

      <RejectReasonDialog
        dialogProps={{
          open: Boolean(rejectReasonDialogState?.isShowRejectReasonDialog),
          onClose: () => {
            setRejectReasonDialogState(undefined);
          },
          fullWidth: true,
          withCloseIcon: false,
          PaperProps: { sx: { maxWidth: '570px' } },
          closeByClickOnBackdrop: true,
        }}
        campaignToReject={rejectReasonDialogState?.campaign}
      />
    </>
  );
};
