import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { AUTH_TOKEN_KEY } from '../../constants';
import { USER_ROLES } from '../../constants/enums/user-roles';
import { getCurrentUserByToken, selectAuthState } from '../../features/authentication/authSlice';
import { useAppDispatch, useAppSelector } from './redux-hooks';
import { useMatchRoutes } from './use-match-routes';
import { useUserRole } from './use-user-role';

export const useRedirectByUserRole = () => {
  const { authorizedUser } = useAppSelector(selectAuthState);
  const isAdminRole = useUserRole(USER_ROLES.Admin);
  const isRetailRole = useUserRole(USER_ROLES.Retail);
  const savedInternalAuthToken = localStorage.getItem(AUTH_TOKEN_KEY);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isOneOfAuthPages = useMatchRoutes(['/log-in', '/sign-up', '/recovery', '/forgot-password']);
  const isDashboardRoute = useMatchRoutes(['/dashboard']);

  useEffect(() => {
    if (authorizedUser?.token) {
      let previousLocation = location?.state?.from;

      if (previousLocation) {
        //if the authorized user is available in the state, need to redirect to a URL that depends on user's role (redirectUrl)
        navigate(previousLocation.path + previousLocation.search);
      } else if (isOneOfAuthPages) {
        window.location.href = '/';
      } else if (isDashboardRoute) {
        navigate(isAdminRole || isRetailRole ? '/dashboard/manager' : '/dashboard/brand');
      }
    } else if (savedInternalAuthToken) {
      //if internal auth token is available in local storage, then need to take this token and find user by this token
      dispatch(getCurrentUserByToken());
    }
  }, [authorizedUser]);
};
