import React, { useState } from 'react';

import { Box } from '@mui/material';

import { DashboardBasicContainer } from '../../../common/components/dashboard/BasicContainer';
import { DashboardCaption } from '../../../common/components/dashboard/Caption';
import { TabsComponent } from '../../../common/components/TabsComponent';
import { BookingsTab } from './BookingsTab';
import { PreBookingsTab } from './PreBookingsTab';

export const BookingsPage = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);

  const bookingTabs = [
    {
      label: 'Booking from sent',
      tabPanelComponent: <PreBookingsTab />,
    },
    {
      label: 'Bookings made',
      tabPanelComponent: <BookingsTab />,
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <DashboardCaption>BOOKINGS</DashboardCaption>

      <DashboardBasicContainer>
        <TabsComponent
          value={activeTab}
          data={bookingTabs}
          handleChangeTab={handleChange}
          tabsStyles={{ marginBottom: 12, width: '100%' }}
          ariaLabel="booking-tab"
        ></TabsComponent>
      </DashboardBasicContainer>
    </Box>
  );
};
