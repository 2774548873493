import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import CampaignBundleDTO from '../models/campaign/campaign-bundle-dto';
import CampaignDTO from '../models/campaign/campaign-dto';
import CampaignFilterDTO from '../models/campaign/campaign-filter-dto';

export class CampaignManagerService {
  private readonly basePath: string = 'manager/campaigns';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getCampaignsById(id: number): ApiRequestWrapper<CampaignDTO> {
    return this.apiUrlProvider.get(this.basePath + `/${id}`);
  }

  public getCampaignsByFilter(filter: CampaignFilterDTO): ApiRequestWrapper<CampaignBundleDTO> {
    return this.apiUrlProvider.post(this.basePath + '/filter', filter);
  }

  public approveCampaign(id: number): ApiRequestWrapper<CampaignDTO> {
    return this.apiUrlProvider.post(this.basePath + `/${id}/approve`, {});
  }

  public rejectCampaign(id: number, reason: string): ApiRequestWrapper<CampaignDTO> {
    return this.apiUrlProvider.post(this.basePath + `/${id}/reject`, { reason: reason });
  }
}
