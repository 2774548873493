import { isString, isStringWithNumbers, replaceUnicodeSpaceCharacters } from '../helpers/utils';

export function useStateFormControlHandler(state: any, stateUpdater: any) {
  const inputHandler = (e: any) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    target.type === 'radio' && isStringWithNumbers(value) ? (value = parseInt(value)) : value;
    isString(value) ? (value = replaceUnicodeSpaceCharacters(value)) : value;

    if (typeof state === 'object') {
      stateUpdater({
        ...state,
        [target.name]: value,
      });
    } else {
      stateUpdater(value);
    }
  };

  return { inputHandler };
}
