import React from 'react';

import { StyledBoxWithBackground } from './styles';

export const BoxWithBackground = ({
  children,
  className,
  sx = {},
  onClick,
}: {
  className?: string;
  children: any;
  sx: object;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <StyledBoxWithBackground className={className} sx={sx} onClick={onClick}>
      {children}
    </StyledBoxWithBackground>
  );
};
