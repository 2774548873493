import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import ImageEditDTO from '../../models/image-edit-dto';
import PreCampaignDTO from '../../models/pre-campaign/pre-campaign-dto';
import SpotDTO from '../../models/spot/spot-dto';
import StoreDTO from '../../models/store/store-dto';
import { PreCampaignBrandService } from '../../services/pre-campaign-brand';
import { SpotsService } from '../../services/spots';
import { StoresService } from '../../services/stores';

interface initialStateTypes {
  preCampaignState: PreCampaignDTO;
  selectedSpot: SpotDTO;
  selectedStore: StoreDTO;
  formattedSummaryData: {
    formattedDateRange: string;
    daysDuration: number;
    formattedPricePerWeek: string;
    formattedDepositAmount: string;
    formattedTotalAmount: string;
    formattedAmountAtCheckIn: string;
    spotNameWithStoreAddress: string;
  };
}

const initialState = {
  preCampaignState: {},
  selectedSpot: {},
  selectedStore: {},
  formattedSummaryData: {},
} as initialStateTypes;

const preCampaignBrandService: PreCampaignBrandService = new PreCampaignBrandService();
const storesService: StoresService = new StoresService();
const spotsService: SpotsService = new SpotsService();

export const getPreCampaignById = createAsyncThunk('getPreCampaignById', async (id: string) => {
  return await preCampaignBrandService.getPreCampaignById(id).execute();
});

export const setBankAccount = createAsyncThunk(
  'setBankAccount',
  async ({ id, file }: { id: string; file: { image: ImageEditDTO } }) => {
    return await preCampaignBrandService.addBankAccountFile(id, file).execute();
  },
);

export const getSpotById = createAsyncThunk('getSpotById', async (id: number) => {
  return await spotsService.getSpotById(id).execute();
});

export const getStoreById = createAsyncThunk('getStoreById', async (id: number) => {
  return await storesService.getStoreById(id).execute();
});

export const preBookingSlice = createSlice({
  name: 'preBooking',
  initialState: initialState,
  reducers: {
    setFormattedSummaryData: (state, action) => {
      state.formattedSummaryData = { ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(getPreCampaignById.fulfilled, (state, action) => {
      state.preCampaignState = { ...action.payload };
    });
    builder.addCase(getSpotById.fulfilled, (state, action) => {
      state.selectedSpot = { ...action.payload };
    });
    builder.addCase(getStoreById.fulfilled, (state, action) => {
      state.selectedStore = { ...action.payload };
    });
    builder.addCase(setBankAccount.fulfilled, (state, action) => {
      state.preCampaignState = { ...action.payload };
    });
  },
});

export const selectPreBookingState = function (state: RootState): any {
  return state.preBooking;
};

export const { setFormattedSummaryData } = preBookingSlice.actions;

export default preBookingSlice.reducer;
