import React, { forwardRef, useState } from 'react';

import { Typography } from '@mui/material';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { StyledCalendarContainer, StyledDatePickerInputContainer, StyledPopperContainer } from './styles';

export const BasicDatePicker = ({
  id,
  name,
  placeholder,
  defaultHour,
  isSelectsRange = false,
  openToDateStr,
  selectedStr,
  startDateStr,
  endDateStr,
  selectsStart,
  selectsEnd,
  withoutBorder = false,
  inline = false,
  monthsShown,
  dropdownStyle = false,
  disabledKeyboardNavigation = true,
  isBlackColorPalette = false,
  dayClassName,
  inputStyles = {},
  containerStyles = {},
  popperStyles = {},
  onChange,
  onSelect,
  onDayMouseEnter,
  excludeDates,
  highlightDates,
  filterDate,
  minDateStr,
  aheadIconUrl,
}: DatePickerProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  const selectedDateObj = selectedStr ? new Date(selectedStr) : undefined;
  const startDateObj = startDateStr ? new Date(startDateStr) : undefined;
  const endDateObj = endDateStr ? new Date(endDateStr) : undefined;
  const minDateObj = minDateStr ? new Date(minDateStr) : undefined;
  const openToDate = openToDateStr ? new Date(openToDateStr) : undefined;

  const DatePickerContainer = ({ className, children }: { className: string; children: any }) => {
    return (
      <StyledCalendarContainer
        className={className}
        isInlineContainer={inline}
        isBlackColorPalette={isBlackColorPalette}
        withoutBorder={withoutBorder}
        sx={containerStyles}
      >
        {children}
      </StyledCalendarContainer>
    );
  };

  const DatepickerDropdown = forwardRef<HTMLDivElement, { onClick?: () => void; value?: any }>(
    ({ onClick, value }, ref) => (
      <div className="datepicker-dropdown-value" onClick={onClick} ref={ref}>
        <Typography variant={'subtitle2'} sx={{ typography: { xs: 'h6', md: 'h3' } }}>
          {value ? value : placeholder}
        </Typography>
      </div>
    ),
  );

  const DatePickerPopperContainer = ({ className, children }: { className: string; children: any }) => {
    return (
      <StyledPopperContainer className={className} withoutBorder={withoutBorder} sx={popperStyles}>
        {children}
      </StyledPopperContainer>
    );
  };

  const openDatePickerHandler = () => {
    setOpen(true);
  };

  const closeDatePickerHandler = () => {
    setOpen(false);
  };

  const changeDatePickerHandler = (date: Date) => {
    if (Array.isArray(date)) {
      const [from, to] = date;

      let fromDate = defaultHour ? new Date(from.setHours(defaultHour)) : from;
      let toDate = defaultHour ? new Date(to.setHours(defaultHour)) : to;

      onChange({
        target: { type: 'datepicker', value: { from: fromDate?.toISOString(), to: toDate?.toISOString() }, name: name },
      });
    } else {
      let newDate = defaultHour ? new Date(date.setHours(defaultHour)) : date;

      onChange({ target: { type: 'datepicker', value: newDate.toISOString(), name: name } });
    }
  };

  return (
    <StyledDatePickerInputContainer
      sx={inputStyles}
      isOpen={isOpen}
      withoutBorder={withoutBorder}
      aheadIconUrl={aheadIconUrl}
    >
      <DatePicker
        portalId={id}
        name={name}
        wrapperClassName={dropdownStyle ? 'datepicker-dropdown-wrapper' : 'datepicker-input-wrapper'}
        autoComplete="off"
        dateFormat={`${defaultHour ? 'dd/MM/yyyy - ha' : 'dd/MM/yyyy'}`}
        calendarStartDay={6}
        monthsShown={monthsShown}
        openToDate={openToDate}
        selected={selectedDateObj}
        startDate={startDateObj}
        endDate={endDateObj}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        placeholderText={placeholder}
        filterDate={filterDate}
        minDate={minDateObj ? minDateObj : new Date()}
        excludeDates={excludeDates}
        highlightDates={highlightDates}
        inline={inline}
        selectsRange={isSelectsRange}
        disabledKeyboardNavigation={disabledKeyboardNavigation}
        popperContainer={DatePickerPopperContainer}
        calendarContainer={DatePickerContainer}
        dayClassName={dayClassName}
        onCalendarClose={closeDatePickerHandler}
        onCalendarOpen={openDatePickerHandler}
        onChange={(date: Date) => {
          changeDatePickerHandler(date);
        }}
        onSelect={onSelect}
        onDayMouseEnter={onDayMouseEnter}
        customInput={dropdownStyle ? <DatepickerDropdown /> : undefined}
      />
    </StyledDatePickerInputContainer>
  );
};

interface DatePickerProps extends ReactDatePickerProps {
  id: string;
  name: string;
  placeholder?: string;
  openToDateStr?: string | undefined;
  selectedStr?: string;
  startDateStr?: string;
  endDateStr?: string;
  minDateStr?: string;
  isSelectsRange?: boolean;
  defaultHour?: number;
  isBlackColorPalette?: boolean;
  dropdownStyle?: boolean;
  withoutBorder?: boolean;
  inputStyles?: object;
  containerStyles?: object;
  popperStyles?: object;
  onChange: (e: any) => void;
  aheadIconUrl?: string;
}
