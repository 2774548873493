import { Card } from '@mui/material';
import { styled } from '@mui/system';

export const StyledCityCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  borderRadius: '20px',
  boxShadow: 'none',

  '.MuiCardContent-root, .MuiCardContent-root:last-child': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: 'auto',
    padding: '8px 10px',
    textAlign: 'left',
    textTransform: 'uppercase',
    background: theme.palette.common.black,
    color: theme.palette.common.white,

    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    },
  },
}));
