import { AppBar, Toolbar, ToolbarProps } from '@mui/material';
import { styled } from '@mui/system';

interface styledMobileToolbarProps extends ToolbarProps {
  leftNavDrawerWidth: number;
  headerBreakpoint: number;
}

interface styledDesktopToolbarProps extends ToolbarProps {
  headerBreakpoint: number;
  isPageWithoutDesktopHeader: boolean;
}

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.common.white,
}));

export const StyledMobileToolbar = styled(Toolbar, {
  shouldForwardProp: (propName) => propName !== 'leftNavDrawerWidth' && propName !== 'headerBreakpoint',
})<styledMobileToolbarProps>(({ theme, leftNavDrawerWidth, headerBreakpoint }) => ({
  minHeight: '80px !important',
  justifyContent: 'space-between',
  margin: `0 ${theme.spacing(8)}`,
  padding: `${theme.spacing(8)} 0`,
  borderBottom: `1px solid ${theme.palette.common.black}`,
  backgroundColor: theme.palette.common.white,
  boxShadow: 'none',

  [theme.breakpoints.up(headerBreakpoint)]: {
    display: 'none',
    width: `calc(100% - ${leftNavDrawerWidth}px)`,
  },

  '.logo': {
    margin: '0 auto',
  },
}));

export const StyledDesktopToolbar = styled(Toolbar, {
  shouldForwardProp: (propName) => propName !== 'isPageWithoutDesktopHeader' && propName !== 'headerBreakpoint',
})<styledDesktopToolbarProps>(({ theme, headerBreakpoint, isPageWithoutDesktopHeader }) => ({
  '&': {
    display: 'none',
    minHeight: '100px',
    padding: 0,
  },

  [theme.breakpoints.up(headerBreakpoint)]: {
    display: !isPageWithoutDesktopHeader && 'flex',
    justifyContent: !isPageWithoutDesktopHeader && 'center',
  },

  '.MuiContainer-root': {
    paddingTop: `${theme.spacing(8)} !important`,
    paddingBottom: `${theme.spacing(8)} !important`,
    marginLeft: `${theme.spacing(8)}`,
    marginRight: `${theme.spacing(8)}`,
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },

  '.nav-link': {
    fontSize: '20px',
    fontWeight: '400',
    marginRight: '20px',
    color: theme.palette.common.black,
    textDecoration: 'none',

    '&.active': {
      textDecoration: 'underline',
    },
  },
}));
