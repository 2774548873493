import React from 'react';

import { Box, CircularProgress, circularProgressClasses, CircularProgressProps } from '@mui/material';

export const CircleProgressBar = (props: CircularProgressProps): JSX.Element => {
  const { value, sx = {}, ...otherProps } = props;

  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{ transform: 'none !important', color: (theme) => theme.palette.common.white, ...sx }}
        {...otherProps}
      />
      <CircularProgress
        variant="determinate"
        value={value}
        sx={{
          color: (theme) => theme.palette.secondary.main,
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        {...otherProps}
      />
    </Box>
  );
};

// interface CircleProgressBarProps {}
