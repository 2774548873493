import { format } from 'date-fns';

export function getFormattedDatesRange(firstDate: Date, secondDate: Date): string {
  const firstDateInSeconds = firstDate?.getTime();
  const secondDateInSeconds = secondDate?.getTime();

  if (firstDateInSeconds <= secondDateInSeconds) {
    return `${format(firstDate, 'dd/MM/yyyy')} - ${format(secondDate, 'dd/MM/yyyy')}`;
  }

  return '';
}

export function getFormattedNumber(number: number, roundOff: boolean): string {
  let res = '';

  if (number && !roundOff) {
    let sValue = number.toFixed(2);
    let fsValue = parseFloat(sValue);
    res = fsValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    res = Math.ceil(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return res;
}

export const getFormattedPrice = (price: number, currencySymbol: string, roundOff = false): string => {
  const isAvailablePriceAndCurrency = price && currencySymbol;

  if (!isAvailablePriceAndCurrency) {
    return '';
  }

  return currencySymbol + getFormattedNumber(price, roundOff);
};
