import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSpotInfoBlock = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'calc(50% - 10px) calc(50% - 10px)',
  gridTemplateRows: 'auto auto',
  justifyContent: 'center',
  gridGap: '48px',

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'auto auto auto auto',
    gridTemplateRows: 'auto',
    justifyContent: 'initial',
    gridGap: '10px',
  },
}));
