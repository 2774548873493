import { Container } from '@mui/material';
import { styled } from '@mui/system';

export interface StyledDashboardBasicContainerProps {
  maxWidthInPixels?: '600px' | '970px' | '1170px';
}

export const StyledDashboardBasicContainer = styled(Container, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'maxWidthInPixels',
})<StyledDashboardBasicContainerProps>(({ theme, maxWidthInPixels }) => ({
  '&.MuiContainer-root': {
    maxWidth: maxWidthInPixels,
    margin: 0,
    padding: 0,
  },
}));
