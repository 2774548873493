import { Grid } from '@mui/material';
import { styled } from '@mui/system';

interface GridWithRePopBackgroundProps {
  repopBackground: string;
  columnBackground: string;
  isAvailableBgColumnOnMobile?: boolean;
}

export const StyledGridWithRePopBackground = styled(Grid, {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'repopBackground' &&
    propName !== 'columnBackground' &&
    propName !== 'withoutMainTitle' &&
    propName !== 'isAvailableBgColumnOnMobile',
})<GridWithRePopBackgroundProps>(({ theme, repopBackground, columnBackground, isAvailableBgColumnOnMobile }) => ({
  '&': {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },

  '.column-with-background': {
    display: isAvailableBgColumnOnMobile ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: `${columnBackground}`,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },

    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },

    '.pop-up-your-brand-img': {
      paddingBottom: theme.spacing(9),

      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(17),
      },
    },

    '.repop-background-img': {
      width: '100%',
      maxWidth: '645px',
      height: '360px',
      backgroundImage: `url(${repopBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      marginBottom: theme.spacing(15),

      [theme.breakpoints.up('md')]: {
        height: '100%',
        marginBottom: theme.spacing(28),
      },
    },

    '.additional-info-block': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxWidth: '645px',

      '.logo-img': {
        minWidth: '110px',
        marginRight: theme.spacing(10),

        [theme.breakpoints.up('md')]: {
          width: '150px',
        },

        [theme.breakpoints.up('lg')]: {
          width: '206px',
        },
      },

      '.url': {
        color: theme.palette.common.white,
      },
    },
  },
}));
