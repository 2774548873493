import { isString, isStringWithNumbers, replaceUnicodeSpaceCharacters } from '../helpers/utils';

export function useFormControlHandler(dispatcher: any, action: any) {
  const inputHandler = (e: any) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    target.type === 'radio' && isStringWithNumbers(value) ? (value = parseInt(value)) : value;
    isString(value) ? (value = replaceUnicodeSpaceCharacters(value)) : value;

    dispatcher(action({ [target.name]: value }));
  };

  return { inputHandler };
}
