import axios, { AxiosInstance } from 'axios';

import { API_URL, AUTH_TOKEN_KEY } from '../constants';
import ApiRequestWrapper from './api-request-wrapper';

export default class ApiUrlProvider {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: API_URL,
    });

    this.axiosInstance.interceptors.response.use(
      ApiUrlProvider.onSuccess.bind(this),
      ApiUrlProvider.onError.bind(this),
    );

    this.axiosInstance.interceptors.request.use(
      (config: any) => {
        const token = localStorage.getItem(AUTH_TOKEN_KEY);
        if (token) {
          config.headers.common['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  private static onSuccess(response: any) {
    return response;
  }

  private static onError(error: any) {
    return Promise.reject(error);
  }

  public get<TResp>(path: string): ApiRequestWrapper<TResp> {
    return new ApiRequestWrapper<TResp>(this.axiosInstance.get(path));
  }

  public post<TResp, TReq>(path: string, model: TReq): ApiRequestWrapper<TResp> {
    return new ApiRequestWrapper<TResp>(
      this.axiosInstance.request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: model,
      }),
    );
  }

  public put<TResp, TReq>(path: string, model: TReq): ApiRequestWrapper<TResp> {
    return new ApiRequestWrapper<TResp>(
      this.axiosInstance.request({
        method: 'PUT',
        url: path,
        responseType: 'json',
        data: model,
      }),
    );
  }

  public delete<TResp>(path: string): ApiRequestWrapper<TResp> {
    return new ApiRequestWrapper<TResp>(this.axiosInstance.delete(path));
  }
}
