import { Stepper } from '@mui/material';
import { styled } from '@mui/system';

export const StyledRefundDepositBlock = styled(Stepper)(({ theme }) => ({
  '.MuiStep-root': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    '.MuiStepLabel-labelContainer': {
      '.MuiStepLabel-alternativeLabel': {
        marginTop: theme.spacing(1),
        color: theme.palette.common.black,

        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
        },
      },
    },

    '.MuiStepConnector-root': {
      top: '4px',
      left: '-50%',
      right: '50%',
      borderRadius: '15px',

      '.MuiStepConnector-line': {
        borderTopWidth: '10px',
        borderColor: theme.palette.common.black,
      },
    },
  },

  '.MuiStep-root.Mui-completed': {
    '.MuiStepConnector-root': {
      '.MuiStepConnector-line': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },

  '.MuiStepConnector-root.Mui-active': {
    '.MuiStepConnector-line': {
      borderColor: theme.palette.secondary.main,
    },
  },
}));


export const StepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(
  ({ theme, ownerState }) => ({
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    width: '18px',
    height: '18px',
    fontSize: '20px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: ownerState.active || ownerState.completed ? theme.palette.secondary.main : theme.palette.common.black,
    backgroundColor:
      ownerState.active || ownerState.completed ? theme.palette.secondary.main : theme.palette.common.white,
  }),
);
