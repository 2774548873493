import { Theme } from '@mui/material/styles';

export const bookingRequestReadyDialogStyles = (theme: Theme) =>
  ({
    maxWidth: '430px',
    margin: '0 auto',
    pt: 15,
    textAlign: 'center',

    '.check-mark-icon': {
      width: '76px',
      height: '76px',
      color: theme.palette.common.white,
    },

    '.email-form': {
      width: '100%',
    },

    '.row-with-action-btn': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      
      // [theme.breakpoints.down('sm')]: {
      //   '& > p:first-of-type': {
      //     pb: 10
      //   }
      // },
    
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        flexDirection: 'row',
      },
    },
  } as const);
