import { useLayoutEffect } from 'react';

import { useLocation } from 'react-router-dom';

export const ScrollToTopWrapper = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};
