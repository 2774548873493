import { Select } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';

interface StyledSelectProps {
  aheadIconUrl?: string;
}

export const StyledSelect = styled(Select, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'aheadIconUrl',
})<StyledSelectProps>(({ theme, aheadIconUrl }) => ({
  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: theme.palette.common.black,
    },
  },

  '&.Mui-error': {
    '.MuiOutlinedInput-input': {
      '&::placeholder': {
        color: theme.palette.error.main,
      },
    },
  },

  '.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',

    '&::before': {
      content: "''",
      display: aheadIconUrl ? 'block' : 'none',
      width: '25px',
      height: '25px',
      flexShrink: '0',
      marginRight: '10px',
      background: `no-repeat center url(${aheadIconUrl})`,

      [theme.breakpoints.down('md')]: {
        width: '16px',
        height: '16px',
        backgroundSize: 'contain',
        marginRight: '6px',
      },
    },
  },

  '.MuiSelect-select.MuiOutlinedInput-input': {
    minHeight: 'initial',

    '&::placeholder': {
      color: theme.palette.common.black,
      opacity: '1',
    },

    '.MuiTypography-root': {
      lineHeight: '13px',
    },
  },

  '&.open': {
    '.arrow-box': {
      transform: 'rotate(180deg)',
    },
  },

  '&.no-border': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },

  '&.select-like-dropdown': {
    '.MuiSelect-select.MuiOutlinedInput-input': {
      fontWeight: 700,

      '.MuiTypography-root': {
        fontWeight: 700,
      },
    },
  },
}));

export const actionDirectionArrowBox = {
  position: 'absolute',
  right: '10px',
  cursor: 'pointer',
  zIndex: '-1',
} as const;

export const selectLabelStyles = (theme: Theme) => ({
  '&.MuiInputLabel-root': {
    transform: 'none',
    transition: 'none',
    transformOrigin: 'unset',
    left: theme.spacing(5),
    top: theme.spacing(7),
    lineHeight: 1,
  },
});

export const selectMenuStyles = (theme: Theme) => ({
  // '.MuiMenu-paper': {
  //   boxShadow: 'none',
  // },

  '.MuiMenuItem-root': {
    '&.Mui-selected': {
      fontWeight: 700,

      '& > .MuiTypography-root': {
        fontWeight: 700,
      },
    },

    [theme.breakpoints.down('sm')]: {
      minHeight: '35px',
    },
  },
});
