import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Dropzone } from '../../../common/components/Dropzone';
import { createImageDto } from '../../../common/helpers/img-helper';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { UploadedFileInfoDTO } from '../../../models/ui/uploaded-file-info-dto';
import { enqueueNotification as enqueueSnackbarAction } from '../../notifier/notifierSlice';
import { selectPreBookingState, setBankAccount } from '../../pre-booking/preBookingSlice';
import { selectBrandPaymentsState, setBankAccountFile } from '../brandPaymentsSlice';

export const BankAccount = ({ totalAmount }: { totalAmount: string }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { preCampaignState } = useAppSelector(selectPreBookingState);
  const { bankAccountFiles } = useAppSelector(selectBrandPaymentsState);

  const bankAccountData = [
    { title: 'Bank name', value: 'Bank Leumi' },
    { title: 'Branch', value: '864' },
    { title: 'Account number', value: '22630056' },
    { title: 'Account name', value: 'Spotango Ltd' },
  ];

  const loadedBankAccountImageHandler = (fileInfoArr: string[], file: UploadedFileInfoDTO[]) => {
    const bankAccountFile = createImageDto(fileInfoArr[0]);

    dispatch(setBankAccount({ id: preCampaignState.id, file: { image: bankAccountFile } }))
      .unwrap()
      .then((data) => {
        if (data?.bankAccountId) {
          dispatch(
            enqueueSnackbarAction({
              message: 'File was successfully uploaded',
              options: { variant: 'success' },
            }),
          );
          dispatch(setBankAccountFile(file));
        }
      });
  };

  return (
    <Grid item container spacing={{ xs: 10, md: 20 }}>
      <Grid item xs={12} sx={{ marginTop: 8 }}>
        <Typography variant="body2" sx={{ typography: { xs: 'h6', md: 'h5' } }}>
          Bank Transfer payment
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="body1" sx={{ typography: 'subtitle2' }}>
          Total Price:{' '}
          <Typography variant="caption" sx={{ typography: 'h3' }}>
            {totalAmount}
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" sx={{ typography: { xs: 'h6', md: 'h5' } }}>
          Bank account information
        </Typography>
      </Grid>

      <Grid item xs={8} sx={{ margin: '0 auto' }}>
        {bankAccountData.map((bankAccountElement, index) => {
          return (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 6 }}>
              <Typography variant="body2" sx={{ typography: { xs: 'body2', md: 'body1', marginRight: 5 } }}>
                {bankAccountElement.title}
              </Typography>
              <Typography variant="body2" sx={{ typography: { xs: 'h6', md: 'h5' } }}>
                {bankAccountElement.value}
              </Typography>
            </Box>
          );
        })}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" sx={{ typography: { xs: 'h6', md: 'h5' } }}>
          Upload the confirmation of the transfer on the total ammount
        </Typography>
        <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
          Once we receive the booking request, the confirmation will be within 24 hours
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ paddingBottom: { xs: 10, md: 20 } }}>
        <Dropzone maxFilesProp={1} selectedFiles={bankAccountFiles} setSelectedFiles={loadedBankAccountImageHandler} />
      </Grid>
    </Grid>
  );
};
