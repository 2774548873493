import React from 'react';

import { CardContent, CardMedia, CardProps, Typography } from '@mui/material';

import { StyledCityCard } from './styles';

export const CityCard = ({ cityName, imageSrc, onClick }: CountryCardProps): JSX.Element => {
  return (
    <StyledCityCard onClick={onClick}>
      <CardMedia component="img" image={imageSrc} />
      <CardContent>
        <Typography variant="h3">{cityName}</Typography>
      </CardContent>
    </StyledCityCard>
  );
};

interface CountryCardProps extends CardProps {
  cityName: string;
  imageSrc: string;
}
