import React, { useEffect, useState } from 'react';

import { Box, Grid, Link, MenuItem, Typography, useMediaQuery } from '@mui/material';

import { ReactComponent as CreditCardIcon } from '../../../assets/icons/credit-card.svg';
import { ReactComponent as RLetter } from '../../../assets/icons/letters/r-letter.svg';
import { ReactComponent as StripeIcon } from '../../../assets/icons/stripe.svg';
import { BasicButton } from '../../../common/components/basic/Button';
import { BasicInput } from '../../../common/components/basic/Input';
import { BasicSelect } from '../../../common/components/basic/Select';
import { DashboardBasicContainer } from '../../../common/components/dashboard/BasicContainer';
import { DashboardCaption } from '../../../common/components/dashboard/Caption';
import { DefaultCreditCardBlock } from '../../../common/components/DefaultCreditCardBlock';
import { TermAndConditionsDialog } from '../../../common/components/Dialogs/TermAndConditions';
import { GoogleAutocompleteInput } from '../../../common/components/GoogleAutocomplete';
import { PhotoUploader } from '../../../common/components/PhotoUploader';
import { TabsComponent } from '../../../common/components/TabsComponent';
import { createAddressDtoFromGoogleObj } from '../../../common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { useStateFormControlHandler } from '../../../common/hooks/use-state-from-control-handler';
import { getAllCurrencies } from '../../../constants/enums/currency';
import BrandEditDTO from '../../../models/brand/brand-edit-dto';
import ImageEditDTO from '../../../models/image-edit-dto';
import PaymentMethodDTO from '../../../models/payment/payment-method-dto';
import UserEditDTO from '../../../models/user/user-edit-dto';
import AuthService from '../../../services/auth';
import { BrandService } from '../../../services/brand';
import { PaymentsBrandService } from '../../../services/payments-brand';
import theme from '../../../theme-config/theme';
import { changeUserCurrency, selectAuthState, updateCurrentUser } from '../../authentication/authSlice';
import {
  deletePaymentMethodById,
  getAvailablePaymentMethods,
  selectBrandPaymentsState,
  setDefaultPaymentMethod,
} from '../../brand-payments/brandPaymentsSlice';
import { PayPlus } from '../../brand-payments/PaymentTabs/PayPlus';
import { Stripe } from '../../brand-payments/PaymentTabs/Stripe';
import { enqueueNotification as enqueueSnackbarAction } from '../../notifier/notifierSlice';

export const Profile = (): JSX.Element => {
  const userEditInitialState = {} as UserEditDTO;
  const brandEditInitialState = {} as BrandEditDTO;
  const dispatch = useAppDispatch();
  const { authorizedUser } = useAppSelector(selectAuthState);
  const { availablePaymentMethods } = useAppSelector(selectBrandPaymentsState);
  const [activeTab, setActiveTab] = useState(0);
  const [isShowTermsAndConditionsDialog, showTermsAndConditionsDialog] = useState(false);
  const [userEditData, setUserEditData] = useState(userEditInitialState);
  const [brandLogo, setBrandLogo] = useState('');
  const [brandEditData, setBrandEditData] = useState(brandEditInitialState);
  const [stripeKey, setStripeKey] = useState<string | null>(null);
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const { inputHandler: changeUserDataInputHandler } = useStateFormControlHandler(userEditData, setUserEditData);
  const { inputHandler: changeBrandDataInputHandler } = useStateFormControlHandler(brandEditData, setBrandEditData);

  const authService: AuthService = new AuthService();
  const brandService: BrandService = new BrandService();
  const paymentsBrandService: PaymentsBrandService = new PaymentsBrandService();

  const paymentTabs = [
    {
      label: 'Credit card',
      icon: <CreditCardIcon />,
      tabPanelComponent: <PayPlus />,
    },
  ];

  stripeKey &&
    paymentTabs.splice(1, 0, {
      label: 'Stripe',
      icon: <StripeIcon />,
      tabPanelComponent: <Stripe stripeKey={stripeKey} />,
    });

  const clickOnSaveBrandDataHandler = () => {
    brandService
      .updateCurrentBrand(brandEditData)
      .execute()
      .then((data) => {
        setBrandEditData({
          ...data,
          newLogo: null,
          newBanner: null,
        });
      });
  };

  const clickOnSaveUserDataHandler = () => {
    dispatch(
      updateCurrentUser({
        ...userEditData,
      }),
    );
  };

  const clickOnTermsAndConditionsLinkHandler = () => {
    showTermsAndConditionsDialog(true);
  };

  const clickOnResendEmailConfirmation = () => {
    authService
      .emailVerificationRequest(authorizedUser.email)
      .execute()
      .then(() => {
        dispatch(
          enqueueSnackbarAction({
            message: 'We have sent a confirmation to your email',
            options: { variant: 'success' },
          }),
        );
      });
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const loadedLogoHandler = (files: ImageEditDTO[]) => {
    setBrandEditData({
      ...brandEditData,
      newLogo: files[0],
    });
  };

  const changeUserAddressHandler = (place: any) => {
    const addressObj = createAddressDtoFromGoogleObj(place);
    setUserEditData({ ...userEditData, address: addressObj });
  };

  const changeBrandAddressHandler = (place: any) => {
    const addressObj = createAddressDtoFromGoogleObj(place);
    setBrandEditData({ ...brandEditData, address: addressObj });
  };

  const clickOnSetDefaultPaymentBtnHandler = (id: number) => {
    dispatch(setDefaultPaymentMethod(id));
  };

  const clickOnDeletePaymentBtnHandler = (id: number) => {
    dispatch(deletePaymentMethodById(id));
  };

  useEffect(() => {
    dispatch(getAvailablePaymentMethods());

    brandService
      .getCurrentBrand()
      .execute()
      .then((data) => {
        setBrandEditData({
          ...data,
          newLogo: null,
          newBanner: null,
        });
        setBrandLogo(data.logo);
      });

    paymentsBrandService
      .getStripeKey()
      .execute()
      .then((key) => {
        setStripeKey(key.PublishableKey);
      });
  }, []);

  useEffect(() => {
    setUserEditData({
      firstName: authorizedUser.firstName,
      lastName: authorizedUser.lastName,
      address: authorizedUser.address,
      phoneNumber: authorizedUser.phoneNumber,
    });
  }, [authorizedUser]);

  return (
    <>
      <DashboardCaption>
        P
        <RLetter />
        ofile
      </DashboardCaption>

      <DashboardBasicContainer>
        <Grid
          container
          columns={12}
          rowSpacing={10}
          columnSpacing={15}
          sx={{ maxWidth: '600px', margin: { xs: undefined, md: '0 auto' }, marginBottom: 20 }}
        >
          <Grid item xs={6} md={12} sx={{ position: 'relative' }}>
            <PhotoUploader
              uploaderBoxStyles={{
                width: { xs: '166px', sm: 'auto' },
                justifyContent: 'center',
                '.dropzone': { borderRadius: '50%' },
              }}
              maxFiles={1}
              alreadySavedFiles={brandLogo}
              selectedFiles={brandEditData?.newLogo}
              setSelectedFiles={loadedLogoHandler}
            >
              <Typography>Brand logo</Typography>
              <Typography>(upload)</Typography>
            </PhotoUploader>

            {isUpMdBreakPoint && (
              <Box sx={{ maxWidth: '185px', position: 'absolute', right: 0, bottom: 0 }}>
                <Typography noWrap title={authorizedUser?.email} variant="body1" sx={{ marginBottom: 4 }}>
                  {authorizedUser?.email}
                </Typography>

                {!authorizedUser.emailConfirmed ? (
                  <Link
                    component="button"
                    type="button"
                    align={'left'}
                    sx={{ mb: 10 }}
                    variant="body1"
                    color="inherit"
                    underline="always"
                    onClick={clickOnResendEmailConfirmation}
                  >
                    Resend email confirmation
                  </Link>
                ) : null}

                <Typography noWrap title={authorizedUser?.phoneNumber} variant="body1">
                  {authorizedUser?.phoneNumber}
                </Typography>
              </Box>
            )}
          </Grid>

          {!isUpMdBreakPoint && (
            <Grid item xs={6} md={2} sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-end' }}>
              <Typography noWrap variant="body1" sx={{ marginBottom: 4 }}>
                {authorizedUser?.email}
              </Typography>

              {!authorizedUser.emailConfirmed ? (
                <Link
                  component="button"
                  type="button"
                  align={'left'}
                  sx={{ mb: 10 }}
                  variant="body1"
                  color="inherit"
                  underline="always"
                  onClick={clickOnResendEmailConfirmation}
                >
                  Resend email confirmation
                </Link>
              ) : null}

              <Typography noWrap variant="body1">
                {authorizedUser?.phoneNumber}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <BasicInput
              id="name"
              value={brandEditData?.name || ''}
              fullWidth
              inputProps={{
                name: 'name',
                placeholder: 'Brand name',
                onChange: changeBrandDataInputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <GoogleAutocompleteInput
              placeholder="Brands full address (Google Autocomplete)"
              formattedAddress={brandEditData?.address?.formattedAddress}
              onPlaceSelectedHandler={changeBrandAddressHandler}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicInput
              id="about"
              value={brandEditData?.about || ''}
              fullWidth
              multiline={true}
              rows={10}
              inputProps={{
                name: 'about',
                placeholder: 'Brand description',
                onChange: changeBrandDataInputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicInput
              id="brandUrl"
              value={brandEditData?.website || ''}
              fullWidth
              inputProps={{
                name: 'website',
                placeholder: 'Brand URL',
                onChange: changeBrandDataInputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicInput
              id="brandUrl"
              value={brandEditData?.facebookUrl || ''}
              fullWidth
              inputProps={{
                name: 'facebookUrl',
                placeholder: 'Facebook URL',
                onChange: changeBrandDataInputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicInput
              id="brandUrl"
              value={brandEditData?.instagramUrl || ''}
              fullWidth
              inputProps={{
                name: 'instagramUrl',
                placeholder: 'Instagram URL',
                onChange: changeBrandDataInputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicButton
              className="btn-black btn-small"
              sx={{ minWidth: { xs: '140px', md: '170px', margin: '0 auto 30px auto' }, display: 'block' }}
              variant="contained"
              onClick={clickOnSaveBrandDataHandler}
            >
              <Typography variant="subtitle2">SAVE</Typography>
            </BasicButton>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              User info
            </Typography>

            <BasicInput
              id="first-name"
              value={userEditData?.firstName || ''}
              fullWidth
              inputProps={{
                name: 'firstName',
                placeholder: 'First name',
                autoComplete: 'given-name',
                onChange: changeUserDataInputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicInput
              id="las-name"
              value={userEditData?.lastName || ''}
              fullWidth
              inputProps={{
                name: 'lastName',
                placeholder: 'Last name',
                autoComplete: 'family-name',
                onChange: changeUserDataInputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <GoogleAutocompleteInput
              placeholder="User full address (Google Autocomplete)"
              formattedAddress={userEditData?.address?.formattedAddress}
              onPlaceSelectedHandler={changeUserAddressHandler}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicButton
              className="btn-black btn-small"
              sx={{ minWidth: { xs: '140px', md: '170px', margin: '0 auto 30px auto' }, display: 'block' }}
              variant="contained"
              onClick={clickOnSaveUserDataHandler}
            >
              <Typography variant="subtitle2">SAVE</Typography>
            </BasicButton>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              Payment info
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 10 }}>
              This information will be used to book your future Pop-Ups, pay for your spot, request additions and more
            </Typography>

            <TabsComponent
              value={activeTab}
              data={paymentTabs}
              handleChangeTab={handleChangeTab}
              tabsStyles={{ marginBottom: 12, width: '100%' }}
              ariaLabel="payment-tab"
            ></TabsComponent>

            {availablePaymentMethods?.find((paymentMethod: PaymentMethodDTO) => !paymentMethod.isDefault)?.length ? (
              <Typography variant="h3" sx={{ marginBottom: 10 }}>
                Saved credit cards
              </Typography>
            ) : null}

            <Grid item container rowSpacing={10} columnSpacing={15} alignItems="center">
              {availablePaymentMethods?.map((savedPaymentMethod: PaymentMethodDTO, index: number) => {
                const paymentData = savedPaymentMethod?.stripeData
                  ? savedPaymentMethod.stripeData
                  : savedPaymentMethod.payPlusData;
                const type = savedPaymentMethod?.stripeData?.type ? savedPaymentMethod.stripeData.type : '';

                if (!savedPaymentMethod.isDefault) {
                  return (
                    <React.Fragment key={index}>
                      <Grid item xs={12} md={6}>
                        <DefaultCreditCardBlock
                          type={type}
                          lastDigit={paymentData?.lastDigit || paymentData?.lastDigit}
                          expiredDate={paymentData?.expriedAt || paymentData?.expriedAt}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <BasicButton
                          className="btn-small"
                          sx={{
                            display: 'block',
                            minWidth: { xs: '140px', md: '170px' },
                            margin: '0 auto 10px auto',
                          }}
                          onClick={() => clickOnSetDefaultPaymentBtnHandler(savedPaymentMethod.id)}
                        >
                          Set as default
                        </BasicButton>

                        <BasicButton
                          className="btn-black btn-small"
                          sx={{
                            display: 'block',
                            minWidth: { xs: '140px', md: '170px' },
                            margin: '0 auto',
                          }}
                          variant="contained"
                          onClick={() => clickOnDeletePaymentBtnHandler(savedPaymentMethod.id)}
                        >
                          Delete
                        </BasicButton>
                      </Grid>
                    </React.Fragment>
                  );
                }
              })}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" sx={{ marginBottom: 10 }}>
              Legal info
            </Typography>
            <Link
              component="button"
              type="button"
              variant="body1"
              color="inherit"
              underline="always"
              onClick={clickOnTermsAndConditionsLinkHandler}
            >
              Terms and Conditions
            </Link>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ marginBottom: 10 }}>
              Currency
            </Typography>
            <BasicSelect
              id="currency"
              value={authorizedUser.currency}
              name="currency"
              placeholder="Select currency"
              className="select-like-dropdown"
              placeholderStyles={{ fontSize: { xs: 12, md: 20 }, fontWeight: 700 }}
              onChange={(e: any) => {
                dispatch(changeUserCurrency(e.target.value));
              }}
            >
              {getAllCurrencies().map((type, index) => {
                return (
                  <MenuItem value={type.id} key={index}>
                    {type.name}
                  </MenuItem>
                );
              })}
            </BasicSelect>
          </Grid>
        </Grid>
      </DashboardBasicContainer>

      <TermAndConditionsDialog
        dialogProps={{
          open: isShowTermsAndConditionsDialog,
          onClose: () => {
            showTermsAndConditionsDialog(false);
          },
          fullWidth: true,
          withCloseIcon: true,
          PaperProps: { sx: { maxWidth: '970px' } },
        }}
      />
    </>
  );
};
