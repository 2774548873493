import { isNotUndefinedOrNull } from './utils';

export const createNewWindowInCenter = (url: string, title: string, w: number, h: number) => {
  const dualScreenLeft = isNotUndefinedOrNull(window.screenLeft) ? window.screenLeft : window.screenX;
  const dualScreenTop = isNotUndefinedOrNull(window.screenTop) ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  return window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `,
  );
};
