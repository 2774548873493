import { Box } from '@mui/material';
import { styled } from '@mui/system';

import actionDirectionArrow from '../../../../assets/icons/action-direction-arrow.svg';

type StyledDatePickerProps = {
  isOpen: boolean;
  withoutBorder: boolean;
  aheadIconUrl?: string;
};

type StyledDatePickerContainerProps = {
  isBlackColorPalette: boolean;
  isInlineContainer: boolean;
  withoutBorder: boolean;
};

type StyledDatePickerPopperProps = {
  withoutBorder: boolean;
};

export const StyledDatePickerInputContainer = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isOpen',
})<StyledDatePickerProps>(({ theme, isOpen, withoutBorder, aheadIconUrl }) => ({
  '.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input': {
    width: '100%',
    height: '40px',
  },

  '.react-datepicker-wrapper.datepicker-input-wrapper': {
    '.react-datepicker__input-container': {
      display: 'flex',
      alignItems: 'center',

      input: {
        padding: '14px 32px 13px 10px',
        border: withoutBorder ? 'none' : 'solid 1px #000000',
        borderRadius: '5px',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        outline: 'none',

        '&::placeholder': {
          color: 'inherit',
        },
      },

      '&::after': {
        content: "''",
        position: 'absolute',
        right: '10px',
        display: 'inline-block',
        width: '18px',
        height: '100%',
        transform: isOpen ? 'rotate(180deg)' : '',
        background: `no-repeat center url(${actionDirectionArrow})`,
      },
    },
  },

  '.react-datepicker-wrapper.datepicker-dropdown-wrapper': {
    '.react-datepicker__input-container': {
      display: 'flex',
    },

    '.datepicker-dropdown-value': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '0 32px 0 10px',
      cursor: 'pointer',

      '&::before': {
        content: "''",
        display: aheadIconUrl ? 'block' : 'none',
        width: '25px',
        height: '25px',
        flexShrink: '0',
        marginRight: '10px',
        background: `no-repeat center url(${aheadIconUrl})`,

        [theme.breakpoints.down('md')]: {
          width: '16px',
          height: '16px',
          backgroundSize: 'contain',
        },
      },

      '&::after': {
        content: "''",
        position: 'absolute',
        right: '10px',
        display: 'inline-block',
        width: '18px',
        height: '100%',
        transform: isOpen ? 'rotate(180deg)' : '',
        background: `no-repeat center url(${actionDirectionArrow})`,
      },
    },
  },
}));

export const StyledPopperContainer = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'withoutBorder',
})<StyledDatePickerPopperProps>(({ theme, withoutBorder }) => ({
  ".react-datepicker-popper[data-placement^='bottom']": {
    padding: withoutBorder ? '0' : '10px',

    '.react-datepicker__triangle': {
      '&:before': {
        borderBottomColor: '#000000',
      },

      '&:after': {
        borderBottomColor: '#ffffff',
      },
    },
  },
}));

export const StyledCalendarContainer = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isBlackColorPalette' && propName !== 'isInlineContainer',
})<StyledDatePickerContainerProps>(({ theme, isBlackColorPalette, isInlineContainer, withoutBorder }) => ({
  display: isInlineContainer ? 'flex' : 'inline-block',
  justifyContent: isInlineContainer ? 'center' : undefined,
  fontFamily: 'inherit',
  fontSize: '12px',
  border: isBlackColorPalette || withoutBorder ? 'none' : 'solid 1px #000000',
  borderRadius: isInlineContainer ? '20px' : undefined,
  backgroundColor: isBlackColorPalette ? theme.palette.common.black : theme.palette.common.white,

  [theme.breakpoints.up('md')]: {
    fontSize: isInlineContainer ? '20px' : '14px',
  },

  '.react-datepicker__triangle': {
    left: ' -10px !important',
  },

  '.react-datepicker__header': {
    padding: '16px 0 0 0',
    borderBottom: 'none',
    borderRadius: isInlineContainer ? '20px' : undefined,
    backgroundColor: isBlackColorPalette ? 'transparent' : theme.palette.common.white,
  },

  '.react-datepicker__current-month': {
    paddingBottom: '10px',
    fontSize: isInlineContainer ? '20px' : undefined,
    fontWeight: 400,
    color: isBlackColorPalette ? theme.palette.common.white : theme.palette.common.black,
  },

  '.react-datepicker__day': {
    width: isInlineContainer ? '2.2rem' : undefined,
    borderRadius: '5px',
    lineHeight: isInlineContainer ? '2.2rem' : undefined,
    color: isBlackColorPalette ? theme.palette.common.white : theme.palette.common.black,
    outline: 'none',

    '&:hover:not(&--disabled):not(&--disable-selection):not(&--highlighted-range):not(&--highlighted-blocked-date)': {
      color: isBlackColorPalette ? theme.palette.common.black : theme.palette.common.white,
      backgroundColor: isBlackColorPalette ? theme.palette.common.white : theme.palette.common.black,
    },

    [theme.breakpoints.up('md')]: {
      width: isInlineContainer ? '3rem' : undefined,
      lineHeight: isInlineContainer ? '3rem' : undefined,
    },

    '&-name': {
      width: isInlineContainer ? '2.2rem' : undefined,
      lineHeight: isInlineContainer ? '1.4rem' : undefined,
      color: isBlackColorPalette ? theme.palette.common.white : theme.palette.common.black,
      fontWeight: 700,

      [theme.breakpoints.up('md')]: {
        width: isInlineContainer ? '3rem' : undefined,
      },
    },

    '&--disabled, &--disable-selection': {
      color: '#808080',
    },

    '&--highlighted-blocked-date': {
      position: 'relative',

      '&:before': {
        content: "''",
        position: 'absolute',
        top: isInlineContainer ? '16px' : '12',
        left: '4px',
        width: '28px',
        height: '1px',
        opacity: '0.5',
        backgroundColor: isBlackColorPalette ? theme.palette.common.white : theme.palette.common.black,
        transform: 'rotate(-40deg)',

        [theme.breakpoints.up('md')]: {
          top: isInlineContainer ? '23px' : '12px',
          left: '0',
          width: isInlineContainer ? '46px' : '28px',
        },
      },
    },

    '&--disable-selection': {
      backgroundColor: 'unset',
    },

    '&--selected': {
      backgroundColor: isBlackColorPalette ? theme.palette.common.white : theme.palette.common.black,
      color: isBlackColorPalette ? theme.palette.common.black : theme.palette.common.white,
    },

    '&--in-range, &--in-selecting-range, &--highlighted-range': {
      backgroundColor: isBlackColorPalette ? theme.palette.secondary.main : theme.palette.common.black,
      color: isBlackColorPalette ? theme.palette.common.black : theme.palette.secondary.main,
    },

    '&--keyboard-selected': {
      backgroundColor: 'initial',

      '&:hover': {
        backgroundColor: 'initial',
      },
    },
  },

  '.react-datepicker__navigation': {
    top: '8px',

    '&-icon': {
      '&:before': {
        borderColor: isBlackColorPalette ? theme.palette.common.white : theme.palette.common.black,
        borderWidth: '2px 2px 0 0',
        borderRadius: '2px',
      },
    },
  },
}));
