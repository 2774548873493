import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/system';

export interface StyledUploadedItemProps extends BoxProps {
  previewImg: string;
}

export interface StyledDropzoneProps extends BoxProps {
  previewImg: string | undefined;
}

export const StyledDropzone = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'previewImg',
})<StyledDropzoneProps>(({ theme, previewImg }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '170px',
  height: '170px',
  position: 'relative',
  padding: theme.spacing(15),
  textAlign: 'center',
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: '10px',
  cursor: 'pointer',
  overflow: 'hidden',
  background: `url(${previewImg}) center center no-repeat`,
  backgroundSize: 'contain',

  '&:hover': {
    '.btn-block': {
      opacity: '1',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },

  '.btn-block': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: '0',
    textAlign: 'center',
    transition: '.5s',

    button: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}));

export const StyledUploadedItem = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'previewImg',
})<StyledUploadedItemProps>(({ theme, previewImg }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '170px',
  height: '170px',
  position: 'relative',
  cursor: 'pointer',
  borderRadius: '10px',
  background: `url(${previewImg}) center center no-repeat`,
  backgroundSize: 'contain',
  marginRight: theme.spacing(15),
  marginBottom: theme.spacing(15),

  '&:hover': {
    '.btn-block': {
      opacity: '1',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },

  '.btn-block': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: '0',
    borderRadius: 'inherit',
    textAlign: 'center',
    transition: '.5s',

    button: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}));
