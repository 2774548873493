import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import amenitiesReducer from 'features/amenities/amenitiesSlice';
import authReducer from 'features/authentication/authSlice';
import bookingReducer from 'features/booking/bookingSlice';
import brandCampaignsReducer from 'features/brand-campaigns/brandCampaignsSlice';
import brandDashboardReducer from 'features/brand-dashboard/brandDashboardSlice';
import brandPaymentsReducer from 'features/brand-payments/brandPaymentsSlice';
import currencyReducer from 'features/currency/currencySlice';
import drawerReducer from 'features/drawer/drawerSlice';
import furnitureReducer from 'features/furniture/furnitureSlice';
import loaderReducer from 'features/loader/loaderSlice';
import bookingsManagerDashboardReducer from 'features/manager-dashboard/bookings/bookingsManagerDashboardSlice';
import personalizedBookingReducer from 'features/manager-dashboard/create-personalized-booking/personalizedBookingSlice';
import managerDashboardReducer from 'features/manager-dashboard/managerDashboardSlice';
import notifierReducer from 'features/notifier/notifierSlice';
import preBookingReducer from 'features/pre-booking/preBookingSlice';

export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    loader: loaderReducer,
    notifier: notifierReducer,
    auth: authReducer,
    currency: currencyReducer,
    preBooking: preBookingReducer,
    booking: bookingReducer,
    brandCampaigns: brandCampaignsReducer,
    brandPayments: brandPaymentsReducer,
    managerDashboard: managerDashboardReducer,
    personalizedBooking: personalizedBookingReducer,
    bookingsManagerDashboard: bookingsManagerDashboardReducer,
    brandDashboard: brandDashboardReducer,
    furniture: furnitureReducer,
    amenities: amenitiesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
