import { Box, BoxProps, Card } from '@mui/material';
import { styled } from '@mui/system';

export interface StyledHeroSectionProps extends BoxProps {
  imageUrl: string;
}

export const StyledHeroSection = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'imageUrl',
})<StyledHeroSectionProps>(({ theme, imageUrl }) => ({
  position: 'relative',
  height: '360px',
  background: `url(${imageUrl}) no-repeat center`,
  backgroundSize: 'cover',

  [theme.breakpoints.up('md')]: {
    height: '560px',
  },
}));

export const StyledStreetCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '600px',
  height: '300px',
  borderRadius: '5px',
  backgroundSize: 'cover',
  boxShadow: 'none',

  [theme.breakpoints.up('md')]: {
    cursor: 'pointer',

    '&:hover': {
      '.street-description': {
        opacity: '1',
        maxHeight: '200px',
      },
    },
  },

  '.street-description': {
    marginTop: '10px',

    [theme.breakpoints.up('md')]: {
      maxHeight: '15px',
      opacity: '0',
      transition: '1s',
    },
  },

  '.MuiCardContent-root': {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '10px !important',
    color: theme.palette.common.white,

    [theme.breakpoints.up('md')]: {
      paddingTop: '58px',
      paddingRight: '10px',
      paddingLeft: '10px',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    },
  },

  '.MuiCardMedia-root': {
    height: '100%',
  },

  '.mobile-background': {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.4)',
    },
  },
}));

export const StyledMobileHeroSectionBackground = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0',
  zIndex: '1',
  background: 'linear-gradient(180deg, #7D7D7D 19.6%, rgba(125, 125, 125, 0) 53.71%)',
  transform: 'rotate(-180deg)',
  mixBlendMode: 'multiply',
}));
