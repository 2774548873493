import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/system';

interface StyledOutlinedInputProps {
  isWhiteColorPalette: boolean;
}

export const StyledOutlinedInput = styled(OutlinedInput, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isWhiteColorPalette',
})<StyledOutlinedInputProps>(({ theme, isWhiteColorPalette }) => ({
  '&.MuiOutlinedInput-root.MuiInputBase-multiline': {
    padding: 0,
  },

  '&.MuiOutlinedInput-root': {
    borderRadius: '5px',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,

    '&.input-rounded': {
      borderRadius: '20px',
    },

    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderColor: isWhiteColorPalette ? theme.palette.common.white : theme.palette.common.black,
      },
    },

    '&.Mui-error': {
      '.MuiOutlinedInput-input': {
        color: theme.palette.error.main,

        '&::placeholder': {
          color: theme.palette.error.main,
        },
      },

      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },
    },
  },

  '.MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
    borderColor: isWhiteColorPalette ? theme.palette.common.white : theme.palette.common.black,
  },

  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: isWhiteColorPalette ? theme.palette.common.white : theme.palette.common.black,
    },
  },

  '.MuiOutlinedInput-input': {
    height: 'initial',

    '&::placeholder': {
      color: theme.palette.common.black,
      opacity: '1',
    },

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      webkitAppearance: 'none',
      margin: 0,
    },

    '&[type=number]': {
      mozAppearance: 'textfield',
    },
  },
}));
