import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IconRoundedNumber1 } from '../../../assets/icons/rounded-number-1.svg';
import { ReactComponent as IconRoundedNumber2 } from '../../../assets/icons/rounded-number-2.svg';
import { ReactComponent as IconRoundedNumber3 } from '../../../assets/icons/rounded-number-3.svg';
import { BasicButton } from '../../../common/components/basic/Button';
import { HorizontalStepper } from '../../../common/components/HorizontalStepper';
import { RePopLogoBlock } from '../../../common/components/RePopLogoBlock';
import { useAppSelector } from '../../../common/hooks/redux-hooks';
import { useOnBeforeUnload } from '../../../common/hooks/use-on-before-unload';
import { selectPreBookingState } from '../preBookingSlice';

export const Welcome = (): JSX.Element => {
  const { preCampaignState, selectedSpot, selectedStore } = useAppSelector(selectPreBookingState);
  const steps = ['Welcome', 'Proposal', 'Checkout'];
  const stepsDescriptionListData = [
    { iconComponent: <IconRoundedNumber1 width="100%" height="100%" />, title: 'Check the booking proposal' },
    {
      iconComponent: <IconRoundedNumber2 width="100%" height="100%" />,
      title: 'Save your spot by only making the deposit',
    },
    {
      iconComponent: <IconRoundedNumber3 width="100%" height="100%" />,
      title: 'Look for your new STATION on your dashboard',
    },
  ];
  const spotNameWithStoreAddress =
    selectedSpot?.name && selectedStore?.address?.shortAddress
      ? `${selectedSpot.name}, ${selectedStore.address.shortAddress}`
      : '';

  const navigate = useNavigate();

  useOnBeforeUnload();

  return (
    <Grid container sx={{ minHeight: { md: '100vh' }, alignItems: { md: 'stretch' } }}>
      <Grid item container sm={12} md={6} lg={6} sx={{ justifyContent: 'flex-end' }}>
        <Grid
          item
          sm={12}
          md={12}
          lg={10}
          sx={{ width: '100%', maxWidth: { md: '630px' }, padding: { xs: 8, md: 15 } }}
        >
          <RePopLogoBlock sx={{ margin: { xs: '0 auto', md: 'initial' }, marginBottom: { xs: 13, md: '8.5vh' } }} />

          <HorizontalStepper
            steps={steps}
            activeStep={0}
            sx={{ display: { xs: 'block', md: 'none' }, paddingBottom: 15 }}
          ></HorizontalStepper>

          <Box sx={{ paddingBottom: { xs: 15, md: '7.5vh' } }}>
            <Typography variant="h1" sx={{ typography: { xs: 'h3', md: 'h1' }, textTransform: { md: 'uppercase' } }}>
              Welcome {preCampaignState?.brandName}
            </Typography>
          </Box>

          <Box
            sx={{
              width: { xs: 'calc(100% + 16px * 2)', md: '100%' },
              marginLeft: { xs: '-16px', md: '0' },
              paddingX: { xs: 10, md: 21 },
              paddingY: { xs: 20, md: 25 },
              backgroundColor: (theme) => theme.palette.common.black,
            }}
          >
            <Typography
              sx={{
                typography: { xs: 'body2', md: 'subtitle2' },
                textAlign: 'center',
                color: (theme) => theme.palette.common.white,
                lineHeight: { xs: '20px', md: '30px' },
              }}
            >
              We are sending you the request for the STATION in {spotNameWithStoreAddress}. This is a personalized
              price, in a personalized message. You will have 24 hours to complete the process of the deposit. If not,
              the proposal will expire.{' '}
              <Box sx={{ fontWeight: '700' }} component="span">
                Good luck!
              </Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item container sm={12} md={6} lg={6}>
        <Grid
          item
          sm={12}
          md={12}
          lg={10}
          sx={{ width: '100%', maxWidth: { md: '630px' }, padding: { xs: 8, md: 15 } }}
        >
          <HorizontalStepper
            steps={steps}
            activeStep={0}
            sx={{
              maxWidth: '300px',
              display: { xs: 'none', md: 'block' },
              marginLeft: 'auto',
              paddingTop: '11vh',
              paddingBottom: '12.5vh',
            }}
          ></HorizontalStepper>

          <Typography variant="h3" sx={{ typography: { xs: 'body2', md: 'h3' }, paddingBottom: 15 }}>
            RePop yourself in three simple steps!
          </Typography>

          {stepsDescriptionListData.map((listItem, index) => {
            return (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: { xs: 15, md: 25 } }}>
                <Box sx={{ width: { xs: '24px', md: '30px' }, height: { xs: '24px', md: '30px' } }}>
                  {listItem.iconComponent}
                </Box>

                <Typography
                  sx={{
                    typography: { xs: 'body2', md: 'subtitle2' },
                    paddingLeft: { xs: 5, md: 20 },
                  }}
                >
                  {listItem.title}
                </Typography>
              </Box>
            );
          })}

          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' },
              paddingTop: { xs: 10, md: 30 },
            }}
          >
            <BasicButton
              className="btn-large"
              sx={{ minWidth: '165px', marginRight: { xs: 6, md: 15 } }}
              variant="outlined"
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </BasicButton>
            <BasicButton
              className="btn-large btn-yellow"
              sx={{ minWidth: '165px' }}
              variant="contained"
              type="button"
              onClick={() => {
                navigate('/pre-booking/proposal');
              }}
            >
              Next
            </BasicButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
