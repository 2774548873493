import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledStripeElementBlock = styled(Box)(({ theme }) => ({
  '.StripeElement': {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.common.black,
    borderRadius: '20px',
    minHeight: '40px',
    padding: '13px 10px 12px',

    '&--invalid': {
      borderColor: 'red',
    }
  }
}));
