export enum EXTERNAL_SIGN_IN {
  Google = 0,
  Facebook = 1,
}

export const getAllExternalSignInTypes = () => {
  return [
    {
      id: EXTERNAL_SIGN_IN.Google,
      name: 'google',
    },
    {
      id: EXTERNAL_SIGN_IN.Facebook,
      name: 'facebook',
    },
  ];
};

export const getById = (externalSignInId: EXTERNAL_SIGN_IN): any => {
  return getAllExternalSignInTypes().find((x: any) => x.id === externalSignInId);
};

export const getByName = (externalSignInName: string) => {
  return getAllExternalSignInTypes().find((x: any) => x.name == externalSignInName);
};
