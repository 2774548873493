import React, { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import repopBackground from '../../assets/images/repop-backgrounds/repop-backgound3-1x.png';
import { BasicButton } from '../../common/components/basic/Button';
import { BasicInput } from '../../common/components/basic/Input';
import { GridWithRepopBackground } from '../../common/components/GridWithRepopBackground';
import { RePopLogoBlock } from '../../common/components/RePopLogoBlock';
import { useAppDispatch } from '../../common/hooks/redux-hooks';
import { useStateFormControlHandler } from '../../common/hooks/use-state-from-control-handler';
import { passwordRecovery } from '../authentication/authSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../notifier/notifierSlice';

export const RecoveryPassword = (): JSX.Element => {
  const [recoveryPasswordData, setRecoveryPasswordData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const { newPassword, confirmNewPassword } = recoveryPasswordData;
  const { inputHandler } = useStateFormControlHandler(recoveryPasswordData, setRecoveryPasswordData);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const submitHandler = (e: any) => {
    e.preventDefault();
    const { uId, token } = Object.fromEntries([...searchParams]);

    if (newPassword !== confirmNewPassword) {
      dispatch(
        enqueueSnackbarAction({
          message: 'Password should be the same with conform password',
          options: { variant: 'error' },
        }),
      );
    } else if (uId && token && newPassword) {
      dispatch(
        passwordRecovery({
          userId: uId,
          token: token,
          newPassword: newPassword,
        }),
      );
    }
  };

  return (
    <GridWithRepopBackground columnBackground={'black'} repopBackground={repopBackground} sx={{ textAlign: 'center' }}>
      <Grid container sx={{ justifyContent: { sm: 'center', md: 'flex-start' } }}>
        <Grid item xs={12} sm={10} md={12} lg={11}>
          <RePopLogoBlock sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '8.5vh' }} />
        </Grid>
        <Grid item xs={12} sm={10} md={12} lg={11}>
          <Typography variant="subtitle2" sx={{ paddingBottom: 6, paddingTop: { xs: 72, md: 52 } }}>
            Reset your password
          </Typography>

          <Typography variant="body1" sx={{ paddingBottom: 10 }}>
            Reset your password to be able to enter your
            <br /> dashboard and see your stores
          </Typography>
        </Grid>
      </Grid>

      <form>
        <Grid container spacing={10} sx={{ justifyContent: { sm: 'center', md: 'flex-start' } }}>
          <Grid item xs={12} sm={10} md={12} lg={11}>
            <BasicInput
              id="new-password"
              value={newPassword}
              fullWidth
              className="input-rounded"
              inputProps={{
                name: 'newPassword',
                type: 'password',
                placeholder: 'New password',
                autoComplete: 'new-password',
                onChange: inputHandler,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={12} lg={11} sx={{ paddingBottom: 10 }}>
            <BasicInput
              id="confirm-new-password"
              value={confirmNewPassword}
              fullWidth
              className="input-rounded"
              inputProps={{
                name: 'confirmNewPassword',
                type: 'password',
                placeholder: 'Confirm new password',
                autoComplete: 'new-password',
                onChange: inputHandler,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={12} lg={11}>
            <BasicButton
              className="btn-large btn-yellow fs-20px"
              sx={{ minWidth: '170px' }}
              variant="contained"
              onClick={submitHandler}
            >
              Submit
            </BasicButton>
          </Grid>
        </Grid>
      </form>
    </GridWithRepopBackground>
  );
};
