import React from 'react';

import { Typography } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import { StyledAddNewCreditCardBlock } from './styles';

export const AddNewCreditCardBlock = ({
  title,
  showIframeAction,
}: {
  title: string;
  showIframeAction: () => void;
}): JSX.Element => {
  return (
    <StyledAddNewCreditCardBlock onClick={showIframeAction}>
      <Typography variant="body2" sx={{ typography: { xs: 'body2', md: 'body1' }, textAlign: 'center' }}>
        {title}
      </Typography>
      <PlusIcon></PlusIcon>
    </StyledAddNewCreditCardBlock>
  );
};
