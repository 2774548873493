import React, { useEffect, useState } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { addDays, addWeeks, format, isWithinInterval, parseJSON } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { DURATION_FILTER_DATA } from '../../../constants/ui';
import { selectBookingState, setDateRange } from '../../../features/booking/bookingSlice';
import theme from '../../../theme-config/theme';
import {
  calculateBlockedDates,
  getEachWeekOfInterval,
  getEndOfTheWeek,
  getStartOfTheWeek,
} from '../../helpers/date-heplers';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { Swiper } from '../Swiper';

export const AvailableWeeksBox = ({ sx = {} }): JSX.Element => {
  const { dateRange, selectedSpotBookedDates, selectedSpotBlockedDates } = useAppSelector(selectBookingState);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [availableRanges, setAvailableRanges] = useState<{ startDate: string; endDate: string }[]>([]);
  const [selectedAvailableRangeIndex, setSelectedAvailableRangeIndex] = useState<number>();
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const isUpSmBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));

  const blockedDates = calculateBlockedDates(selectedSpotBlockedDates, selectedSpotBookedDates);
  const durationParameter = searchParams.get('duration');
  const startAvailabilityDateParameter = searchParams.get('date');

  const generateAvailableRanges = () => {
    let arrayOfAvailableRanges: { startDate: string; endDate: string }[] = [];
    const defaultDurationWeeks = DURATION_FILTER_DATA[0].searchParameter;
    const defaultStartAvailabilityDate = blockedDates?.length
      ? addDays(blockedDates[blockedDates.length - 1], 1)
      : new Date();
    const durationWeeks = durationParameter ? durationParameter.split('-') : defaultDurationWeeks.split('-');
    const minimumDurationOfWeeks = parseInt(durationWeeks[0]);
    const maximumDurationOfWeeks = parseInt(durationWeeks[1]);
    const startAvailabilityDate = startAvailabilityDateParameter
      ? getStartOfTheWeek(new Date(startAvailabilityDateParameter))
      : getStartOfTheWeek(defaultStartAvailabilityDate);
    const dayOfLastDurationWeek = addWeeks(new Date(startAvailabilityDate), maximumDurationOfWeeks);
    const weeksToGenerateRanges = getEachWeekOfInterval(new Date(startAvailabilityDate), dayOfLastDurationWeek);

    weeksToGenerateRanges.forEach((firstDayOfWeek) => {
      const firstDateOfRange = getStartOfTheWeek(firstDayOfWeek);
      //we already have first week as firstDateOfRange, so we need to remove one week from the minimumDurationOfWeeks
      const lastDateOfRange = getEndOfTheWeek(addWeeks(firstDateOfRange, minimumDurationOfWeeks - 1));
      const rangeObj = {
        startDate: firstDateOfRange.toISOString(),
        endDate: lastDateOfRange.toISOString(),
      };
      const isExistOverlapDateWithBlockedDates = blockedDates?.find((blockedDate) =>
        isWithinInterval(blockedDate.setHours(0, 0, 0, 0), { start: firstDateOfRange, end: lastDateOfRange }),
      );

      !isExistOverlapDateWithBlockedDates && arrayOfAvailableRanges.push(rangeObj);
    });

    setAvailableRanges(arrayOfAvailableRanges);
  };

  const clickOnAvailableWeekItemHandler = (index: number) => {
    dispatch(
      setDateRange({
        startDateStr: availableRanges[index].startDate,
        endDateStr: availableRanges[index].endDate,
      }),
    );

    if (index === selectedAvailableRangeIndex) {
      setSelectedAvailableRangeIndex(undefined);
      dispatch(
        setDateRange({
          startDateStr: undefined,
          endDateStr: undefined,
        }),
      );
    } else {
      setSelectedAvailableRangeIndex(index);
    }
  };

  useEffect(() => {
    generateAvailableRanges();
  }, [durationParameter, startAvailabilityDateParameter, blockedDates?.length]);

  useEffect(() => {
    if (!(dateRange.startDateStr && dateRange.endDateStr)) {
      //reset selected item in case when range has been unselected by the CrossIcon in the BookNowBox component
      setSelectedAvailableRangeIndex(undefined);
    }
  }, [dateRange]);

  return (
    <>
      {availableRanges.length ? (
        <Box
          sx={{
            width: { xs: '100%', sm: 'calc(85% - 20px)', lg: 'calc(57% - 20px)' },
            position: { xs: 'relative', sm: 'absolute' },
            bottom: { xs: 'initial', sm: '20px' },
            left: { xs: 'initial', sm: '20px' },
            zIndex: 1,
            ...sx,
          }}
        >
          <Typography
            sx={{
              typography: { xs: 'h6', md: 'subtitle2' },
              marginBottom: 5,
              color: { sm: theme.palette.common.white },
            }}
          >
            Next Available Weeks
          </Typography>
          <Swiper
            className={'available-weeks'}
            rewind={true}
            {...(availableRanges.length > 3 && { singleNavigationArrow: true })}
            slidesPerView={3}
            spaceBetween={isUpMdBreakPoint ? 30 : 10}
          >
            {availableRanges.map((range, index) => {
              const isSelectedRange = index === selectedAvailableRangeIndex;

              return (
                <Box
                  key={index}
                  sx={{
                    textAlign: 'center',
                    padding: { xs: '10px 10px', lg: '10px 30px' },
                    borderRadius: '25px',
                    transition: '.5s',
                    background: (theme: Theme) =>
                      isSelectedRange ? theme.palette.secondary.main : theme.palette.common.black,
                    color: (theme: Theme) =>
                      isSelectedRange ? theme.palette.common.black : theme.palette.common.white,
                    cursor: 'pointer',
                  }}
                  onClick={() => clickOnAvailableWeekItemHandler(index)}
                >
                  <Typography sx={{ typography: { xs: 'body2', md: 'body1' }, marginBottom: 1 }}>
                    {isUpSmBreakPoint && (
                      <Box component="b" sx={{ marginRight: 2 }}>
                        From:
                      </Box>
                    )}

                    {format(parseJSON(range.startDate), 'dd/MM/yyyy')}
                  </Typography>

                  {!isUpSmBreakPoint && (
                    <Typography sx={{ typography: { xs: 'body2', md: 'body1' }, marginBottom: 1 }}>-</Typography>
                  )}

                  <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                    {isUpSmBreakPoint && (
                      <Box component="b" sx={{ marginRight: 2 }}>
                        To:
                      </Box>
                    )}

                    {format(parseJSON(range.endDate), 'dd/MM/yyyy')}
                  </Typography>
                </Box>
              );
            })}
          </Swiper>
        </Box>
      ) : null}
    </>
  );
};
