import React, { useEffect, useState } from 'react';

import { Box, FormControl, FormControlLabel, Grid, Link, MenuItem, Radio, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { BasicButton } from '../../../common/components/basic/Button';
import { BasicDatePicker } from '../../../common/components/basic/DatePicker';
import { BasicInput } from '../../../common/components/basic/Input';
import { BasicRadioBtnGroup } from '../../../common/components/basic/RadioBtnGroup';
import { BasicSelect } from '../../../common/components/basic/Select';
import { DashboardBasicContainer } from '../../../common/components/dashboard/BasicContainer';
import { DashboardCaption } from '../../../common/components/dashboard/Caption';
import { TermAndConditionsDialog } from '../../../common/components/Dialogs/TermAndConditions';
import { calculateBlockedDates, isSaturday } from '../../../common/helpers/date-heplers';
import {
  findSpotByIdInPreCampaign,
  findStoreBySpotIdInPreCampaign,
} from '../../../common/helpers/find-data-in-pre-campaign-filter';
import { findPartInAddressObjByType } from '../../../common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { useFormControlHandler } from '../../../common/hooks/use-form-control-handler';
import { PRE_CAMPAIGN_DEPOSIT_TYPES } from '../../../constants/enums/pre-campaign/pre-campaign-deposit-types';
import { getById as getPreCampaignDepositValueById } from '../../../constants/enums/pre-campaign/pre-campaign-deposit-value';
import SpotDTO from '../../../models/spot/spot-dto';
import SpotsPreCampaignFilterDTO from '../../../models/spot/spots-pre-campaign-filter-dto';
import { CountriesService } from '../../../services/countries';
import { SpotManagerService } from '../../../services/spot-manager';
import { BookingRequestReadyDialog } from './BookingRequestReadyDialog';
import { BookingSummaryTable } from './BookingSummaryTable';
import { GuidelineList } from './GuidelineList';
import {
  createPreCampaign,
  getAllSpotsByCountry,
  getPreCampaignById,
  getSpotBlockedDatesBySpotId,
  getSpotBookedDatesBySpotId,
  resetPreCampaignEdit,
  selectPersonalizedBookingState,
  updatePreCampaignEdit,
  updatePreCampaignFilterData,
  updateSelectedData,
} from './personalizedBookingSlice';

export const CreatePersonalizedBooking = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { preCampaignEditState, preCampaignFormState } = useAppSelector(selectPersonalizedBookingState);

  const countriesService: CountriesService = new CountriesService();
  const spotManagerService: SpotManagerService = new SpotManagerService();

  const [isShowBookingRequestReadyDialog, showBookingRequestReadyDialog] = useState(false);
  const [isShowTermsAndConditionsDialog, showTermsAndConditionsDialog] = useState(false);
  const [countries, setCountriesData] = useState<Array<string>>([]);

  const dispatch = useAppDispatch();
  const { inputHandler } = useFormControlHandler(dispatch, updatePreCampaignEdit);

  let formattedStoreAddress: string | undefined;
  const spotIdParameter = searchParams.get('spot_id');
  const preCampaignIdParameter = searchParams.get('pre_campaign_id');
  const { spotBlockedDate, spotBookedDate } = preCampaignFormState?.selectedData || {};
  const blockedDates = (spotBlockedDate || spotBookedDate) && calculateBlockedDates(spotBlockedDate, spotBookedDate);

  const {
    brandName,
    spotId,
    depositType,
    depositValue,
    vatInPercentage,
    discountInPercentage,
    startDate,
    endDate,
    beforeCheckIn,
    duringCheckOut,
    message,
  } = preCampaignEditState;

  const changeCountryHandler = (e: any) => {
    dispatch(updatePreCampaignEdit({ spotId: null }));
    dispatch(updatePreCampaignEdit({ startDate: undefined, endDate: undefined }));
    dispatch(updateSelectedData({ country: e.target.value }));
    spotManagerService
      .getSpotsByCountry(e.target.value)
      .showLoader(false)
      .execute()
      .then((res: SpotsPreCampaignFilterDTO) => {
        dispatch(updatePreCampaignFilterData(res));
      });
  };

  const setSelectedSpotInfo = (spotId: number) => {
    let preCampaignFilter = preCampaignFormState?.preCampaignFilterData;
    let isExistingSpotInPreCampaignFilter = preCampaignFilter?.spots?.find((spot: SpotDTO) => spot.id === spotId);
    let selectedStore = preCampaignFilter && findStoreBySpotIdInPreCampaign(spotId, preCampaignFilter);
    let selectedSpotInfo = preCampaignFilter && findSpotByIdInPreCampaign(spotId, preCampaignFilter);
    let storeCountry =
      selectedStore?.address?.parts && findPartInAddressObjByType(selectedStore?.address.parts, 'country')?.name;

    dispatch(
      updateSelectedData({
        spot: selectedSpotInfo,
        store: selectedStore,
        country: storeCountry,
      }),
    );

    dispatch(getSpotBlockedDatesBySpotId(spotId));
    dispatch(getSpotBookedDatesBySpotId(spotId));
    dispatch(updatePreCampaignEdit({ startDate: undefined, endDate: undefined }));

    isExistingSpotInPreCampaignFilter && dispatch(updatePreCampaignEdit({ spotId: spotId }));
  };

  const changeStationHandler = (e: any) => {
    const spotId = e.target.value;

    setSelectedSpotInfo(spotId);
  };

  const clickOnTermsAndConditionsLinkHandler = () => {
    showTermsAndConditionsDialog(true);
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    dispatch(createPreCampaign(preCampaignEditState)).then((data) => {
      if (data.payload) {
        showBookingRequestReadyDialog(true);
      }
    });
  };

  useEffect(() => {
    countriesService
      .getAllCountries()
      .showLoader(false)
      .execute()
      .then((res) => {
        setCountriesData(res.map((name) => name.charAt(0).toUpperCase() + name.slice(1)));
      });

    dispatch(getAllSpotsByCountry());

    if (preCampaignIdParameter) {
      dispatch(getPreCampaignById(preCampaignIdParameter));
    }

    return () => {
      dispatch(resetPreCampaignEdit());
    };
  }, []);

  useEffect(() => {
    //set selected spot after fetching spots, store and countries
    if (preCampaignFormState.preCampaignFilterData && countries) {
      if (spotIdParameter) {
        const parsedSpotIdParameter = parseInt(spotIdParameter);
        setSelectedSpotInfo(parsedSpotIdParameter);
      }

      if (preCampaignIdParameter) {
        setSelectedSpotInfo(spotId);
      }
    }
  }, [preCampaignFormState.preCampaignFilterData, countries, spotId]);

  return (
    <Box>
      <DashboardCaption>Personalized bookings</DashboardCaption>

      <DashboardBasicContainer>
        <form>
          <Grid container rowSpacing={10} columnSpacing={15}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mb: 5, fontWeight: 700 }}>
                Booking form
              </Typography>
              <Typography variant="body1">This form will be sent to the Brand that you choose</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <BasicInput
                id="brand-name"
                value={brandName || ''}
                fullWidth
                inputProps={{
                  name: 'brandName',
                  placeholder: 'Business / Brand Name',
                  onChange: inputHandler,
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <BasicSelect
                fullWidth
                value={
                  preCampaignFormState?.selectedData?.country && countries
                    ? preCampaignFormState.selectedData.country
                    : ''
                }
                name="country"
                id="country-select"
                placeholder="Country"
                onChange={changeCountryHandler}
              >
                {countries.map((country, index) => {
                  return (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  );
                })}
              </BasicSelect>
            </Grid>

            <Grid item xs={12} md={3}>
              <BasicInput
                id="vat-in-percentage"
                value={vatInPercentage || ''}
                fullWidth
                inputProps={{
                  type: 'number',
                  name: 'vatInPercentage',
                  placeholder: 'VAT%',
                  onChange: inputHandler,
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <BasicSelect
                fullWidth
                value={spotId && preCampaignFormState?.preCampaignFilterData?.spots ? spotId : ''}
                name="spotId"
                id="spot-select"
                placeholder="Spot"
                onChange={changeStationHandler}
              >
                {preCampaignFormState?.preCampaignFilterData?.spots.map((spot: SpotDTO) => {
                  formattedStoreAddress = findStoreBySpotIdInPreCampaign(
                    spot.id,
                    preCampaignFormState.preCampaignFilterData,
                  )?.address?.shortAddress;
                  return (
                    <MenuItem key={spot.id} value={spot.id}>
                      {spot.name}
                      {formattedStoreAddress && `, ${formattedStoreAddress}`}
                    </MenuItem>
                  );
                })}
              </BasicSelect>
            </Grid>

            <Grid item xs={12} md={4}>
              <BasicDatePicker
                id="start-date-select"
                name="startDate"
                selectsStart
                selectedStr={startDate}
                startDateStr={startDate || ''}
                endDateStr={endDate || ''}
                highlightDates={[
                  {
                    'react-datepicker__day--highlighted-blocked-date': blockedDates?.length ? blockedDates : [],
                  },
                ]}
                excludeDates={blockedDates}
                defaultHour={8}
                onChange={inputHandler}
                placeholder="Check in date &amp; time"
              ></BasicDatePicker>
            </Grid>

            <Grid item xs={12} md={4}>
              <BasicDatePicker
                id="end-date-select"
                name="endDate"
                selectsEnd
                selectedStr={endDate}
                startDateStr={startDate || ''}
                endDateStr={endDate || ''}
                minDateStr={startDate}
                highlightDates={[
                  {
                    'react-datepicker__day--highlighted-blocked-date': blockedDates?.length ? blockedDates : [],
                  },
                ]}
                excludeDates={blockedDates}
                defaultHour={17}
                onChange={inputHandler}
                placeholder="Check out date &amp; time"
              ></BasicDatePicker>
            </Grid>

            <Grid item xs={12} md={4}>
              <BasicInput
                id="discount-in-percentage"
                value={discountInPercentage || ''}
                fullWidth
                inputProps={{
                  type: 'number',
                  name: 'discountInPercentage',
                  placeholder: 'Apply discount (% off)',
                  onChange: inputHandler,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <BasicRadioBtnGroup
                label="Deposit amount:"
                radioGroupProps={{
                  name: 'depositType',
                  value: `${depositType}, ${depositValue}` || '',
                  row: true,
                  onChange: (e: any) => {
                    const depositValues = e.target.value.split(',');
                    const parsedDepositType = parseInt(depositValues?.[0]);
                    const parsedDepositValue = parseInt(depositValues?.[1]);

                    dispatch(
                      updatePreCampaignEdit({ [e.target.name]: parsedDepositType, depositValue: parsedDepositValue }),
                    );
                  },
                }}
              >
                <FormControlLabel
                  value={`${PRE_CAMPAIGN_DEPOSIT_TYPES.OfThePrice}, 15`}
                  control={<Radio />}
                  label={getPreCampaignDepositValueById(0)?.name}
                />
                <FormControlLabel
                  value={`${PRE_CAMPAIGN_DEPOSIT_TYPES.Monthly}, 1`}
                  control={<Radio />}
                  label="1 month"
                />
              </BasicRadioBtnGroup>
            </Grid>

            <Grid item container direction="column" sx={{ mt: 5 }} xs={12} md={6}>
              <GuidelineList
                editable
                title="Before your Pop-up - Check in"
                guidelines={beforeCheckIn}
                guidelineInputProps={{
                  id: 'before-check-in',
                  name: 'beforeCheckIn',
                  placeholder: 'Add a guideline',
                }}
              />
            </Grid>

            <Grid item container direction="column" sx={{ mt: 5 }} xs={12} md={6}>
              <GuidelineList
                editable
                title="During your Pop-up - Check out"
                guidelines={duringCheckOut}
                guidelineInputProps={{
                  id: 'during-check-out',
                  name: 'duringCheckOut',
                  placeholder: 'Add a guideline',
                }}
              ></GuidelineList>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700, pt: 15, pb: 5 }}>
                Terms and Conditions
              </Typography>
              <Link
                component="button"
                type="button"
                variant="body1"
                color="inherit"
                underline="always"
                onClick={clickOnTermsAndConditionsLinkHandler}
              >
                Open the Terms and Conditions
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700, pt: 15, pb: 28 }}>
                Booking summary
              </Typography>
              <Grid item container xs={12} md={8} sx={{ mx: 'auto' }}>
                <BookingSummaryTable></BookingSummaryTable>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700, pt: 15, mb: 5 }}>
                Message
              </Typography>

              <FormControl fullWidth={true}>
                <BasicInput
                  id="message"
                  value={message || ''}
                  fullWidth
                  multiline={true}
                  rows={8}
                  formControlProps={{
                    sx: {
                      mr: 5,
                    },
                  }}
                  inputProps={{
                    name: 'message',
                    placeholder: 'Write a comment to the client',
                    onChange: inputHandler,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <BasicButton
                className="btn-black btn-large"
                sx={{ minWidth: '170px', display: 'block', marginTop: 15, mr: 0, marginBottom: 10, ml: 'auto' }}
                variant="contained"
                onClick={submitHandler}
              >
                Confirm
              </BasicButton>
            </Grid>
          </Grid>
        </form>
      </DashboardBasicContainer>

      <BookingRequestReadyDialog
        dialogProps={{
          open: isShowBookingRequestReadyDialog,
          onClose: () => {
            showBookingRequestReadyDialog(false);
          },
          fullWidth: true,
          PaperProps: { sx: { maxWidth: '570px' } },
        }}
      />
      <TermAndConditionsDialog
        dialogProps={{
          open: isShowTermsAndConditionsDialog,
          onClose: () => {
            showTermsAndConditionsDialog(false);
          },
          fullWidth: true,
          withCloseIcon: true,
          PaperProps: { sx: { maxWidth: '970px' } },
        }}
      />
    </Box>
  );
};
