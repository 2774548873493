import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import PaymentMethodDTO from '../../models/payment/payment-method-dto';
import { UploadedFileInfoDTO } from '../../models/ui/uploaded-file-info-dto';
import { PaymentsBrandService } from '../../services/payments-brand';

interface initialStateTypes {
  availablePaymentMethods: Array<PaymentMethodDTO>;
  bankAccountFiles: UploadedFileInfoDTO[];
  shownPayPlusIframe: boolean;
  shownStripeIframe: boolean;
  payPlusIframeUrl: string;
}

const initialState = {
  availablePaymentMethods: [],
  bankAccountFiles: [],
  shownPayPlusIframe: false,
  shownStripeIframe: false,
  payPlusIframeUrl: '',
} as initialStateTypes;

const paymentsBrandService: PaymentsBrandService = new PaymentsBrandService();

export const setDefaultPaymentMethod = createAsyncThunk('setDefaultPaymentMethod', async (id: number) => {
  return await paymentsBrandService.setDefaultPaymentMethod(id).execute();
});

export const deletePaymentMethodById = createAsyncThunk('deletePaymentMethodById', async (id: number) => {
  return await paymentsBrandService.deletePaymentMethodById(id).execute();
});

export const getAvailablePaymentMethods = createAsyncThunk('getAvailablePaymentMethods', async () => {
  return await paymentsBrandService.getAvailablePaymentMethods().execute();
});

export const getPayPlusIframeUrl = createAsyncThunk('getPayPlusIframeUrl', async () => {
  return await paymentsBrandService.getPayPlusIframeLink().execute();
});

export const brandPaymentsSlice = createSlice({
  name: 'brandPayment',
  initialState,
  reducers: {
    addNewCreditCard: (state, action) => {
      const indexOfPreviousDefaultPaymentMethod = state.availablePaymentMethods.findIndex((item) => item.isDefault);
      const previousDefaultPaymentMethod = state.availablePaymentMethods[indexOfPreviousDefaultPaymentMethod];

      previousDefaultPaymentMethod ? (previousDefaultPaymentMethod.isDefault = false) : null;
      state.availablePaymentMethods.push(action.payload);
    },
    setBankAccountFile: (state, action) => {
      state.bankAccountFiles = action.payload;
    },
    setPayPlusIframeShowStatus: (state, action) => {
      state.shownPayPlusIframe = action.payload;
    },
    setStripeIframeShowStatus: (state, action) => {
      state.shownStripeIframe = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAvailablePaymentMethods.fulfilled, (state, action: PayloadAction<PaymentMethodDTO[]>) => {
      state.availablePaymentMethods = action.payload;
    });
    builder.addCase(getPayPlusIframeUrl.fulfilled, (state, action) => {
      state.payPlusIframeUrl = action.payload.link;
    });
    builder.addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
      const indexOfNewDefaultPaymentMethod = state.availablePaymentMethods.findIndex(
        (item) => item.id === action.meta.arg,
      );
      const newDefaultPaymentMethod = state.availablePaymentMethods[indexOfNewDefaultPaymentMethod];
      const indexOfPreviousDefaultPaymentMethod = state.availablePaymentMethods.findIndex((item) => item.isDefault);
      const previousDefaultPaymentMethod = state.availablePaymentMethods[indexOfPreviousDefaultPaymentMethod];

      previousDefaultPaymentMethod ? (previousDefaultPaymentMethod.isDefault = false) : null;
      newDefaultPaymentMethod ? (newDefaultPaymentMethod.isDefault = true) : null;
    });
    builder.addCase(deletePaymentMethodById.fulfilled, (state, action) => {
      const indexOfDeletedPaymentMethod = state.availablePaymentMethods.findIndex(
        (item) => item.id === action.meta.arg,
      );

      state.availablePaymentMethods.splice(indexOfDeletedPaymentMethod, 1);
    });
  },
});

export const selectBrandPaymentsState = function (state: RootState): any {
  return state.brandPayments;
};

export const { addNewCreditCard, setPayPlusIframeShowStatus, setStripeIframeShowStatus, setBankAccountFile } =
  brandPaymentsSlice.actions;

export default brandPaymentsSlice.reducer;
