import ApiRequestWrapper from 'api/api-request-wrapper';
import ApiUrlProvider from 'api/api-url-provider';
import FeedbackManagerDTO from 'models/feedback/feedback-manager-dto';
import SpotDTO from 'models/spot/spot-dto';
import SpotEditDTO from 'models/spot/spot-edit-dto';
import SpotsPreCampaignFilterDTO from 'models/spot/spots-pre-campaign-filter-dto';

export class SpotManagerService {
  private readonly basePath: string = 'manager/spots';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getSpotsByCountry(country: string): ApiRequestWrapper<SpotsPreCampaignFilterDTO> {
    return this.apiUrlProvider.post(this.basePath + '/pre-campaign-filter/', { country: country });
  }

  public getAllSpotsByCountry(): ApiRequestWrapper<SpotsPreCampaignFilterDTO> {
    return this.apiUrlProvider.post(this.basePath + '/pre-campaign-filter', { country: '' });
  }

  public getFeedbackBySpotId(id: number): ApiRequestWrapper<Array<FeedbackManagerDTO>> {
    return this.apiUrlProvider.get(`${this.basePath}/${id}/feedbacks`);
  }

  public createSpot(spotEdit: SpotEditDTO): ApiRequestWrapper<SpotDTO> {
    return this.apiUrlProvider.post(`${this.basePath}/${spotEdit.storeId}`, spotEdit);
  }

  public updateSpot(spotEdit: SpotEditDTO, spotId: number): ApiRequestWrapper<SpotDTO> {
    return this.apiUrlProvider.put(`${this.basePath}/${spotEdit.storeId}/${spotId}`, spotEdit);
  }

  public deleteSpot(spotId: number, storeId: number) {
    return this.apiUrlProvider.delete(this.basePath + `/${storeId}/${spotId}`);
  }
}
