import { Theme } from '@mui/material/styles';

export const termAndConditionsDialog = (theme: Theme) =>
  ({
    '&': {
      maxWidth: '700px',
      m: '0 auto',
      pt: 4,
    },

    '.MuiTypography-body1': {
      lineHeight: '15px',
    },
 
    '.MuiListItem-root': {
      pt: 0,
      pb: 0,
    },
  } as const);
