import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import AddressDTO from '../models/address/address-dto';
import BlockedDatesDTO from '../models/blocked-dates-dto';
import BookedDatesDTO from '../models/booked-dates-dto';
import FeedbackDTO from '../models/feedback/feedback-dto';
import NewestSpotFilterDTO from '../models/spot/newest-spot-filter-dto';
import SpotDTO from '../models/spot/spot-dto';
import SpotsAllDTO from '../models/spot/spots-all-dto';
import SpotsFilterDTO from '../models/spot/spots-filter-dto';

export class SpotsService {
  private readonly basePath: string = 'spots';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getSpotById(id: number): ApiRequestWrapper<SpotDTO> {
    return this.apiUrlProvider.get(this.basePath + `/${id}`);
  }

  public getAllSpots(): ApiRequestWrapper<SpotDTO[]> {
    return this.apiUrlProvider.get(`${this.basePath}`);
  }

  public getSpotsByFilter(filter: SpotsFilterDTO): ApiRequestWrapper<SpotsAllDTO> {
    return this.apiUrlProvider.post(`${this.basePath}/filter`, filter);
  }

  public availableLocations(): ApiRequestWrapper<AddressDTO[]> {
    return this.apiUrlProvider.get(`${this.basePath}/available-locations`);
  }

  public getBlockedDatesBySpotId(id: number): ApiRequestWrapper<BlockedDatesDTO[]> {
    return this.apiUrlProvider.get(`${this.basePath}/${id}/blocked-dates`);
  }

  public getBookedDatesBySpotId(id: number): ApiRequestWrapper<BookedDatesDTO[]> {
    return this.apiUrlProvider.get(`${this.basePath}/${id}/booking-dates`);
  }

  public getFeedbackBySpotId(id: number): ApiRequestWrapper<FeedbackDTO[]> {
    return this.apiUrlProvider.get(`${this.basePath}/${id}/feedbacks`);
  }

  public getNewestSpots(count: number): ApiRequestWrapper<NewestSpotFilterDTO> {
    return this.apiUrlProvider.get(`${this.basePath}/news?count=${count}`);
  }
}
