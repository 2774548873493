import React from 'react';

import { Typography } from '@mui/material';

import { StyledTitleWithEmojiIcon } from './styles';

export const TitleWithEmojiIcon = ({ children, title, sx = {} }: TitleWithEmojiIconProps): JSX.Element => {
  return (
    <StyledTitleWithEmojiIcon
      className="title"
      sx={[{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'flex-start' } }, sx]}
    >
      <Typography sx={{ typography: { xs: 'h2', md: 'h1' } }}>{title}</Typography>
      {children}
    </StyledTitleWithEmojiIcon>
  );
};

interface TitleWithEmojiIconProps {
  children: JSX.Element;
  title: string;
  sx?: object;
}
