import { Divider, DividerProps } from '@mui/material';
import { styled } from '@mui/system';

interface StyledDividerProps extends DividerProps {
  isVertical: boolean;
}

export const StyledDivider = styled(Divider, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isVertical',
})<StyledDividerProps>(({ theme, isVertical }) => ({
  borderColor: theme.palette.common.black,
  border: isVertical ? 'none' : undefined,
  backgroundColor: isVertical ? theme.palette.common.black : undefined,
  width: isVertical ? '1px' : undefined,
}));
