import { getAllLanguages, getDefaultLanguage } from 'constants/enums/language';

import React from 'react';

import { Grid, MenuItem } from '@mui/material';
import { BasicButton } from 'common/components/basic/Button';
import { BasicInput } from 'common/components/basic/Input';
import { BasicSelect } from 'common/components/basic/Select';
import SpotTranslateDTO from 'models/translations/spot-translate-dto';

export const DashboardSpotTranslationBox = ({
  translations,
  changeHandler,
}: DashboardSpotTranslationBoxProps): JSX.Element => {
  const changeTranslationHandler = (e: any, index: number) => {
    const value = e.target.value;
    const name = e.target.name;

    const newState = [
      ...translations.slice(0, index),
      {
        ...translations[index],
        [name]: value,
      },
      ...translations.slice(index + 1),
    ];
    changeHandler(newState);
  };

  const clickOnAddNewRangeHandler = () => {
    const previousTranslations = translations ? translations : [];

    changeHandler([
      ...previousTranslations,
      {
        language: null,
        name: '',
        description: '',
      },
    ]);
  };

  const clickOnRemoveRangeHandler = (index: number) => {
    const newState = [...translations.slice(0, index), ...translations.slice(index + 1)];
    changeHandler(newState);
  };

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        spacing={10}
        sx={{ marginBottom: translations?.length ? 20 : undefined }}
      >
        {translations?.length
          ? translations.map((translationItem, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={5}>
                    <BasicSelect
                      value={translationItem.language ? translationItem.language : ''}
                      name="language"
                      fullWidth={true}
                      placeholder="Language"
                      onChange={(e: any) => {
                        changeTranslationHandler(e, index);
                      }}
                    >
                      {getAllLanguages().map((language, index) => {
                        if (language.id !== getDefaultLanguage().id) {
                          return (
                            <MenuItem value={language.id} key={index}>
                              {language.name}
                            </MenuItem>
                          );
                        }
                      })}
                    </BasicSelect>
                  </Grid>

                  <Grid item xs={5}>
                    <BasicInput
                      value={translationItem.name || ''}
                      fullWidth
                      inputProps={{
                        name: 'name',
                        placeholder: 'Spot name translation',
                        onChange: (e: any) => {
                          changeTranslationHandler(e, index);
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={10}>
                    <BasicInput
                      value={translationItem.description || ''}
                      fullWidth
                      multiline={true}
                      rows={10}
                      inputProps={{
                        name: 'description',
                        placeholder: 'Spot Description translation',
                        onChange: (e: any) => {
                          changeTranslationHandler(e, index);
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={10} sx={{ textAlign: 'center' }}>
                    <BasicButton
                      className="btn-black btn-small"
                      variant="contained"
                      onClick={() => clickOnRemoveRangeHandler(index)}
                    >
                      Remove translation
                    </BasicButton>
                  </Grid>
                </React.Fragment>
              );
            })
          : null}

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <BasicButton className="btn-black btn-small" variant="contained" onClick={clickOnAddNewRangeHandler}>
            Add new translations
          </BasicButton>
        </Grid>
      </Grid>
    </>
  );
};

interface DashboardSpotTranslationBoxProps {
  translations: SpotTranslateDTO[];
  changeHandler: (translations: SpotTranslateDTO[]) => void;
}
