import { getBase64Strings } from 'exif-rotate-js';

export async function fileUploaderHelper(files: File[]): Promise<string[]> {
  const blobFiles: Blob[] = files;
  let fileInfoArr: string[] = [];
  const base64Strings = await getBase64Strings(blobFiles, { maxSize: 1024, type: 'image/png' });

  if (files.length > 0) {
    files.map((file, index) => {
      fileInfoArr.push(base64Strings[index]);
    });
  }

  return fileInfoArr;
}
