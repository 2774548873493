import { AppDispatch } from '../../app/store';
import { getByName as getExternalSignTypeByName } from '../../constants/enums/external-sign-in';
import { isJsonString, isNotUndefinedOrNull } from './utils';

export const receiveMessageHandler = (dispatcher: AppDispatch, action: any, e: any, externalSignInWindow: any) => {
  if (e.origin !== location.origin || !isJsonString(e.data)) return;

  const data = JSON.parse(e.data);
  const providerType = getExternalSignTypeByName(data.provider)?.id;

  if (data.accessToken && isNotUndefinedOrNull(providerType)) {
    dispatcher(
      action({
        signInProvider: providerType,
        accessToken: data.accessToken,
      }),
    ).then(() => {
      externalSignInWindow?.close();
    });
  }
};
