import { useEffect } from 'react';

import { getById as getCurrencyById, getDefault as getDefaultCurrency } from '../../constants/enums/currency';
import { selectAuthState } from '../../features/authentication/authSlice';
import { getCurrencyRates } from '../../features/currency/currencySlice';
import { isNotUndefinedOrNull } from '../helpers/utils';
import { useAppDispatch, useAppSelector } from './redux-hooks';

export const useSetCurrencyRatesByUserCurrency = () => {
  const { authorizedUser } = useAppSelector(selectAuthState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const currency = isNotUndefinedOrNull(authorizedUser?.currency)
      ? getCurrencyById(authorizedUser.currency).name
      : getDefaultCurrency().name;

    dispatch(getCurrencyRates(currency));
  }, [authorizedUser, dispatch]);
};
