import React from 'react';

import { DialogContent, DialogTitle, IconButton } from '@mui/material';

import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { StyledDialog } from './styles';

export const BaseDialog = ({ dialogProps, children }: BaseDialogProps): JSX.Element => {
  let { onClose, closeByClickOnBackdrop = true, withCloseIcon, ...otherDialogProps } = dialogProps;

  return (
    <StyledDialog
      {...otherDialogProps}
      onClose={() => {
        closeByClickOnBackdrop && onClose();
      }}
    >
      {withCloseIcon && (
        <DialogTitle>
          <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 20, top: 20 }}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
};

interface BaseDialogProps {
  dialogProps: any;
  children: JSX.Element | JSX.Element[];
}
