import React from 'react';

import { SummaryTable } from '../../../../common/components/SummaryTable';
import { getDaysBetweenTwoDates } from '../../../../common/helpers/date-heplers';
import { findStoreBySpotIdInPreCampaign } from '../../../../common/helpers/find-data-in-pre-campaign-filter';
import { getFormattedDatesRange, getFormattedPrice } from '../../../../common/helpers/formatters';
import { useAppSelector } from '../../../../common/hooks/redux-hooks';
import { useCurrencyConverter } from '../../../../common/hooks/use-currency-converter';
import { getById as getCurrencyById } from '../../../../constants/enums/currency';
import { PRE_CAMPAIGN_DEPOSIT_TYPES } from '../../../../constants/enums/pre-campaign/pre-campaign-deposit-types';
import { getById as getDepositValueById } from '../../../../constants/enums/pre-campaign/pre-campaign-deposit-value';
import { selectPersonalizedBookingState } from '../personalizedBookingSlice';

export const BookingSummaryTable = (): JSX.Element => {
  const { preCampaignEditState, preCampaignFormState } = useAppSelector(selectPersonalizedBookingState);
  const { selectedData } = preCampaignFormState;
  const { depositType, vatInPercentage, discountInPercentage, startDate, endDate } = preCampaignEditState;
  const selectedStoreWorkingDays = findStoreBySpotIdInPreCampaign(
    preCampaignEditState.spotId,
    preCampaignFormState.preCampaignFilterData,
  )?.workingDays;
  const parsedStartDate = startDate && new Date(startDate);
  const parsedEndDate = endDate && new Date(endDate);

  const selectedSpotAddress =
    selectedData?.spot?.name && selectedData?.store?.address?.shortAddress
      ? `${selectedData.spot.name}, ${selectedData.store.address.shortAddress}`
      : '';

  const selectedSpotCurrency = getCurrencyById(selectedData?.spot?.currency);
  const { convertedPrice: convertedSpotPrice = 0, currencySymbol = '' } = useCurrencyConverter(
    selectedSpotCurrency,
    selectedData?.spot?.price,
  );
  const daysDuration =
    selectedStoreWorkingDays && getDaysBetweenTwoDates(parsedStartDate, parsedEndDate, selectedStoreWorkingDays);
  const totalAmount = daysDuration && convertedSpotPrice * daysDuration;
  const formattedPricePerWeek = getFormattedPrice(convertedSpotPrice * 7, currencySymbol);
  const formattedTotalAmount = totalAmount && getFormattedPrice(totalAmount, currencySymbol);

  const calculateDeposit = () => {
    const percentage = getDepositValueById(0)?.value;

    if (totalAmount && depositType === PRE_CAMPAIGN_DEPOSIT_TYPES.OfThePrice) {
      return (totalAmount * percentage) / 100;
    } else if (totalAmount && depositType === PRE_CAMPAIGN_DEPOSIT_TYPES.Monthly) {
      return totalAmount * 30;
    } else {
      return 0;
    }
  };

  const summaryTableData = [
    {
      headTitle: selectedSpotAddress ? selectedSpotAddress : 'Spot',
      rowValue: formattedPricePerWeek && formattedPricePerWeek + '/Week',
      isHighlightedTitle: true,
    },
    {
      headTitle: 'Discount applied',
      rowValue: discountInPercentage && discountInPercentage + '%',
    },
    {
      headTitle: 'Dates',
      rowValue: getFormattedDatesRange(parsedStartDate, parsedEndDate),
    },
    {
      headTitle: 'Total days',
      rowValue: daysDuration && daysDuration,
    },
    {
      headTitle: 'Total Amount',
      rowValue: formattedTotalAmount && formattedTotalAmount,
      isHighlightedTitle: true,
      isHighlightedValue: true,
    },
    {
      headTitle: 'VAT',
      rowValue: vatInPercentage && vatInPercentage + '%',
    },
    {
      headTitle: 'Deposit',
      rowValue: getFormattedPrice(calculateDeposit(), currencySymbol),
    },
  ];

  return (
    <SummaryTable
      tableData={summaryTableData}
      styles={{
        '&': { fontSize: { xs: '14px', md: '20px' } },
        '> div:not(:last-of-type) .table-row:not(.table-row-highlighted)': { marginBottom: 10 },
        '.table-row.table-row-highlighted': { marginTop: 10, marginBottom: 20 },
      }}
    ></SummaryTable>
  );
};
