import React from 'react';

import { Box, Link, Typography } from '@mui/material';

import { BaseDialog } from '../../BaseDialog';

export const SuccessfullyBookedPopUpDialog = ({
  dialogProps,
  spotName,
  bookingDates,
}: SuccessfullyBookedPopUpDialogProps): JSX.Element => {
  return (
    <BaseDialog dialogProps={dialogProps}>
      <Box sx={{ textAlign: 'center', paddingY: 12 }}>
        <Typography variant="h2" sx={{ marginBottom: 20 }}>
          You just booked your Pop-Up
        </Typography>
        <Typography variant="h3">Congratulations!</Typography>
        <Typography variant="subtitle2" sx={{ marginBottom: 20 }}>
          Your Pop-Up at REPOP it’s just arround the corner!
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 6 }}>
          {' '}
          You just booked:
        </Typography>
        <Typography variant="h3" sx={{ marginBottom: 10 }}>
          {spotName}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 6 }}>
          Dates:
        </Typography>
        <Typography variant="subtitle2" sx={{ marginBottom: 30 }}>
          {bookingDates}
        </Typography>
        <Link
          component="button"
          type="button"
          variant="body1"
          sx={{ typography: { xs: 'body2', md: 'body1' } }}
          color="inherit"
          underline="always"
          onClick={() => {
            window.location.replace('/');
          }}
        >
          Go back to the Homepage
        </Link>
      </Box>
    </BaseDialog>
  );
};

interface SuccessfullyBookedPopUpDialogProps {
  dialogProps: object;
  spotName: string;
  bookingDates: string;
}
