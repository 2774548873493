import { Drawer, DrawerProps } from '@mui/material';
import { styled } from '@mui/system';

import { MOBILE_HEADER_BREAKPOINT_DASHBOARD } from '../../../../constants/ui';

interface StyledLeftDrawer extends DrawerProps {
  leftNavDrawerWidth: number;
}

export const StyledDashboardLeftNavDrawer = styled(Drawer, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'leftNavDrawerWidth',
})<StyledLeftDrawer>(({ theme, leftNavDrawerWidth }) => ({
  '&.mobile-left-drawer, &.desktop-left-drawer': {
    '.MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: leftNavDrawerWidth,
      backgroundColor: '#000000',
    },
  },

  '&.mobile-left-drawer': {
    display: 'block',

    [theme.breakpoints.up(MOBILE_HEADER_BREAKPOINT_DASHBOARD)]: {
      display: 'none',
    },

    '.MuiDrawer-paper': {
      padding: '20px 16px',
    },
  },

  '&.desktop-left-drawer': {
    display: 'none',
    width: leftNavDrawerWidth,
    flexShrink: 0,

    [theme.breakpoints.up(MOBILE_HEADER_BREAKPOINT_DASHBOARD)]: {
      display: 'block',
    },

    '.MuiDrawer-paper': {
      padding: '30px 12px',
    },
  },
}));
