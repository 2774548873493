import { Stepper } from '@mui/material';
import { styled } from '@mui/system';

interface StyledHorizontalStepperProps {
  colorPalette: string;
}

export const StyledHorizontalStepper = styled(Stepper, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'colorPalette',
})<StyledHorizontalStepperProps>(({ theme, colorPalette }) => ({
  '.MuiStep-root': {
    '.MuiStepLabel-labelContainer': {
      '.MuiStepLabel-alternativeLabel': {
        marginTop: theme.spacing(4),
        fontSize: '12px',
        color: colorPalette,
      },
    },

    '.MuiStepConnector-root': {
      top: '15px',
      left: 'calc(-50% + 15px)',
      right: 'calc(50% + 15px)',

      '.MuiStepConnector-line': {
        borderTopWidth: '2px',
        borderColor: colorPalette,
      },
    },
  },

  '.MuiStep-root.Mui-completed': {
    '.MuiStepConnector-root': {
      '.MuiStepConnector-line': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },

  '.MuiStepConnector-root.Mui-active': {
    '.MuiStepConnector-line': {
      borderColor: theme.palette.secondary.main,
    },
  },

  '.MuiStepLabel-labelContainer': {
    '.MuiStepLabel-alternativeLabel.Mui-active': {
      fontWeight: 700,
    },
  },
}));

export const StepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(
  ({ theme, ownerState }) => ({
    display: 'flex',
    width: '30px',
    height: '30px',
    fontSize: '20px',
    borderRadius: '50%',
    fontWeight: ownerState.active ? '700' : '400',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: ownerState.active ? '3px' : '1px',
    borderColor: ownerState.active || ownerState.completed ? theme.palette.secondary.main : theme.palette.common.black,
    backgroundColor: ownerState.completed ? theme.palette.secondary.main : theme.palette.common.white,
  }),
);
