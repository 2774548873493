import React, { useState } from 'react';

import { Grid, Typography } from '@mui/material';

import wavingHandIcon from '../../assets/icons/emoji/waving-hand.png';
import repopBackground from '../../assets/images/repop-backgrounds/repop-backgound1-1x.png';
import { BasicButton } from '../../common/components/basic/Button';
import { BasicInput } from '../../common/components/basic/Input';
import { GridWithRepopBackground } from '../../common/components/GridWithRepopBackground';
import { RePopLogoBlock } from '../../common/components/RePopLogoBlock';
import { TitleWithEmojiIcon } from '../../common/components/TitleWithEmojiIcon';
import { useAppDispatch } from '../../common/hooks/redux-hooks';
import { useStateFormControlHandler } from '../../common/hooks/use-state-from-control-handler';
import UserRecoveryRequestDTO from '../../models/user/user-recovery-request-dto';
import { passwordRecoveryRequest } from '../authentication/authSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../notifier/notifierSlice';

export const ForgotPassword = (): JSX.Element => {
  const initialState = {} as UserRecoveryRequestDTO;
  const [forgotPasswordData, setForgotPasswordData] = useState(initialState);
  const { email } = forgotPasswordData;
  const { inputHandler } = useStateFormControlHandler(forgotPasswordData, setForgotPasswordData);
  const dispatch = useAppDispatch();

  const submitHandler = (e: any) => {
    e.preventDefault();
    dispatch(passwordRecoveryRequest(forgotPasswordData)).then((data) => {
      if (data.meta.requestStatus !== 'fulfilled') return;

      dispatch(
        enqueueSnackbarAction({
          message: 'Check your email for a password reset link',
          options: { variant: 'success' },
        }),
      );

      setForgotPasswordData(initialState);
    });
  };

  return (
    <GridWithRepopBackground
      columnBackground={'#000000'}
      repopBackground={repopBackground}
      sx={{ textAlign: 'center' }}
    >
      <Grid container sx={{ justifyContent: { sm: 'center', md: 'flex-start' } }}>
        <Grid item xs={12} sm={10} md={12} lg={11}>
          <RePopLogoBlock sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '8.5vh' }} />
        </Grid>

        <Grid item xs={12} sm={10} md={12} lg={11}>
          <TitleWithEmojiIcon
            title="Hi there!"
            sx={{ paddingTop: { xs: 25, md: 'initial' }, paddingBottom: { xs: 33, md: '7.5vh' } }}
          >
            <img src={wavingHandIcon} className="emoji-icon" alt="waving-hand-emoji" />
          </TitleWithEmojiIcon>
        </Grid>

        <Grid item xs={12} sm={10} md={12} lg={11}>
          <Typography variant="subtitle2" sx={{ paddingBottom: 6 }}>
            Forgot your password? Don’t worry!
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={12} lg={11}>
          <Typography variant="body1" sx={{ paddingBottom: 10 }}>
            Write down your email liked with your REPOP account.
            <br /> We will send you a recovery email
          </Typography>
        </Grid>
      </Grid>

      <form>
        <Grid container spacing={10} sx={{ justifyContent: { sm: 'center', md: 'flex-start' } }}>
          <Grid item xs={12} sm={10} md={12} lg={11} sx={{ paddingBottom: 10 }}>
            <BasicInput
              id="email"
              value={email || ''}
              fullWidth
              className="input-rounded"
              inputProps={{
                name: 'email',
                placeholder: 'Email',
                onChange: inputHandler,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={12} lg={11}>
            <BasicButton
              className="btn-large btn-yellow fs-20px"
              sx={{ minWidth: '170px' }}
              variant="contained"
              onClick={submitHandler}
            >
              Submit
            </BasicButton>
          </Grid>
        </Grid>
      </form>
    </GridWithRepopBackground>
  );
};
