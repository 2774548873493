import React from 'react';

import { Box, IconButton } from '@mui/material';

import { ReactComponent as CloseArrowIcon } from '../../../../assets/icons/close-arrow.svg';
import { hideRightNavDrawer, selectDrawerState } from '../../../../features/drawer/drawerSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { StyledRightDrawer } from './styles';

export const DashboardRightDrawer = ({ contentElements }: { contentElements: JSX.Element[] }): JSX.Element => {
  const { isOpenRightDrawer, nameOfContentElementForRightDrawer } = useAppSelector(selectDrawerState);
  const selectedContentElement = contentElements?.find(
    (element) => element.props['data-name'] === nameOfContentElementForRightDrawer,
  );
  const dispatch = useAppDispatch();
  const handleDrawerToggle = () => {
    dispatch(hideRightNavDrawer());
  };
  return (
    <StyledRightDrawer anchor="right" open={isOpenRightDrawer} onClose={handleDrawerToggle}>
      <Box role="presentation">
        <IconButton
          sx={{ p: 0, m: 0 }}
          color="inherit"
          aria-label="close right drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <CloseArrowIcon />
        </IconButton>

        {selectedContentElement}
      </Box>
    </StyledRightDrawer>
  );
};
