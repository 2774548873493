import React from 'react';

import { Container, ContainerProps } from '@mui/material';

import { basicContainerStyles } from './styles';

export const BasicContainer = ({ children, sx = [], disableGutters = true }: BasicContainer): JSX.Element => {
  return (
    <Container
      maxWidth="lg"
      sx={[basicContainerStyles, ...(Array.isArray(sx) ? sx : [sx])]}
      disableGutters={disableGutters}
    >
      {children}
    </Container>
  );
};

interface BasicContainer extends ContainerProps {
  children: React.ReactNode;
}
