import React, { useEffect, useState } from 'react';

import { Box, CardContent, CardMedia, Typography } from '@mui/material';
import cardTemp from 'assets/images/temp/cardTemp.png';
import { BasicButton } from 'common/components/basic/Button';
import { useAppDispatch } from 'common/hooks/redux-hooks';
import { deleteSpots } from 'features/manager-dashboard/managerDashboardSlice';
import { enqueueNotification as enqueueSnackbarAction } from 'features/notifier/notifierSlice';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ImgService } from 'services/img-service';

import { StyledSpotCardDashboard } from './styles';

export const DashboardSpotCard = ({
  name,
  storeId,
  spotId,
  shortAddress,
  imageId,
}: StoreCardDashboardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [imgPreview, setImgPreview] = useState('');

  const imgService: ImgService = new ImgService();

  const navigateToSpotPage = () => {
    navigate(`edit-spot/${storeId}/${spotId}`);
  };

  const handleClickOnEdit = (e: any) => {
    e.stopPropagation();
    navigateToSpotPage();
  };

  const handleClickOnMakeNewBooking = (e: any) => {
    e.stopPropagation();

    if (spotId) {
      const searchParamsObj: Record<string, string> = { spot_id: spotId.toString() };

      navigate({
        pathname: `personalized-booking`,
        search: `${createSearchParams(searchParamsObj)}`,
      });
    }
  };

  const handleClickOnDelete = (e: any) => {
    e.stopPropagation();

    if (spotId && storeId) {
      dispatch(deleteSpots({ spotId, storeId })).then(() => {
        dispatch(
          enqueueSnackbarAction({
            message: 'Spot was successfully deleted',
            options: { variant: 'success' },
          }),
        );
      });
    }
  };

  useEffect(() => {
    const imgPreviewLink = imageId ? imgService.getImgPreviewUrl(imageId, { width: 600, height: 600 }) : cardTemp;
    setImgPreview(imgPreviewLink);
  }, [imageId]);

  return (
    <StyledSpotCardDashboard>
      <CardMedia component="img" image={imgPreview ? imgPreview : cardTemp} />
      <CardContent>
        <Box className="navigations">
          <BasicButton
            className="btn-large transparent transparent--white"
            sx={{ minWidth: '200px', marginBottom: 10 }}
            variant="outlined"
            onClick={handleClickOnEdit}
          >
            Edit
          </BasicButton>

          <BasicButton
            className="btn-large transparent transparent--white"
            sx={{ minWidth: '200px', marginBottom: 10 }}
            variant="outlined"
            onClick={handleClickOnDelete}
          >
            Delete
          </BasicButton>

          <BasicButton
            className="btn-large transparent transparent--white"
            sx={{ minWidth: '200px' }}
            variant="outlined"
            onClick={handleClickOnMakeNewBooking}
          >
            Make new booking
          </BasicButton>
        </Box>
        <Box className="address">
          <Typography variant="h3">
            {name}, {shortAddress}
          </Typography>
        </Box>
      </CardContent>
    </StyledSpotCardDashboard>
  );
};

interface StoreCardDashboardProps {
  name: string;
  shortAddress: string;
  spotId: number;
  storeId: number;
  imageId: string;
}
