import CampaignBundleDTO from '../../models/campaign/campaign-bundle-dto';
import CampaignBundleItemDTO from '../../models/campaign/campaign-bundle-item-dto';

export const getRelatedDataToCampaign = (
  campaignId: number,
  bookingsBundleData: CampaignBundleDTO,
): CampaignBundleItemDTO => {
  const relatedCampaign = bookingsBundleData?.searchPage?.items?.find((preCampaign) => preCampaign.id === campaignId);
  const relatedPreCampaign = bookingsBundleData.preCampaigns.find(
    (preCampaign) => preCampaign.id === relatedCampaign?.preCampaignId,
  );
  const relatedSpot = bookingsBundleData?.spots?.find((spot) => spot.id === relatedCampaign?.spotId);
  const relatedStore = bookingsBundleData?.stores?.find((store) => store.id === relatedSpot?.storeId);
  const relatedBrand = bookingsBundleData?.brands?.find((brand) => brand.id === relatedCampaign?.brandId);

  return {
    campaign: relatedCampaign,
    preCampaign: relatedPreCampaign,
    spot: relatedSpot,
    store: relatedStore,
    brand: relatedBrand,
  } as CampaignBundleItemDTO;
};
