import React from 'react';

import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';

import theme from '../../../theme-config/theme';

export const Chip = ({ label, variant, isBlackColorPalette = false, sx = [] }: StyledChipProps): JSX.Element => {
  return (
    <MuiChip
      sx={[
        {
          border: `solid 1px ${isBlackColorPalette ? theme.palette.common.black : theme.palette.common.white}`,
          color: isBlackColorPalette ? theme.palette.common.black : theme.palette.common.white,
          borderRadius: '15px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      label={label}
      variant={variant}
    />
  );
};

interface StyledChipProps extends MuiChipProps {
  isBlackColorPalette?: boolean;
}
