import CampaignDTO from '../../models/campaign/campaign-dto';

export const getActionMenuItemsForCampaign = (
  campaign: CampaignDTO,
  clickOnApproveHandler: (campaign: CampaignDTO) => void,
  clickOnRejectHandler: (campaign: CampaignDTO) => void,
) => {
  return [
    {
      title: 'Approve',
      action: () => {
        clickOnApproveHandler(campaign);
      },
    },
    {
      title: 'Reject',
      action: () => {
        clickOnRejectHandler(campaign);
      },
    },
  ];
};
