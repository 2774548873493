export const summaryTableStyles = {
  '.table-row': {
    display: 'flex',
    alignItems: 'flex-end',
  },
  '.table-row-highlighted': {
    fontWeight: '700',
  },
  '.title': {
    width: '100%',
    position: 'relative',
    display: 'block',
    overflow: 'hidden',

    '&:after': {
      content: '""',
      position: 'absolute',
      marginLeft: '.3em',
      bottom: '.2em',
      width: '100%',
      borderBottom: '1px dashed',
    },
  },
  '.value': {
    float: 'right',
    paddingLeft: '.2em',
    whiteSpace: 'nowrap',
  },
} as const;
