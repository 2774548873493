import theme from '../../theme-config/theme';

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const scrollToElement = (ref: any) => {
  if (ref?.current) {
    const headerHeight = window.innerWidth < theme.breakpoints.values.sm ? 80 : 100;
    const position = ref.current.offsetTop - headerHeight;

    window.scrollTo({ top: position, behavior: 'smooth' });
  }
};
