import React, { useEffect } from 'react';

import { Grid, SvgIcon } from '@mui/material';
import { ReactComponent as CheckedMarkBolder } from 'assets/icons/checked-mark-bolder.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { getAmenities, selectAmenitiesState } from 'features/amenities/amenitiesSlice';
import AmenitiesDTO from 'models/amenities-dto';

export const AmenityList = ({ amenityItems }: { amenityItems: Array<number> }): JSX.Element => {
  const { amenitiesList } = useAppSelector(selectAmenitiesState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    !amenitiesList.length && dispatch(getAmenities());
  }, []);

  return (
    <Grid item container spacing={10}>
      {amenityItems?.map((amenityId: number, index: number) => {
        const amenity = amenitiesList?.find((amenity: AmenitiesDTO) => amenity.id === amenityId);

        if (amenity) {
          return (
            <Grid key={index} item xs={6}>
              <SvgIcon
                sx={{
                  width: '11px',
                  height: '9px',
                  fill: 'none',
                  marginRight: '10px',
                }}
                viewBox={'0 0 11 9'}
                component={CheckedMarkBolder}
              />
              {amenity.name}
            </Grid>
          );
        }
      })}
    </Grid>
  );
};
