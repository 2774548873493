import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { BasicButton } from 'common/components/basic/Button';
import { BasicDatePicker } from 'common/components/basic/DatePicker';
import BlockedDatesDTO from 'models/blocked-dates-dto';

export const DashboardBlockedDatesBox = ({
  blockedDates,
  changeHandler,
}: DashboardBlockedDatesBoxProps): JSX.Element => {
  const blockedDatesInitialState = [
    {
      startDate: '',
      endDate: '',
    },
  ] as BlockedDatesDTO[];
  const [blockedDatesState, setBlockedDatesState] = useState<Array<BlockedDatesDTO>>(blockedDatesInitialState);

  const changeDatepickerHandler = (e: any, index: number) => {
    const value = e.target.value;

    const newState = [
      ...blockedDatesState.slice(0, index),
      {
        startDate: value?.from ? value.from : '',
        endDate: value?.to ? value.to : '',
      },
      ...blockedDatesState.slice(index + 1),
    ];

    changeHandler(newState);
  };

  const clickOnAddNewRangeHandler = () => {
    const newState = [
      ...blockedDatesState,
      {
        startDate: '',
        endDate: '',
      },
    ];

    changeHandler(newState);
  };

  const clickOnRemoveRangeHandler = (index: number) => {
    const newState = [...blockedDatesState.slice(0, index), ...blockedDatesState.slice(index + 1)];
    changeHandler(newState);
  };

  useEffect(() => {
    blockedDates?.length ? setBlockedDatesState(blockedDates) : setBlockedDatesState(blockedDatesInitialState);
  }, [blockedDates]);

  return (
    <>
      <Grid item container alignItems="center" spacing={10}>
        {blockedDatesState?.length &&
          blockedDatesState.map((blockedDatesItem, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={7} md={5}>
                  <BasicDatePicker
                    id={`blocked-dates-${index}`}
                    name="blockedDates"
                    isSelectsRange={true}
                    startDateStr={blockedDatesItem?.startDate || ''}
                    endDateStr={blockedDatesItem?.endDate || ''}
                    onChange={(e) => changeDatepickerHandler(e, index)}
                    placeholder="Select blocked dates"
                  ></BasicDatePicker>
                </Grid>

                {(blockedDatesItem?.startDate || blockedDatesItem?.endDate || blockedDatesState.length > 1) && (
                  <Grid item xs={3} md={3}>
                    <BasicButton
                      className="btn-black btn-small"
                      variant="contained"
                      onClick={() => clickOnRemoveRangeHandler(index)}
                    >
                      Remove range
                    </BasicButton>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
      </Grid>
      <Grid item xs={7} md={5} sx={{ textAlign: 'center' }}>
        <BasicButton className="btn-black btn-small" variant="contained" onClick={clickOnAddNewRangeHandler}>
          Add new range
        </BasicButton>
      </Grid>
    </>
  );
};

interface DashboardBlockedDatesBoxProps {
  blockedDates: BlockedDatesDTO[];
  changeHandler: (blockedDates: BlockedDatesDTO[]) => void;
}
