import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import BankAccountVerifyEditDTO from '../models/pre-campaign/bank-account-verify-edit-dto';
import PreCampaignBundleDTO from '../models/pre-campaign/pre-campaign-bundle-dto';
import PreCampaignDTO from '../models/pre-campaign/pre-campaign-dto';
import PreCampaignEditDTO from '../models/pre-campaign/pre-campaign-edit-dto';
import PreCampaignsFilterDTO from '../models/pre-campaign/pre-campaigns-filter-dto';

export class PreCampaignManagerService {
  private readonly basePath: string = 'manager/precampaigns';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public createPreCampaign(preCampaignEdit: PreCampaignEditDTO): ApiRequestWrapper<PreCampaignDTO> {
    return this.apiUrlProvider.post(this.basePath, preCampaignEdit);
  }

  public sendBookingUrlToEmail(id: string, emailAddress: string): ApiRequestWrapper<PreCampaignDTO> {
    return this.apiUrlProvider.post(this.basePath + `/${id}/send-to-mail`, { email: emailAddress });
  }

  public getPreCampaignsByFilter(filter: PreCampaignsFilterDTO): ApiRequestWrapper<PreCampaignBundleDTO> {
    return this.apiUrlProvider.post(this.basePath + '/filter', filter);
  }

  public getPreCampaignsById(id: string): ApiRequestWrapper<PreCampaignDTO> {
    return this.apiUrlProvider.get(this.basePath + `/${id}`);
  }

  public approveBankAccount(
    preCampaignId: string,
    verifyData: BankAccountVerifyEditDTO,
  ): ApiRequestWrapper<PreCampaignDTO> {
    return this.apiUrlProvider.put(this.basePath + `/${preCampaignId}/VerifyBankAccount`, verifyData);
  }

  public deletePreCampaignById(preCampaignId: string): ApiRequestWrapper<boolean> {
    return this.apiUrlProvider.delete(this.basePath + `/${preCampaignId}`);
  }
}
