import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { BasicButton } from '../../../../common/components/basic/Button';
import { BasicInput } from '../../../../common/components/basic/Input';
import { PhotoUploader } from '../../../../common/components/PhotoUploader';
import { Rating } from '../../../../common/components/Rating';
import { useAppDispatch } from '../../../../common/hooks/redux-hooks';
import { useStateFormControlHandler } from '../../../../common/hooks/use-state-from-control-handler';
import FeedbackEditManagerDTO from '../../../../models/feedback/feedback-edit-manager-dto';
import FeedbackManagerDTO from '../../../../models/feedback/feedback-manager-dto';
import ImageEditDTO from '../../../../models/image-edit-dto';
import SpotDTO from '../../../../models/spot/spot-dto';
import { FeedbackManagerService } from '../../../../services/feedback-manager';
import { SpotsService } from '../../../../services/spots';
import { enqueueNotification as enqueueSnackbarAction } from '../../../notifier/notifierSlice';

export const CreateFeedback = ({ feedbackList, changeHandler }: CreateFeedbackProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { spotId: spotIdParameter } = useParams();

  const initialState = {
    spotId: spotIdParameter ? parseInt(spotIdParameter) : '',
  } as FeedbackEditManagerDTO;
  const [feedbackFormState, setFeedbackFormState] = useState<FeedbackEditManagerDTO>(initialState);
  const [relatedSpot, setRelatedSpot] = useState<SpotDTO>();
  const { inputHandler } = useStateFormControlHandler(feedbackFormState, setFeedbackFormState);

  const feedbackManagerService: FeedbackManagerService = new FeedbackManagerService();
  const spotService: SpotsService = new SpotsService();

  const { cleanness, customerSupport, overallExperience, description, brandName, brandLogo } = feedbackFormState;

  const loadedBrandLogoHandler = (files: ImageEditDTO[]) => {
    setFeedbackFormState({
      ...feedbackFormState,
      brandLogo: files[0],
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    feedbackManagerService
      .createFeedback(feedbackFormState)
      .execute()
      .then((data) => {
        if (data?.id) {
          dispatch(
            enqueueSnackbarAction({
              message: 'Feedback successfully created',
              options: { variant: 'success' },
            }),
          );
          setFeedbackFormState(initialState);
          changeHandler([...feedbackList, data]);
        }
      });
  };

  useEffect(() => {
    if (spotIdParameter) {
      spotService
        .getSpotById(parseInt(spotIdParameter))
        .showLoader(false)
        .execute()
        .then((data) => {
          setRelatedSpot(data);
        });
    }
  }, []);

  return (
    <Box sx={{ marginTop: 15 }}>
      <Typography variant="h1" sx={{ textTransform: 'uppercase', marginBottom: 15 }}>
        {`Review for ${relatedSpot?.name}`}
      </Typography>

      <form>
        <Grid container rowSpacing={10} columnSpacing={15} sx={{ marginBottom: 20 }}>
          <Grid item xs={12} md={12}>
            <BasicInput
              id="brand-name"
              value={brandName || ''}
              fullWidth
              inputProps={{
                name: 'brandName',
                placeholder: 'Brand name',
                onChange: inputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <PhotoUploader
              maxFiles={1}
              selectedFiles={brandLogo}
              setSelectedFiles={loadedBrandLogoHandler}
              uploaderBoxStyles={{ justifyContent: 'center' }}
            >
              <Typography>Brand logo</Typography>
              <Typography>(upload)</Typography>
            </PhotoUploader>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Cleanness
            </Typography>
            <Rating
              name="feedback-score-controlled"
              value={cleanness || null}
              onChange={(event, newValue) =>
                setFeedbackFormState({
                  ...feedbackFormState,
                  cleanness: newValue,
                })
              }
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              REPOP Customer support
            </Typography>
            <Rating
              name="feedback-score-controlled"
              value={customerSupport || null}
              onChange={(event, newValue) =>
                setFeedbackFormState({
                  ...feedbackFormState,
                  customerSupport: newValue,
                })
              }
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Overall experience
            </Typography>
            <Rating
              name="feedback-score-controlled"
              value={overallExperience || null}
              onChange={(event, newValue) =>
                setFeedbackFormState({
                  ...feedbackFormState,
                  overallExperience: newValue,
                })
              }
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <BasicInput
              id="feedback-description"
              value={description || ''}
              fullWidth
              multiline={true}
              rows={7}
              inputProps={{
                name: 'description',
                placeholder: 'Write your Review here',
                onChange: inputHandler,
              }}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'} rowSpacing={10} columnSpacing={15} sx={{ marginTop: 10 }}>
          <Grid item>
            <BasicButton
              onClick={() => {
                setFeedbackFormState(initialState);
              }}
              className="btn-large"
              sx={{ minWidth: { xs: '140px', md: '170px' }, display: 'block' }}
            >
              Cancel
            </BasicButton>
          </Grid>

          <Grid item>
            <BasicButton
              className="btn-black btn-large"
              sx={{ minWidth: { xs: '140px', md: '170px' }, display: 'block' }}
              variant="contained"
              onClick={submitHandler}
            >
              <Typography variant="body1">NEXT</Typography>
            </BasicButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

interface CreateFeedbackProps {
  feedbackList: FeedbackManagerDTO[];
  changeHandler: (feedbackList: FeedbackManagerDTO[]) => void;
}
