import React from 'react';

import { Box, ListItem, ListItemText, Stack, Typography } from '@mui/material';

import { BaseDialog } from '../../BaseDialog';
import { MarkedList } from '../../MarkedList';
import { termAndConditionsDialog } from './styles';

export const TermAndConditionsDialog = ({ dialogProps }: any): JSX.Element => {
  return (
    <BaseDialog dialogProps={dialogProps}>
      <Stack sx={termAndConditionsDialog}>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">1. Terms</Typography>
          <Typography variant="body1">
            By accessing the website at www.repop.shop, you are agreeing to be bound by these terms of service, all
            applicable laws and regulations, and agree that you are responsible for compliance with any applicable local
            laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The
            materials contained in this website are protected by applicable copyright and trademark law.
          </Typography>
        </Box>

        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">2. Use License</Typography>
          <Typography variant="body1">
            Permission is granted to temporarily download one copy of the materials (information or software) on Repop
            Shops LTD.&apos;s website for personal, non-commercial transitory viewing only. This is the grant of a
            license, not a transfer of title, and under this license you may not:
          </Typography>
          <MarkedList>
            <ListItem>
              <ListItemText>modify or copy the materials;</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                attempt to decompile or reverse engineer any software contained on Repop Shops LTD.&apos;s website;
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>remove any copyright or other proprietary notations from the materials;</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                or transfer the materials to another person or&quot;mirror&quot; the materials on any other server.
              </ListItemText>
            </ListItem>
          </MarkedList>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">3.</Typography>
          <Typography variant="body1">
            This license shall automatically terminate if you violate any of these restrictions and may be terminated by
            Repop Shops LTD. at any time. Upon terminating your viewing of these materials or upon the termination of
            this license, you must destroy any downloaded materials in your possession whether in electronic or printed
            format.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">4. Disclaimer</Typography>
          <Typography variant="body1">
            The materials on Repop Shops LTD.&apos;s website are provided on an &apos;as is&apos; basis. Repop Shops
            LTD. makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties
            including, without limitation, implied warranties or conditions of merchantability, fitness for a particular
            purpose, or non- infringement of intellectual property or other violation of rights. Further, Repop Shops
            LTD. does not warrant or make any representations concerning the accuracy, likely results, or reliability of
            the use of the materials on its website or otherwise relating to such materials or on any sites linked to
            this site.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">5. Limitations</Typography>
          <Typography variant="body1">
            In no event shall Repop Shops LTD. or its suppliers be liable for any damages (including, without
            limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or
            inability to use the materials on Repop Shops LTD.&apos;s website, even if Repop Shops LTD. or a Repop Shops
            LTD. authorized representative has been notified orally or in writing of the possibility of such damage.
            Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for
            consequential or incidental damages, these limitations may not apply to you.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">6. Accuracy of materials</Typography>
          <Typography variant="body1">
            The materials appearing on Repop Shops LTD.&apos;s website could include technical, typographical, or
            photographic errors. Repop Shops LTD. does not warrant that any of the materials on its website are
            accurate, complete or current. Repop Shops LTD. may make changes to the materials contained on its website
            at any time without notice. However Repop Shops LTD. does not make any commitment to update the materials.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">7. Links</Typography>
          <Typography variant="body1">
            Repop Shops LTD. has not reviewed all of the sites linked to its website and is not responsible for the
            contents of any such linked site. The inclusion of any link does not imply endorsement by Repop Shops LTD.
            of the site. Use of any such linked website is at the user&apos;s own risk.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">8. Modifications</Typography>
          <Typography variant="body1">
            Repop Shops LTD. may revise these terms of service for its website at any time without notice. By using this
            website you are agreeing to be bound by the then current version of these terms of service.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">9. Governing Law</Typography>
          <Typography variant="body1">
            These terms and conditions are governed by and constructed in accordance with the laws of Israel and you
            irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">10. Booking Policy</Typography>
          <Typography variant="body1">
            Users that request to book a spot using the Repop Shops platform will do so after accepting the terms
            defined in this document which includes this booking policy. Any user that will book spots using our
            platform will be charged the spot booking fee + VAT (if applied) + the Repop Shops service fee once their
            booking is approved. Cancellation policies include a 100% return on bookings canceled up to 60 days prior to
            the start-date of the booking and a 50% return on bookings canceled between 59 days and 30 days prior to the
            start date of the booking. If a booking is cancelled by the brand’s side after 30 days prior to the booking
            start date, the brand will be charged the full price of the booking made. The cancellation policy can vary
            and change according to the company’s sole decision.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">11. Payments</Typography>
          <Typography variant="body1">
            We are offering our users to promote their products in retail locations offered by our featured retailers.
            The payment for the spots are processed using third-party secured services such as Isracard and PayPal. Once
            booking and paying for a retail location, the payments are transferred to Repop Shops, and from Repop Shops
            to the relevant retailers. Canceling a transaction is subject to the rules of the consumer defence
            regulation - of the year of 2010 and the consumer defense bill of 1981.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">12. App Usage</Typography>
          <Typography variant="body1">
            We are offering our users to promote their products in retail locations offered by our featured retailers.
            The payment for the spots are processed using third-party secured services such as Isracard and PayPal. Once
            booking and paying for a retail location, the payments are transferred to Repop Shops, and from Repop Shops
            to the relevant retailers.
          </Typography>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">13. The Services</Typography>
          <Typography variant="body1">
            Repop Shops ltd. operates the www.Repop Shops.com website and offers its users the ability to engage in the
            following business activities:
          </Typography>
          <MarkedList>
            <ListItem>
              <ListItemText>List and promote retail spaces (“Spots”) in the platform</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Book retail spaces in the platform (“Promote”)</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Pay for the promotion using the platform’s third-party secured payment processor or via PayPal
              </ListItemText>
            </ListItem>
          </MarkedList>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">14. The Service Limitations</Typography>
          <Typography variant="body1">
            Repop Shops ltd. is the operator of the www.Repop Shops.com website. The interactions between retailers and
            brands in the platform (users in the platform) from all aspects and at any time or event are on the sole
            responsibility of each party alone and combined. Repop Shops Ltd. does not hold any responsibly for the
            interaction between both parties. The limitations are as follows:
          </Typography>
          <MarkedList>
            <ListItem>
              <ListItemText>
                Repop Shops does not guaranty any of its users that their booking request for spots will be approved by
                the retailers who lists and promotes the spots.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Repop Shops does not hold responsibiiity for the promotion period of the sellers who book spots using
                its platform.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                The payment for the retailers on their promoted spots will be processed upon receiving an invoice for
                the price of the promotion, minus VAT and the Repop Shops fee which stands on a 20% on top of the daily
                price for the promotion.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Repop Shops is not accountable in any way on damage or risks that occur in the promoted retail spots.
                The entire responsibility for the promotion and retail spots are on the users (the retailers who promote
                the spots, and the sellers who book them and run sales events at them).
              </ListItemText>
            </ListItem>
          </MarkedList>
        </Box>
        <Box sx={{ pb: 8 }}>
          <Typography variant="subtitle1">15. Privacy Policy</Typography>
          <Typography variant="body1">
            Your privacy is important to us. It is Repop Shops LTD.&apos;s policy to respect your privacy regarding any
            information we may collect from you across our website, www.Repop Shops.com, and other sites we own and
            operate. We only ask for personal information when we truly need it to provide a service to you. We collect
            it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it
            and how it will be used. We only retain collected information for as long as necessary to provide you with
            your requested service. What data we store, we’ll protect within commercially acceptable means to prevent
            loss and theft, as well as unauthorised access, disclosure, copying, use or modification. We don’t share any
            personally identifying information publicly or with third-parties, except when required to by law. Our
            website may link to external sites that are not operated by us. Please be aware that we have no control over
            the content and practices of these sites, and cannot accept responsibility or liability for their respective
            privacy policies. You are free to refuse our request for your personal information, with the understanding
            that we may be unable to provide you with some of your desired services. Your continued use of our website
            will be regarded as acceptance of our practices around privacy and personal information. If you have any
            questions about how we handle user data and personal information, feel free to contact us.
          </Typography>
        </Box>
      </Stack>
    </BaseDialog>
  );
};
