import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import StoreDTO from '../models/store/store-dto';
import StoreEditDTO from '../models/store/store-edit-dto';

export class StoresManagerService {
  private readonly basePath: string = 'manager/stores';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public createStore(storeEdit: StoreEditDTO): ApiRequestWrapper<StoreDTO> {
    return this.apiUrlProvider.post(this.basePath, storeEdit);
  }

  public updateStore(storeEdit: StoreEditDTO, storeId: number): ApiRequestWrapper<StoreDTO> {
    return this.apiUrlProvider.put(`${this.basePath}/${storeId}`, storeEdit);
  }

  public deleteStoreById(id: number) {
    return this.apiUrlProvider.delete(this.basePath + `/${id}`);
  }
}
