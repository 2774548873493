import React, { useState } from 'react';

import { SvgIcon } from '@mui/material';
import { Navigation, Pagination } from 'swiper';
import { Swiper as SwiperReact, SwiperProps as SwiperReactProps, SwiperSlide } from 'swiper/react';
import { SwiperModule } from 'swiper/types/shared';

import 'swiper/css';
import 'swiper/css/navigation';
import './swiper-styles.scss';
import { ReactComponent as Arrow } from '../../../assets/icons/collapsing-arrow.svg';
import { StyledSingleNavigationBox } from './styles';

export const Swiper = ({
  className,
  children,
  navigation,
  pagination,
  spaceBetween,
  slidesPerView,
  rewind,
  loop,
  singleNavigationArrow = false,
}: SwiperProps): JSX.Element => {
  const [swiperRef, setSwiperRef] = useState<any>();

  let modules: SwiperModule[] = [];
  navigation && modules.push(Navigation);
  pagination && modules.push(Pagination);

  return (
    <>
      <SwiperReact
        onSwiper={setSwiperRef}
        className={className}
        slidesPerView={slidesPerView}
        navigation={navigation}
        pagination={pagination}
        modules={modules}
        rewind={rewind}
        loop={loop}
        {...(spaceBetween ? { spaceBetween: spaceBetween } : { spaceBetween: 0 })}
      >
        {Array.isArray(children) ? (
          children.map((childrenItem, index) => <SwiperSlide key={'swiper-slide' + index}>{childrenItem}</SwiperSlide>)
        ) : (
          <SwiperSlide>{children}</SwiperSlide>
        )}
      </SwiperReact>
      {singleNavigationArrow && (
        <StyledSingleNavigationBox onClick={() => swiperRef.slideNext()}>
          <SvgIcon viewBox={'0 0 18 9'} component={Arrow}></SvgIcon>
        </StyledSingleNavigationBox>
      )}
    </>
  );
};

interface SwiperProps extends SwiperReactProps {
  children: JSX.Element | JSX.Element[] | (JSX.Element | undefined)[];
  singleNavigationArrow?: boolean;
}
