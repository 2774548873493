export const radioBtnGroupStyles = {
  '.radio-group-box': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: '40px',

    '.MuiFormControlLabel': {
      '&-root': {
        marginLeft: 0,
      },
      '&-label': {
        lineHeight: '40px',
      },
    },

    '.MuiFormLabel-root': {
      marginRight: 12,
    },

    '.MuiRadio-root': {
      marginRight: '5px',

      '.MuiSvgIcon-root': {
        width: '22px',
        height: '22px',
      },
    },
  },
} as const;
