import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

import { DashboardMainTemplate } from '../../common/components/dashboard/MainTemplate';
import { useAppDispatch, useAppSelector } from '../../common/hooks/redux-hooks';
import { selectAuthState } from '../authentication/authSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../notifier/notifierSlice';
import { Billing } from './billing';
import { Home } from './home';
import { PopUps } from './pop-ups';
import { Profile } from './profile';

export const BrandDashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { authorizedUser } = useAppSelector(selectAuthState);

  useEffect(() => {
    if (authorizedUser?.token && !authorizedUser.emailConfirmed) {
      dispatch(
        enqueueSnackbarAction({
          message:
            'You have an unconfirmed email address. Please, go to the profile page in the dashboard to send a confirmation email',
          options: { variant: 'warning' },
        }),
      );
    }
  }, []);

  return (
    <DashboardMainTemplate>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/pop-ups" element={<PopUps />} />
        <Route path="/billing" element={<Billing />} />
      </Routes>
    </DashboardMainTemplate>
  );
};
