import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const GeneralStyles = styled(Box)(({ theme }) => ({
  main: {
    width: '100%',
  },

  '.responsive-img': {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },

  '.full-width': {
    borderRadius: '0',
    width: 'calc(100% + 16px * 2)',
    marginLeft: '-16px',

    '&-on-sm': {
      [theme.breakpoints.down('sm')]: {
        borderRadius: '0',
        width: 'calc(100% + 16px * 2)',
        marginLeft: '-16px',
      },
    },
  },
}));
