import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledDefaultCreditCardBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '246px',
  height: '130px',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.secondary.main,
  padding: '20px 10px',
  borderRadius: '20px',

  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '300px',
    height: '140px',
  },

  '.credit-card-icon-row': {
    textAlign: 'right',
  },

  '.last-digit-row': {
    marginBottom: '10px',
  },

  '.expired-date-row': {
    display: 'flex',
    justifyContent: 'space-between',

    '.MuiTypography-body1': {
      fontSize: '10px',
    },
  },
}));
