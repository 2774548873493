import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import PaymentTransactionsFilterDTO from '../models/payment-transactions/payment-transactions-filter';
import PaymentTransactionsResultDTO from '../models/payment-transactions/payment-transactions-result-dto';

export class PaymentTransactionsManagerService {
  private readonly basePath: string = 'manager/PaymentTransactions';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getPaymentTransactionsByFilterManager(
    filter: PaymentTransactionsFilterDTO,
  ): ApiRequestWrapper<PaymentTransactionsResultDTO> {
    return this.apiUrlProvider.post(this.basePath + '/filter', filter);
  }
}
