import React, { useEffect, useState } from 'react';

import { ReactGoogleAutocompleteProps, usePlacesWidget } from 'react-google-autocomplete';

import { BasicInput } from '../basic/Input';

const API_KEY = window.spaSettings.googleMapApiKey;

export const GoogleAutocompleteInput = ({
  formattedAddress,
  onPlaceSelectedHandler,
  options = { types: ['address'] },
  placeholder = 'Full address (Google Autocomplete)',
}: GoogleAutocompleteInput): JSX.Element => {
  const [selectedPace, setSelectedPace] = useState();
  const [autocompleteValue, setAutocompleteValue] = useState('');

  const { ref: materialRef } = usePlacesWidget({
    apiKey: API_KEY,
    onPlaceSelected: (place) => {
      setSelectedPace(place);
    },
    options: options,
    language: 'en',
  });

  const onChangeHandler = (e: any) => {
    setAutocompleteValue(e.target.value);
  };

  useEffect(() => {
    if (selectedPace) {
      onPlaceSelectedHandler(selectedPace);
    }
  }, [selectedPace]);

  useEffect(() => {
    formattedAddress ? setAutocompleteValue(formattedAddress) : setAutocompleteValue('');
  }, [formattedAddress]);

  return (
    <BasicInput
      fullWidth
      value={autocompleteValue}
      inputRef={materialRef}
      inputProps={{
        placeholder: placeholder,
      }}
      onChange={onChangeHandler}
    />
  );
};

interface GoogleAutocompleteInput extends ReactGoogleAutocompleteProps {
  formattedAddress: string;
  onPlaceSelectedHandler: (place: object) => void;
  placeholder?: string;
}
