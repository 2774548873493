export const getPreBookingStatus = (
  isVerifiedBankAccount: boolean | null,
  isPreBookingWithBankAccount: boolean,
  isAvailableRelatedCampaign: boolean,
) => {
  if (isPreBookingWithBankAccount && isVerifiedBankAccount) {
    return {
      id: 1,
      name: 'Approved bank account',
      color: '#79C26A',
    };
  } else if (isPreBookingWithBankAccount && isVerifiedBankAccount !== null && !isVerifiedBankAccount) {
    return {
      id: 2,
      name: 'Rejected bank account',
      color: '#DD4D47',
    };
  } else if (!isPreBookingWithBankAccount && isAvailableRelatedCampaign) {
    return {
      id: 3,
      name: 'Paid by credit card',
      color: '#79C26A',
    };
  } else if (isPreBookingWithBankAccount && isVerifiedBankAccount === null) {
    return {
      id: 4,
      name: 'Waiting confirmation of bank account',
      color: '#ffa726',
    };
  } else {
    return {
      id: 5,
      name: 'Pending',
      color: '#ffa726',
    };
  }
};
