import SpotTypesDTO from "../../../models/spot/spot-types-dto";

export enum SPOT_TYPES {
  FullSpot = 4,
  HalfSpot = 5,
}

export const getAllSpotTypes = (): Array<SpotTypesDTO> => {
  return [
    {
      id: SPOT_TYPES.FullSpot,
      name: 'Full Spot',
    },
    {
      id: SPOT_TYPES.HalfSpot,
      name: 'Half Spot',
    },
  ];
};