import { USER_ROLES } from 'constants/enums/user-roles';
import { MOBILE_HEADER_BREAKPOINT_DASHBOARD } from 'constants/ui';

import React from 'react';

import { Box, Link, ListItem, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as BookIcon } from 'assets/icons/side-nav/book.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/side-nav/home.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/side-nav/invoice.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/side-nav/logout.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/side-nav/profile.svg';
import { ReactComponent as StarIcon } from 'assets/icons/side-nav/star.svg';
import { ReactComponent as StoreIcon } from 'assets/icons/side-nav/store.svg';
import DrawerList from 'common/components/DrawerList';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { useUserRole } from 'common/hooks/use-user-role';
import { logOut } from 'features/authentication/authSlice';
import { hideLeftNavDrawer, selectDrawerState } from 'features/drawer/drawerSlice';
import { NavLink } from 'react-router-dom';
import theme from 'theme-config/theme';

import { StyledDashboardLeftNavDrawer } from './styles';

export const DashboardLeftNavDrawer = (): JSX.Element => {
  const drawerState = useAppSelector(selectDrawerState);
  const leftNavDrawerWidth = useMediaQuery(theme.breakpoints.up(MOBILE_HEADER_BREAKPOINT_DASHBOARD)) ? 125 : 282;
  const dispatch = useAppDispatch();
  const isRetailRole = useUserRole(USER_ROLES.Retail);
  const isAdminRole = useUserRole(USER_ROLES.Admin);
  const isBrandRole = useUserRole(USER_ROLES.Brand);

  const handleClickOnTheLogOutItem = () => {
    dispatch(logOut());
  };

  const DrawerListComponent = (
    <DrawerList>
      {(isRetailRole || isAdminRole) && (
        <>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/manager" end>
              <Box className="icon-box">
                <SvgIcon component={StoreIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                Home
              </Typography>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/manager/bookings" end>
              <Box className="icon-box">
                <SvgIcon component={BookIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                Booking
              </Typography>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/manager/brands" end>
              <Box className="icon-box">
                <SvgIcon component={StarIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                Brands
              </Typography>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/manager/billing" end>
              <Box className="icon-box">
                <SvgIcon component={InvoiceIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                Billing
              </Typography>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/manager/profile" end>
              <Box className="icon-box">
                <SvgIcon component={ProfileIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                Profile
              </Typography>
            </NavLink>
          </ListItem>
        </>
      )}

      {isBrandRole && (
        <>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/brand" end>
              <Box className="icon-box">
                <SvgIcon component={HomeIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                Home
              </Typography>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/brand/pop-ups" end>
              <Box className="icon-box">
                <SvgIcon component={StoreIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                POP-UPS
              </Typography>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/brand/billing" end>
              <Box className="icon-box">
                <SvgIcon component={InvoiceIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                Billing
              </Typography>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink className="nav-link" to="/dashboard/brand/profile" end>
              <Box className="icon-box">
                <SvgIcon component={ProfileIcon} viewBox={'0 0 30 30'} />
              </Box>
              <Typography className="link-text" variant="subtitle2">
                Profile
              </Typography>
            </NavLink>
          </ListItem>
          <ListItem className="contact-us-list-item">
            <Typography variant="body1" sx={{ marginBottom: 6 }}>
              Contact us:
            </Typography>

            <Box>
              <Link className="phone-link" href="https://wa.me/+972548200202" target="_blank" />
              <Link className="email-link" href="mailto:hello@repop.shop" />
            </Box>
          </ListItem>
        </>
      )}

      <ListItem>
        <Box className="nav-link nav-link--log-out" onClick={handleClickOnTheLogOutItem}>
          <Box className="icon-box">
            <SvgIcon component={LogOutIcon} viewBox={'0 0 20 20'} />
          </Box>
          <Typography className="link-text" variant="subtitle2">
            Log out
          </Typography>
        </Box>
      </ListItem>
    </DrawerList>
  );

  const handleDrawerToggle = () => {
    dispatch(hideLeftNavDrawer());
  };

  return (
    <Box component="nav">
      <StyledDashboardLeftNavDrawer
        className="mobile-left-drawer"
        variant="temporary"
        open={drawerState.isOpenLeftDrawer}
        onClose={handleDrawerToggle}
        leftNavDrawerWidth={leftNavDrawerWidth}
      >
        {DrawerListComponent}
      </StyledDashboardLeftNavDrawer>
      <StyledDashboardLeftNavDrawer
        className="desktop-left-drawer"
        variant="permanent"
        open
        leftNavDrawerWidth={leftNavDrawerWidth}
      >
        {DrawerListComponent}
      </StyledDashboardLeftNavDrawer>
    </Box>
  );
};
