import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as CheckMarkCircleIcon } from '../../../../assets/icons/check-mark-circle.svg';
import { BasicButton } from '../../../../common/components/basic/Button';
import { BasicInput } from '../../../../common/components/basic/Input';
import { useAppDispatch } from '../../../../common/hooks/redux-hooks';
import theme from '../../../../theme-config/theme';
import { updatePreCampaignEdit } from '../personalizedBookingSlice';

export const GuidelineList = ({
  title,
  guidelines,
  guidelineInputProps,
  colorPalette = theme.palette.common.black,
  editable = false,
  sx = {},
}: GuidelineProps): JSX.Element => {
  const { id, name, placeholder } = guidelineInputProps;
  const guidelineList = guidelines ? JSON.parse(guidelines) : [];
  const [guidelineInputValue, changeGuidelineInputValue] = useState('');

  const dispatch = useAppDispatch();

  const changeInputValueHandler = (e: any) => {
    changeGuidelineInputValue(e.target.value);
  };

  const clickOnAddGuidelineBtn = () => {
    guidelineList.push(guidelineInputValue);
    dispatch(updatePreCampaignEdit({ [name]: JSON.stringify(guidelineList) }));
    changeGuidelineInputValue('');
  };

  const guidelineListItems = guidelineList?.map((guidelineItem: string, index: number) => {
    return (
      <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 10 }}>
        <CheckMarkCircleIcon style={{ flexShrink: '0' }} stroke={colorPalette}></CheckMarkCircleIcon>
        <Typography variant="body1" sx={{ ml: '10px' }}>
          {guidelineItem}
        </Typography>
      </Box>
    );
  });

  return (
    <Box sx={[{ color: colorPalette }, sx]}>
      <Typography className="title" variant="body1" sx={{ mb: 5, fontWeight: 700 }}>
        {title}
      </Typography>
      {guidelineListItems}
      {editable && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
          <BasicInput
            id={id}
            value={guidelineInputValue}
            inputProps={{ name: name, placeholder: placeholder, onChange: changeInputValueHandler }}
            fullWidth
          />
          <BasicButton
            className="btn-black"
            disabled={!guidelineInputValue}
            variant="contained"
            sx={{ ml: 5 }}
            onClick={clickOnAddGuidelineBtn}
          >
            Add
          </BasicButton>
        </Box>
      )}
    </Box>
  );
};

interface GuidelineProps {
  title: string;
  guidelines: string;
  editable?: boolean;
  colorPalette?: string;
  guidelineInputProps: { id: string; name: string; placeholder: string };
  sx?: object;
}
