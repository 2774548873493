import React, { useEffect, useState } from 'react';

import { Box, Grid, Link, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BankAccountIcon } from '../../../assets/icons/bank-account.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/icons/credit-card.svg';
import { ReactComponent as StripeIcon } from '../../../assets/icons/stripe.svg';
import { BasicButton } from '../../../common/components/basic/Button';
import { Checkbox } from '../../../common/components/basic/Checkbox';
import { SuccessfullyBookedPopUpDialog } from '../../../common/components/Dialogs/SuccessfullyBookedPopUp';
import { TermAndConditionsDialog } from '../../../common/components/Dialogs/TermAndConditions';
import { HorizontalStepper } from '../../../common/components/HorizontalStepper';
import { RefundDeposit } from '../../../common/components/RefundDeposit';
import { RePopLogoBlock } from '../../../common/components/RePopLogoBlock';
import { SummaryTable } from '../../../common/components/SummaryTable';
import { TabsComponent } from '../../../common/components/TabsComponent';
import { getFormattedDatesRange } from '../../../common/helpers/formatters';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { useOnBeforeUnload } from '../../../common/hooks/use-on-before-unload';
import { PRE_CAMPAIGN_ID_KEY } from '../../../constants';
import { CampaignBrandService } from '../../../services/campaigns-brand';
import { PaymentsBrandService } from '../../../services/payments-brand';
import { BankAccount } from '../../brand-payments/PaymentTabs/BankAccount';
import { PayPlus } from '../../brand-payments/PaymentTabs/PayPlus';
import { Stripe } from '../../brand-payments/PaymentTabs/Stripe';
import { enqueueNotification as enqueueSnackbarAction } from '../../notifier/notifierSlice';
import { selectPreBookingState } from '../preBookingSlice';

export const Checkout = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const steps = ['Welcome', 'Proposal', 'Checkout'];
  const { preCampaignState, formattedSummaryData, selectedSpot } = useAppSelector(selectPreBookingState);
  const [activeTab, setActiveTab] = useState(0);
  const [isShowTermsAndConditionsDialog, showTermsAndConditionsDialog] = useState(false);
  const [isDisabledBookNowBtn, setDisabledBookNowBtn] = useState(true);
  const [isShowSuccessfullyBookedPopUpDialog, setIsShowSuccessfullyBookedPopUpDialog] = useState(false);
  const [stripeKey, setStripeKey] = useState<string | null>(null);

  const campaignBrandService = new CampaignBrandService();
  const paymentsBrandService: PaymentsBrandService = new PaymentsBrandService();

  const paymentTabs = [
    {
      label: 'Credit card',
      icon: <CreditCardIcon />,
      tabPanelComponent: <PayPlus depositAmount={formattedSummaryData?.formattedDepositAmount} />,
    },
    {
      label: 'Bank transfer',
      icon: <BankAccountIcon />,
      tabPanelComponent: <BankAccount totalAmount={formattedSummaryData?.formattedTotalAmount} />,
    },
  ];

  stripeKey &&
    paymentTabs.splice(1, 0, {
      label: 'Stripe',
      icon: <StripeIcon />,
      tabPanelComponent: <Stripe stripeKey={stripeKey} depositAmount={formattedSummaryData?.formattedDepositAmount} />,
    });

  const { startDate, endDate, discountInPercentage, vatInPercentage } = preCampaignState;
  const {
    formattedDateRange,
    daysDuration,
    formattedPricePerWeek,
    formattedTotalAmount,
    formattedDepositAmount,
    formattedAmountAtCheckIn,
    spotNameWithStoreAddress,
  } = formattedSummaryData;
  const parsedStartDate = startDate && new Date(startDate);
  const parsedEndDate = endDate && new Date(endDate);

  const summaryTableData = [
    {
      headTitle: 'Dates',
      rowValue: formattedDateRange && formattedDateRange,
    },
    {
      headTitle: 'Total days',
      rowValue: daysDuration && daysDuration,
      isCollapsed: true,
    },
    {
      headTitle: 'Spot',
      rowValue: formattedPricePerWeek && formattedPricePerWeek + '/Week',
      isCollapsed: true,
    },
    {
      headTitle: 'Total Amount',
      rowValue: formattedTotalAmount && formattedTotalAmount,
    },
    {
      headTitle: 'VAT',
      rowValue: vatInPercentage && vatInPercentage + '%',
      isCollapsed: true,
    },
    {
      headTitle: 'Deposit',
      rowValue: formattedDepositAmount && formattedDepositAmount,
      isShowDivider: true,
    },
    {
      headTitle: 'Pay at Check in',
      rowValue: formattedAmountAtCheckIn && formattedAmountAtCheckIn,
      isHighlightedTitle: true,
      isHighlightedValue: true,
    },
  ];

  //add discount row only in case if discount is available
  discountInPercentage &&
    summaryTableData.splice(5, 0, {
      headTitle: 'Discount applied',
      rowValue: discountInPercentage + '%',
      isCollapsed: true,
    });

  const clickOnTermsAndConditionsLinkHandler = () => {
    showTermsAndConditionsDialog(true);
  };

  const changeTermsAndConditionsCheckboxHandler = (e: any) => {
    e.target.checked ? setDisabledBookNowBtn(false) : setDisabledBookNowBtn(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const createCampaign = () => {
    campaignBrandService
      .createCampaign({
        spotId: preCampaignState.spotId,
        name: selectedSpot.name,
        startDate: preCampaignState.startDate,
        endDate: preCampaignState.endDate,
        preCampaignId: preCampaignState.id,
      })
      .execute()
      .then(() => {
        setIsShowSuccessfullyBookedPopUpDialog(true);
        window.onbeforeunload = null;
        localStorage.removeItem(PRE_CAMPAIGN_ID_KEY);
      });
  };

  const clickOnBookNowBtnHandler = () => {
    if (activeTab === 2) {
      if (preCampaignState.bankAccountId) {
        createCampaign();
      } else {
        dispatch(
          enqueueSnackbarAction({
            message: 'You need to upload a bank transaction confirmation file',
            options: { variant: 'error' },
          }),
        );
      }
    } else {
      createCampaign();
    }
  };

  useOnBeforeUnload();

  useEffect(() => {
    paymentsBrandService
      .getStripeKey()
      .execute()
      .then((key) => {
        setStripeKey(key.PublishableKey);
      });
  }, []);

  return (
    <>
      <Grid container sx={{ minHeight: { md: '100vh' }, alignItems: { md: 'stretch' } }}>
        <Grid item container sm={12} md={6} lg={6} sx={{ justifyContent: 'flex-end' }}>
          <Grid
            item
            sm={12}
            md={12}
            lg={10}
            sx={{ width: '100%', maxWidth: { md: '630px' }, padding: { xs: 8, md: 15 } }}
          >
            <RePopLogoBlock sx={{ margin: { xs: '0 auto', md: 'initial' }, marginBottom: { xs: 13, md: '8.5vh' } }} />

            <HorizontalStepper
              steps={steps}
              activeStep={2}
              sx={{ display: { xs: 'block', md: 'none' }, paddingBottom: 15 }}
            ></HorizontalStepper>

            <Box sx={{ paddingBottom: { xs: 15, md: '7.5vh' } }}>
              <Typography variant="h1" sx={{ typography: { xs: 'h3', md: 'h1' }, textTransform: { md: 'uppercase' } }}>
                Checkout
              </Typography>
            </Box>

            <Box
              sx={{
                maxWidth: '400px',
                display: { xs: 'block', md: 'none' },
                marginLeft: 'auto',
                border: (theme) => `1px solid ${theme.palette.common.black}`,
                borderRadius: '10px',
                padding: 10,
                margin: '0 auto 20px auto',
              }}
            >
              <SummaryTable
                tableData={summaryTableData}
                styles={{
                  '&': { fontSize: { xs: '12px', md: '14px' } },
                  '> div:not(:last-of-type) .table-row:not(.table-row-highlighted)': { marginBottom: 10 },
                  '.table-row.table-row-highlighted': { marginY: 10 },
                }}
              ></SummaryTable>
            </Box>

            <Typography
              variant="body1"
              sx={{
                typography: { xs: 'body2', md: 'body1' },
                textAlign: { xs: 'center', md: 'left' },
                paddingBottom: 10,
              }}
            >
              This information will be used to book your future Pop-Ups, pay for your spot, request additions and more
            </Typography>

            <TabsComponent
              value={activeTab}
              data={paymentTabs}
              handleChangeTab={handleChange}
              tabsStyles={{ marginBottom: 12, width: '100%' }}
              ariaLabel="payment-tab"
            ></TabsComponent>

            <Typography sx={{ typography: { xs: 'h6', md: 'h3' }, textAlign: 'center', paddingBottom: 10 }}>
              Free cancellation and deposit refund until{' '}
              {parsedStartDate &&
                format(new Date(parsedStartDate).setDate(parsedStartDate.getDate() - 45), 'dd/MM/yyyy')}
            </Typography>

            <Box sx={{ paddingBottom: 15 }}>
              {parsedStartDate && <RefundDeposit startDateOfBooking={parsedStartDate} />}
            </Box>

            <Box sx={{ paddingBottom: 15 }}>
              <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                *The rest of the payment will be charged on the first day of the Pop-up period
              </Typography>
            </Box>

            <Box sx={{ paddingBottom: 10 }}>
              <Checkbox
                changeHandler={changeTermsAndConditionsCheckboxHandler}
                label={
                  <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                    By clicking here you are accepting the{' '}
                    <Link
                      component="button"
                      type="button"
                      sx={{ typography: { xs: 'body2', md: 'h5' } }}
                      variant="body1"
                      color="inherit"
                      underline="always"
                      onClick={clickOnTermsAndConditionsLinkHandler}
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                }
              ></Checkbox>
            </Box>

            <Grid item xs={12} sx={{ textAlign: { xs: 'center', md: 'right', marginTop: 20 } }}>
              <BasicButton
                className="btn-large"
                sx={{ minWidth: '165px' }}
                variant="outlined"
                type="button"
                disabled={isDisabledBookNowBtn}
                onClick={clickOnBookNowBtnHandler}
              >
                Book now
              </BasicButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6} lg={6}>
          <Grid
            item
            container
            alignContent="space-between"
            sm={12}
            md={12}
            lg={10}
            sx={{ width: '100%', maxWidth: { md: '630px' }, padding: { xs: 8, md: 15 } }}
          >
            <Grid item xs={12}>
              <Box>
                <HorizontalStepper
                  steps={steps}
                  activeStep={2}
                  sx={{
                    maxWidth: '300px',
                    display: { xs: 'none', md: 'block' },
                    marginLeft: 'auto',
                    paddingTop: '11vh',
                    paddingBottom: '12.5vh',
                  }}
                ></HorizontalStepper>

                <Box
                  sx={{
                    maxWidth: '400px',
                    display: { xs: 'none', md: 'block' },
                    marginLeft: 'auto',
                    border: (theme) => `1px solid ${theme.palette.common.black}`,
                    borderRadius: '10px',
                    padding: 15,
                  }}
                >
                  <Typography variant="h3" sx={{ paddingBottom: 5 }}>
                    {spotNameWithStoreAddress}
                  </Typography>
                  <SummaryTable
                    tableData={summaryTableData}
                    styles={{
                      '&': { fontSize: { xs: '12px', md: '14px' } },
                      '> div:not(:last-of-type) .table-row:not(.table-row-highlighted)': { marginBottom: 10 },
                      '.table-row.table-row-highlighted': { marginY: 10 },
                    }}
                  ></SummaryTable>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                textAlign: { xs: 'start', md: 'end' },
                marginTop: { xs: 9, md: 20 },
              }}
            >
              <BasicButton
                className="btn-large"
                sx={{ minWidth: '165px' }}
                variant="outlined"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </BasicButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TermAndConditionsDialog
        dialogProps={{
          open: isShowTermsAndConditionsDialog,
          onClose: () => {
            showTermsAndConditionsDialog(false);
          },
          fullWidth: true,
          withCloseIcon: true,
          PaperProps: { sx: { maxWidth: '970px' } },
        }}
      />
      <SuccessfullyBookedPopUpDialog
        dialogProps={{
          open: isShowSuccessfullyBookedPopUpDialog,
          onClose: () => {
            setIsShowSuccessfullyBookedPopUpDialog(false);
          },
          fullWidth: true,
          withCloseIcon: false,
          PaperProps: { sx: { maxWidth: '570px' } },
          closeByClickOnBackdrop: false,
        }}
        spotName={spotNameWithStoreAddress}
        bookingDates={getFormattedDatesRange(parsedStartDate, parsedEndDate)}
      />
    </>
  );
};
