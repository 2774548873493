import { getByName as getCurrencyByName } from '../../constants/enums/currency';
import { selectCurrencyState } from '../../features/currency/currencySlice';
import CurrencyDTO from '../../models/currency-dto';
import { isNotEmptyObject } from '../helpers/utils';
import { useAppSelector } from './redux-hooks';

export const useCurrencyConverter = (fromCurrency: CurrencyDTO, price: number | undefined): CurrencyConverterTypes => {
  const { exchangeRate } = useAppSelector(selectCurrencyState); //exchanged rates by currency from user dto
  let convertedPriceObj: CurrencyConverterTypes = { convertedPrice: undefined, currencySymbol: undefined };

  if (isNotEmptyObject(exchangeRate) && isNotEmptyObject(fromCurrency) && price) {
    const toCurrency = getCurrencyByName(exchangeRate.baseCurrency);
    const baseCurrencyRate =
      toCurrency.name === fromCurrency.name ? null : exchangeRate.rates.find((x: any) => x.name === fromCurrency.name);

    if (baseCurrencyRate) {
      convertedPriceObj.convertedPrice = price / baseCurrencyRate.value;
      convertedPriceObj.currencySymbol = toCurrency.symbol;
    } else {
      convertedPriceObj.convertedPrice = price;
      convertedPriceObj.currencySymbol = fromCurrency.symbol;
    }
  }

  return convertedPriceObj;
};

interface CurrencyConverterTypes {
  convertedPrice: number | undefined;
  currencySymbol: string | undefined;
}
