import { Theme } from '@mui/material/styles';

import facebookIcon from '../../../../assets/icons/social/facebook.svg';
import googleIcon from '../../../../assets/icons/social/google.svg';

export const basicButtonStyles = (theme: Theme) =>
  ({
    textTransform: 'initial',
    boxShadow: 'none',
    borderRadius: '25px',
    flexShrink: 0,
    color: '#FFFF00',
    transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp}`,

    '&:hover, &:active': {
      boxShadow: 'none',
    },

    '&.Mui-disabled': {
      opacity: '0.5',
    },

    '&.btn-large': {
      '&.fs-20px': {
        fontSize: '20px',
      },
    },

    '&.MuiButton-outlined': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: `solid 1px ${theme.palette.common.black}`,
      padding: '12px 36px',

      '&.btn-small': {
        padding: '14px 16px',
      },

      '&.btn-large': {
        padding: '17px 36px',
      },

      '&.btn-large.fs-20px': {
        padding: '14px 36px',
      },

      '&.btn-large.btn-with-icon': {
        padding: '13px 36px',
      },

      '&.btn-outlined-black-with-white-text': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        border: `solid 1px ${theme.palette.common.white}`,
      },

      '&.btn-outlined-black-with-yellow-text': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.secondary.main,
        border: `solid 1px ${theme.palette.secondary.main}`,
      },

      '&.transparent': {
        backgroundColor: 'transparent',

        '&--white': {
          color: theme.palette.common.white,
          border: `solid 2px ${theme.palette.common.white}`,
        },

        '&--black': {
          color: theme.palette.common.black,
          border: `solid 2px ${theme.palette.common.black}`,
        },
      },
    },

    '&.MuiButton-contained': {
      padding: '13px 36px',

      '&.padding-15-36': {
        padding: '15px 36px',
      },

      '&.btn-small': {
        padding: '13px 16px',
      },

      '&.btn-large': {
        padding: '18px 36px',
      },

      '&.btn-large.fs-20px': {
        padding: '15px 36px',
      },

      '&.btn-large.btn-with-icon': {
        padding: '14px 36px',
      },

      '&.btn-black': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.secondary.main,

        '&--with-hover:hover': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.black,
        },
      },

      '&.btn-yellow': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.black,
      },

      '&.btn-blue': {
        backgroundColor: '#1C81DF',
        color: theme.palette.common.white,
      },

      '&.btn-black-with-white-text': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
    },

    '&.btn-with-icon': {
      '&:before': {
        content: "''",
        display: 'inline-block',
        width: '22px',
        height: '22px',
        marginRight: theme.spacing(5),
        backgroundRepeat: 'no-repeat',
      },

      '&--google:before': {
        backgroundImage: `url(${googleIcon})`,
      },

      '&--facebook:before': {
        backgroundImage: `url(${facebookIcon})`,
      },
    },
  } as const);
