import React from 'react';

import { List, Toolbar } from '@mui/material';

import { MOBILE_HEADER_BREAKPOINT, MOBILE_HEADER_BREAKPOINT_DASHBOARD } from '../../../constants/ui';
import { hideLeftNavDrawer } from '../../../features/drawer/drawerSlice';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { useMatchRoutes } from '../../hooks/use-match-routes';
import { RePopLogoBlock } from '../RePopLogoBlock';
import { StyledDrawerList } from './styles';

export const DrawerList = ({ children }: { children: any }): JSX.Element => {
  const dispatch = useAppDispatch();
  const isPageWithoutDesktopHeader = useMatchRoutes([
    '/log-in',
    '/sign-up',
    '/recovery',
    '/forgot-password',
    '/dashboard/*',
  ]);

  const clickOnDrawerHandler = () => {
    dispatch(hideLeftNavDrawer());
  };

  return (
    <StyledDrawerList
      onClick={clickOnDrawerHandler}
      onKeyDown={clickOnDrawerHandler}
      headerBreakpoint={isPageWithoutDesktopHeader ? MOBILE_HEADER_BREAKPOINT_DASHBOARD : MOBILE_HEADER_BREAKPOINT}
    >
      <Toolbar>
        <RePopLogoBlock isWhiteLogo />
      </Toolbar>

      <List>{children}</List>
    </StyledDrawerList>
  );
};
