import React, { useEffect, useState } from 'react';

import { Avatar, Box, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';

import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import { BasicButton } from '../../../../common/components/basic/Button';
import { DashboardRightDrawer } from '../../../../common/components/dashboard/RightDrawer';
import { getFormattedPrice } from '../../../../common/helpers/formatters';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/redux-hooks';
import { getById as getCurrencyById } from '../../../../constants/enums/currency';
import BrandDTO from '../../../../models/brand/brand-dto';
import CampaignDTO from '../../../../models/campaign/campaign-dto';
import PaymentTransactionDTO from '../../../../models/payment-transactions/payment-transaction-dto';
import { ImgService } from '../../../../services/img-service';
import theme from '../../../../theme-config/theme';
import { hideRightNavDrawer, openRightNavDrawer } from '../../../drawer/drawerSlice';
import {
  getPaymentTransactionsByFilterManager,
  selectManagerDashboardState,
  setInitialStateForPaymentTransactionsFilterResult,
} from '../../managerDashboardSlice';
import { InvoicesAdditionalInfo } from '../InvoicesAdditionalInfo';

export const InvoicesTab = (): JSX.Element => {
  const initialPageState = {
    campaignId: null,
    paymentMethodId: null,
    brandId: null,
    text: '',
    itemsOnPage: 10,
    page: 1,
  };
  const [pageFilterState, setFilterPageState] = useState(initialPageState);
  const [selectedPaymentTransactionId, setSelectedPaymentTransactionId] = useState<number>();
  const dispatch = useAppDispatch();
  const { paymentTransactionsFilterResult } = useAppSelector(selectManagerDashboardState);
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));

  const imgService: ImgService = new ImgService();

  const clickOnViewMoreBtnHandler = () => {
    const nextPage = pageFilterState.page + 1;
    setFilterPageState({ ...pageFilterState, page: nextPage });
  };

  const clickOnPaymentItemHandler = (paymentId: number) => {
    setSelectedPaymentTransactionId(paymentId);
    dispatch(openRightNavDrawer('InvoicesAdditionalInfo'));
  };

  useEffect(() => {
    return () => {
      dispatch(setInitialStateForPaymentTransactionsFilterResult());
      dispatch(hideRightNavDrawer());
    };
  }, []);

  useEffect(() => {
    dispatch(getPaymentTransactionsByFilterManager(pageFilterState));
  }, [pageFilterState]);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={15} sx={{ marginBottom: 20 }}>
        {paymentTransactionsFilterResult?.searchPage?.items.map(
          (paymentTransaction: PaymentTransactionDTO, index: number) => {
            const campaign = paymentTransactionsFilterResult?.campaigns.find(
              (campaign: CampaignDTO) => campaign.id === paymentTransaction.campaignId,
            );
            const brand = paymentTransactionsFilterResult?.brands.find(
              (brand: BrandDTO) => brand.id === campaign.brandId,
            );
            const currency = getCurrencyById(paymentTransaction.currency);
            const brandLogoUrl = brand?.logo
              ? imgService.getImgPreviewUrl(brand.logo, {
                  width: 100,
                  height: 100,
                })
              : '';

            return (
              <Grid key={index} item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    padding: { xs: '12px 10px', md: '15px 20px' },
                    border: 'solid 1px black',
                    borderRadius: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    clickOnPaymentItemHandler(paymentTransaction.id);
                  }}
                >
                  <Grid container columns={16} columnSpacing={{ xs: 10, md: 15 }} rowSpacing={5} alignItems="center">
                    <Grid item xs={14} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{
                          width: '50px',
                          height: '50px',
                          marginRight: { xs: 5, md: 15 },
                          border: 'solid 2px white',
                        }}
                        src={brandLogoUrl}
                        alt="user avatars"
                      />

                      <Typography variant="subtitle2">{brand.name}</Typography>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ marginLeft: { xs: 30, md: 0 } }}>
                      <Typography variant="body1">
                        {format(new Date(paymentTransaction.createdAt), 'dd/MM/yyyy')}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={3} sx={{ marginLeft: { xs: 30, md: 0 } }}>
                      <Typography variant="body1">
                        {getFormattedPrice(paymentTransaction.cost, currency.symbol, true)}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={3}>
                      <Typography variant="body1">Credit card</Typography>
                    </Grid>

                    {isUpMdBreakPoint && (
                      <Grid item xs={8} md={1} sx={{ textAlign: 'right' }}>
                        {paymentTransaction?.invoiceUrl && (
                          <Link
                            title={`Green invoice ID: ${paymentTransaction?.greenInvoiceId}`}
                            underline="none"
                            href={`${paymentTransaction.invoiceUrl}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <DownloadIcon />
                          </Link>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  {!isUpMdBreakPoint && paymentTransaction?.invoiceUrl && (
                    <Link
                      title={`Green invoice ID: ${paymentTransaction?.greenInvoiceId}`}
                      sx={{ marginLeft: 10, alignSelf: 'center' }}
                      underline="none"
                      href={`${paymentTransaction.invoiceUrl}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DownloadIcon />
                    </Link>
                  )}
                </Box>
              </Grid>
            );
          },
        )}
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <BasicButton
            className="btn-large btn-yellow"
            variant="contained"
            onClick={clickOnViewMoreBtnHandler}
            disabled={
              paymentTransactionsFilterResult?.searchPage?.items
                ? paymentTransactionsFilterResult.searchPage.items.length >=
                  paymentTransactionsFilterResult.searchPage.totalCount
                : true
            }
          >
            <Typography variant="body1">View more</Typography>
          </BasicButton>
        </Grid>
      </Grid>
      <DashboardRightDrawer
        contentElements={[
          <InvoicesAdditionalInfo
            data-name={'InvoicesAdditionalInfo'}
            selectedPaymentTransactionId={selectedPaymentTransactionId}
          />,
        ]}
      />
    </>
  );
};
