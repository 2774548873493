import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import ExchangeRatesDTO from '../../models/exchange-rate/exchange-rate-dto';
import { ExchangeRatesService } from '../../services/currency-rates';

interface initialStateTypes {
  exchangeRate: ExchangeRatesDTO;
}

const initialState = {
  exchangeRate: {},
} as initialStateTypes;

const exchangeRatesService: ExchangeRatesService = new ExchangeRatesService();

export const getCurrencyRates = createAsyncThunk('getCurrencyRates', async (baseCurrency: string) => {
  return await exchangeRatesService.getCurrencyRates(baseCurrency).execute();
});

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCurrencyRates.fulfilled, (state, action) => {
      state.exchangeRate = { ...action.payload };
    });
  },
});

export const selectCurrencyState = function (state: RootState): any {
  return state.currency;
};

export default currencySlice.reducer;
