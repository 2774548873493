import React from 'react';

import { Rating as MuiRating, RatingProps as MuiRatingProps } from '@mui/material';

import { ReactComponent as StarFilledIcon } from '../../../assets/icons/star-filled.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';

export const Rating = ({ name, value, readOnly, onChange }: MuiRatingProps): JSX.Element => {
  return (
    <MuiRating
      name={name}
      value={value}
      readOnly={readOnly}
      onChange={onChange}
      icon={<StarFilledIcon fontSize="inherit" />}
      emptyIcon={<StarIcon fontSize="inherit" />}
    />
  );
};
