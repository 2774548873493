import React from 'react';

import { Avatar, Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

import { enqueueNotification as enqueueSnackbarAction } from '../../../../features/notifier/notifierSlice';
import FeedbackManagerDTO from '../../../../models/feedback/feedback-manager-dto';
import { FeedbackManagerService } from '../../../../services/feedback-manager';
import { ImgService } from '../../../../services/img-service';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { BasicButton } from '../../basic/Button';
import { Checkbox } from '../../basic/Checkbox';
import { Rating } from '../../Rating';

export const DashboardFeedbackBox = ({ feedbackList, changeHandler }: DashboardFeedbackBoxProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const imgService: ImgService = new ImgService();
  const feedbackManagerService: FeedbackManagerService = new FeedbackManagerService();

  const changeFeedbackShowingHandler = (e: any, feedbackId: string, feedbackIndex: number) => {
    const isChecked = e.target.checked;

    feedbackManagerService
      .setFeedbackActivity(feedbackId, isChecked)
      .showLoader(false)
      .execute()
      .then((data) => {
        const newState = [
          ...feedbackList.slice(0, feedbackIndex),
          {
            ...data,
          },
          ...feedbackList.slice(feedbackIndex + 1),
        ];

        changeHandler(newState);
      });
  };

  const clickOnDeleteFeedback = (feedbackId: string, feedbackIndex: number) => {
    feedbackManagerService
      .deleteFeedbackById(feedbackId)
      .showLoader(false)
      .execute()
      .then(() => {
        const newState = [...feedbackList.slice(0, feedbackIndex), ...feedbackList.slice(feedbackIndex + 1)];

        changeHandler(newState);

        dispatch(
          enqueueSnackbarAction({
            message: 'Review was successfully deleted',
            options: { variant: 'success' },
          }),
        );
      });
  };

  return (
    <>
      {feedbackList?.map((feedbackItem, index) => {
        const { id, brandLogo, brandName, overallExperience, description, createDate, isEnabled } = feedbackItem;
        const brandLogoUrl = imgService.getImgPreviewUrl(brandLogo, { width: 100, height: 100 });

        return (
          <Grid key={index} item xs={12} md={4} lg={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px 10px',
                border: 'solid 1px black',
                borderRadius: '10px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Avatar
                  sx={{
                    width: { xs: '50px', md: '70px' },
                    height: { xs: '50px', md: '70px' },
                    marginRight: '10px',
                  }}
                  src={brandLogoUrl}
                  alt="brand avatar"
                />

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ marginBottom: 7 }}>
                    {brandName}
                  </Typography>

                  {overallExperience && (
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                      Overall Score - {overallExperience.toFixed(1)}
                    </Typography>
                  )}

                  <Rating readOnly name="feedback-score" value={overallExperience || null} />
                </Box>
              </Box>

              <Box sx={{ marginBottom: 15 }}>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                  {description}
                </Typography>
                <Typography variant="body1" sx={{ color: '#808080' }}>
                  Created on: {format(new Date(createDate), 'dd/MM/yyyy')}
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 10 }}>
                <Checkbox
                  name={'isBlocked'}
                  checked={Boolean(isEnabled)}
                  label={<Typography variant="body1">Show this review</Typography>}
                  changeHandler={(e) => changeFeedbackShowingHandler(e, id, index)}
                />
              </Box>

              <Box sx={{ textAlign: 'center' }}>
                <BasicButton onClick={() => clickOnDeleteFeedback(id, index)}>Delete</BasicButton>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </>
  );
};

interface DashboardFeedbackBoxProps {
  feedbackList: FeedbackManagerDTO[];
  changeHandler: (feedbackList: FeedbackManagerDTO[]) => void;
}
