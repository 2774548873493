import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import BrandsSearchDTO from '../../models/brand/brands-search-dto';
import BrandsSearchFilterDTO from '../../models/brand/brands-search-filter-dto';
import PaymentTransactionsFilterDTO from '../../models/payment-transactions/payment-transactions-filter';
import PaymentTransactionsResultDTO from '../../models/payment-transactions/payment-transactions-result-dto';
import SpotItemDTO from '../../models/spot/spot-item-dto';
import SpotsAllDTO from '../../models/spot/spots-all-dto';
import SpotsFilterDTO from '../../models/spot/spots-filter-dto';
import { BrandsManagerService } from '../../services/brands-manager';
import { PaymentTransactionsManagerService } from '../../services/payment-transactions-manager';
import { SpotManagerService } from '../../services/spot-manager';
import { SpotsService } from '../../services/spots';
import { StoresManagerService } from '../../services/stores-manager';

interface initialStateTypes {
  spotsFilterResult: SpotsAllDTO;
  brandsFilterResult: BrandsSearchDTO;
  paymentTransactionsFilterResult: PaymentTransactionsResultDTO;
}

const initialState = {
  spotsFilterResult: {},
  brandsFilterResult: {},
  paymentTransactionsFilterResult: {},
} as initialStateTypes;

const brandManagerService: BrandsManagerService = new BrandsManagerService();
const paymentTransactionsManagerService: PaymentTransactionsManagerService = new PaymentTransactionsManagerService();
const spotsService: SpotsService = new SpotsService();
const spotsManagerService: SpotManagerService = new SpotManagerService();
const storesManagerService: StoresManagerService = new StoresManagerService();

export const getBrandsByFilter = createAsyncThunk('getBrandsByFilter', async (filter: BrandsSearchFilterDTO) => {
  return await brandManagerService.getBrandsByFilter(filter).execute();
});

export const getBrandsBySearchValue = createAsyncThunk(
  'getBrandsBySearchValue',
  async (filter: BrandsSearchFilterDTO) => {
    return await brandManagerService.getBrandsByFilter(filter).execute();
  },
);

export const getPaymentTransactionsByFilterManager = createAsyncThunk(
  'getPaymentTransactionsByFilterManager',
  async (filter: PaymentTransactionsFilterDTO) => {
    return await paymentTransactionsManagerService.getPaymentTransactionsByFilterManager(filter).execute();
  },
);

export const getSpotsByFilter = createAsyncThunk('getSpotsByFilter', async (filter: SpotsFilterDTO) => {
  return await spotsService.getSpotsByFilter(filter).execute();
});

export const deleteSpots = createAsyncThunk(
  'deleteSpots',
  async ({ spotId, storeId }: { spotId: number; storeId: number }) => {
    return await spotsManagerService.deleteSpot(spotId, storeId).execute();
  },
);

export const deleteStoreById = createAsyncThunk('deleteStoreById', async (id: number) => {
  return await storesManagerService.deleteStoreById(id).execute();
});

export const managerDashboardSlice = createSlice({
  name: 'managerDashboard',
  initialState: initialState,
  reducers: {
    setInitialStateForBrandsFilterResult: (state) => {
      state.brandsFilterResult = initialState.brandsFilterResult;
    },
    setInitialStateForPaymentTransactionsFilterResult: (state) => {
      state.paymentTransactionsFilterResult = initialState.paymentTransactionsFilterResult;
    },
  },
  extraReducers(builder) {
    builder.addCase(getBrandsByFilter.fulfilled, (state, action) => {
      const previousBrands = state.brandsFilterResult?.searchPage?.items
        ? state.brandsFilterResult.searchPage.items
        : [];
      const previousUsers = state.brandsFilterResult?.users ? state.brandsFilterResult?.users : [];

      state.brandsFilterResult = {
        searchPage: { ...action.payload.searchPage, items: [...previousBrands, ...action.payload.searchPage.items] },
        users: [...previousUsers, ...action.payload.users],
      };
    });
    builder.addCase(getBrandsBySearchValue.fulfilled, (state, action) => {
      state.brandsFilterResult = action.payload;
    });
    builder.addCase(getPaymentTransactionsByFilterManager.fulfilled, (state, action) => {
      const previousPaymentTransactions = state.paymentTransactionsFilterResult?.searchPage?.items
        ? state.paymentTransactionsFilterResult.searchPage.items
        : [];

      const {
        preCampaigns = [],
        campaigns = [],
        spots = [],
        stores = [],
        brands = [],
      } = state.paymentTransactionsFilterResult;

      state.paymentTransactionsFilterResult = {
        searchPage: {
          items: [...previousPaymentTransactions, ...action.payload.searchPage.items],
          totalCount: action.payload.searchPage.totalCount,
        },
        preCampaigns: [...preCampaigns, ...action.payload.preCampaigns],
        campaigns: [...campaigns, ...action.payload.campaigns],
        spots: [...spots, ...action.payload.spots],
        stores: [...stores, ...action.payload.stores],
        brands: [...brands, ...action.payload.brands],
      };
    });
    builder.addCase(getSpotsByFilter.fulfilled, (state, action) => {
      state.spotsFilterResult = action.payload;
    });
    builder.addCase(deleteSpots.fulfilled, (state, action) => {
      if (state?.spotsFilterResult?.items?.length) {
        const indexOfDeletedSpot = state.spotsFilterResult.items.findIndex(
          (item: SpotItemDTO) => item.spotId === action.meta.arg.spotId,
        );

        state.spotsFilterResult.items.splice(indexOfDeletedSpot, 1);
        state.spotsFilterResult.totalCount--;
      }
    });
  },
});

export const selectManagerDashboardState = function (state: RootState): any {
  return state.managerDashboard;
};

export const { setInitialStateForBrandsFilterResult, setInitialStateForPaymentTransactionsFilterResult } =
  managerDashboardSlice.actions;

export default managerDashboardSlice.reducer;
