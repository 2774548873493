import { createTheme } from '@mui/material';

import BlatantBoldOtf from '../assets/fonts/blatant/Blatant-Bold.otf';
import BlatantOtf from '../assets/fonts/blatant/Blatant.otf';
import { defaultPalette } from './palette';
import { defaultTypography } from './typography';

const theme = createTheme({
  palette: defaultPalette,
  typography: defaultTypography,
  spacing: 2,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Blatant';
          font-weight: 400;
          src: local('Blatant'), local('Blatant'), url(${BlatantOtf}) format('opentype');
        }

        @font-face {
          font-family: 'Blatant';
          font-weight: 700;
          src: local('Blatant'), local('Blatant-Bold'), url(${BlatantBoldOtf}) format('opentype');
        }
      `,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          lineHeight: '13px',
          fontSize: '14px',
          padding: '14px 10px 13px',

          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            display: 'none',
          },
          '&::placeholder': {
            fontSize: '14px',
          },
        },
        notchedOutline: {
          borderWidth: '1px',
          borderColor: '#000000',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          textDecorationColor: 'inherit',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          color: '#000000',

          '&:hover': {
            backgroundColor: 'initial',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '10px',
          marginRight: '10px',
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '20px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          transition: '500ms',
        },
      },
    },
  },
});

export default theme;
