import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  Link,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ReactComponent as CheckMarkCircleIconWhite } from '../../../assets/icons/check-mark-circle-white.svg';
import { ReactComponent as CheckMarkCircleIcon } from '../../../assets/icons/check-mark-circle.svg';
import { ReactComponent as CollapsingIcon } from '../../../assets/icons/collapsing-arrow.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import managerAvatarUrl from '../../../assets/images/manager-avatar.jpeg';
import { AmenityList } from '../../../common/components/AmenitiesList';
import { AvailableWeeksBox } from '../../../common/components/AvailableWeeksBox';
import { BasicContainer } from '../../../common/components/BasicContainer';
import { BookNowBox } from '../../../common/components/BookNowBox';
import { BoxWithBackground } from '../../../common/components/BoxWithBackground';
import { Divider } from '../../../common/components/Divider';
import { FurnitureList } from '../../../common/components/FurnitureList';
import { GoogleMap } from '../../../common/components/GoogleMap';
import { GoogleMapPin } from '../../../common/components/GoogleMapPin';
import { SpotInfoBox } from '../../../common/components/SpotInfoBox';
import { Swiper } from '../../../common/components/Swiper';
import { findPartInAddressObjByType } from '../../../common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { useBookingData } from '../../../common/hooks/use-booking-data';
import BlockedDatesDTO from '../../../models/blocked-dates-dto';
import BookedDatesDTO from '../../../models/booked-dates-dto';
import FeedbackDTO from '../../../models/feedback/feedback-dto';
import SpotDTO from '../../../models/spot/spot-dto';
import StoreDTO from '../../../models/store/store-dto';
import { ImgService } from '../../../services/img-service';
import { SpotsService } from '../../../services/spots';
import theme from '../../../theme-config/theme';
import {
  getBlockedDatesBySpotId,
  getBookedDatesBySpotId,
  getSpotById,
  getStoreById,
  selectBookingState,
} from '../bookingSlice';
import { StyledHeroSection, StyledMobileHeroSectionBackground, StyledStreetCard } from './styles';

const howToRentItems = [
  'The Stores are rented from Saturday to Friday',
  'The minimum rent is two weeks, starting from Saturday',
  'Discounts may apply when booking for longer periods',
];

export const Spot = (): JSX.Element => {
  const {
    selectedSpot,
    selectedStore,
    dateRange,
  }: {
    selectedSpot: SpotDTO;
    selectedStore: StoreDTO;
    dateRange: { startDateStr: string; endDateStr: string };
  } = useAppSelector(selectBookingState);
  const { startDateStr, endDateStr } = dateRange;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { spotId } = useParams();
  const [isOpenHowToRentBlock, setIsOpenHowToRentBlock] = useState(true);
  const [feedbackList, setFeedbackList] = useState<FeedbackDTO[]>();
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const isUpSmBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));
  const { formattedPricePerWeek, spotCityName } = useBookingData(selectedSpot, selectedStore, startDateStr, endDateStr);
  const spotsService: SpotsService = new SpotsService();
  const imgService: ImgService = new ImgService();

  const durationParameter = searchParams.get('duration');
  const searchParamsObj: Record<string, string> = {
    duration: durationParameter ? durationParameter : '',
    start_date: startDateStr ? startDateStr : '',
    end_date: endDateStr ? endDateStr : '',
  };

  const spotCoordinates = { lat: selectedStore?.address?.lat, lng: selectedStore?.address?.lng };
  const defaultStoreImgUrl =
    selectedStore?.defaultImage &&
    imgService.getImgPreviewUrl(selectedStore.defaultImage, {
      width: 1000,
      height: 1000,
    });
  const defaultStreetImgUrl =
    selectedStore?.streetImage &&
    imgService.getImgPreviewUrl(selectedStore.streetImage, {
      width: 600,
      height: 600,
    });
  const spotStreetName =
    selectedStore?.address?.parts && findPartInAddressObjByType(selectedStore.address.parts, 'route')?.name;

  const mobileSpotInfoBlock = (
    <>
      <Box sx={{ position: 'absolute', bottom: '0', left: '16px', zIndex: '1' }}>
        <Box>
          <Typography
            variant="h3"
            sx={{
              display: 'inline-block',
              textTransform: 'uppercase',
              color: (theme) => theme.palette.common.white,
              marginBottom: 5,
            }}
          >
            {selectedSpot?.name}
          </Typography>
          {spotCityName && (
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.common.white }}>
              {', ' + spotCityName}
            </Typography>
          )}
        </Box>

        <Typography variant="h3" sx={{ color: (theme) => theme.palette.common.white, marginBottom: 5 }}>
          {formattedPricePerWeek ? formattedPricePerWeek : ''}
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.common.white }}>
            /Week
          </Typography>
        </Typography>
      </Box>
    </>
  );

  const clickOnBookNowHandler = () => {
    const { duration, ...dateRange }: Record<string, string> = searchParamsObj;

    navigate({
      pathname: `/booking/checkout/${spotId}`,
      search: `${createSearchParams(dateRange)}`,
    });
  };

  useEffect(() => {
    if (spotId) {
      dispatch(getSpotById(parseInt(spotId)))
        .unwrap()
        .then((spotData: SpotDTO) => {
          dispatch(getStoreById(spotData.storeId));
          dispatch(getBlockedDatesBySpotId(spotData.id));
          dispatch(getBookedDatesBySpotId(spotData.id));
          spotsService
            .getFeedbackBySpotId(spotData.id)
            .execute()
            .then((data) => {
              setFeedbackList(data);
            });
        });
    }
  }, [spotId]);

  return (
    <BasicContainer>
      {selectedSpot?.images?.length ? (
        <Box className="full-width-on-sm" sx={{ position: 'relative', marginBottom: 15 }}>
          <Swiper className={'spots'} loop={true} navigation pagination={{ type: 'fraction' }} slidesPerView={1}>
            {selectedSpot.images.map((imgId: string, index: number) => {
              const imgUrl = imgService.getImgPreviewUrl(imgId, {
                width: 1000,
                height: 1000,
              });

              return (
                <React.Fragment key={'img-item' + index}>
                  <StyledMobileHeroSectionBackground />
                  <StyledHeroSection imageUrl={imgUrl}></StyledHeroSection>
                </React.Fragment>
              );
            })}
          </Swiper>

          {!isUpSmBreakPoint && mobileSpotInfoBlock}
          {isUpSmBreakPoint && <AvailableWeeksBox />}
        </Box>
      ) : (
        <>
          <StyledHeroSection
            sx={{ marginBottom: 15 }}
            imageUrl={defaultStoreImgUrl ? defaultStoreImgUrl : ''}
            className="full-width-on-sm"
          >
            <StyledMobileHeroSectionBackground />
            {!isUpSmBreakPoint && mobileSpotInfoBlock}
            {isUpSmBreakPoint && <AvailableWeeksBox />}
          </StyledHeroSection>
        </>
      )}

      <Grid container columnSpacing={20} sx={{ paddingBottom: { xs: 15, md: 20 } }}>
        <Grid item xs={12} md={6} lg={7}>
          <Box sx={{ maxWidth: { xs: '100%', md: '600px' } }}>
            {!isUpSmBreakPoint && <AvailableWeeksBox sx={{ marginTop: 10, marginBottom: 10 }} />}

            {isUpSmBreakPoint && (
              <Typography variant="h1" sx={{ marginBottom: 5 }}>
                {selectedSpot?.name}
                {spotCityName && ', ' + spotCityName}
              </Typography>
            )}

            {!isUpMdBreakPoint && (
              <BookNowBox sx={{ marginX: 'auto', marginBottom: 10 }} clickOnBookNowHandler={clickOnBookNowHandler} />
            )}

            <Typography variant="h5" sx={{ fontSize: '16px', marginBottom: 2 }}>
              About this space:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '16px', marginBottom: 15 }}>
              {selectedSpot?.description}
            </Typography>

            <SpotInfoBox selectedSpot={selectedSpot} selectedStore={selectedStore}></SpotInfoBox>
            <Divider sx={{ marginY: 15 }} />

            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              Amenities
            </Typography>
            <AmenityList amenityItems={selectedSpot?.amenities} />

            <Divider sx={{ marginY: 15 }} />

            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              Inside
            </Typography>
            <FurnitureList spotFurnitureItems={selectedSpot?.furnitures} />

            <BoxWithBackground
              className="full-width-on-sm"
              sx={{ paddingX: 20, paddingY: 15, marginTop: 15, marginBottom: 15, textAlign: 'center' }}
            >
              <Typography variant="subtitle2" sx={{ marginBottom: 5 }}>
                Contact our community manager of {selectedSpot?.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Avatar
                  sx={{ width: '60px', height: '60px', marginRight: '10px', border: 'solid 2px white' }}
                  src={managerAvatarUrl}
                  alt="manager avatars"
                />
                <Typography sx={{ typography: { xs: 'h3', md: 'h5' }, marginRight: '28px' }}>
                  {selectedStore?.contactName}
                </Typography>
                <Link
                  href={`https://wa.me/${selectedStore?.contactPhoneNumber && selectedStore.contactPhoneNumber}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgIcon
                    viewBox={'0 0 40 40'}
                    component={PhoneIcon}
                    sx={{ marginRight: 13, width: '40px', height: '40px' }}
                  />
                </Link>
                <Link href={`mailto:${selectedStore?.contactEmail && selectedStore.contactEmail}`}>
                  <SvgIcon viewBox={'0 0 51 40'} component={MailIcon} sx={{ width: '50px', height: '40px' }} />
                </Link>
              </Box>
            </BoxWithBackground>

            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              Location
            </Typography>

            <Box sx={{ width: '100%', height: '210px', borderRadius: '10px', overflow: 'hidden' }}>
              <GoogleMap
                center={spotCoordinates?.lat && spotCoordinates?.lng ? spotCoordinates : undefined}
                defaultZoom={14}
              >
                <GoogleMapPin lat={spotCoordinates?.lat} lng={spotCoordinates?.lng} />
              </GoogleMap>
            </Box>

            {feedbackList?.length ? (
              <>
                <Divider sx={{ marginY: 15 }} />
                <Typography variant="h3" sx={{ marginBottom: { xs: 10, md: 20 } }}>
                  What our Brands think about {selectedSpot?.name}
                </Typography>

                <Swiper className="feedbacks" navigation slidesPerView={1}>
                  {feedbackList.map((feedbackItem, index) => {
                    const { brandLogo, brandName, description } = feedbackItem;
                    const brandLogoUrl = imgService.getImgPreviewUrl(brandLogo, { width: 100, height: 100 });

                    return (
                      <Box
                        key={'feedback-item' + index}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          padding: { xs: '0 40px', md: '0 70px' },
                        }}
                      >
                        <Avatar
                          sx={{
                            width: { xs: '40px', md: '70px' },
                            height: { xs: '40px', md: '70px' },
                            marginRight: { xs: '12px', md: '20px' },
                          }}
                          src={brandLogoUrl}
                          alt="brand avatar"
                        />

                        <Box>
                          <Typography variant="h4" sx={{ typography: { xs: 'h6', md: 'h3', marginBottom: 5 } }}>
                            {brandName}
                          </Typography>

                          <Typography variant="h4" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                            {description}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Swiper>
              </>
            ) : null}

            {selectedStore?.famousBrands?.length ? (
              <>
                <Divider sx={{ marginY: 15 }} />
                <Typography variant="h3" sx={{ marginBottom: { xs: 10, md: 9 } }}>
                  {selectedSpot?.name} famous brands
                </Typography>

                <Swiper className="famous-brands" spaceBetween={15} slidesPerView={4}>
                  {selectedStore.famousBrands.map((famousBrandItem: string, index: number) => {
                    const famousBrandLogoUrl = imgService.getImgPreviewUrl(famousBrandItem, {
                      width: 200,
                      height: 200,
                    });

                    return <img key={'famous-brand-item' + index} alt="famous-brand-logo" src={famousBrandLogoUrl} />;
                  })}
                </Swiper>
              </>
            ) : null}

            {isUpMdBreakPoint && <Divider sx={{ marginY: 15 }} />}

            {!isUpMdBreakPoint && (
              <BoxWithBackground
                className="full-width-on-sm"
                sx={{
                  paddingX: 8,
                  paddingY: 15,
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <>
                  <Typography variant="h3" sx={{ marginBottom: 5 }}>
                    How to rent at Repop
                  </Typography>

                  {howToRentItems.map((item, index) => {
                    return (
                      <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <SvgIcon
                          viewBox={'0 0 18 18'}
                          component={CheckMarkCircleIconWhite}
                          sx={{
                            width: '18px',
                            height: '18px',
                            marginRight: 5,
                            fill: 'none',
                          }}
                        />

                        {item}
                      </Box>
                    );
                  })}
                </>
              </BoxWithBackground>
            )}

            <StyledStreetCard>
              <CardMedia component="img" image={defaultStreetImgUrl} />
              <Box className="mobile-background" />
              <CardContent>
                <Typography variant="h3">{spotStreetName}</Typography>
                <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} className="street-description">
                  {selectedStore?.streetDescription}
                </Typography>
              </CardContent>
            </StyledStreetCard>
          </Box>
        </Grid>

        {isUpMdBreakPoint && (
          <Grid item xs={12} md={6} lg={5}>
            <Box sx={{ maxWidth: '470px', marginLeft: { md: 'auto', lg: 'initial' } }}>
              <BookNowBox clickOnBookNowHandler={clickOnBookNowHandler} />

              <Box
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: 10, marginBottom: 5 }}
                onClick={() => {
                  setIsOpenHowToRentBlock(!isOpenHowToRentBlock);
                }}
              >
                <Typography sx={{ typography: { xs: 'h6', md: 'h5' }, marginRight: 5 }}>
                  How to rent at Repop
                </Typography>

                <SvgIcon
                  sx={{
                    width: '18px',
                    height: '9px',
                    fill: 'none',
                    transition: 'none',
                    transform: isOpenHowToRentBlock ? 'rotate(180deg)' : '',
                  }}
                  viewBox={'0 0 18 9'}
                  component={CollapsingIcon}
                />
              </Box>

              <Collapse in={isOpenHowToRentBlock} timeout="auto">
                {howToRentItems.map((item, index) => {
                  return (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                      <SvgIcon
                        viewBox={'0 0 20 20'}
                        component={CheckMarkCircleIcon}
                        sx={{
                          width: '18px',
                          height: '18px',
                          marginRight: 5,
                          fill: (theme: Theme) => theme.palette.common.white,
                        }}
                      />

                      {item}
                    </Box>
                  );
                })}
              </Collapse>
            </Box>
          </Grid>
        )}
      </Grid>
    </BasicContainer>
  );
};
