import React from 'react';

import { Box, BoxProps, Grid, Typography } from '@mui/material';

import popUpYourBrand from '../../../assets/images/pop-up-your-brand.svg';
import repopLogoWhite from '../../../assets/images/repop-logo-white.svg';
import { StyledGridWithRePopBackground } from './styles';

export const GridWithRepopBackground = ({
  repopBackground,
  columnBackground,
  children,
  isAvailableBgColumnOnMobile = false,
  sx = {},
}: GridWithRepopBackgroundProps): JSX.Element => {
  return (
    <StyledGridWithRePopBackground
      container
      columnBackground={columnBackground}
      repopBackground={repopBackground}
      isAvailableBgColumnOnMobile={isAvailableBgColumnOnMobile}
      sx={sx}
    >
      <Grid item container sm={12} md={6} lg={6} sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}>
        <Grid item sm={10} md={12} lg={10} sx={{ maxWidth: { md: '630px' }, padding: { xs: 8, md: 15 } }}>
          {children}
        </Grid>
      </Grid>
      <Grid item container sm={12} md={6} lg={6} className="column-with-background">
        <Grid item sm={12} sx={{ display: 'flex', flexDirection: 'column', padding: { xs: 8, md: 15 } }}>
          <Box>
            <img className="responsive-img pop-up-your-brand-img" src={popUpYourBrand} alt="pop-up-your-brand" />
          </Box>
          <Box className="repop-background-img"></Box>
          <Box className="additional-info-block">
            <img className="responsive-img logo-img" src={repopLogoWhite} alt="white logo" />
            <Typography className="url" sx={{ fontSize: { xs: '20px', sm: '30px', lg: '40px' } }}>
              WWW.REPOP.SHOP
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </StyledGridWithRePopBackground>
  );
};

interface GridWithRepopBackgroundProps extends BoxProps {
  repopBackground: string;
  columnBackground: string;
  children: JSX.Element | JSX.Element[];
  sx?: object;
  isAvailableBgColumnOnMobile?: boolean;
}
