import { Drawer } from '@mui/material';
import { styled } from '@mui/system';

export const StyledRightDrawer = styled(Drawer)(({ theme }) => ({
  '> .MuiPaper-root': {
    width: '100%',
    padding: theme.spacing(12),

    [theme.breakpoints.up('sm')]: {
      width: '535px',
    },
  },

  '.full-width': {
    borderRadius: '0',
    width: `calc(100% + ${theme.spacing(12)} * 2)`,
    marginLeft: `-${theme.spacing(12)}`,
  },
}));
