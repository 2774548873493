import { getAllCurrencies } from 'constants/enums/currency';

import React, { useEffect, useState } from 'react';

import { Grid, MenuItem, Typography } from '@mui/material';
import { BasicButton } from 'common/components/basic/Button';
import { BasicInput } from 'common/components/basic/Input';
import { BasicSelect } from 'common/components/basic/Select';
import { DashboardBasicContainer } from 'common/components/dashboard/BasicContainer';
import { DashboardCaption } from 'common/components/dashboard/Caption';
import { GoogleAutocompleteInput } from 'common/components/GoogleAutocomplete';
import { createAddressDtoFromGoogleObj } from 'common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { useStateFormControlHandler } from 'common/hooks/use-state-from-control-handler';
import { changeUserCurrency, selectAuthState, updateCurrentUser } from 'features/authentication/authSlice';
import UserEditDTO from 'models/user/user-edit-dto';

export const Profile = (): JSX.Element => {
  const userEditInitialState = {} as UserEditDTO;
  const dispatch = useAppDispatch();
  const { authorizedUser } = useAppSelector(selectAuthState);
  const [userEditData, setUserEditData] = useState(userEditInitialState);
  const { inputHandler } = useStateFormControlHandler(userEditData, setUserEditData);

  const clickOnSaveUserDataHandler = () => {
    dispatch(
      updateCurrentUser({
        ...userEditData,
      }),
    );
  };

  const changeUserAddressHandler = (place: any) => {
    const addressObj = createAddressDtoFromGoogleObj(place);
    setUserEditData({ ...userEditData, address: addressObj });
  };

  useEffect(() => {
    setUserEditData({
      firstName: authorizedUser.firstName,
      lastName: authorizedUser.lastName,
      address: authorizedUser.address,
      phoneNumber: authorizedUser.phoneNumber,
    });
  }, [authorizedUser]);

  return (
    <>
      <DashboardCaption>Profile</DashboardCaption>

      <DashboardBasicContainer>
        <Grid
          container
          columns={12}
          rowSpacing={10}
          columnSpacing={15}
          sx={{ maxWidth: '600px', margin: { xs: undefined, md: '0 auto' } }}
        >
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              User info
            </Typography>

            <Typography variant="body1">
              <b>Email:</b> {authorizedUser?.email}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <BasicInput
              id="first-name"
              value={userEditData?.firstName || ''}
              fullWidth
              inputProps={{
                name: 'firstName',
                placeholder: 'First name',
                autoComplete: 'given-name',
                onChange: inputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicInput
              id="last-name"
              value={userEditData?.lastName || ''}
              fullWidth
              inputProps={{
                name: 'lastName',
                placeholder: 'Last name',
                autoComplete: 'family-name',
                onChange: inputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicInput
              id="phone"
              value={userEditData?.phoneNumber || ''}
              fullWidth
              inputProps={{
                name: 'phoneNumber',
                placeholder: 'Phone (with country code)',
                type: 'tel',
                autoComplete: 'tel',
                onChange: inputHandler,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <GoogleAutocompleteInput
              placeholder="User full address (Google Autocomplete)"
              formattedAddress={userEditData?.address?.formattedAddress}
              onPlaceSelectedHandler={changeUserAddressHandler}
            />
          </Grid>

          <Grid item xs={12}>
            <BasicButton
              className="btn-black btn-small"
              sx={{ minWidth: { xs: '140px', md: '170px', margin: '0 auto 30px auto' }, display: 'block' }}
              variant="contained"
              onClick={clickOnSaveUserDataHandler}
            >
              <Typography variant="subtitle2">SAVE</Typography>
            </BasicButton>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ marginBottom: 10 }}>
              Currency
            </Typography>
            <BasicSelect
              id="currency"
              value={authorizedUser.currency}
              name="currency"
              placeholder="Select currency"
              className="select-like-dropdown"
              placeholderStyles={{ fontSize: { xs: 12, md: 20 }, fontWeight: 700 }}
              onChange={(e: any) => {
                dispatch(changeUserCurrency(e.target.value));
              }}
            >
              {getAllCurrencies().map((type, index) => {
                return (
                  <MenuItem value={type.id} key={index}>
                    {type.name}
                  </MenuItem>
                );
              })}
            </BasicSelect>
          </Grid>
        </Grid>
      </DashboardBasicContainer>
    </>
  );
};
