import { getAllCurrencies } from 'constants/enums/currency';
import { getAllSpotTypes } from 'constants/enums/spot/spot-types';

import React, { useEffect, useState } from 'react';

import { Grid, MenuItem, Typography } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { BasicButton } from 'common/components/basic/Button';
import { Checkbox } from 'common/components/basic/Checkbox';
import { BasicInput } from 'common/components/basic/Input';
import { BasicSelect } from 'common/components/basic/Select';
import { DashboardBasicContainer } from 'common/components/dashboard/BasicContainer';
import { DashboardBlockedDatesBox } from 'common/components/dashboard/BlockedDatesBox';
import { DashboardCaption } from 'common/components/dashboard/Caption';
import { DashboardFurnitureList } from 'common/components/dashboard/FurnitureList';
import { DashboardSpotTranslationBox } from 'common/components/dashboard/SpotTranslationBox';
import { PhotoUploader } from 'common/components/PhotoUploader';
import { createArrayByCheckboxValue } from 'common/helpers/create-array-by-checkbox-value';
import { isNotUndefinedOrNull } from 'common/helpers/utils';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { useStateFormControlHandler } from 'common/hooks/use-state-from-control-handler';
import { getAmenities, selectAmenitiesState } from 'features/amenities/amenitiesSlice';
import { enqueueNotification as enqueueSnackbarAction } from 'features/notifier/notifierSlice';
import AmenitiesDTO from 'models/amenities-dto';
import BlockedDatesDTO from 'models/blocked-dates-dto';
import ImageEditDTO from 'models/image-edit-dto';
import SpotEditDTO from 'models/spot/spot-edit-dto';
import SpotFurnitureDTO from 'models/spot/spot-furniture-dto';
import StoreDTO from 'models/store/store-dto';
import SpotTranslateDTO from 'models/translations/spot-translate-dto';
import { SpotManagerService } from 'services/spot-manager';
import { StoresService } from 'services/stores';

export const NewSpot = (): JSX.Element => {
  const { amenitiesList } = useAppSelector(selectAmenitiesState);
  const dispatch = useAppDispatch();
  const initialState = {} as SpotEditDTO;
  const [newSpotData, setNewSpotData] = useState<SpotEditDTO>(initialState);
  const [storesList, setStoresList] = useState<Array<StoreDTO>>([]);
  const { inputHandler } = useStateFormControlHandler(newSpotData, setNewSpotData);

  const storeService: StoresService = new StoresService();
  const spotManagerService: SpotManagerService = new SpotManagerService();

  let {
    name,
    description,
    storeId,
    sizeInMeters,
    type,
    currency,
    price,
    vat,
    furnitures,
    amenities,
    sales,
    newImages,
    isHot,
    isBlocked,
    blockedDates,
    translates,
  } = newSpotData;

  const loadedStoreImageHandler = (files: ImageEditDTO[]) => {
    setNewSpotData({ ...newSpotData, newImages: files });
  };

  const changeInsideCheckboxHandler = (spotFurniture: SpotFurnitureDTO[]) => {
    setNewSpotData({
      ...newSpotData,
      furnitures: spotFurniture,
    });
  };

  const changeAmenitiesCheckboxHandler = (event: any, id: number) => {
    setNewSpotData({
      ...newSpotData,
      amenities: createArrayByCheckboxValue(event, id, amenities),
    });
  };

  const changeBlockedDatesHandler = (blockedDates: BlockedDatesDTO[]) => {
    setNewSpotData({
      ...newSpotData,
      blockedDates: blockedDates,
    });
  };

  const changeTranslationsHandler = (translations: SpotTranslateDTO[]) => {
    setNewSpotData({
      ...newSpotData,
      translates: translations,
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();

    spotManagerService
      .createSpot(newSpotData)
      .execute()
      .then((data) => {
        if (data?.id) {
          dispatch(
            enqueueSnackbarAction({
              message: 'Spot successfully created',
              options: { variant: 'success' },
            }),
          );
          setNewSpotData(initialState);
        }
      });
  };

  useEffect(() => {
    storeService
      .getAllStores()
      .execute()
      .then((res) => setStoresList(res));

    !amenitiesList.length && dispatch(getAmenities());
  }, []);

  return (
    <>
      <DashboardCaption>NEW SPOT</DashboardCaption>

      <form>
        <DashboardBasicContainer maxWidthInPixels="970px">
          <Grid container columns={10} rowSpacing={10} columnSpacing={15} sx={{ marginBottom: 20 }}>
            <Grid container item rowSpacing={10} columnSpacing={15}>
              <Grid item xs={12} md={4}>
                <BasicInput
                  id="name"
                  value={name || ''}
                  fullWidth
                  inputProps={{
                    name: 'name',
                    placeholder: 'Spot name',
                    onChange: inputHandler,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <BasicSelect
                  fullWidth
                  value={storeId || ''}
                  name="storeId"
                  id="store-id"
                  placeholder="Store"
                  onChange={inputHandler}
                >
                  {storesList.map((store, index) => {
                    return (
                      <MenuItem value={store.id} key={index}>
                        {store.name}
                      </MenuItem>
                    );
                  })}
                </BasicSelect>
              </Grid>

              <Grid item xs={12} md={4}>
                <BasicSelect
                  fullWidth
                  value={type || ''}
                  name="type"
                  id="type"
                  placeholder="Type"
                  onChange={inputHandler}
                >
                  {getAllSpotTypes().map((type, index) => {
                    return (
                      <MenuItem value={type.id} key={index}>
                        {type.name}
                      </MenuItem>
                    );
                  })}
                </BasicSelect>
              </Grid>
              <Grid item xs={12} md={4}>
                <BasicInput
                  id="sales"
                  value={sales || ''}
                  fullWidth
                  inputProps={{
                    name: 'sales',
                    type: 'number',
                    placeholder: 'Daily sales',
                    onChange: inputHandler,
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={5} md={2}>
              <BasicInput
                id="size"
                value={sizeInMeters || ''}
                fullWidth
                inputProps={{
                  name: 'sizeInMeters',
                  type: 'number',
                  placeholder: 'Size (mts)',
                  onChange: inputHandler,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={2}>
              <BasicSelect
                id="currency"
                value={isNotUndefinedOrNull(currency) ? currency : ''}
                name="currency"
                fullWidth={true}
                placeholder="Currency"
                onChange={inputHandler}
              >
                {getAllCurrencies().map((currency, index) => {
                  return (
                    <MenuItem value={currency.id} key={index}>
                      {currency.name}
                    </MenuItem>
                  );
                })}
              </BasicSelect>
            </Grid>

            <Grid item xs={12} sm={5} md={2}>
              <BasicInput
                id="price"
                value={price || ''}
                fullWidth
                inputProps={{
                  name: 'price',
                  type: 'number',
                  placeholder: 'Price (per day)',
                  onChange: inputHandler,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={2}>
              <BasicInput
                id="vat"
                value={vat || ''}
                fullWidth
                inputProps={{
                  name: 'vat',
                  type: 'number',
                  placeholder: 'VAT%',
                  onChange: inputHandler,
                }}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <BasicInput
                id="description"
                value={description || ''}
                fullWidth
                multiline={true}
                rows={10}
                inputProps={{
                  name: 'description',
                  placeholder: 'Spot Description',
                  onChange: inputHandler,
                }}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <Checkbox
                name={'isHot'}
                checked={Boolean(isHot)}
                label={<Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>Hot</Typography>}
                changeHandler={inputHandler}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 20 }}>
              <Typography variant="h3">Translations</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <DashboardSpotTranslationBox translations={translates} changeHandler={changeTranslationsHandler} />
            </Grid>
          </Grid>
        </DashboardBasicContainer>

        <DashboardBasicContainer maxWidthInPixels="600px">
          <Grid container columns={10} rowSpacing={10} columnSpacing={15} sx={{ marginBottom: 20 }}>
            <Grid item xs={12} sx={{ marginTop: 10 }}>
              <Typography variant="h3">Blocked dates</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={12} md={8}>
              <Checkbox
                name={'isBlocked'}
                checked={Boolean(isBlocked)}
                label={<Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>Blocked</Typography>}
                changeHandler={inputHandler}
              />
            </Grid>
            <DashboardBlockedDatesBox blockedDates={blockedDates} changeHandler={changeBlockedDatesHandler} />
          </Grid>
        </DashboardBasicContainer>

        <DashboardBasicContainer>
          <Grid container>
            <Grid item xs={12}>
              <Grid container rowSpacing={10} columnSpacing={15}>
                <Grid item xs={12} sx={{ marginTop: 10 }}>
                  <Typography variant="h3">Photos</Typography>
                </Grid>
                <Grid item xs={12}>
                  <PhotoUploader maxFiles={10} selectedFiles={newImages} setSelectedFiles={loadedStoreImageHandler}>
                    <PlusIcon />
                  </PhotoUploader>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DashboardBasicContainer>

        <DashboardBasicContainer maxWidthInPixels="970px">
          <Grid container columns={10} rowSpacing={10} columnSpacing={15} sx={{ marginBottom: 20 }}>
            <Grid item xs={12} md={8} sx={{ mt: 20, mb: 10 }}>
              <Typography className="title" variant="h3" sx={{ mb: 10 }}>
                Inside
              </Typography>
              <Grid container item rowSpacing={16}>
                <DashboardFurnitureList selectedItems={furnitures} handler={changeInsideCheckboxHandler} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} sx={{ mb: 20 }}>
              <Typography className="title" variant="h3" sx={{ mb: 10 }}>
                Amenities
              </Typography>
              <Grid container direction="row" rowSpacing={16}>
                {amenitiesList.map((item: AmenitiesDTO, index: number) => {
                  return (
                    <Grid item xs={4} key={index}>
                      <Checkbox
                        checked={!!amenities?.find((itemFromState) => item.id === itemFromState)}
                        label={
                          <Typography
                            sx={{
                              typography: {
                                xs: 'body2',
                                md: 'body1',
                              },
                            }}
                          >
                            {item.name}
                          </Typography>
                        }
                        changeHandler={(e) => {
                          changeAmenitiesCheckboxHandler(e, item.id);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            {/*<Grid item xs={9}>*/}
            {/*    <LinksList*/}
            {/*        editable*/}
            {/*        title="Links and files"*/}
            {/*        linksListInputProps={{*/}
            {/*            id: 'view-link',*/}
            {/*            name: 'ViewLink',*/}
            {/*            placeholder: '3D View link',*/}
            {/*        }}*/}
            {/*    ></LinksList>*/}
            {/*</Grid>*/}
          </Grid>
        </DashboardBasicContainer>

        <DashboardBasicContainer>
          <Grid
            container
            justifyContent={{ xs: 'center', sm: 'end' }}
            rowSpacing={10}
            columnSpacing={15}
            sx={{ marginTop: 10 }}
          >
            <Grid item>
              <BasicButton
                onClick={() => {
                  setNewSpotData(initialState);
                }}
                className="btn-large"
                sx={{ minWidth: { xs: '140px', md: '170px' }, display: 'block' }}
              >
                Cancel
              </BasicButton>
            </Grid>

            <Grid item>
              <BasicButton
                className="btn-black btn-large"
                sx={{ minWidth: { xs: '140px', md: '170px' }, display: 'block' }}
                variant="contained"
                onClick={submitHandler}
              >
                <Typography variant="body1">NEXT</Typography>
              </BasicButton>
            </Grid>
          </Grid>
        </DashboardBasicContainer>
      </form>
    </>
  );
};
