import React from 'react';

import { DashboardBasicContainer } from '../../../common/components/dashboard/BasicContainer';
import { DashboardCaption } from '../../../common/components/dashboard/Caption';
import { InvoicesTab } from './InvoicesTab';

export const Billing = (): JSX.Element => {
  return (
    <>
      <DashboardCaption>Billing</DashboardCaption>

      <DashboardBasicContainer>
        <InvoicesTab></InvoicesTab>
      </DashboardBasicContainer>
    </>
  );
};
