import React from 'react';

import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { getFormattedPrice } from '../../../../common/helpers/formatters';
import { findPartInAddressObjByType } from '../../../../common/helpers/google-heplers';
import { useAppSelector } from '../../../../common/hooks/redux-hooks';
import { getById as getCurrencyById } from '../../../../constants/enums/currency';
import { getAllPaymentTransactionStatuses } from '../../../../constants/enums/payment-transaction-status';
import BrandDTO from '../../../../models/brand/brand-dto';
import CampaignDTO from '../../../../models/campaign/campaign-dto';
import PaymentTransactionDTO from '../../../../models/payment-transactions/payment-transaction-dto';
import SpotDTO from '../../../../models/spot/spot-dto';
import StoreDTO from '../../../../models/store/store-dto';
import { selectManagerDashboardState } from '../../managerDashboardSlice';

export const InvoicesAdditionalInfo = ({
  selectedPaymentTransactionId,
}: {
  selectedPaymentTransactionId: number | undefined;
}): JSX.Element => {
  const { paymentTransactionsFilterResult } = useAppSelector(selectManagerDashboardState);

  const paymentTransaction = paymentTransactionsFilterResult?.searchPage?.items.find(
    (paymentTransaction: PaymentTransactionDTO) => paymentTransaction.id === selectedPaymentTransactionId,
  );
  const campaign = paymentTransactionsFilterResult?.campaigns.find(
    (campaign: CampaignDTO) => campaign.id === paymentTransaction?.campaignId,
  );
  const brand = paymentTransactionsFilterResult?.brands.find((brand: BrandDTO) => brand.id === campaign?.brandId);
  const spot = paymentTransactionsFilterResult?.spots.find((spot: SpotDTO) => spot.id == campaign?.spotId);
  const store = paymentTransactionsFilterResult?.stores.find((store: StoreDTO) => store.id == spot?.storeId);
  const spotCityName = store?.address?.parts ? findPartInAddressObjByType(store.address.parts, 'locality')?.name : '';
  const parsedStatus = getAllPaymentTransactionStatuses().find((x) => x.id == paymentTransaction?.status);
  const currency = getCurrencyById(paymentTransaction.currency);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 15 }}>
        <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
          {brand?.name}
        </Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ marginBottom: 6 }}>
        Payment
      </Typography>

      {paymentTransaction?.createdAt && (
        <Typography variant="body1" sx={{ color: '#808080', marginBottom: 20 }}>
          Created on: {format(new Date(paymentTransaction.createdAt), 'dd/MM/yyyy')}
        </Typography>
      )}

      {campaign?.startDate && campaign?.endDate && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
          <Typography variant="h3" sx={{ marginRight: 10 }}>
            From:
            <Typography variant="caption" sx={{ typography: { xs: 'body2', md: 'body1', marginLeft: 5 } }}>
              {format(new Date(campaign.startDate), 'dd/MM/yyyy')}
            </Typography>
          </Typography>

          <Typography variant="h3">
            To:
            <Typography variant="caption" sx={{ typography: { xs: 'body2', md: 'body1' }, marginLeft: 5 }}>
              {format(new Date(campaign.endDate), 'dd/MM/yyyy')}
            </Typography>
          </Typography>
        </Box>
      )}

      <Box sx={{ marginBottom: 20 }}>
        <Typography variant="h3" sx={{ marginBottom: 6 }}>
          Store
        </Typography>
        <Typography variant="body1">
          {spot?.name}
          {spotCityName && ', ' + spotCityName}
        </Typography>
      </Box>

      <Box sx={{ marginBottom: 50 }}>
        <Typography variant="h3" sx={{ marginBottom: 6 }}>
          Status
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Box
            sx={{
              width: '12px',
              height: '12px',
              flexShrink: '0',
              marginRight: 5,
              borderRadius: '20px',
              backgroundColor: parsedStatus?.color,
            }}
          ></Box>

          <Typography variant="subtitle2">{parsedStatus?.name}</Typography>
        </Box>

        {paymentTransaction?.cost && currency?.symbol && (
          <Typography variant="body1">
            Total payment: {getFormattedPrice(paymentTransaction.cost, currency.symbol, true)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
