import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import FeedbackDTO from '../models/feedback/feedback-dto';
import FeedbackEditDTO from '../models/feedback/feedback-edit-dto';

export class FeedbackBrandService {
  private readonly basePath: string = 'brand/feedbacks';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public createFeedback(feedback: FeedbackEditDTO): ApiRequestWrapper<FeedbackDTO> {
    return this.apiUrlProvider.post(this.basePath, feedback);
  }
}
