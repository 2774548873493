import React, { useEffect, useState } from 'react';

import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';

import cardTemp from '../../../assets/images/temp/cardTemp.png';
import { getById as getCurrencyById } from '../../../constants/enums/currency';
import { ImgService } from '../../../services/img-service';
import { getFormattedPrice } from '../../helpers/formatters';
import { useCurrencyConverter } from '../../hooks/use-currency-converter';
import { storeCardStyles } from './styles';

export const StoreCard = ({
  className,
  name,
  spotPrice,
  spotCurrency,
  shortAddress,
  sizeInMeters,
  imageId,
  clickHandler,
}: StoreCardProps): JSX.Element => {
  const imgService: ImgService = new ImgService();
  const [imgPreview, setImgPreview] = useState('');
  const spotCurrencyObj = getCurrencyById(spotCurrency);
  const { convertedPrice: convertedSpotPrice = 0, currencySymbol = '' } = useCurrencyConverter(
    spotCurrencyObj,
    spotPrice,
  );
  const formattedPricePerWeek = getFormattedPrice(convertedSpotPrice * 7, currencySymbol, true);

  useEffect(() => {
    const imgPreviewLink = imageId ? imgService.getImgPreviewUrl(imageId, { width: 600, height: 600 }) : cardTemp;
    setImgPreview(imgPreviewLink);
  }, [imageId]);

  return (
    <Card className={className} sx={storeCardStyles} onClick={clickHandler}>
      <CardMedia component="img" image={imgPreview ? imgPreview : cardTemp} />
      <CardContent>
        <Typography variant="h3">{name}</Typography>

        <Box className="spot-details">
          <Typography variant="body1" sx={{ marginBottom: 5 }}>
            {shortAddress}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle2" sx={{ marginRight: '20px' }}>
              {sizeInMeters}
              <Typography variant="caption">sq/m</Typography>
            </Typography>

            <Typography variant="subtitle2">
              {formattedPricePerWeek}
              <Typography variant="caption">/Week</Typography>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

interface StoreCardProps {
  className?: string;
  name: string;
  spotPrice: number;
  spotCurrency: number;
  shortAddress: string;
  sizeInMeters: number;
  imageId: string;
  clickHandler?: () => void;
}
