import React from 'react';

import { Box, Tab, TabsProps } from '@mui/material';

import { StyledTabs } from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  ariaLabel: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ariaLabel, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${ariaLabel}-tabpanel-${index}`}
      aria-labelledby={`${ariaLabel}-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number, ariaLabel: string) {
  return {
    id: `${ariaLabel}-${index}`,
    'aria-controls': `${ariaLabel}-tabpanel-${index}`,
  };
}

export const TabsComponent = ({
  data,
  ariaLabel,
  value,
  handleChangeTab,
  variant = 'standard',
  tabsStyles = {},
}: TabsComponentAdditionalProps): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <StyledTabs value={value} onChange={handleChangeTab} variant={variant} aria-label={ariaLabel} sx={tabsStyles}>
        {data.map((tabData, index) => {
          return (
            <Tab
              key={index}
              label={tabData.label}
              icon={tabData.icon}
              iconPosition="end"
              {...a11yProps(index, ariaLabel)}
            />
          );
        })}
      </StyledTabs>

      {data.map((tabData, index) => {
        return (
          <TabPanel key={index} value={value} index={index} ariaLabel={ariaLabel}>
            {tabData.tabPanelComponent}
          </TabPanel>
        );
      })}
    </Box>
  );
};

interface TabsComponentAdditionalProps extends TabsProps {
  data: Array<{
    label: string;
    icon?: React.ReactElement;
    tabPanelComponent: React.ReactElement;
  }>;
  ariaLabel: string;
  handleChangeTab: any;
  tabsStyles?: object;
}
