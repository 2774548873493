import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { format } from 'date-fns';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { BasicButton } from '../../../common/components/basic/Button';
import { BasicDatePicker } from '../../../common/components/basic/DatePicker';
import { BasicContainer } from '../../../common/components/BasicContainer';
import { BoxWithBackground } from '../../../common/components/BoxWithBackground';
import { Chip } from '../../../common/components/Chip';
import { CityCard } from '../../../common/components/CityCard';
import { Swiper } from '../../../common/components/Swiper';
import { isSaturday } from '../../../common/helpers/date-heplers';
import { scrollToElement } from '../../../common/helpers/scroll-to-top';
import { CITIES_FILTER_DATA, DURATION_FILTER_DATA } from '../../../constants/ui';
import AddressDTO from '../../../models/address/address-dto';
import { BookingFiltersSearchParamsDTO } from '../../../models/ui/booking-filters-search-params-dto';
import SelectedCityFilter from '../../../models/ui/selected-city-filter';
import SelectedDurationFilter from '../../../models/ui/selected-duration-filter';
import { SpotsService } from '../../../services/spots';
import theme from '../../../theme-config/theme';

export const Wizard = (): JSX.Element => {
  const navigate = useNavigate();
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [availableLocation, setAvailableLocation] = useState<AddressDTO[]>([]);
  const [selectedCity, setSelectedCity] = useState<SelectedCityFilter | undefined>();
  const [selectedDurationFilter, setSelectedDurationFilter] = useState<SelectedDurationFilter | undefined>();
  const isUpSmBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));
  const checkInSectionRef = useRef<HTMLDivElement | null>(null);
  const durationSectionRef = useRef<HTMLDivElement | null>(null);
  const goButtonRef = useRef<HTMLDivElement | null>(null);
  const filtersSearchParams: BookingFiltersSearchParamsDTO = {
    city: selectedCity?.searchParameter ? selectedCity.searchParameter : '',
    date: selectedStartDate ? selectedStartDate : '',
    duration: selectedDurationFilter?.searchParameter ? selectedDurationFilter.searchParameter : '',
  };
  const mobileHeightForSections = 'calc(100vh - 80px)'; //80px is height of the mobile header
  const spaceBetweenSlides = isUpSmBreakPoint ? 30 : 12;

  const spotsService: SpotsService = new SpotsService();

  const handleChange = (e: any) => {
    const dateValue = e.target.value;
    setSelectedStartDate(dateValue);
    scrollToElement(durationSectionRef);
  };

  const handleClickOnDurationCard = (durationFilter: SelectedDurationFilter, isSelectedDuration: boolean) => {
    if (isSelectedDuration) {
      setSelectedDurationFilter(undefined);
    } else {
      setSelectedDurationFilter(durationFilter);
    }
    scrollToElement(goButtonRef);
  };

  const handleClickOnCityCard = (city: SelectedCityFilter) => {
    setSelectedCity(city);
    scrollToElement(checkInSectionRef);
  };

  useEffect(() => {
    spotsService
      .availableLocations()
      .execute()
      .then((data) => {
        setAvailableLocation(data);
      });
  }, []);

  return (
    <>
      <BasicContainer
        sx={{
          minHeight: { xs: mobileHeightForSections, sm: 'auto' },
          textAlign: 'center',
          paddingY: { xs: 50, md: 30 },
        }}
      >
        <Typography variant="h1" sx={{ typography: { xs: 'h3', md: 'h1' }, marginBottom: { xs: 8, md: 20 } }}>
          BOOK YOUR REPOP SHOP TODAY
        </Typography>
        <Typography variant="h2" sx={{ typography: { xs: 'body2', md: 'h3' }, marginBottom: { xs: 24, md: 15 } }}>
          First, start with selecting the city you would like to pop-up at:
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          {availableLocation ? (
            <Swiper
              className={`swiper-city ${availableLocation.length <= 2 ? 'centered-content' : ''}`}
              {...(availableLocation.length > 1 ? { spaceBetween: spaceBetweenSlides } : { spaceBetween: 0 })}
              slidesPerView={isUpSmBreakPoint ? 3 : 2}
            >
              {availableLocation.map((location, index) => {
                const city: SelectedCityFilter | undefined = CITIES_FILTER_DATA.find(
                  (city) => !location.shortAddress.toLowerCase().indexOf(city.name.toLowerCase()),
                );

                if (city) {
                  return (
                    <Box key={index} sx={{ maxWidth: '100%' }}>
                      <CityCard
                        cityName={city.name}
                        imageSrc={city.imgSrc}
                        onClick={() => {
                          handleClickOnCityCard(city);
                        }}
                      />
                    </Box>
                  );
                }
              })}
            </Swiper>
          ) : null}
        </Box>
      </BasicContainer>

      <div ref={checkInSectionRef}>
        <Grid
          sx={{
            minHeight: { xs: mobileHeightForSections, sm: 'auto' },
            backgroundColor: (theme: Theme) => theme.palette.common.black,
            color: (theme: Theme) => theme.palette.common.white,
          }}
        >
          <BasicContainer sx={{ textAlign: 'center', padding: { xs: 50, md: 30 } }}>
            {selectedCity?.name && (
              <Chip
                label={
                  <Typography variant="h3" sx={{ textTransform: 'uppercase' }}>
                    {selectedCity.name}
                  </Typography>
                }
                variant="outlined"
              />
            )}

            <Typography variant="h3" sx={{ marginTop: 12, marginBottom: 4 }}>
              Choose your check-in date:
            </Typography>

            <Typography
              variant="body2"
              sx={{ typography: { xs: 'body2', md: 'body1' }, marginTop: 12, marginBottom: 15 }}
            >
              Remember that check-ins are only on <b>Saturdays</b> at 18pm
            </Typography>

            <BasicDatePicker
              id="check-in"
              name="check-in"
              selectedStr={selectedStartDate}
              monthsShown={isUpSmBreakPoint ? 2 : undefined}
              filterDate={isSaturday}
              onChange={handleChange}
              inline={true}
              isBlackColorPalette={true}
              inputStyles={{
                maxWidth: 'fit-content',
                margin: '0 auto',
              }}
            />

            <Link
              component="button"
              type="button"
              variant="subtitle2"
              color={theme.palette.common.white}
              underline="always"
              onClick={() => scrollToElement(durationSectionRef)}
            >
              I am flexible
            </Link>
          </BasicContainer>
        </Grid>
      </div>

      <div ref={durationSectionRef}>
        <BasicContainer
          sx={{
            minHeight: { xs: mobileHeightForSections, sm: 'auto' },
            textAlign: 'center',
            paddingY: { xs: 50, md: 30 },
          }}
        >
          {(selectedCity?.name || selectedStartDate) && (
            <Chip
              isBlackColorPalette={true}
              label={
                <Typography variant="h3" sx={{ textTransform: 'uppercase' }}>
                  {selectedCity?.name && selectedCity.name}
                  {selectedCity?.name && selectedStartDate ? ', ' : null}
                  {selectedStartDate && format(new Date(selectedStartDate), 'dd/MM/yyyy')}
                </Typography>
              }
              variant="outlined"
            />
          )}

          <Typography variant="h3" sx={{ marginTop: 12, marginBottom: 4 }}>
            Choose a duration:
          </Typography>

          <Typography
            variant="body2"
            sx={{ typography: { xs: 'body2', md: 'body1' }, marginTop: 12, marginBottom: 15 }}
          >
            The minimum rent duration is 2 weeks. Longer periods have better discounts!
          </Typography>

          <Grid container spacing={15} sx={{ marginBottom: 50 }}>
            {DURATION_FILTER_DATA.map((filter, index) => {
              const isSelectedDuration = selectedDurationFilter?.name === filter.name;

              return (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <BoxWithBackground
                    sx={{
                      cursor: 'pointer',
                      padding: 10,
                      transition: '.5s',
                      background: (theme: Theme) => (isSelectedDuration ? theme.palette.secondary.main : undefined),
                      color: (theme: Theme) => (isSelectedDuration ? theme.palette.common.black : undefined),
                    }}
                    onClick={() => {
                      handleClickOnDurationCard(filter, isSelectedDuration);
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        textAlign: 'left',
                        marginBottom: filter.discountPercentage > 0 ? 4 : { xs: 10, md: 11 },
                        typography: { xs: 'h6', md: 'h5' },
                        transition: '.5s',
                        color: (theme: Theme) =>
                          isSelectedDuration ? theme.palette.common.black : theme.palette.secondary.main,
                      }}
                    >
                      {filter.discountPercentage > 0 ? `${filter.discountPercentage}% OFF` : ''}
                    </Typography>

                    <Typography variant="subtitle2" sx={{ marginBottom: 10 }}>
                      {filter.name}
                    </Typography>
                  </BoxWithBackground>
                </Grid>
              );
            })}
          </Grid>

          <Link
            sx={{ marginBottom: 20 }}
            component="button"
            type="button"
            variant="subtitle2"
            underline="always"
            onClick={() =>
              navigate({
                pathname: '/booking/spots',
                search: `${createSearchParams(filtersSearchParams)}`,
              })
            }
          >
            I haven’t decided
          </Link>

          <div ref={goButtonRef}>
            <BasicButton
              className="btn-yellow"
              sx={{ display: 'block', margin: '0 auto', minWidth: '165px', fontSize: '20px' }}
              variant="contained"
              type="button"
              disabled={!selectedDurationFilter}
              onClick={() =>
                navigate({
                  pathname: '/booking/spots',
                  search: `${createSearchParams(filtersSearchParams)}`,
                })
              }
            >
              Go
            </BasicButton>
          </div>
        </BasicContainer>
      </div>
    </>
  );
};
