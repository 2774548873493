import { Drawer } from '@mui/material';
import { styled } from '@mui/system';

import { MOBILE_HEADER_BREAKPOINT } from '../../../constants/ui';

export const StyledLeftDrawer = styled(Drawer)(({ theme }) => ({
  '&.mobile-left-drawer': {
    display: 'block',

    [theme.breakpoints.up(MOBILE_HEADER_BREAKPOINT)]: {
      display: 'none',
    },

    '.MuiDrawer-paper': {
      width: '282px',
      boxSizing: 'border-box',
      padding: '20px 16px',
      backgroundColor: '#000000',
    },
  },
}));
