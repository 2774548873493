import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { createUserAsBrand, externalSignIn, userLogIn } from '../../../features/authentication/authSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../../../features/notifier/notifierSlice';
import UserRegisterAsBrandDTO from '../../../models/user/user-register-as-brand';
import { createNewWindowInCenter } from '../../helpers/create-new-window';
import { receiveMessageHandler } from '../../helpers/receive-massage-from-social-auth-view';
import { getLinkForFacebookLogIn, getLinkForGoogleLogIn } from '../../helpers/social-auth-link-generator';
import { logInSchema, signUpSchema } from '../../helpers/validation';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { BasicButton } from '../basic/Button';
import { BasicInput } from '../basic/Input';
import { BoxWithBackground } from '../BoxWithBackground';

export const SignUpLogInBox = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isActiveSignUp, setIsActiveSignUp] = useState(true);
  const validationSchema = isActiveSignUp ? signUpSchema : logInSchema;
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserRegisterAsBrandDTO>({
    resolver: yupResolver(validationSchema),
  });
  let externalSignInWindow: any;

  const submitHandler = (data: UserRegisterAsBrandDTO) => {
    if (isActiveSignUp) {
      const { firstName, lastName } = data;
      const combinedBrandName = firstName && lastName ? firstName + ' ' + lastName : firstName + lastName;

      dispatch(createUserAsBrand({ ...data, brandName: combinedBrandName })).then((data) => {
        if (!data.payload) return;

        dispatch(
          enqueueSnackbarAction({
            message: 'We have sent a confirmation to your email',
            options: { variant: 'success' },
          }),
        );

        reset();
      });
    } else {
      const { email: emailValue, password: passwordValue } = data;
      dispatch(userLogIn({ email: emailValue, password: passwordValue })).then(() => {
        reset();
      });
    }
  };

  const clickOnSocialSignInHandler = (link: string) => {
    externalSignInWindow = createNewWindowInCenter(link, '', 600, 600);
  };

  const messageHandler = useCallback(
    (e: any) => {
      receiveMessageHandler(dispatch, externalSignIn, e, externalSignInWindow);
    },
    [externalSignInWindow],
  );

  useEffect(() => {
    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  useEffect(() => {
    reset();
  }, [isActiveSignUp]);

  return (
    <BoxWithBackground
      className="full-width-on-sm"
      sx={{
        width: '100%',
        textAlign: 'center',
        margin: { xs: '0 auto', md: 'initial' },
        maxWidth: '600px',
        borderRadius: { xs: '0', sm: '10px' },
        padding: { xs: '20px 16px', md: '30px 35px' },
      }}
    >
      <Typography variant="subtitle2" sx={{ marginBottom: 5 }}>
        {isActiveSignUp ? 'Sign up' : 'Log in'} to complete the checkout
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: 10 }}>
        {isActiveSignUp ? 'Already have an account?' : 'New to REPOP?'}
        <Link
          component="button"
          type="button"
          sx={{ typography: { xs: 'body2', md: 'body1' }, ml: 2 }}
          variant="body2"
          color="inherit"
          underline="always"
          onClick={() => {
            setIsActiveSignUp(!isActiveSignUp);
          }}
        >
          {isActiveSignUp ? 'Log In' : 'Sign Up'}
        </Link>
      </Typography>

      <Box sx={{ paddingBottom: 12 }}>
        <BasicButton
          className="btn-large btn-with-icon btn-with-icon--google"
          sx={{ minWidth: { sm: '170px' }, marginRight: { xs: 6, lg: 15 } }}
          variant="outlined"
          type="button"
          onClick={() => {
            clickOnSocialSignInHandler(getLinkForGoogleLogIn());
          }}
        >
          {isActiveSignUp ? 'Sign up' : 'Log in'}
        </BasicButton>
        <BasicButton
          className="btn-blue btn-large btn-with-icon btn-with-icon--facebook"
          sx={{ minWidth: { sm: '170px' } }}
          variant="contained"
          type="button"
          onClick={() => {
            clickOnSocialSignInHandler(getLinkForFacebookLogIn());
          }}
        >
          {isActiveSignUp ? 'Sign up' : 'Log in'}
        </BasicButton>
      </Box>

      <Typography variant="body1" sx={{ paddingBottom: 12 }}>
        or
      </Typography>

      <Box component="form" autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
        <Grid container spacing={10} sx={{ justifyContent: { sm: 'center', md: 'flex-start' } }}>
          {isActiveSignUp ? (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BasicInput
                      value={value || ''}
                      fullWidth
                      className="input-rounded"
                      controlError={errors?.firstName ? errors.firstName.message : ''}
                      inputProps={{
                        placeholder: 'First name',
                        autoComplete: 'given-name',
                        onChange: onChange,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BasicInput
                      value={value || ''}
                      fullWidth
                      className="input-rounded"
                      controlError={errors?.lastName ? errors.lastName.message : ''}
                      inputProps={{
                        placeholder: 'Last name',
                        autoComplete: 'family-name',
                        onChange: onChange,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BasicInput
                      value={value || ''}
                      fullWidth
                      className="input-rounded"
                      controlError={errors?.email ? errors.email.message : ''}
                      inputProps={{
                        placeholder: 'Email',
                        autoComplete: 'email',
                        onChange: onChange,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BasicInput
                      value={value || ''}
                      fullWidth
                      className="input-rounded"
                      controlError={errors?.phoneNumber ? errors.phoneNumber.message : ''}
                      inputProps={{
                        type: 'tel',
                        placeholder: 'Phone (with country code)',
                        autoComplete: 'tel',
                        onChange: onChange,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BasicInput
                      value={value || ''}
                      fullWidth
                      className="input-rounded"
                      controlError={errors?.password ? errors.password.message : ''}
                      inputProps={{
                        type: 'password',
                        placeholder: 'Password',
                        autoComplete: 'new-password',
                        onChange: onChange,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={10} md={6} sx={{ paddingBottom: 10 }}>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BasicInput
                      value={value || ''}
                      fullWidth
                      className="input-rounded"
                      controlError={errors?.confirmPassword ? errors.confirmPassword.message : ''}
                      inputProps={{
                        type: 'password',
                        placeholder: 'Confirm password',
                        autoComplete: 'new-password',
                        onChange: onChange,
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BasicInput
                      value={value || ''}
                      fullWidth
                      className="input-rounded"
                      controlError={errors?.email ? errors.email.message : ''}
                      inputProps={{
                        placeholder: 'Email',
                        autoComplete: 'email',
                        onChange: onChange,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BasicInput
                      value={value || ''}
                      fullWidth
                      className="input-rounded"
                      controlError={errors?.password ? errors.password.message : ''}
                      inputProps={{
                        type: 'password',
                        placeholder: 'Password',
                        autoComplete: 'new-password',
                        onChange: onChange,
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <BasicButton className="btn-yellow" sx={{ maxWidth: '120px' }} variant="contained" type="submit">
              {isActiveSignUp ? 'Sign up' : 'Log in'}
            </BasicButton>
          </Grid>
        </Grid>
      </Box>
    </BoxWithBackground>
  );
};
