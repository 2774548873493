import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { rejectCampaign } from '../../../../features/manager-dashboard/bookings/bookingsManagerDashboardSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../../../../features/notifier/notifierSlice';
import CampaignDTO from '../../../../models/campaign/campaign-dto';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { useStateFormControlHandler } from '../../../hooks/use-state-from-control-handler';
import { BaseDialog } from '../../BaseDialog';
import { BasicButton } from '../../basic/Button';
import { BasicInput } from '../../basic/Input';

export const RejectReasonDialog = ({ dialogProps, campaignToReject }: BookNowDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [rejectReason, setRejectReason] = useState('');
  const { inputHandler } = useStateFormControlHandler(rejectReason, setRejectReason);
  const { onClose } = dialogProps;

  const clickOnTheRejectBtn = () => {
    if (campaignToReject) {
      dispatch(rejectCampaign({ id: campaignToReject.id, reason: rejectReason })).then(() => {
        dispatch(
          enqueueSnackbarAction({
            message: 'Booking was successfully rejected',
            options: { variant: 'success' },
          }),
        );
        onClose();
      });
    }
  };

  useEffect(() => {
    !campaignToReject && setRejectReason('');
  }, [campaignToReject]);

  return (
    <BaseDialog dialogProps={dialogProps}>
      <Box sx={{ textAlign: 'center', paddingX: { md: 25 } }}>
        <Typography variant="h2" sx={{ marginTop: 10, marginBottom: 6 }}>
          Reason for rejection
        </Typography>
        <Typography variant="subtitle2" sx={{ marginBottom: 10 }}>
          You should enter the reason for rejection below:
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          maxWidth: { xs: '100%', md: '80%' },
          marginX: { xs: 'initial', md: 'auto' },
          marginBottom: 10,
        }}
      >
        <BasicInput
          id="rejection-reason"
          value={rejectReason || ''}
          fullWidth
          formControlProps={{ sx: { marginRight: { xs: '10px', md: '20px' } } }}
          inputProps={{
            name: 'rejectionReason',
            placeholder: 'Reason for rejection',
            onChange: inputHandler,
          }}
        />

        <BasicButton sx={{ minWidth: '114px' }} variant="outlined" onClick={clickOnTheRejectBtn}>
          <Typography variant="body1">Reject</Typography>
        </BasicButton>
      </Box>
    </BaseDialog>
  );
};

interface BookNowDialogProps {
  dialogProps: any;
  campaignToReject: CampaignDTO | undefined;
}
