import React from 'react';

import GoogleMapReact from 'google-map-react';
import googleMapReact from 'google-map-react';

const GOOGLE_MAP_API_KEY = window.spaSettings.googleMapApiKey;

export const GoogleMap = ({ children, center, defaultZoom }: GoogleMapProps): JSX.Element => {
  const defaultCenter = {
    lat: 32.072135,
    lng: 34.791624,
  }; // Tel Aviv coordinates

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY ? GOOGLE_MAP_API_KEY : '' }}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      center={center}
    >
      {children}
    </GoogleMapReact>
  );
};

interface GoogleMapProps extends googleMapReact.Props {
  children: JSX.Element | JSX.Element[];
}
