import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';

export class CountriesService {
  private readonly basePath: string = "locations/countries";

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getAllCountries(): ApiRequestWrapper<Array<string>> {
    return this.apiUrlProvider.get(this.basePath);
  }
}