import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBoxWithBackground = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isClosedBookNowFixedBlock',
})(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
}));
