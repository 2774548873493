import { getById as getCurrencyById } from '../../constants/enums/currency';
import SpotDTO from '../../models/spot/spot-dto';
import StoreDTO from '../../models/store/store-dto';
import { getDaysBetweenTwoDates } from '../helpers/date-heplers';
import { getFormattedPrice } from '../helpers/formatters';
import { findPartInAddressObjByType } from '../helpers/google-heplers';
import { useCurrencyConverter } from './use-currency-converter';

export const useBookingData = (spotData: SpotDTO, storeData: StoreDTO, startDate: string, endDate: string) => {
  const selectedSpotCurrency = getCurrencyById(spotData?.currency);
  const { convertedPrice: convertedSpotPrice = 0, currencySymbol = '' } = useCurrencyConverter(
    selectedSpotCurrency,
    spotData?.price,
  );
  const startDateObj = startDate && new Date(startDate);
  const endDateObj = endDate && new Date(endDate);

  const daysDuration = startDateObj && endDateObj && getDaysBetweenTwoDates(startDateObj, endDateObj);
  const workingDaysDuration =
    startDateObj &&
    endDateObj &&
    storeData?.workingDays?.length &&
    getDaysBetweenTwoDates(startDateObj, endDateObj, storeData.workingDays);
  const totalAmount = workingDaysDuration ? convertedSpotPrice * workingDaysDuration : '';
  const formattedTotalAmount = totalAmount ? getFormattedPrice(totalAmount, currencySymbol, true) : '';

  const formattedPricePerWeek =
    convertedSpotPrice && currencySymbol ? getFormattedPrice(convertedSpotPrice * 7, currencySymbol, true) : '';
  const spotCityName = storeData?.address?.parts
    ? findPartInAddressObjByType(storeData.address.parts, 'locality')?.name
    : '';

  return {
    daysDuration: daysDuration,
    workingDaysDuration: workingDaysDuration,
    totalAmount: totalAmount,
    formattedTotalAmount: formattedTotalAmount,
    formattedPricePerWeek: formattedPricePerWeek,
    spotCityName: spotCityName,
  };
};
