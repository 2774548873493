import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

interface notifierDto {
  notifications: Array<any>;
}

let notifierState: notifierDto;

notifierState = {
  notifications: [],
};

const makeNotificationObject = (notificationData: Record<string, unknown>) => {
  return {
    key: new Date().getTime() + Math.random(),
    ...notificationData,
  };
};

export const notifierSlice = createSlice({
  name: 'notifier',
  initialState: notifierState,
  reducers: {
    enqueueNotification: (state, action) => {
      state.notifications.push(makeNotificationObject(action.payload));
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter((notification) => action.payload !== notification.key);
    },
  },
});

export const { enqueueNotification, removeNotification } = notifierSlice.actions;

export const selectNotifierState = function (state: RootState): any {
  return state.notifier;
};

export default notifierSlice.reducer;
