import React from 'react';

import { Box, Link, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ActionMenu } from '../../../../common/components/ActionMenu';
import { BasicButton } from '../../../../common/components/basic/Button';
import { fileDownloader } from '../../../../common/helpers/file-downaloder';
import { getFormattedPrice } from '../../../../common/helpers/formatters';
import { getActionMenuItemsForCampaign } from '../../../../common/helpers/get-action-menu-items-for-campaign';
import { getRelatedDataToCampaign } from '../../../../common/helpers/get-related-data-to-campaign';
import { findPartInAddressObjByType } from '../../../../common/helpers/google-heplers';
import { useAppSelector } from '../../../../common/hooks/redux-hooks';
import { useCurrencyConverter } from '../../../../common/hooks/use-currency-converter';
import { CampaignStatus, getAllCampaignStatuses } from '../../../../constants/enums/campaign/campaign-status';
import { getById as getCurrencyById } from '../../../../constants/enums/currency';
import CampaignBundleDTO from '../../../../models/campaign/campaign-bundle-dto';
import CampaignDTO from '../../../../models/campaign/campaign-dto';
import { ImgService } from '../../../../services/img-service';
import { selectBookingsManagerDashboardState } from '../bookingsManagerDashboardSlice';

export const BookingAdditionalInfo = ({
  clickOnApproveHandler,
  clickOnRejectHandler,
}: BookingAdditionalInfoProps): JSX.Element => {
  const {
    bookingsBundleData,
    selectedBookingId,
  }: { bookingsBundleData: CampaignBundleDTO; selectedBookingId: number } = useAppSelector(
    selectBookingsManagerDashboardState,
  );
  const { campaign, preCampaign, store, spot, brand } = getRelatedDataToCampaign(selectedBookingId, bookingsBundleData);

  const imgService: ImgService = new ImgService();

  const createdAtDate = campaign?.createdAt && format(new Date(campaign.createdAt), 'dd/MM/yyyy');
  const startDate = campaign?.startDate && format(new Date(campaign.startDate), 'dd/MM/yyyy');
  const endDate = campaign?.endDate && format(new Date(campaign.endDate), 'dd/MM/yyyy');
  const spotCityName = store?.address?.parts ? findPartInAddressObjByType(store.address.parts, 'locality')?.name : '';
  const parsedStatus = getAllCampaignStatuses().find((x) => x.id == campaign?.status);
  const currencyObj = getCurrencyById(campaign?.currency);
  const { convertedPrice: convertedCostWithFeeAndVat = 0, currencySymbol = '' } = useCurrencyConverter(
    currencyObj,
    campaign?.costWithFeeAndVat,
  );
  const formattedCostWithFeeAndVat = currencyObj
    ? getFormattedPrice(convertedCostWithFeeAndVat, currencySymbol, true)
    : '';
  const isPreBookingWithBankAccount = Boolean(preCampaign?.bankAccount && preCampaign?.bankAccountId);
  const bankAccountImgUrl = isPreBookingWithBankAccount
    ? imgService.getImgPreviewUrl(preCampaign.bankAccount.image, {
        width: 1000,
        height: 1000,
      })
    : '';
  const originalBankAccountImgUrl =
    isPreBookingWithBankAccount && imgService.getOriginalUrlImg(preCampaign.bankAccount.image);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 15 }}>
        <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
          BOOKING FOR
        </Typography>
        {CampaignStatus.Pending === campaign.status && (
          <ActionMenu items={getActionMenuItemsForCampaign(campaign, clickOnApproveHandler, clickOnRejectHandler)} />
        )}
      </Box>
      <Typography variant="subtitle2" sx={{ marginBottom: 6 }}>
        {brand?.name}
      </Typography>
      {createdAtDate && (
        <Typography variant="body1" sx={{ color: '#808080', marginBottom: 20 }}>
          {`Created on:  ${createdAtDate}`}
        </Typography>
      )}

      {startDate && endDate ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
          <Typography variant="h3" sx={{ marginRight: 10 }}>
            From:
            <Typography variant="caption" sx={{ typography: { xs: 'body2', md: 'body1', marginLeft: 5 } }}>
              {startDate}
            </Typography>
          </Typography>

          <Typography variant="h3">
            To:
            <Typography variant="caption" sx={{ typography: { xs: 'body2', md: 'body1' }, marginLeft: 5 }}>
              {endDate}
            </Typography>
          </Typography>
        </Box>
      ) : null}

      <Box sx={{ marginBottom: 20 }}>
        <Typography variant="h3" sx={{ marginBottom: 6 }}>
          Store
        </Typography>
        <Typography variant="body1">
          {spot?.name}
          {spotCityName && ', ' + spotCityName}
        </Typography>
      </Box>

      <Box sx={{ marginBottom: 50 }}>
        <Typography variant="h3" sx={{ marginBottom: 6 }}>
          Status
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Box
            sx={{
              width: '12px',
              height: '12px',
              flexShrink: '0',
              marginRight: 5,
              borderRadius: '20px',
              backgroundColor: parsedStatus?.color,
            }}
          ></Box>

          <Typography variant="subtitle2">{parsedStatus?.name}</Typography>
        </Box>

        {formattedCostWithFeeAndVat && (
          <Typography variant="body1">Total payment: {formattedCostWithFeeAndVat}</Typography>
        )}

        {isPreBookingWithBankAccount ? (
          <>
            <Typography variant="body1">Payment method: Bank transfer</Typography>

            <Box sx={{ display: 'flex', marginTop: 10 }}>
              <Typography variant="h5">Uploaded File:</Typography>

              <Link
                href={bankAccountImgUrl}
                target="_blank"
                rel="noreferrer"
                variant="body1"
                color="inherit"
                underline="always"
                sx={{ marginX: 10 }}
              >
                View
              </Link>

              <Link
                component="button"
                type="button"
                variant="body1"
                color="inherit"
                underline="always"
                onClick={() => fileDownloader(originalBankAccountImgUrl, `${preCampaign?.brandName}-bank-transfer.png`)}
              >
                Download
              </Link>
            </Box>
          </>
        ) : null}

        {!isPreBookingWithBankAccount && formattedCostWithFeeAndVat ? (
          <Typography variant="body1" sx={{ marginTop: 10 }}>
            Payment method: Credit card
          </Typography>
        ) : null}
      </Box>

      {CampaignStatus.Pending === campaign.status && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <BasicButton
            className="btn-large"
            sx={{ minWidth: { xs: '150', md: '165px' }, marginRight: { xs: 7, md: 15 } }}
            variant="outlined"
            onClick={() => clickOnApproveHandler(campaign)}
          >
            Approve
          </BasicButton>

          <BasicButton
            className="btn-large"
            sx={{ minWidth: { xs: '150', md: '165px' } }}
            variant="outlined"
            onClick={() => clickOnRejectHandler(campaign)}
          >
            Reject
          </BasicButton>
        </Box>
      )}
    </Box>
  );
};

interface BookingAdditionalInfoProps {
  clickOnApproveHandler: (campaign: CampaignDTO) => void;
  clickOnRejectHandler: (campaign: CampaignDTO) => void;
}
