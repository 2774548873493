import React, { useEffect, useState } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

import { useAppSelector } from '../../common/hooks/redux-hooks';
import { selectLoaderState } from './loaderSlice';

const LoaderWithBackdrop = () => {
  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }} component={'div'}>
      <CircularProgress />
    </Backdrop>
  );
};

export const Loader = (): JSX.Element => {
  const { loaderCount } = useAppSelector(selectLoaderState);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    if (loaderCount) {
      setIsLoadingData(true);
    } else {
      setIsLoadingData(false);
    }
  }, [loaderCount]);

  return <>{isLoadingData ? LoaderWithBackdrop() : null}</>;
};
