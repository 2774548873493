import { Theme } from '@mui/material/styles';

export const storeCardStyles = (theme: Theme) =>
  ({
    '&.MuiCard-root': {
      position: 'relative',
      borderRadius: '20px',
      cursor: 'pointer',
      boxShadow: 'none',
      height: '342px',

      [theme.breakpoints.up('md')]: {
        height: '500px',

        '&.square-view': {
          height: '370px',
        },
      },
    },

    [theme.breakpoints.up('md')]: {
      cursor: 'pointer',

      '&:hover': {
        '.spot-details': {
          opacity: '1',
          maxHeight: '100px',
        },
      },
    },

    '.spot-details': {
      [theme.breakpoints.up('md')]: {
        maxHeight: '0px',
        opacity: '0',
        transition: '1s',
      },
    },

    '.MuiCardMedia-img': {
      height: '100%',
    },

    '.MuiCardContent-root': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      padding: '80px 30px 28px 30px',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 140%)',
      color: theme.palette.common.white,
    },
  } as const);
