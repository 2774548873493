import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import BlockedDatesDTO from '../../../models/blocked-dates-dto';
import BookedDatesDTO from '../../../models/booked-dates-dto';
import PreCampaignDTO from '../../../models/pre-campaign/pre-campaign-dto';
import PreCampaignEditDTO from '../../../models/pre-campaign/pre-campaign-edit-dto';
import SpotDTO from '../../../models/spot/spot-dto';
import SpotsPreCampaignFilterDTO from '../../../models/spot/spots-pre-campaign-filter-dto';
import StoreDTO from '../../../models/store/store-dto';
import { PreCampaignManagerService } from '../../../services/pre-campaign-manager';
import { SpotManagerService } from '../../../services/spot-manager';
import { SpotsService } from '../../../services/spots';

interface initialStateTypes {
  preCampaignFormState: {
    selectedData: {
      spot: SpotDTO;
      store: StoreDTO;
      country: string;
      spotBlockedDate: BlockedDatesDTO[];
      spotBookedDate: BookedDatesDTO[];
    };
    preCampaignFilterData: SpotsPreCampaignFilterDTO;
  };
  preCampaignEditState: PreCampaignEditDTO;
  preCampaignState: PreCampaignDTO;
}

const initialState = {
  preCampaignFormState: {},
  preCampaignEditState: {},
  preCampaignState: {},
} as initialStateTypes;

const preCampaignManagerService: PreCampaignManagerService = new PreCampaignManagerService();
const spotManagerService: SpotManagerService = new SpotManagerService();
const spotServiceService: SpotsService = new SpotsService();

export const createPreCampaign = createAsyncThunk('createPreCampaign', async (obj: PreCampaignEditDTO) => {
  return await preCampaignManagerService.createPreCampaign(obj).execute();
});

export const getAllSpotsByCountry = createAsyncThunk('getAllSpotsByCountry', async () => {
  return await spotManagerService.getAllSpotsByCountry().showLoader(false).execute();
});

export const getPreCampaignById = createAsyncThunk('getPreCampaignById', async (id: string) => {
  return await preCampaignManagerService.getPreCampaignsById(id).execute();
});

export const getSpotBlockedDatesBySpotId = createAsyncThunk('getSpotBlockedDatesBySpotId', async (id: number) => {
  return await spotServiceService.getBlockedDatesBySpotId(id).execute();
});

export const getSpotBookedDatesBySpotId = createAsyncThunk('getSpotBookedDatesBySpotId', async (id: number) => {
  return await spotServiceService.getBookedDatesBySpotId(id).execute();
});

export const personalizedBookingSlice = createSlice({
  name: 'personalizedBooking',
  initialState,
  reducers: {
    updatePreCampaignEdit: (state, action) => {
      state.preCampaignEditState = {
        ...state.preCampaignEditState,
        ...action.payload,
      };
    },
    updateSelectedData: (state, action) => {
      state.preCampaignFormState.selectedData = { ...action.payload };
    },
    updatePreCampaignFilterData: (state, action) => {
      state.preCampaignFormState.preCampaignFilterData = { ...action.payload };
    },
    resetPreCampaignEdit: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(createPreCampaign.fulfilled, (state, action) => {
      if (action.payload) {
        state.preCampaignState = action.payload;
      }
    });
    builder.addCase(getAllSpotsByCountry.fulfilled, (state, action) => {
      if (action.payload) {
        state.preCampaignFormState.preCampaignFilterData = action.payload;
      }
    });
    builder.addCase(getPreCampaignById.fulfilled, (state, action) => {
      if (action.payload) {
        state.preCampaignEditState = action.payload;
      }
    });
    builder.addCase(getSpotBlockedDatesBySpotId.fulfilled, (state, action) => {
      if (action.payload) {
        state.preCampaignFormState.selectedData.spotBlockedDate = action.payload;
      }
    });
    builder.addCase(getSpotBookedDatesBySpotId.fulfilled, (state, action) => {
      if (action.payload) {
        state.preCampaignFormState.selectedData.spotBookedDate = action.payload;
      }
    });
  },
});

export const selectPersonalizedBookingState = function (state: RootState): any {
  return state.personalizedBooking;
};

export const { updatePreCampaignEdit, updateSelectedData, updatePreCampaignFilterData, resetPreCampaignEdit } =
  personalizedBookingSlice.actions;

export default personalizedBookingSlice.reducer;
