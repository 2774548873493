import React, { useEffect, useState } from 'react';

import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import bellIconUrl from '../../../assets/icons/bell-2.svg';
import clockIconUrl from '../../../assets/icons/clock.svg';
import locationIconUrl from '../../../assets/icons/location.svg';
import { BasicButton } from '../../../common/components/basic/Button';
import { BasicDatePicker } from '../../../common/components/basic/DatePicker';
import { BasicSelect } from '../../../common/components/basic/Select';
import { BasicContainer } from '../../../common/components/BasicContainer';
import { Divider } from '../../../common/components/Divider';
import { StoreCard } from '../../../common/components/StoreCard';
import { isSaturday } from '../../../common/helpers/date-heplers';
import { isNotUndefinedOrNull } from '../../../common/helpers/utils';
import { useAppDispatch } from '../../../common/hooks/redux-hooks';
import { useStateFormControlHandler } from '../../../common/hooks/use-state-from-control-handler';
import { CITIES_FILTER_DATA } from '../../../constants/ui';
import AddressDTO from '../../../models/address/address-dto';
import SpotItemDTO from '../../../models/spot/spot-item-dto';
import SpotsFilterDTO from '../../../models/spot/spots-filter-dto';
import { BookingFiltersSearchParamsDTO } from '../../../models/ui/booking-filters-search-params-dto';
import SelectedCityFilter from '../../../models/ui/selected-city-filter';
import { SpotsService } from '../../../services/spots';
import { resetBookingState } from '../bookingSlice';

export const SpotsList = ({ isDashboardSpotList = false }: { isDashboardSpotList?: boolean }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [spots, setSpots] = useState<Array<SpotItemDTO>>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [availableLocation, setAvailableLocation] = useState<AddressDTO[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const cityParameter = searchParams.get('city');
  const dateParameter = searchParams.get('date');
  const durationParameter = searchParams.get('duration');
  const filtersInitialState = {
    city: isNotUndefinedOrNull(cityParameter) ? cityParameter : '',
    date: isNotUndefinedOrNull(dateParameter) ? dateParameter : '',
    duration: isNotUndefinedOrNull(durationParameter) ? durationParameter : '',
  } as BookingFiltersSearchParamsDTO;
  const [filtersState, setFiltersState] = useState<BookingFiltersSearchParamsDTO>(filtersInitialState);
  const { inputHandler } = useStateFormControlHandler(filtersState, setFiltersState);
  const { city, date, duration } = filtersState;

  const spotsService: SpotsService = new SpotsService();

  const changeCityHandler = (value: string) => {
    setPage(1);
    setFiltersState({ ...filtersState, city: value });
  };

  const navigateToSpotPage = (spotId: number) => {
    navigate({
      pathname: `/booking/spots/${spotId}`,
      search: `${createSearchParams({ duration: filtersState.duration, date: filtersState.date })}`,
    });
  };

  const getSpots = () => {
    const cityNameBySearchParameter: string | undefined = CITIES_FILTER_DATA.find(
      (cityFilterItem) => cityFilterItem.searchParameter === city,
    )?.name;
    const spotsFilter: SpotsFilterDTO = {
      location: cityNameBySearchParameter ? cityNameBySearchParameter : '',
      itemsOnPage: 2,
      page: page,
    };

    spotsService
      .getSpotsByFilter(spotsFilter)
      .execute()
      .then((res) => {
        setSpots(page > 1 ? [...spots, ...res.items] : [...res.items]);
        setTotal(res.totalCount);
      });
  };

  useEffect(() => {
    spotsService
      .availableLocations()
      .execute()
      .then((data) => {
        setAvailableLocation(data);
      });
    dispatch(resetBookingState());
  }, []);

  useEffect(() => {
    setSearchParams({ city: city, date: date, duration: duration });
  }, [city, date, duration]);

  useEffect(() => {
    getSpots();
  }, [city, page]);

  return (
    <>
      <BasicContainer>
        <Grid
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mb: { sm: 10 },
            ...(!isDashboardSpotList && {
              pt: { xs: 20, md: 50 },
            }),
          }}
        >
          <Grid
            sx={{
              width: { xs: '100%', md: 'auto' },
              marginBottom: { xs: 7, md: '0' },
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Typography variant={'h3'}>OUR REPOP SHOPS</Typography>

            <Divider isVertical={true} sx={{ display: { xs: 'none', md: 'block' }, height: 40, ml: 10 }} />
          </Grid>

          <Box sx={{ width: { xs: '100%', sm: '150px', md: '182px' } }}>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <BasicSelect
                id="city"
                value={city && availableLocation?.length ? city : ''}
                name="city"
                placeholder="City"
                className="select-like-dropdown"
                placeholderStyles={{ fontSize: { xs: 12, md: 20 }, fontWeight: 700 }}
                noBorder={true}
                onChange={(e: any) => changeCityHandler(e.target.value)}
                aheadIconUrl={locationIconUrl}
              >
                {availableLocation.map((location, index) => {
                  const city: SelectedCityFilter | undefined = CITIES_FILTER_DATA.find(
                    (city) => !location.shortAddress.toLowerCase().indexOf(city.name.toLowerCase()),
                  );

                  if (city) {
                    return (
                      <MenuItem key={index} value={city.searchParameter}>
                        <Typography variant={'subtitle2'} sx={{ typography: { xs: 'body2', md: 'subtitle2' } }}>
                          {city.name}
                        </Typography>
                      </MenuItem>
                    );
                  }
                })}
              </BasicSelect>

              <Divider isVertical={true} sx={{ display: { xs: 'none', sm: 'block' }, height: 40 }} />
            </Grid>
            <Divider sx={{ display: { xs: 'block', sm: 'none' }, mb: { xs: 10 } }} />
          </Box>

          <Grid
            sx={{
              width: { xs: '50%', sm: '150px', md: '240px' },
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <BasicDatePicker
              id="availability"
              name="date"
              placeholder="Availability"
              selectedStr={date || ''}
              filterDate={isSaturday}
              onChange={inputHandler}
              dropdownStyle={true}
              inputStyles={{ width: '100%' }}
              withoutBorder={true}
              aheadIconUrl={bellIconUrl}
            />

            <Divider isVertical={true} sx={{ height: 40 }} />
          </Grid>

          <Grid
            sx={{
              width: { xs: '50%', sm: '150px', md: '260px' },
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <BasicSelect
              id="duration"
              value={duration || ''}
              name="duration"
              placeholder="Duration"
              className="select-like-dropdown"
              placeholderStyles={{ fontSize: { xs: 12, md: 20 }, fontWeight: 700, paddingRight: 2 }}
              noBorder={true}
              onChange={inputHandler}
              aheadIconUrl={clockIconUrl}
            >
              <MenuItem value="2-4">
                <Typography variant={'subtitle2'} sx={{ display: { xs: 'none', md: 'block' } }}>
                  2 Weeks - 4 Weeks
                </Typography>
                <Typography variant={'body2'} sx={{ display: { xs: 'block', md: 'none' } }}>
                  2-4 Weeks
                </Typography>
              </MenuItem>

              <MenuItem value="4-24">
                <Typography variant={'subtitle2'} sx={{ display: { xs: 'none', md: 'block' } }}>
                  1 Month - 6 Months
                </Typography>
                <Typography variant={'body2'} sx={{ display: { xs: 'block', md: 'none' } }}>
                  1-6 Months
                </Typography>
              </MenuItem>

              <MenuItem value="24-52">
                <Typography variant={'subtitle2'} sx={{ typography: { xs: 'body2', md: 'subtitle2' } }}>
                  + 6 Months
                </Typography>
              </MenuItem>
            </BasicSelect>

            <Divider isVertical={true} sx={{ display: { xs: 'none', sm: 'block' }, height: 40 }} />
          </Grid>
        </Grid>
        <Divider sx={{ display: { xs: 'block', sm: 'none' }, marginTop: { xs: 3 }, marginBottom: { xs: 10 } }} />

        <Grid container spacing={15} mb={30}>
          {spots &&
            spots.map((item, index) => {
              return (
                <Grid item xs={12} sm={isDashboardSpotList ? 12 : 6} md={6} key={index}>
                  <StoreCard
                    name={item.storeName}
                    spotPrice={item.spotPrice}
                    spotCurrency={item.spotCurrency}
                    shortAddress={item.shortAddress}
                    sizeInMeters={item.sizeInMeters}
                    imageId={item.storeImage}
                    clickHandler={() => navigateToSpotPage(item.spotId)}
                  />
                </Grid>
              );
            })}
        </Grid>

        <Grid container spacing={15} direction="row" justifyContent="center" mb={isDashboardSpotList ? 30 : 74}>
          <Grid item xs="auto">
            <BasicButton
              className="btn-large btn-yellow"
              variant="contained"
              onClick={() => setPage(page + 1)}
              disabled={spots.length >= total}
            >
              <Typography variant="body1">View more</Typography>
            </BasicButton>
          </Grid>
        </Grid>
      </BasicContainer>
    </>
  );
};
