import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledAddNewCreditCardBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: '246px',
  height: '130px',
  backgroundColor: theme.palette.common.white,
  padding: '10px',
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: '20px',
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '300px',
    height: '140px',
  },

  '.MuiTypography-root': {
    marginBottom: theme.spacing(7),
  },
}));
