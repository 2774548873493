export enum DAYS_OF_WEEK {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}

export const getNameByDay = (day: DAYS_OF_WEEK): string => {
  let res = '';
  switch (day) {
    case DAYS_OF_WEEK.Monday: {
      res = 'Monday';
      break;
    }
    case DAYS_OF_WEEK.Tuesday: {
      res = 'Tuesday';
      break;
    }
    case DAYS_OF_WEEK.Wednesday: {
      res = 'Wednesday';
      break;
    }
    case DAYS_OF_WEEK.Thursday: {
      res = 'Thursday';
      break;
    }
    case DAYS_OF_WEEK.Friday: {
      res = 'Friday';
      break;
    }
    case DAYS_OF_WEEK.Saturday: {
      res = 'Saturday';
      break;
    }
    case DAYS_OF_WEEK.Sunday: {
      res = 'Sunday';
      break;
    }
  }
  return res;
};

export const week = (): DAYS_OF_WEEK[] => {
  return [
    DAYS_OF_WEEK.Sunday,
    DAYS_OF_WEEK.Monday,
    DAYS_OF_WEEK.Tuesday,
    DAYS_OF_WEEK.Wednesday,
    DAYS_OF_WEEK.Thursday,
    DAYS_OF_WEEK.Friday,
    DAYS_OF_WEEK.Saturday,
  ];
};

export const getDisableDates = (working: number[]): number[] | undefined => {
  if (working.length > 0) {
    let weekArr: DAYS_OF_WEEK[] = week();

    working.forEach((day) => {
      let index = weekArr.indexOf(day);
      if (index != -1) {
        weekArr.splice(index, 1);
      }
    });

    return weekArr;
  }
};

export const getDayFromDate = (dateStr: any): DAYS_OF_WEEK | null => {
  let res = null;

  if (dateStr) {
    let date = new Date(dateStr);
    let day = date.getDay();

    switch (day) {
      case 1: {
        res = DAYS_OF_WEEK.Monday;
        break;
      }
      case 2: {
        res = DAYS_OF_WEEK.Tuesday;
        break;
      }
      case 3: {
        res = DAYS_OF_WEEK.Wednesday;
        break;
      }
      case 4: {
        res = DAYS_OF_WEEK.Thursday;
        break;
      }
      case 5: {
        res = DAYS_OF_WEEK.Friday;
        break;
      }
      case 6: {
        res = DAYS_OF_WEEK.Saturday;
        break;
      }
      case 0: {
        res = DAYS_OF_WEEK.Sunday;
        break;
      }
    }
  }

  return res;
};
