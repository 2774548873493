import PreCampaignDepositValueDTO from '../../../models/pre-campaign/pre-campaign-deposit-value-dto';

export enum PRE_CAMPAIGN_DEPOSIT_VALUE {
  Percent_15 = 0,
}

export const getAllCurrencies = (): Array<PreCampaignDepositValueDTO> => {
  return [
    {
      id: PRE_CAMPAIGN_DEPOSIT_VALUE.Percent_15,
      name: '15% of the Price',
      value: 15,
    },
  ];
};

export const getById = (depositValue: PRE_CAMPAIGN_DEPOSIT_VALUE): any => {
  return getAllCurrencies().find((x: any) => x.id == depositValue);
};
