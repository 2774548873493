import { Tabs } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '&': {
    minHeight: '30px',

    '.MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },

    '.MuiTab-root': {
      minHeight: '30px',
      padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
      textTransform: 'none',
      lineHeight: '1',
      color: theme.palette.common.black,
      border: `solid 1px ${theme.palette.common.black}`,
      borderRadius: '15px',
      fontSize: '12px',

      [theme.breakpoints.up('md')]: {
        fontSize: '14px',
        lineHeight: '21px',
      },

      '&:not(:last-of-type)': {
        marginRight: theme.spacing(6),

        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(15),
        },
      },

      '.MuiTab-iconWrapper': {
        marginLeft: theme.spacing(4),

        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(5),
        },
      },

      '&.Mui-selected': {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.common.black,

        '.MuiTab-iconWrapper': {
          path: {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },

    '.MuiTabs-indicator': {
      display: 'none',
    },
  },
}));
