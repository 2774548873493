import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import FurnituresDTO from '../models/furnitures-dto';

export class FurnitureService {
  private readonly basePath: string = '/furnitures';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getFurniture(): ApiRequestWrapper<FurnituresDTO[]> {
    return this.apiUrlProvider.get(this.basePath);
  }
}
