import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { parseJSON } from 'date-fns';
import { Outlet, useMatch, useNavigate, useSearchParams } from 'react-router-dom';

import wavingHandIcon from '../../assets/icons/emoji/waving-hand.png';
import columnBackground from '../../assets/images/repop-backgrounds/column-backgound2-1x.png';
import repopBackground from '../../assets/images/repop-backgrounds/repop-backgound2-1x.png';
import { BasicButton } from '../../common/components/basic/Button';
import { GridWithRepopBackground } from '../../common/components/GridWithRepopBackground';
import { RePopLogoBlock } from '../../common/components/RePopLogoBlock';
import { TitleWithEmojiIcon } from '../../common/components/TitleWithEmojiIcon';
import { getDaysBetweenTwoDates } from '../../common/helpers/date-heplers';
import { getFormattedDatesRange, getFormattedPrice } from '../../common/helpers/formatters';
import { useAppDispatch, useAppSelector } from '../../common/hooks/redux-hooks';
import { useCurrencyConverter } from '../../common/hooks/use-currency-converter';
import { PRE_CAMPAIGN_ID_KEY } from '../../constants';
import { getById as getCurrencyById } from '../../constants/enums/currency';
import {
  getPreCampaignById,
  getSpotById,
  getStoreById,
  selectPreBookingState,
  setFormattedSummaryData,
} from './preBookingSlice';

export const PreBooking = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const isOneOfBookingSteps = useMatch('/pre-booking/:step')?.params?.step;
  const { preCampaignState, selectedStore, selectedSpot } = useAppSelector(selectPreBookingState);
  const [isDisabledStartButton, setIsDisabledStartButton] = useState(true);
  const [isShowExpiredText, setIsShowExpiredText] = useState(false);

  const { startDate, endDate, currency, amount, depositAmount } = preCampaignState;
  const parsedStartDate = startDate && parseJSON(startDate);
  const parsedEndDate = endDate && parseJSON(endDate);
  const preCampaignCurrency = getCurrencyById(currency);
  const { convertedPrice: convertedAmount = 0, currencySymbol = '' } = useCurrencyConverter(
    preCampaignCurrency,
    amount,
  );
  const { convertedPrice: convertedDepositAmount = 0 } = useCurrencyConverter(preCampaignCurrency, depositAmount);
  const { convertedPrice: convertedSpotPrice = 0 } = useCurrencyConverter(preCampaignCurrency, selectedSpot?.price);

  const formattedAmount = getFormattedPrice(convertedAmount, currencySymbol, true);
  const formattedDepositAmount = getFormattedPrice(convertedDepositAmount, currencySymbol, true);
  const formattedAmountAtCheckIn = getFormattedPrice(convertedAmount - convertedDepositAmount, currencySymbol, true);
  const formattedPricePerWeek = getFormattedPrice(convertedSpotPrice * 7, currencySymbol, true);

  const handleError = useCallback(() => {
    //remove invalid campaign id
    localStorage.removeItem(PRE_CAMPAIGN_ID_KEY);

    //redirect from the current step if we don't have pre-campaign data
    if (isOneOfBookingSteps) {
      navigate('/pre-booking');
    } else {
      setIsShowExpiredText(true);
    }
  }, [isOneOfBookingSteps, navigate]);

  const getSpotAndStoreDataByPreCampaignId = useCallback(
    (id: string, spotId: number) => {
      dispatch(getSpotById(spotId))
        .unwrap()
        .then((data) => {
          const storeId = data.storeId;

          if (storeId) {
            dispatch(getStoreById(storeId));
            localStorage.setItem(PRE_CAMPAIGN_ID_KEY, id);
            setIsDisabledStartButton(false);
          } else {
            handleError();
          }
        });
    },
    [dispatch, handleError],
  );

  const getPreCampaignData = useCallback(
    (preCampaignId: string) => {
      dispatch(getPreCampaignById(preCampaignId))
        .unwrap()
        .then((data) => {
          if (data && data?.id && data?.spotId) {
            const { id, spotId } = data;
            getSpotAndStoreDataByPreCampaignId(id, spotId);
          } else {
            handleError();
          }
        });
    },
    [dispatch, getSpotAndStoreDataByPreCampaignId, handleError],
  );

  useEffect(() => {
    const preCampaignIdFromUrl = searchParams.get('precampaign_id');
    const preCampaignIdFromLocalStorage: string | null = localStorage.getItem(PRE_CAMPAIGN_ID_KEY) || null;

    //if we already have pre-campaign data in the store, then no need to fetch data again
    if (preCampaignState?.id) return;

    if (preCampaignIdFromUrl) {
      getPreCampaignData(preCampaignIdFromUrl);
    } else if (preCampaignIdFromLocalStorage) {
      getPreCampaignData(preCampaignIdFromLocalStorage);
    } else {
      handleError();
    }
  }, [getPreCampaignData, handleError, preCampaignState?.id, searchParams]);

  useEffect(() => {
    const spotNameWithStoreAddress =
      selectedSpot?.name && selectedStore?.address?.shortAddress
        ? `${selectedSpot.name}, ${selectedStore.address.shortAddress}`
        : '';
    const daysDuration =
      selectedStore.workingDays && getDaysBetweenTwoDates(parsedStartDate, parsedEndDate, selectedStore.workingDays);

    dispatch(
      setFormattedSummaryData({
        formattedDateRange: getFormattedDatesRange(parsedStartDate, parsedEndDate),
        daysDuration: daysDuration,
        formattedPricePerWeek: formattedPricePerWeek,
        formattedDepositAmount: formattedDepositAmount,
        formattedTotalAmount: formattedAmount,
        formattedAmountAtCheckIn: formattedAmountAtCheckIn,
        spotNameWithStoreAddress: spotNameWithStoreAddress,
      }),
    );
  }, [selectedStore, selectedSpot, preCampaignState]);

  return (
    <>
      {isOneOfBookingSteps ? (
        <Outlet />
      ) : (
        <GridWithRepopBackground
          columnBackground={`top / cover no-repeat url(${columnBackground})`}
          repopBackground={repopBackground}
          isAvailableBgColumnOnMobile
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: { xs: 'center', md: 'flex-start' } }}>
            <RePopLogoBlock sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '8.5vh' }} />

            <TitleWithEmojiIcon title="Hi there!" sx={{ paddingBottom: { xs: 5, md: '7.5vh' } }}>
              <img src={wavingHandIcon} className="emoji-icon" alt="waving-hand-emoji" />
            </TitleWithEmojiIcon>

            <Typography
              sx={{
                typography: { xs: 'body2', md: 'subtitle2' },
                lineHeight: { xs: '20px', md: '30px' },
                paddingBottom: { xs: 25, md: 42 },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              We have sent you this very special propsal to book a REPOP store for you next pop up. Please go through
              the booking steps - from reading the pop-up policy, to securely adding your payment method. While we would
              be happy to save the requested dates for your pop-up, we can&apos;t guarantee that it will be available if
              you won&apos;t complete the booking steps as soon as possible. Good luck!
            </Typography>

            <BasicButton
              className="btn-large btn-black btn-black--with-hover"
              sx={{ width: '170px', margin: { xs: '0 auto 20px auto', md: '0 0 20px auto' } }}
              variant="contained"
              type="button"
              disabled={isDisabledStartButton}
              onClick={() => {
                navigate('welcome');
              }}
            >
              Start now
            </BasicButton>

            {isShowExpiredText && (
              <Typography
                sx={{
                  typography: { xs: 'body2', md: 'body1' },
                  lineHeight: '15px',
                }}
              >
                *Looks like you already went through this Booking Form, or the time expired. Please contact REPOP
                support for help.
              </Typography>
            )}
          </Box>
        </GridWithRepopBackground>
      )}
    </>
  );
};
