import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import CampaignBundleDTO from '../../../models/campaign/campaign-bundle-dto';
import CampaignFilterDTO from '../../../models/campaign/campaign-filter-dto';
import BankAccountVerifyEditDTO from '../../../models/pre-campaign/bank-account-verify-edit-dto';
import PreCampaignBundleDTO from '../../../models/pre-campaign/pre-campaign-bundle-dto';
import PreCampaignsFilterDTO from '../../../models/pre-campaign/pre-campaigns-filter-dto';
import { CampaignManagerService } from '../../../services/campaigns-manager';
import { PreCampaignManagerService } from '../../../services/pre-campaign-manager';

interface initialStateTypes {
  preBookingsBundleData: PreCampaignBundleDTO;
  preBookingsPaginationPage: number;
  bookingsBundleData: CampaignBundleDTO;
  bookingsPaginationPage: number;
  selectedBookingId: number;
  selectedPreBookingId: string;
}

const initialState = {
  preBookingsBundleData: {},
  preBookingsPaginationPage: 1,
  bookingsBundleData: {},
  bookingsPaginationPage: 1,
} as initialStateTypes;

const campaignManagerService: CampaignManagerService = new CampaignManagerService();
const preCampaignManagerService: PreCampaignManagerService = new PreCampaignManagerService();

export const getPreCampaignsByFilter = createAsyncThunk(
  'getPreCampaignsByFilter',
  async (filter: PreCampaignsFilterDTO) => {
    return await preCampaignManagerService.getPreCampaignsByFilter(filter).execute();
  },
);

export const getUpdatedPreCampaignsByFilterAfterDeletingItem = createAsyncThunk(
  'getUpdatedPreCampaignsByFilterAfterDeletingItem',
  async (filter: PreCampaignsFilterDTO) => {
    return await preCampaignManagerService.getPreCampaignsByFilter(filter).execute();
  },
);

export const deletePreCampaignById = createAsyncThunk('deletePreCampaignById', async (id: string) => {
  return await preCampaignManagerService.deletePreCampaignById(id).execute();
});

export const approvePreCampaignBankAccount = createAsyncThunk(
  'approvePreCampaignBankAccount',
  async ({ id, verifyData }: { id: string; verifyData: BankAccountVerifyEditDTO }) => {
    return await preCampaignManagerService.approveBankAccount(id, verifyData).execute();
  },
);

export const getCampaignsByFilter = createAsyncThunk(
  'getCampaignsByFilterPagination',
  async (filter: CampaignFilterDTO) => {
    return await campaignManagerService.getCampaignsByFilter(filter).execute();
  },
);

export const approveCampaign = createAsyncThunk('approveCampaign', async (id: number) => {
  return await campaignManagerService.approveCampaign(id).execute();
});

export const rejectCampaign = createAsyncThunk(
  'rejectCampaign',
  async ({ id, reason }: { id: number; reason: string }) => {
    return await campaignManagerService.rejectCampaign(id, reason).execute();
  },
);

export const bookingsManagerDashboardSlice = createSlice({
  name: 'bookingsManagerDashboard',
  initialState: initialState,
  reducers: {
    updateSelectedBookingId: (state, action) => {
      state.selectedBookingId = action.payload;
    },
    updateSelectedPreBookingId: (state, action) => {
      state.selectedPreBookingId = action.payload;
    },
    setInitialStateForBookingsBundleData: (state) => {
      state.bookingsBundleData = initialState.bookingsBundleData;
      state.bookingsPaginationPage = initialState.bookingsPaginationPage;
      state.selectedBookingId = initialState.selectedBookingId;
    },
    setInitialStateForPreBookingsBundleData: (state) => {
      state.preBookingsBundleData = initialState.preBookingsBundleData;
      state.preBookingsPaginationPage = initialState.preBookingsPaginationPage;
      state.selectedPreBookingId = initialState.selectedPreBookingId;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPreCampaignsByFilter.fulfilled, (state, action) => {
      const previousSearchPageItems = state.preBookingsBundleData.searchPage?.items
        ? state.preBookingsBundleData.searchPage?.items
        : [];
      const previousCampaigns = state.preBookingsBundleData?.campaigns ? state.preBookingsBundleData.campaigns : [];
      const previousSpots = state.preBookingsBundleData?.spots ? state.preBookingsBundleData.spots : [];
      const previousStores = state.preBookingsBundleData?.stores ? state.preBookingsBundleData.stores : [];
      const previousBrands = state.preBookingsBundleData?.brands ? state.preBookingsBundleData.brands : [];

      state.preBookingsBundleData = {
        searchPage: {
          items: [...previousSearchPageItems, ...action.payload.searchPage.items],
          totalCount: action.payload.searchPage.totalCount,
        },
        campaigns: [...previousCampaigns, ...action.payload.campaigns],
        spots: [...previousSpots, ...action.payload.spots],
        stores: [...previousStores, ...action.payload.stores],
        brands: [...previousBrands, ...action.payload.brands],
      };
      state.preBookingsPaginationPage = state.preBookingsPaginationPage + 1;
    });
    builder.addCase(getUpdatedPreCampaignsByFilterAfterDeletingItem.fulfilled, (state, action) => {
      state.preBookingsBundleData = { ...action.payload };
    });
    builder.addCase(approvePreCampaignBankAccount.fulfilled, (state, action) => {
      const indexOfApprovedPreCampaign = state.preBookingsBundleData.searchPage.items.findIndex(
        (item) => item.id === action.payload.id,
      );

      state.preBookingsBundleData.searchPage.items[indexOfApprovedPreCampaign] = action.payload;
    });
    builder.addCase(getCampaignsByFilter.fulfilled, (state, action) => {
      const previousSearchPageItems = state.bookingsBundleData.searchPage?.items
        ? state.bookingsBundleData.searchPage?.items
        : [];
      const previousPreCampaigns = state.bookingsBundleData?.preCampaigns ? state.bookingsBundleData.preCampaigns : [];
      const previousSpots = state.bookingsBundleData?.spots ? state.bookingsBundleData.spots : [];
      const previousStores = state.bookingsBundleData?.stores ? state.bookingsBundleData.stores : [];
      const previousBrands = state.bookingsBundleData?.brands ? state.bookingsBundleData.brands : [];

      state.bookingsBundleData = {
        searchPage: {
          items: [...previousSearchPageItems, ...action.payload.searchPage.items],
          totalCount: action.payload.searchPage.totalCount,
        },
        preCampaigns: [...previousPreCampaigns, ...action.payload.preCampaigns],
        spots: [...previousSpots, ...action.payload.spots],
        stores: [...previousStores, ...action.payload.stores],
        brands: [...previousBrands, ...action.payload.brands],
      };
      state.bookingsPaginationPage++;
    });
    builder.addMatcher(isAnyOf(approveCampaign.fulfilled, rejectCampaign.fulfilled), (state, action) => {
      const indexOfUpdatedCampaign = state.bookingsBundleData.searchPage.items.findIndex(
        (item) => item.id === action.payload.id,
      );

      state.bookingsBundleData.searchPage.items[indexOfUpdatedCampaign] = action.payload;
    });
  },
});

export const selectBookingsManagerDashboardState = function (state: RootState): any {
  return state.bookingsManagerDashboard;
};

export const {
  updateSelectedBookingId,
  updateSelectedPreBookingId,
  setInitialStateForBookingsBundleData,
  setInitialStateForPreBookingsBundleData,
} = bookingsManagerDashboardSlice.actions;

export default bookingsManagerDashboardSlice.reducer;
