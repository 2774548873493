import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

interface DrawerTypes {
  nameOfContentElementForRightDrawer: string;
  isOpenRightDrawer: boolean;
  isOpenLeftDrawer: boolean;
}

const initialState: DrawerTypes = {
  nameOfContentElementForRightDrawer: '',
  isOpenLeftDrawer: false,
  isOpenRightDrawer: false,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: initialState,
  reducers: {
    openRightNavDrawer: (state, action) => {
      state.nameOfContentElementForRightDrawer = action.payload;
      state.isOpenRightDrawer = true;
    },
    hideRightNavDrawer: (state) => {
      state.nameOfContentElementForRightDrawer = initialState.nameOfContentElementForRightDrawer;
      state.isOpenRightDrawer = false;
    },
    openLeftNavDrawer: (state) => {
      state.isOpenLeftDrawer = true;
    },
    hideLeftNavDrawer: (state) => {
      state.isOpenLeftDrawer = false;
    },
  },
});

export const { openLeftNavDrawer, hideLeftNavDrawer, openRightNavDrawer, hideRightNavDrawer } = drawerSlice.actions;

export const selectDrawerState = function (state: RootState): any {
  return state.drawer;
};

export default drawerSlice.reducer;
