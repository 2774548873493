import React from 'react';

import { Avatar, Box, Grid, IconButton, Link, SvgIcon, useMediaQuery, useScrollTrigger } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { ReactComponent as SideMenuIcon } from '../../../assets/icons/side-menu.svg';
import { USER_ROLES } from '../../../constants/enums/user-roles';
import { MOBILE_HEADER_BREAKPOINT, MOBILE_HEADER_BREAKPOINT_DASHBOARD } from '../../../constants/ui';
import { logOut, selectAuthState } from '../../../features/authentication/authSlice';
import { hideLeftNavDrawer, openLeftNavDrawer, selectDrawerState } from '../../../features/drawer/drawerSlice';
import theme from '../../../theme-config/theme';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useMatchRoutes } from '../../hooks/use-match-routes';
import { useUserRole } from '../../hooks/use-user-role';
import { BasicButton } from '../basic/Button';
import { BasicContainer } from '../BasicContainer';
import { RePopLogoBlock } from '../RePopLogoBlock';
import { StyledAppBar, StyledDesktopToolbar, StyledMobileToolbar } from './styles';

interface Props {
  children: JSX.Element;
}

function ElevationScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isPageWithoutDesktopHeader = useMatchRoutes([
    '/log-in',
    '/sign-up',
    '/recovery',
    '/forgot-password',
    '/dashboard/*',
  ]);
  const isOneOfDashboardPages = useMatchRoutes(['/dashboard/*']);
  const drawerState = useAppSelector(selectDrawerState);
  const { authorizedUser } = useAppSelector(selectAuthState);
  const headerBreakpoint = isPageWithoutDesktopHeader ? MOBILE_HEADER_BREAKPOINT_DASHBOARD : MOBILE_HEADER_BREAKPOINT;
  const leftNavDrawerWidth = useMediaQuery(theme.breakpoints.up(headerBreakpoint)) ? 125 : 282;
  const isAdminRole = useUserRole(USER_ROLES.Admin);
  const isRetailRole = useUserRole(USER_ROLES.Retail);
  const dashboardRoute = isAdminRole || isRetailRole ? '/dashboard/manager' : '/dashboard/brand';

  const handleDrawerToggle = () => {
    drawerState.isOpenLeftDrawer ? dispatch(hideLeftNavDrawer()) : dispatch(openLeftNavDrawer());
  };

  function handleClickOnTheSignUpItem() {
    navigate('/sign-up');
  }

  function handleClickOnTheLogOutItem() {
    dispatch(logOut());
  }

  return (
    <>
      <ElevationScroll>
        <StyledAppBar>
          <StyledMobileToolbar leftNavDrawerWidth={leftNavDrawerWidth} headerBreakpoint={headerBreakpoint}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <IconButton
                  sx={{ minWidth: '24px', p: 0, m: 0 }}
                  color="inherit"
                  aria-label="open left nav drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <SvgIcon viewBox={'0 0 20 12'} component={SideMenuIcon} />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <RePopLogoBlock sx={{ width: { xs: '88px' }, margin: '0 auto' }}></RePopLogoBlock>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {authorizedUser.token && isOneOfDashboardPages && <Avatar />}

                  {authorizedUser.token && !isOneOfDashboardPages && (
                    <BasicButton
                      className="btn-black btn-small"
                      variant="contained"
                      onClick={handleClickOnTheLogOutItem}
                    >
                      Log out
                    </BasicButton>
                  )}

                  {!authorizedUser.token && (
                    <BasicButton
                      className="btn-black btn-small"
                      variant="contained"
                      onClick={handleClickOnTheSignUpItem}
                    >
                      Sign-up
                    </BasicButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </StyledMobileToolbar>

          <StyledDesktopToolbar
            headerBreakpoint={headerBreakpoint}
            isPageWithoutDesktopHeader={isPageWithoutDesktopHeader}
          >
            <BasicContainer>
              <Grid container alignItems="center">
                <Grid item xs={4} md={5}>
                  <NavLink className="nav-link" to="/booking/spots" end>
                    Our Locations
                  </NavLink>

                  {/*<Link sx={{ pl: 0, pr: 15 }} component="button" type="button" variant="subtitle2" underline="none">*/}
                  {/*  Blog*/}
                  {/*</Link>*/}

                  {/*<Link sx={{ pl: 0, pr: 15 }} component="button" type="button" variant="subtitle2" underline="none">*/}
                  {/*  Shop*/}
                  {/*</Link>*/}

                  {/*<Link component="button" type="button" variant="subtitle2" underline="none">*/}
                  {/*  For Landlords*/}
                  {/*</Link>*/}
                </Grid>

                <Grid item justifyContent="center" xs={4} md={2}>
                  <RePopLogoBlock sx={{ width: { xs: '150px' }, margin: '0 auto' }}></RePopLogoBlock>
                </Grid>

                <Grid item sx={{ textAlign: 'right' }} xs={4} md={5}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {authorizedUser.token && isOneOfDashboardPages && <Avatar />}

                    {authorizedUser.token && !isOneOfDashboardPages && (
                      <>
                        <Link
                          sx={{ marginRight: 15 }}
                          component="button"
                          type="button"
                          variant="subtitle2"
                          underline="none"
                          onClick={() => navigate(dashboardRoute)}
                        >
                          My Dashboard
                        </Link>

                        <Link
                          component="button"
                          type="button"
                          variant="subtitle2"
                          underline="none"
                          onClick={handleClickOnTheLogOutItem}
                        >
                          Log out
                        </Link>
                      </>
                    )}

                    {!authorizedUser.token && (
                      <Link
                        component="button"
                        type="button"
                        variant="subtitle2"
                        underline="none"
                        onClick={handleClickOnTheSignUpItem}
                      >
                        Sign-up
                      </Link>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </BasicContainer>
          </StyledDesktopToolbar>
        </StyledAppBar>
      </ElevationScroll>

      <StyledMobileToolbar leftNavDrawerWidth={leftNavDrawerWidth} headerBreakpoint={headerBreakpoint} />
      <StyledDesktopToolbar
        headerBreakpoint={headerBreakpoint}
        isPageWithoutDesktopHeader={isPageWithoutDesktopHeader}
      />
    </>
  );
};
