import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import BrandPaymentTransactionsResultDTO from '../models/payment-transactions/brand-payment-transactions-result-dto';
import PaymentTransactionsFilterDTO from '../models/payment-transactions/payment-transactions-filter';

export class PaymentTransactionsBrandService {
  private readonly basePath: string = 'brand/PaymentTransactions';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getPaymentTransactionsByFilterBrand(
    filter: PaymentTransactionsFilterDTO,
  ): ApiRequestWrapper<BrandPaymentTransactionsResultDTO> {
    return this.apiUrlProvider.post(this.basePath + '/filter', filter);
  }
}
