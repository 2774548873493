import ApiUrlProvider from '../api/api-url-provider';
import { API_URL } from '../constants';

export class ImgService {
  private readonly basePath: string = 'images';
  private readonly apiPath: string = API_URL;

  public getImgPreviewUrl(imageId: string, options?: ImageLoadOptions): string {
    let opts = '';

    if (options?.width) {
      opts += 'width=' + options.width;
    }

    if (options?.height) {
      opts += (opts.length > 0 ? '&' : '') + 'height=' + options.height;
    }

    opts = (opts.length > 0 ? '?' : '') + opts;

    return `${this.apiPath}/${this.basePath}/${imageId}${opts}`;
  }

  public getOriginalUrlImg(imageId: string): any {
    return `${this.apiPath}/${this.basePath}/${imageId}/original`;
  }
}

export interface ImageLoadOptions {
  width?: 100 | 200 | 300 | 400 | 600 | 800 | 1000 | 1400;
  height?: 100 | 200 | 300 | 400 | 600 | 800 | 1000 | 1400;
}
