import React from 'react';

import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { BaseDialog } from '../../BaseDialog';
import { BasicButton } from '../../basic/Button';
import { RefundDeposit } from '../../RefundDeposit';

export const CampaignCancellationDialog = ({
  dialogProps,
  campaignStartStr,
  cancellationHandler,
  refuseCancellationHandler,
}: CampaignCancellationDialogProps): JSX.Element => {
  const campaignStartDate = campaignStartStr && new Date(campaignStartStr);

  return (
    <BaseDialog dialogProps={dialogProps}>
      <Box sx={{ textAlign: 'center', paddingX: { md: 20 } }}>
        <Typography variant="h2" sx={{ typography: { xs: 'h3', md: 'h2' }, marginTop: 10, marginBottom: 6 }}>
          Are you sure you want to cancel?
        </Typography>

        {campaignStartDate ? (
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' }, marginBottom: 20 }}>
            You still have until{' '}
            {campaignStartDate &&
              format(new Date(campaignStartDate).setDate(campaignStartDate.getDate() - 45), 'dd/MM/yyyy')}{' '}
            for a full refund. After that date, we will keep the deposit as cancellation fee
          </Typography>
        ) : null}

        <Box sx={{ marginBottom: 20 }}>
          {campaignStartDate && <RefundDeposit startDateOfBooking={campaignStartDate} />}
        </Box>

        <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' }, marginBottom: 20 }}>
          If you cancel your booking now, this spot will disappear from your current list, and the dates
          <br /> will be available for the public.
          <br /> If you want to book it again, check the availability because someone may have taken the dates
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10 }}>
        <BasicButton
          className="btn-small"
          sx={{ minWidth: { xs: '150', md: '170px' }, marginRight: { xs: 6, md: 15 } }}
          variant="outlined"
          type="button"
          onClick={cancellationHandler}
        >
          <Typography variant="body2" sx={{ typography: { xs: 'body2', md: 'subtitle2' } }}>
            Cancel now
          </Typography>
        </BasicButton>

        <BasicButton
          className="btn-black btn-small"
          sx={{ minWidth: { xs: '150', md: '170px' } }}
          variant="contained"
          type="button"
          onClick={refuseCancellationHandler}
        >
          <Typography variant="body2" sx={{ typography: { xs: 'body2', md: 'subtitle2' } }}>
            Keep this Spot
          </Typography>
        </BasicButton>
      </Box>
    </BaseDialog>
  );
};

interface CampaignCancellationDialogProps {
  campaignStartStr: string | undefined;
  dialogProps: object;
  cancellationHandler: () => void;
  refuseCancellationHandler: () => void;
}
