import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import FurnituresDTO from 'models/furnitures-dto';
import { FurnitureService } from 'services/furniture';

interface initialAuthStateTypes {
  furnitureList: FurnituresDTO[];
}

const initialAuthState = {
  furnitureList: [],
} as initialAuthStateTypes;

const furnitureService: FurnitureService = new FurnitureService();

export const getFurniture = createAsyncThunk('getFurniture', async () => {
  return await furnitureService.getFurniture().execute();
});

export const furnitureSlice = createSlice({
  name: 'furniture',
  initialState: initialAuthState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getFurniture.fulfilled, (state, action) => {
      state.furnitureList = action.payload;
    });
  },
});

export const selectFurnitureState = function (state: RootState): any {
  return state.furniture;
};

export default furnitureSlice.reducer;
