import Axios from 'axios';
import fileDownload from 'js-file-download';

export function fileDownloader(url: string, fileName: string) {
  Axios.get(url, {
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, fileName);
  });
}
