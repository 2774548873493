import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTitleWithEmojiIcon = styled(Box)(({ theme }) => ({
  '.emoji-icon': {
    width: '30px',
    height: '30px',
    marginLeft: theme.spacing(5),

    [theme.breakpoints.up('md')]: {
      width: '40px',
      height: '40px',
    },
  },
}));
