import React from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';

export const ActionMenu = ({ items, actionMenuStyles = {} }: ActionMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const clickOnMenuItemHandler = (event: React.MouseEvent<HTMLElement>, itemAction: () => void) => {
    itemAction();
    handleClose(event);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ActionDots />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        sx={actionMenuStyles}
        onClose={handleClose}
      >
        {items.map((item: any, index: number) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              clickOnMenuItemHandler(e, item.action);
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface ActionMenuProps {
  items: {
    title: string;
    action: () => void;
  }[];
  actionMenuStyles?: object;
}
