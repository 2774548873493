import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import BlockedDatesDTO from '../../models/blocked-dates-dto';
import BookedDatesDTO from '../../models/booked-dates-dto';
import SpotDTO from '../../models/spot/spot-dto';
import StoreDTO from '../../models/store/store-dto';
import { SpotsService } from '../../services/spots';
import { StoresService } from '../../services/stores';

interface initialStateTypes {
  selectedSpot: SpotDTO;
  selectedSpotBlockedDates: BlockedDatesDTO[];
  selectedSpotBookedDates: BookedDatesDTO[];
  selectedStore: StoreDTO;
  dateRange: {
    startDateStr: string;
    endDateStr: string;
  };
}

const initialState = {
  selectedSpot: {},
  selectedSpotBlockedDates: [] as BlockedDatesDTO[],
  selectedSpotBookedDates: [] as BookedDatesDTO[],
  selectedStore: {},
  dateRange: {},
} as initialStateTypes;

const storesService: StoresService = new StoresService();
const spotsService: SpotsService = new SpotsService();

export const getSpotById = createAsyncThunk('getSpotById', async (id: number) => {
  return await spotsService.getSpotById(id).execute();
});

export const getBlockedDatesBySpotId = createAsyncThunk('getBlockedDatesBySpotId', async (id: number) => {
  return await spotsService.getBlockedDatesBySpotId(id).execute();
});

export const getBookedDatesBySpotId = createAsyncThunk('getBookedDatesBySpotId', async (id: number) => {
  return await spotsService.getBookedDatesBySpotId(id).execute();
});

export const getStoreById = createAsyncThunk('getStoreById', async (id: number) => {
  return await storesService.getStoreById(id).execute();
});

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialState,
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange = { ...action.payload };
    },
    resetBookingState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSpotById.fulfilled, (state, action) => {
      state.selectedSpot = action.payload;
    });
    builder.addCase(getBlockedDatesBySpotId.fulfilled, (state, action) => {
      state.selectedSpotBlockedDates = action.payload;
    });
    builder.addCase(getBookedDatesBySpotId.fulfilled, (state, action) => {
      state.selectedSpotBookedDates = action.payload;
    });
    builder.addCase(getStoreById.fulfilled, (state, action) => {
      state.selectedStore = action.payload;
    });
  },
});

export const selectBookingState = function (state: RootState): any {
  return state.booking;
};

export const { setDateRange, resetBookingState } = bookingSlice.actions;

export default bookingSlice.reducer;
