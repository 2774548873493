import { useEffect } from 'react';

export const useOnBeforeUnload = () => {
  useEffect(() => {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      let isMailToLink = document?.activeElement?.getAttribute('href')?.includes('mailto');
      let isPhoneToLink = document?.activeElement?.getAttribute('href')?.includes('tel');

      if (!isMailToLink && !isPhoneToLink) {
        e.preventDefault();
        return '';
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);
};
