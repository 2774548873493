import { store } from 'app/store';
import { AxiosResponse } from 'axios';
import { hideLoader, showLoader } from 'features/loader/loaderSlice';
import { enqueueNotification } from 'features/notifier/notifierSlice';

export default class ApiRequestWrapper<TResp> {
  private isNeedToShowLoader = true;

  constructor(private readonly request: Promise<AxiosResponse<TResp>>) {
    this.request = request;
  }

  public showLoader(value: boolean): ApiRequestWrapper<TResp> {
    this.isNeedToShowLoader = value;
    return this;
  }

  public execute(): Promise<TResp> {
    return new Promise<TResp>((resolve, reject) => {
      this.isNeedToShowLoader ? store.dispatch(showLoader()) : false;

      this.request
        .then((resp) => {
          if (resp.status == 204) {
            resp.data = <any>null;
          }

          resolve(resp.data);
        })
        .catch((ex) => {
          const messageFromServer = ex.response?.data?.message;
          const defaultMessage = 'Something went wrong';

          store.dispatch(
            enqueueNotification({
              options: { variant: 'error' },
              message: messageFromServer ? messageFromServer : defaultMessage,
            }),
          );
          reject(ex);
        })
        .finally(() => {
          if (this.isNeedToShowLoader) {
            setTimeout(() => store.dispatch(hideLoader()), 300);
          }
        });
    });
  }
}
