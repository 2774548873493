import React, { useEffect, useState } from 'react';

import { Box, Collapse, IconButton, Link, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { addDays, format, lastDayOfYear } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { selectBookingState, setDateRange } from '../../../features/booking/bookingSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../../../features/notifier/notifierSlice';
import theme from '../../../theme-config/theme';
import {
  calculateBlockedDates,
  getDateRange,
  getDaysBetweenTwoDates,
  getEachWeekOfInterval,
  getEndOfTheWeek,
  getStartOfTheWeek,
  isSaturday,
} from '../../helpers/date-heplers';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useBookingData } from '../../hooks/use-booking-data';
import { BasicButton } from '../basic/Button';
import { BasicDatePicker } from '../basic/DatePicker';
import { BoxWithBackground } from '../BoxWithBackground';
import { StyledBookNowFixed, StyledRangeBox } from './styles';

export const BookNowBox = ({ sx = {}, clickOnBookNowHandler }: BookNowBoxProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { selectedSpot, selectedStore, dateRange, selectedSpotBookedDates, selectedSpotBlockedDates } =
    useAppSelector(selectBookingState);
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const startDateStr = dateRange?.startDateStr || searchParams.get('start_date');
  const endDateStr = dateRange?.endDateStr || searchParams.get('end_date');
  const startDateObj = startDateStr && new Date(startDateStr);
  const endDateObj = endDateStr && new Date(endDateStr);
  const [highlightedRange, setHighlightedRange] = useState<Date[] | undefined>(undefined);
  const [isShowCalendarBlock, setIsShowCalendarBlock] = useState(false);
  const [isNeedToSetDatepickerFilter, setIsNeedToSetDatepickerFilter] = useState<boolean>();
  const [isClosedBookNowFixedBlock, setIsClosedBookNowFixedBlock] = useState<boolean>(
    isUpMdBreakPoint || !(startDateObj && endDateObj),
  );
  const isDeviceWithTouchScreen = 'ontouchstart' in window;
  const isAvailableDataToSetNewRange = !!(startDateStr && !endDateStr && highlightedRange?.length);
  const { daysDuration, workingDaysDuration, formattedTotalAmount, formattedPricePerWeek } = useBookingData(
    selectedSpot,
    selectedStore,
    startDateStr,
    endDateStr,
  );
  const blockedDates = calculateBlockedDates(selectedSpotBlockedDates, selectedSpotBookedDates);

  const whatsAppPredefinedText =
    selectedSpot?.name && selectedSpot?.id
      ? `Hi, I would like to check out ${selectedSpot.name} store. Could we arrange a date and time? Thanks! ${location.origin}/booking/spots/${selectedSpot.id}`
      : '';
  const scheduleVisitLinkUrl = `https://wa.me/${selectedStore.contactPhoneNumber}?text=${whatsAppPredefinedText}`;

  const SpotBookInfoBlock =
    startDateObj && endDateObj && daysDuration && formattedTotalAmount && daysDuration >= 14 ? (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            Check in: {startDateObj && format(startDateObj, 'dd/MM/yyyy')}
          </Typography>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            Check out: {endDateObj && format(endDateObj, 'dd/MM/yyyy')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            Total working days
          </Typography>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            {workingDaysDuration + ' days'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            Total Amount
          </Typography>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            {formattedTotalAmount}
          </Typography>
        </Box>
      </>
    ) : null;

  const selectDateHandler = (value: Date) => {
    if (isAvailableDataToSetNewRange) {
      const fridayFromSelectedDay = getEndOfTheWeek(value);
      const daysDuration = getDaysBetweenTwoDates(startDateObj, fridayFromSelectedDay);
      // set minimal rental period to 14 days
      const endDate = daysDuration >= 14 ? fridayFromSelectedDay : addDays(startDateObj, 13);

      dispatch(
        setDateRange({
          startDateStr: startDateStr,
          endDateStr: endDate.toISOString(),
        }),
      );
    } else {
      dispatch(setDateRange({ startDateStr: value.toISOString(), endDateStr: undefined }));
    }
  };

  const hoverOnDayHandler = (date: Date) => {
    //check the same week to avoid unnecessary re-render
    const firstDate = getStartOfTheWeek(date);
    const isAvailableHighlightedRange = highlightedRange?.length;
    const secondDate = isAvailableHighlightedRange
      ? getStartOfTheWeek(highlightedRange[highlightedRange.length - 1])
      : undefined;
    const isNotTheSameWeek = firstDate.getTime() !== secondDate?.getTime();

    if (isAvailableDataToSetNewRange && isNotTheSameWeek) {
      const selectedRange = getDateRange(startDateObj, getEndOfTheWeek(date), blockedDates);
      const minimalRentalDate = addDays(startDateObj, 13);
      const isValidRange = selectedRange[selectedRange.length - 1]?.getTime() >= minimalRentalDate?.getTime();

      isValidRange && setHighlightedRange(selectedRange);
    }
  };

  const getFistAvailableDayForBooking = (): Date | undefined => {
    const eachSaturdayOfYear = getEachWeekOfInterval(new Date(), lastDayOfYear(new Date()));

    return eachSaturdayOfYear.find((day) => {
      const isExistDayInBlockedDates = blockedDates?.find(
        (blockedDate) => blockedDate.setHours(0, 0, 0, 0) === day.getTime(),
      );

      return !isExistDayInBlockedDates && day.getTime() >= new Date().setHours(0, 0, 0, 0);
    });
  };

  function resetDatepickerRange(errorMessage?: string) {
    setHighlightedRange(undefined);
    dispatch(setDateRange({ startDateStr: undefined, endDateStr: undefined }));

    if (errorMessage) {
      dispatch(
        enqueueSnackbarAction({
          message: errorMessage,
          options: { variant: 'error' },
        }),
      );
    }
  }

  const getSelectedDateToDatepicker = () => {
    if (startDateStr && endDateStr) {
      return endDateStr;
    } else if (startDateStr && !endDateStr && highlightedRange?.length) {
      //when the user selects range for the first time, need to automatically show the next month if the end of the interval falls on the next month
      return highlightedRange.length === 14 && highlightedRange[highlightedRange.length - 1].toISOString();
    } else {
      return '';
    }
  };

  useEffect(() => {
    startDateStr && endDateStr && !isUpMdBreakPoint
      ? setIsClosedBookNowFixedBlock(false)
      : setIsClosedBookNowFixedBlock(true);

    if (startDateObj && !endDateObj) {
      const minimalRange = getDateRange(startDateObj, addDays(startDateObj, 13), blockedDates);

      minimalRange?.length
        ? setHighlightedRange(minimalRange)
        : resetDatepickerRange(
            "You can't select minimal range from this date, because there are locked dates in the range",
          );
    } else if (startDateObj && endDateObj) {
      const selectedRange = getDateRange(startDateObj, endDateObj, blockedDates);

      if (selectedRange?.length) {
        setHighlightedRange(selectedRange);
        setIsShowCalendarBlock(false);
      } else {
        resetDatepickerRange("You can't select this range, because there are locked dates in the range");
      }
    }

    //reset highlightedRange in case the available week item has been unselected
    if (!startDateObj && !endDateObj && highlightedRange?.length) {
      setHighlightedRange(undefined);
    }

    //set filter to allow user select only Saturday
    setIsNeedToSetDatepickerFilter(!startDateStr || (startDateStr && endDateStr));
  }, [startDateStr, endDateStr, blockedDates?.length, isUpMdBreakPoint]);

  useEffect(() => {
    if (daysDuration && daysDuration < 14) {
      resetDatepickerRange('The minimal rental period is two weeks');
    }
  }, [daysDuration]);

  return (
    <>
      <BoxWithBackground
        sx={{
          width: '100%',
          maxWidth: { xs: '600px', md: '470px' },
          borderRadius: '20px',
          padding: { xs: '20px', lg: '20px 40px' },
          ...sx,
        }}
      >
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="h3" sx={{ marginBottom: 5 }}>
            BOOK NOW FOR
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: 10 }}>
            {formattedPricePerWeek && formattedPricePerWeek + ' / Week'}
          </Typography>
          <Collapse in={!isShowCalendarBlock} timeout={500}>
            <Typography variant="body1" sx={{ fontSize: '16px', marginBottom: 10 }}>
              *Minimum booking of 2 weeks, from Saturdays only
            </Typography>
          </Collapse>

          <StyledRangeBox
            sx={{ cursor: 'pointer' }}
            isShowCalendarBlock={isShowCalendarBlock}
            onClick={() => {
              setIsShowCalendarBlock(!isShowCalendarBlock);
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SvgIcon
                sx={{
                  marginRight: 5,
                }}
                viewBox={'0 0 24 24'}
                component={CalendarIcon}
              />

              <Typography variant="body1" sx={{ fontSize: '16px' }}>
                {startDateObj ? format(startDateObj, 'dd/MM/yyyy') : 'Check-in'}
              </Typography>
            </Box>

            <svg width="13" height="3" viewBox="0 0 13 3" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.096 2.648V0.392H0.48V2.648H12.096Z" fill="white" />
            </svg>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SvgIcon
                sx={{
                  marginRight: 5,
                }}
                viewBox={'0 0 24 24'}
                component={CalendarIcon}
              />

              <Typography variant="body1" sx={{ fontSize: '16px' }}>
                {endDateObj ? format(endDateObj, 'dd/MM/yyyy') : 'Check-out'}
              </Typography>
            </Box>
          </StyledRangeBox>
        </Box>

        <Collapse in={isShowCalendarBlock} sx={{ marginBottom: 14 }} timeout={500}>
          <BasicDatePicker
            id="range-select"
            name="range"
            selectedStr={getSelectedDateToDatepicker()}
            highlightDates={[
              {
                'react-datepicker__day--highlighted-range': highlightedRange?.length ? highlightedRange : [],
              },
              {
                'react-datepicker__day--highlighted-blocked-date': blockedDates?.length ? blockedDates : [],
              },
            ]}
            openToDateStr={getFistAvailableDayForBooking()?.toISOString()}
            filterDate={isNeedToSetDatepickerFilter ? isSaturday : undefined}
            excludeDates={blockedDates}
            minDateStr={!isNeedToSetDatepickerFilter ? startDateStr : undefined}
            dayClassName={(date: Date) => {
              return isSaturday(date) ? null : 'react-datepicker__day--disable-selection';
            }}
            onChange={() => false}
            onSelect={(data) => {
              selectDateHandler(data);
            }}
            onDayMouseEnter={(date) => {
              //disable hover event for touch screen devices
              return isDeviceWithTouchScreen ? undefined : hoverOnDayHandler(date);
            }}
            inline={true}
            isBlackColorPalette={true}
            inputStyles={{
              border: 'solid 1px white',
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px',
            }}
            containerStyles={{
              margin: { xs: 10, md: 0 },
            }}
          />
        </Collapse>

        <Box sx={{ marginBottom: { xs: '0', md: 10 } }}>
          {selectedStore?.contactPhoneNumber && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Link
                href={scheduleVisitLinkUrl}
                target="_blank"
                rel="noreferrer"
                variant="body1"
                sx={{ typography: { xs: 'body2', md: 'body1' } }}
                color="inherit"
                underline="always"
              >
                Schedule a visit
              </Link>
            </Box>
          )}
        </Box>

        {isUpMdBreakPoint && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Collapse
                sx={{ '.MuiCollapse-wrapper': { zIndex: '-1' } }}
                in={Boolean(isShowCalendarBlock && startDateStr && endDateStr)}
                orientation="horizontal"
                timeout={500}
              >
                <BasicButton
                  fullWidth={true}
                  variant="outlined"
                  className="btn-outlined-black-with-yellow-text btn-small"
                  sx={{
                    width: { xs: '165px', md: '170px' },
                    flexShrink: '1',
                    margin: { xs: '20px auto 0 auto', md: '0 30px 0 0' },
                  }}
                  onClick={() => resetDatepickerRange()}
                >
                  <Typography variant="subtitle2" sx={{ typography: { xs: 'body2', md: 'subtitle2' } }}>
                    Cancel dates
                  </Typography>
                </BasicButton>
              </Collapse>

              <BasicButton
                fullWidth={true}
                variant="contained"
                className="btn-yellow padding-15-36"
                sx={{
                  width: '170px',
                  flexShrink: '1',
                  margin: { xs: '20px auto 0 auto', md: '0' },
                }}
                disabled={!(startDateStr && endDateStr)}
                onClick={clickOnBookNowHandler}
              >
                <Typography variant="subtitle2" sx={{ typography: { xs: 'body2', md: 'subtitle2' } }}>
                  Book Now
                </Typography>
              </BasicButton>
            </Box>

            <Box sx={{ marginTop: 10 }}>{SpotBookInfoBlock}</Box>
          </>
        )}
      </BoxWithBackground>

      <StyledBookNowFixed isClosedBookNowFixedBlock={isClosedBookNowFixedBlock}>
        <IconButton
          aria-label="close"
          sx={{ position: 'absolute', width: '32px', height: '32px', right: '8px', top: '6px' }}
          onClick={() => {
            setIsClosedBookNowFixedBlock(true);
            resetDatepickerRange();
          }}
        >
          <CrossIcon />
        </IconButton>

        <Typography variant="h3" sx={{ marginBottom: 5 }}>
          BOOK NOW
        </Typography>

        {SpotBookInfoBlock}

        <BasicButton
          variant="contained"
          className={`btn-yellow`}
          sx={{ display: 'block', width: '165px', margin: '20px auto 0 auto' }}
          disabled={!(startDateStr && endDateStr)}
          onClick={clickOnBookNowHandler}
        >
          <Typography variant="body2">Book Now</Typography>
        </BasicButton>
      </StyledBookNowFixed>
    </>
  );
};

interface BookNowBoxProps {
  sx?: SxProps<Theme>;
  clickOnBookNowHandler: () => void;
}
