import * as Yup from 'yup';

const phoneRegExp = /^[+]*[0-9]{10,12}$/;
const firstName = { firstName: Yup.string().required('First name is required') };
const lastName = { lastName: Yup.string().required('Last name is required') };
const email = { email: Yup.string().required('Email is required').email('Email is invalid') };
const phoneNumber = {
  phoneNumber: Yup.string().required('Phone number is required').matches(phoneRegExp, 'Phone number is not valid'),
};
const password = {
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
};
const confirmPassword = {
  ...password,
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
};

export const signUpSchema = Yup.object().shape({
  ...firstName,
  ...lastName,
  ...email,
  ...phoneNumber,
  ...confirmPassword,
});

export const logInSchema = Yup.object().shape({
  ...email,
  ...password,
});
