import React from 'react';

import { FormControl, FormHelperText, InputBaseProps, InputLabel } from '@mui/material';

import { StyledOutlinedInput } from './styles';

export const BasicInput = ({
  id,
  value,
  label,
  controlError = '',
  className,
  fullWidth = true,
  shrink = false,
  multiline = false,
  rows,
  formControlProps = {},
  inputProps = {},
  inputLabelProps = {},
  inputRef,
  isWhiteColorPalette = false,
  onChange,
  onKeyUp,
}: BasicInputProps): JSX.Element => {
  return (
    <FormControl error={!!controlError} fullWidth={!!fullWidth} {...formControlProps}>
      {label && (
        <InputLabel htmlFor={id} shrink={!!shrink} {...inputLabelProps}>
          {label}
        </InputLabel>
      )}
      <StyledOutlinedInput
        value={value}
        className={className}
        multiline={multiline}
        rows={rows}
        inputProps={{ id, ...inputProps }}
        inputRef={inputRef}
        isWhiteColorPalette={isWhiteColorPalette}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
      {controlError && <FormHelperText>{controlError}</FormHelperText>}
    </FormControl>
  );
};

interface BasicInputProps extends InputBaseProps {
  label?: string;
  controlError?: string;
  shrink?: boolean;
  formControlProps?: object;
  inputLabelProps?: object;
  isWhiteColorPalette?: boolean;
}
