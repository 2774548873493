import PreCampaignBundleDTO from '../../models/pre-campaign/pre-campaign-bundle-dto';
import PreCampaignBundleItemDTO from '../../models/pre-campaign/pre-campaign-bundle-item-dto';

export const getRelatedDataToPreCampaign = (
  preCampaignId: string,
  preBookingsBundleData: PreCampaignBundleDTO,
): PreCampaignBundleItemDTO => {
  const relatedPreCampaign = preBookingsBundleData?.searchPage?.items?.find(
    (preCampaign) => preCampaign.id === preCampaignId,
  );
  const relatedCampaign = preBookingsBundleData?.campaigns?.find(
    (campaign) => campaign.preCampaignId === relatedPreCampaign?.id,
  );
  const relatedSpot = preBookingsBundleData?.spots?.find((spot) => spot.id === relatedPreCampaign?.spotId);
  const relatedStore = preBookingsBundleData?.stores?.find((store) => store.id === relatedSpot?.storeId);
  const relatedBrand = preBookingsBundleData?.brands?.find((brand) => brand.id === relatedCampaign?.brandId);

  return {
    preCampaign: relatedPreCampaign,
    campaign: relatedCampaign,
    spot: relatedSpot,
    store: relatedStore,
    brand: relatedBrand,
  } as PreCampaignBundleItemDTO;
};
