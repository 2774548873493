import React from 'react';

import { Box, Collapse, Divider, Stack, SvgIcon, Typography } from '@mui/material';

import { ReactComponent as CollapsingIcon } from '../../../assets/icons/collapsing-arrow.svg';
import { summaryTableStyles } from './styles';
interface SummaryTableProps {
  tableData: Array<{
    headTitle: string;
    rowValue: string | number | undefined | null;
    isHighlightedTitle?: boolean;
    isHighlightedValue?: boolean;
    isCollapsed?: boolean;
    isShowDivider?: boolean;
  }>;
  styles?: object;
}

export const SummaryTable = ({ tableData = [], styles = {} }: SummaryTableProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  let isNeedToShowCollapseTrigger = false;

  let tableRows = tableData.map((row, index) => {
    const isTotalDaysRow = row.headTitle === 'Total days';
    const daysSubText = (
      <Typography component="span" variant="body1" sx={{ marginLeft: 1 }}>
        days
      </Typography>
    );
    if (!isNeedToShowCollapseTrigger && row.isCollapsed) {
      isNeedToShowCollapseTrigger = true;
    }
    const rowItem = (
      <Box className={`table-row ${row.isHighlightedValue ? 'table-row-highlighted' : ''}`}>
        <Box className="title" component="span">
          {row.headTitle}
        </Box>
        <Box className="value" component="span">
          {row.rowValue ? row.rowValue : ''}
          {row.rowValue && isTotalDaysRow ? daysSubText : ''}
        </Box>
      </Box>
    );

    return (
      <Box key={index}>
        {row.isCollapsed ? (
          <Collapse in={isOpen} timeout="auto">
            {rowItem}
          </Collapse>
        ) : (
          rowItem
        )}

        {row.isShowDivider && <Divider sx={{ borderColor: (theme) => theme.palette.common.black }}></Divider>}
      </Box>
    );
  });

  return (
    <Stack sx={{ ...summaryTableStyles, ...styles }} flexGrow="1" direction="column">
      {tableRows}

      {isNeedToShowCollapseTrigger && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Typography variant="body1" sx={{ marginRight: 5, typography: { xs: 'body2', md: 'body1' } }}>
            View more
          </Typography>
          <SvgIcon
            sx={{
              width: '18px',
              height: '9px',
              fill: 'none',
              transition: 'none',
              transform: isOpen ? 'rotate(180deg)' : '',
            }}
            viewBox={'0 0 18 9'}
            component={CollapsingIcon}
          ></SvgIcon>
        </Box>
      )}
    </Stack>
  );
};
