import React, { useEffect } from 'react';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { ActionMenu } from '../../../../common/components/ActionMenu';
import { BasicButton } from '../../../../common/components/basic/Button';
import { DashboardRightDrawer } from '../../../../common/components/dashboard/RightDrawer';
import { getFormattedDatesRange } from '../../../../common/helpers/formatters';
import { getActionMenuItemsForPreCampaign } from '../../../../common/helpers/get-action-menu-items-for-pre-campaign';
import { getPreBookingStatus } from '../../../../common/helpers/get-pre-campaign-status';
import { getRelatedDataToPreCampaign } from '../../../../common/helpers/get-related-data-to-pre-campaign';
import { findPartInAddressObjByType } from '../../../../common/helpers/google-heplers';
import { isNotUndefinedOrNull } from '../../../../common/helpers/utils';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/redux-hooks';
import { enqueueNotification as enqueueSnackbarAction } from '../../../../features/notifier/notifierSlice';
import PreCampaignBundleDTO from '../../../../models/pre-campaign/pre-campaign-bundle-dto';
import PreCampaignDTO from '../../../../models/pre-campaign/pre-campaign-dto';
import PreCampaignsFilterDTO from '../../../../models/pre-campaign/pre-campaigns-filter-dto';
import theme from '../../../../theme-config/theme';
import { hideRightNavDrawer, openRightNavDrawer } from '../../../drawer/drawerSlice';
import {
  approvePreCampaignBankAccount,
  deletePreCampaignById,
  getPreCampaignsByFilter,
  getUpdatedPreCampaignsByFilterAfterDeletingItem,
  selectBookingsManagerDashboardState,
  setInitialStateForPreBookingsBundleData,
  updateSelectedPreBookingId,
} from '../bookingsManagerDashboardSlice';
import { PreBookingAdditionalInfo } from '../PreBookingAdditionalInfo';

export const PreBookingsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const {
    preBookingsBundleData,
    preBookingsPaginationPage,
  }: { preBookingsBundleData: PreCampaignBundleDTO; preBookingsPaginationPage: number } = useAppSelector(
    selectBookingsManagerDashboardState,
  );
  const filter: PreCampaignsFilterDTO = {
    itemsOnPage: 10,
    page: preBookingsPaginationPage,
  };
  const preBookingItems = preBookingsBundleData?.searchPage?.items;

  function redirectToPersonalizedBookingHandler(preCampaign: PreCampaignDTO) {
    const id = preCampaign?.id;

    if (id) {
      const searchParamsObj: Record<string, string> = { pre_campaign_id: id };

      navigate({
        pathname: `/dashboard/manager/personalized-booking`,
        search: `${createSearchParams(searchParamsObj)}`,
      });
    }
  }

  const handleApproveOrReject = (preCampaign: PreCampaignDTO, isVerified: boolean) => {
    const id = preCampaign?.id;
    const depositAmount = preCampaign?.depositAmount;
    const currency = preCampaign?.currency;

    if (isNotUndefinedOrNull(depositAmount) && isNotUndefinedOrNull(currency) && isNotUndefinedOrNull(id)) {
      const verifyData = {
        amount: depositAmount,
        currency: currency,
        isVerified: isVerified,
      };

      dispatch(approvePreCampaignBankAccount({ id, verifyData }))
        .unwrap()
        .then((data) => {
          if (data?.id) {
            dispatch(
              enqueueSnackbarAction({
                message: `Booking was successfully ${isVerified ? 'approved' : 'rejected'}`,
                options: { variant: 'success' },
              }),
            );
          }
        });
    }
  };

  const clickOnRejectHandler = (preCampaign: PreCampaignDTO) => {
    handleApproveOrReject(preCampaign, false);
  };

  const clickOnApproveHandler = (preCampaign: PreCampaignDTO) => {
    handleApproveOrReject(preCampaign, true);
  };

  const clickOnCopyBookingURLHandler = (preCampaign: PreCampaignDTO) => {
    const preBookingUrl = preCampaign?.id ? location.origin + '/pre-booking?precampaign_id=' + preCampaign.id : '';

    navigator.clipboard.writeText(preBookingUrl);
  };

  const clickOnDeleteHandler = (preCampaign: PreCampaignDTO) => {
    const id = preCampaign?.id;

    if (id) {
      dispatch(deletePreCampaignById(id))
        .unwrap()
        .then((isDeleted) => {
          if (isDeleted) {
            dispatch(
              enqueueSnackbarAction({
                message: 'Booking was successfully deleted',
                options: { variant: 'success' },
              }),
            );
            dispatch(
              getUpdatedPreCampaignsByFilterAfterDeletingItem({
                itemsOnPage: preBookingsBundleData?.searchPage.items.length,
                page: 1,
              } as PreCampaignsFilterDTO),
            );
            dispatch(hideRightNavDrawer());
          }
        });
    }
  };

  const clickOnShowMoreHandler = () => {
    dispatch(getPreCampaignsByFilter(filter));
  };

  const clickOnBookingItemHandler = (preBookingId: string) => {
    dispatch(updateSelectedPreBookingId(preBookingId));
    dispatch(openRightNavDrawer('PreBookingAdditionalInfo'));
  };

  useEffect(() => {
    dispatch(getPreCampaignsByFilter(filter));

    return () => {
      dispatch(setInitialStateForPreBookingsBundleData());
      dispatch(hideRightNavDrawer());
    };
  }, []);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={15} sx={{ marginBottom: 20 }}>
        {preBookingItems?.map((preCampaignsItem, index) => {
          const { preCampaign, campaign, spot, store } = getRelatedDataToPreCampaign(
            preCampaignsItem.id,
            preBookingsBundleData,
          );
          const spotCityName = store?.address?.parts
            ? findPartInAddressObjByType(store.address.parts, 'locality')?.name
            : '';
          const isPreBookingWithBankAccount = Boolean(preCampaign?.bankAccount && preCampaign?.bankAccountId);
          const isVerifiedBankAccount = isPreBookingWithBankAccount && preCampaign.bankAccount.isVerified;
          const isAvailableRelatedCampaign = Boolean(campaign?.id);
          const preCampaignStatus = getPreBookingStatus(
            isVerifiedBankAccount,
            isPreBookingWithBankAccount,
            isAvailableRelatedCampaign,
          );
          const isWaitingBankAccountConfirmationStatus = preCampaignStatus && preCampaignStatus.id === 4;
          const ActionMenuComponent = (
            <ActionMenu
              items={getActionMenuItemsForPreCampaign(
                preCampaign,
                redirectToPersonalizedBookingHandler,
                clickOnDeleteHandler,
                clickOnApproveHandler,
                clickOnCopyBookingURLHandler,
                clickOnRejectHandler,
                isPreBookingWithBankAccount,
                isWaitingBankAccountConfirmationStatus,
              )}
            />
          );

          return (
            <Grid key={index} item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  padding: { xs: '12px 10px', md: '15px 20px' },
                  border: 'solid 1px black',
                  borderRadius: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => clickOnBookingItemHandler(preCampaign.id)}
              >
                <Grid container columns={16} columnSpacing={{ xs: 10, md: 15 }} rowSpacing={5} alignItems="center">
                  <Grid item xs={14} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                        {preCampaign?.brandName}
                      </Typography>
                      {preCampaign?.createdAt && (
                        <Typography variant="body1" sx={{ color: '#808080' }}>
                          Created on: {format(new Date(preCampaign.createdAt), 'dd/MM/yyyy')}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', textAlign: 'center' }}
                  >
                    <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                      Store
                    </Typography>
                    <Typography variant="body1">
                      {spot?.name}
                      {spotCityName && ', ' + spotCityName}
                    </Typography>
                  </Grid>

                  {preCampaign?.startDate && preCampaign?.endDate ? (
                    <Grid
                      item
                      md={4}
                      sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', textAlign: 'center' }}
                    >
                      <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                        Dates
                      </Typography>
                      <Typography variant="body1">
                        {getFormattedDatesRange(new Date(preCampaign.startDate), new Date(preCampaign.endDate))}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          width: '12px',
                          height: '12px',
                          flexShrink: '0',
                          marginRight: 5,
                          borderRadius: '20px',
                          backgroundColor: preCampaignStatus.color,
                        }}
                      ></Box>

                      <Typography variant="body1">{preCampaignStatus.name}</Typography>
                    </Box>
                  </Grid>

                  {isUpMdBreakPoint && (
                    <Grid item xs={12} md={1} sx={{ textAlign: 'right' }}>
                      {ActionMenuComponent}
                    </Grid>
                  )}
                </Grid>
                {!isUpMdBreakPoint ? ActionMenuComponent : null}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <BasicButton
            className="btn-large btn-yellow"
            variant="contained"
            onClick={() => clickOnShowMoreHandler()}
            disabled={preBookingItems ? preBookingItems.length >= preBookingsBundleData?.searchPage.totalCount : true}
          >
            <Typography variant="body1">View more</Typography>
          </BasicButton>
        </Grid>
      </Grid>
      <DashboardRightDrawer
        contentElements={[
          <PreBookingAdditionalInfo
            data-name={'PreBookingAdditionalInfo'}
            clickOnDeleteHandler={clickOnDeleteHandler}
            clickOnApproveHandler={clickOnApproveHandler}
            clickOnCopyBookingURLHandler={clickOnCopyBookingURLHandler}
            clickOnRejectHandler={clickOnRejectHandler}
            redirectToPersonalizedBookingHandler={redirectToPersonalizedBookingHandler}
          />,
        ]}
      />
    </>
  );
};
