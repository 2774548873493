import { Box } from '@mui/material';
import { styled } from '@mui/system';

import PinIconUrl from '../../../assets/icons/pin.svg';

interface StyledPinProps {
  lat: number;
  lng: number;
}

export const StyledPin = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'lat' && propName !== 'lng',
})<StyledPinProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '18px',
  height: '26px',
  transform: 'translate(-50%, -50%)',
  background: `url(${PinIconUrl}) center center no-repeat`,
  backgroundSize: 'cover',
}));
