import ApiRequestWrapper from 'api/api-request-wrapper';
import ApiUrlProvider from 'api/api-url-provider';
import AmenitiesDTO from 'models/amenities-dto';

export class AmenitiesService {
  private readonly basePath: string = '/amenities';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getAmenities(): ApiRequestWrapper<AmenitiesDTO[]> {
    return this.apiUrlProvider.get(this.basePath);
  }
}
