import { Box } from '@mui/material';
import { styled } from '@mui/system';

import mailIconUrl from '../../../assets/icons/mail.svg';
import phoneIconUrl from '../../../assets/icons/phone-2.svg';

interface StyledDrawerListProps {
  headerBreakpoint: number;
}

export const StyledDrawerList = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'headerBreakpoint',
})<StyledDrawerListProps>(({ theme, headerBreakpoint }) => ({
  '.MuiToolbar-root': {
    width: '77px',
    minHeight: 'initial',
    padding: 0,
    marginBottom: '50px',

    [theme.breakpoints.up(headerBreakpoint)]: {
      margin: '0 auto 50px auto',
    },
  },

  '.MuiListItem-root': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    padding: 0,

    '&.dashboard-list-item': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '6vh',
    },

    '&.contact-us-list-item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '12vh',

      [theme.breakpoints.up(headerBreakpoint)]: {
        alignItems: 'center',
      },

      '.MuiTypography-root': {
        color: theme.palette.common.white,

        [theme.breakpoints.up(headerBreakpoint)]: {
          marginBottom: '10px',
        },
      },

      '.phone-link, .email-link': {
        display: 'inline-block',
        width: '32px',
        height: '32px',

        [theme.breakpoints.up(headerBreakpoint)]: {
          display: 'block',
        },
      },

      '.phone-link': {
        background: `url(${phoneIconUrl}) center center no-repeat`,

        [theme.breakpoints.down(headerBreakpoint)]: {
          marginRight: '40px',
        },

        [theme.breakpoints.up(headerBreakpoint)]: {
          marginBottom: '20px',
        },
      },

      '.email-link': {
        background: `url(${mailIconUrl}) center center no-repeat`,
        backgroundSize: 'contain',
      },
    },

    '.nav-link': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '20px',
      textDecoration: 'none',
      color: theme.palette.common.white,

      '.icon-box': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
        height: '50px',
        borderRadius: '30px',
        marginRight: '10px',

        [theme.breakpoints.up(headerBreakpoint)]: {
          margin: '0 auto',
        },
      },

      '.link-text': {
        textTransform: 'uppercase',
        fontWeight: '700',
        display: 'block',

        [theme.breakpoints.up(headerBreakpoint)]: {
          display: 'none',
        },
      },

      '&.active': {
        color: theme.palette.secondary.main,

        '.icon-box': {
          backgroundColor: theme.palette.secondary.main,

          svg: {
            path: {
              fill: theme.palette.common.black,
            },
          },
        },
      },

      [theme.breakpoints.up(headerBreakpoint)]: {
        flexDirection: 'column',
      },

      '&--log-out': {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '100px',
        cursor: 'pointer',

        '.icon-box': {
          marginRight: '10px',

          [theme.breakpoints.up(headerBreakpoint)]: {
            width: '20px',
          },
        },

        '.link-text': {
          display: 'block',
          fontWeight: 400,
          textTransform: 'initial',
        },
      },
    },

    '&:last-of-type': {
      marginBottom: '0',
    },
  },
}));
