import { Palette } from '@mui/material/styles/createPalette';
import createTypography, { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { Property } from 'csstype';

import { defaultPalette } from './palette';

const BASE_FONT_FAMILY = "'Blatant', sans-serif";
const DEFAULT_LINE_HEIGHT = 1;
const HTML_FONT_SIZE = 16;

const baseFontFamily = {
  fontFamily: BASE_FONT_FAMILY,
};

type WT = (options?: TypographyStyleOptions) => TypographyStyleOptions;

const wrapTypography = (
  fontWeight: Property.FontWeight,
  fontSize: Property.FontSize<number> = HTML_FONT_SIZE,
  lineHeight: Property.LineHeight = DEFAULT_LINE_HEIGHT,
): WT => {
  return (options): TypographyStyleOptions => ({
    ...baseFontFamily,
    ...(options || {}),
    fontWeight,
    fontSize,
    lineHeight,
  });
};

const h1 = wrapTypography(700, 50);
const h2 = wrapTypography(700, 30);
const h3 = wrapTypography(700, 20);
const h5 = wrapTypography(700, 14);
const h6 = wrapTypography(700, 12);
const subtitle1 = wrapTypography(400, 30);
const subtitle2 = wrapTypography(400, 20);
const body1 = wrapTypography(400, 14);
const body2 = wrapTypography(400, 12);
const caption = wrapTypography(400, 14);
const button = wrapTypography(400, 14);
const overline = wrapTypography(400, 12);

const config: (palette: Palette) => TypographyOptions = (palette) => ({
  ...baseFontFamily,
  htmlFontSize: HTML_FONT_SIZE,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: h1(),
  h2: h2(),
  h3: h3(),
  h4: undefined,
  h5: h5(),
  h6: h6(),
  subtitle1: subtitle1(),
  subtitle2: subtitle2(),
  body1: body1(),
  body2: body2(),
  caption: caption(),
  button: button(),
  overline: overline(),
});

export const defaultTypography = createTypography(defaultPalette, config);
