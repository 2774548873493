import React from 'react';

import {
  FormControlLabel,
  FormGroup,
  Checkbox as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxProps,
} from '@mui/material';

import { ReactComponent as CheckboxIconChecked } from '../../../../assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxIcon } from '../../../../assets/icons/checkbox.svg';

export const Checkbox = ({ label, name, checked, changeHandler, formGroupStyles = {} }: CheckboxProps): JSX.Element => {
  return (
    <FormGroup sx={formGroupStyles}>
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        control={
          <MaterialCheckbox
            name={name}
            checked={checked}
            onChange={changeHandler}
            sx={{ padding: 0, marginRight: 5 }}
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxIconChecked />}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

interface CheckboxProps extends MaterialCheckboxProps {
  label: React.ReactElement;
  formGroupStyles?: object;
  changeHandler?: (e: any) => void;
}
