import {
  addDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfWeek,
  isWithinInterval,
  parseJSON,
  startOfWeek,
} from 'date-fns';

import { getDayFromDate } from '../../constants/enums/days-of-week';
import StoreWorkingDayDTO from '../../models/store/store-working-day-dto';

export function getDaysBetweenTwoDates(startDate: Date, endDate: Date, workingDays?: StoreWorkingDayDTO[]): number {
  if (startDate && endDate && workingDays?.length) {
    let fromDate = new Date(startDate);
    let toDate = new Date(endDate);
    let currentDate = new Date(fromDate);
    let rentalPeriod = 0;

    while (currentDate.setHours(0, 0, 0, 0) <= toDate.setHours(0, 0, 0, 0)) {
      let day = getDayFromDate(currentDate);

      if (workingDays.findIndex((x) => x.day === day) != -1) {
        rentalPeriod++;
      }

      currentDate = addDays(currentDate, 1);
    }

    return rentalPeriod;
  } else if (startDate && endDate) {
    const firstDateInSeconds = startDate.getTime();
    const secondDateInSeconds = endDate.getTime();
    const differenceBetweenDates = secondDateInSeconds - firstDateInSeconds;

    return Math.round(differenceBetweenDates / (1000 * 3600 * 24) + 1);
  } else {
    return 0;
  }
}

export function getDateRange(startDate: Date, endDate: Date, blockedDates?: Date[]): Array<Date> {
  const rangeConfig = { start: startDate, end: endDate };
  let range = eachDayOfInterval(rangeConfig);
  let isAvailableOverlapDateWithBlockedDates = blockedDates?.find((blockedDate) =>
    isWithinInterval(blockedDate.setHours(0, 0, 0, 0), rangeConfig),
  );

  return isAvailableOverlapDateWithBlockedDates ? [] : range;
}

export function calculateBlockedDates(
  blockedDates: { startDate: string; endDate: string }[],
  bookedDates: { startDate: string; endDate: string }[],
): Date[] | undefined {
  let resBlockedDates: Date[] | undefined;

  if (blockedDates && bookedDates) {
    resBlockedDates = [];
    if (blockedDates && blockedDates.length > 0) {
      for (let i = 0; i < blockedDates.length; i++) {
        let date = blockedDates[i];
        let startDate = parseJSON(date.startDate);
        let endDate = parseJSON(date.endDate);
        for (let now = startDate; now <= endDate; now = addDays(now, 1)) {
          resBlockedDates.push(new Date(now));
        }
      }
    }

    if (bookedDates && bookedDates.length > 0) {
      for (let i = 0; i < bookedDates.length; i++) {
        let date = bookedDates[i];
        let startDate = parseJSON(date.startDate);
        let endDate = parseJSON(date.endDate);
        for (let now = startDate; now <= endDate; now = addDays(now, 1)) {
          resBlockedDates.push(new Date(now));
        }
      }
    }
  }

  return resBlockedDates;
}

export function isSaturday(date: Date) {
  const day = getDayFromDate(date);
  return day === 5;
}

export function getStartOfTheWeek(dateOfWeek: Date, weekStartsDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 6): Date {
  return new Date(startOfWeek(dateOfWeek, { weekStartsOn: weekStartsDay }).setHours(0, 0, 0, 0));
}

export function getEndOfTheWeek(dateOfWeek: Date, weekStartsDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 6): Date {
  return new Date(endOfWeek(dateOfWeek, { weekStartsOn: weekStartsDay }).setHours(0, 0, 0, 0));
}

export function getEachWeekOfInterval(
  startDate: Date,
  endDate: Date,
  weekStartsDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 6,
): Date[] {
  return eachWeekOfInterval(
    {
      start: startDate,
      end: endDate,
    },
    { weekStartsOn: weekStartsDay },
  );
}

export function fixedOrderDate(date: Date) {
  let myTimeZone = new Date(Date.now()).getTimezoneOffset();
  let parsedDate = new Date(date).setMinutes(myTimeZone > 0 ? -myTimeZone : myTimeZone * -1);

  return new Date(parsedDate);
}
