import React, { useEffect } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { AUTH_TOKEN_KEY } from '../../../constants';
import { USER_ROLES } from '../../../constants/enums/user-roles';
import { selectAuthState } from '../../../features/authentication/authSlice';
import { Loader } from '../../../features/loader';
import { enqueueNotification as enqueueSnackbarAction } from '../../../features/notifier/notifierSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { MVCPageRedirection } from '../MVCPageRedirection';

interface PrivateRouteProps {
  children: JSX.Element;
  userRoles: Array<USER_ROLES>;
}

export const PrivateRoute = ({ children, userRoles }: PrivateRouteProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { authorizedUser, redirectAfterLogOut } = useAppSelector(selectAuthState);
  let { token, role } = authorizedUser;
  let userHasRequiredRole = role && userRoles.includes(role);
  let tokenFromLocalStorage = localStorage.getItem(AUTH_TOKEN_KEY);

  useEffect(() => {
    if (token && !userHasRequiredRole) {
      dispatch(
        enqueueSnackbarAction({
          message: "You don't have permission",
          options: { variant: 'error' },
        }),
      );
    }
  });

  if (token && userHasRequiredRole) {
    return children;
  }

  if (token && !userHasRequiredRole) {
    return <Navigate to="/log-in" />;
  }

  if (!token && tokenFromLocalStorage) {
    //in this case user will fetch by the useRedirectByUserRole hook
    return <Loader />;
  }

  if (!token && redirectAfterLogOut) {
    return <MVCPageRedirection redirectUrl={'/'} />;
  }

  return <Navigate to="/log-in" state={{ from: { path: location.pathname, search: location.search } }} />;
};
