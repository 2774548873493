import React, { useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import { enqueueNotification as enqueueSnackbarAction } from '../../../features/notifier/notifierSlice';
import { UploadedFileInfoDTO } from '../../../models/ui/uploaded-file-info-dto';
import { fileUploaderHelper } from '../../helpers/file-uploader-helper';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { StyledDropzone } from './styles';

export const Dropzone = ({ maxFilesProp, selectedFiles, setSelectedFiles }: DropzoneProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const onDropRejected = useCallback(() => {
    dispatch(
      enqueueSnackbarAction({
        message: 'File should be in the following formats: .jpg, .jpeg, .png',
        options: { variant: 'error' },
      }),
    );
  }, []);

  const onDropAccepted = useCallback(
    async (files: Array<any>) => {
      const uploadedFilesInBase64 = await fileUploaderHelper(files);
      const fileInfoArr: UploadedFileInfoDTO[] = files.map((file: File) => {
        return {
          name: file.name,
          type: file.type,
        };
      });

      uploadedFilesInBase64.length && fileInfoArr.length ? setSelectedFiles(uploadedFilesInBase64, fileInfoArr) : false;
    },
    [setSelectedFiles],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpg, .jpeg, .png',
    maxFiles: maxFilesProp,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <StyledDropzone>
      <Box {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
          Drag and drop here or{' '}
          <Typography variant="caption" sx={{ typography: { xs: 'h6', md: 'h5' }, textDecoration: 'underline' }}>
            Upload file
          </Typography>
        </Typography>
        <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' }, opacity: '0.5' }}>
          .jpg .jpeg .png
        </Typography>
        {selectedFiles.length ? (
          <Box>
            <Typography variant="body1" sx={{ typography: { xs: 'h6', md: 'h5' }, marginTop: 5, marginBottom: 5 }}>
              Files
            </Typography>

            {selectedFiles.map((file: any, key: number) => {
              return (
                <Typography key={key} variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' }, opacity: '0.5' }}>
                  {file.name}
                </Typography>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </StyledDropzone>
  );
};

interface DropzoneProps {
  maxFilesProp: number;
  selectedFiles: UploadedFileInfoDTO[];
  setSelectedFiles: (fileInfoArr: string[], file: UploadedFileInfoDTO[]) => void;
}
