import React from 'react';

import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ReactComponent as AmexIcon } from '../../../assets/icons/american-express.svg';
import { ReactComponent as MasterCardIcon } from '../../../assets/icons/master-card-icon.svg';
import { ReactComponent as VisaIcon } from '../../../assets/icons/visa-icon.svg';
import { StyledDefaultCreditCardBlock } from './styles';

export const DefaultCreditCardBlock = ({
  type,
  lastDigit,
  expiredDate,
  sx = {},
}: DefaultCreditCardBlockProps): JSX.Element => {
  return (
    <StyledDefaultCreditCardBlock sx={sx}>
      <Box className="credit-card-icon-row">
        {type === 'visa' && <VisaIcon />}
        {type === 'mastercard' && <MasterCardIcon />}
        {type === 'amex' && <AmexIcon />}
      </Box>

      <Box>
        <Typography className="last-digit-row" variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
          XXXX XXXX XXXX {lastDigit}
        </Typography>

        <Box className="expired-date-row">
          <Typography variant="body1" sx={{ marginRight: 10 }}>
            {expiredDate && format(new Date(expiredDate), 'dd/MM/yy')}
          </Typography>
          <Typography variant="body1">CVV XXX</Typography>
        </Box>
      </Box>
    </StyledDefaultCreditCardBlock>
  );
};

interface DefaultCreditCardBlockProps {
  type: string;
  lastDigit: string;
  expiredDate: string;
  sx?: object;
}
