import LanguageDTO from '../../models/language-dto';

export enum LANGUAGE {
  English = 0,
  Hebrew = 1,
  Hungary = 2,
}

export const getAllLanguages = () => {
  return [
    {
      id: LANGUAGE.English,
      name: 'English',
    },
    {
      id: LANGUAGE.Hebrew,
      name: 'Hebrew',
    },
    {
      id: LANGUAGE.Hungary,
      name: 'Hungary',
    },
  ];
};

export const getDefaultLanguage = () => {
  let value = getAllLanguages().find((x) => x.id === LANGUAGE.English);

  if (value === undefined) {
    throw new TypeError('Cant find default currency');
  }

  return value;
};

export const getLanguageById = (language: LANGUAGE | undefined): LanguageDTO => {
  return getAllLanguages().find((x: any) => x.id == language) || getDefaultLanguage();
};
