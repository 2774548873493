import SpotsPreCampaignFilterDTO from '../../models/spot/spots-pre-campaign-filter-dto';

export const findStoreBySpotIdInPreCampaign = (
  selectedSpotId: number,
  preCampaignFilter: SpotsPreCampaignFilterDTO,
) => {
  let selectedStoreId: number | undefined = preCampaignFilter?.spots.find(
    (spot) => spot.id === selectedSpotId,
  )?.storeId;

  return preCampaignFilter?.stores.find((store) => store.id === selectedStoreId);
};

export const findSpotByIdInPreCampaign = (selectedSpotId: number, preCampaignFilter: SpotsPreCampaignFilterDTO) => {
  return preCampaignFilter?.spots.find((x) => x.id === selectedSpotId);
};
