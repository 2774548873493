import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { Checkbox } from 'common/components/basic/Checkbox';
import { BasicInput } from 'common/components/basic/Input';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { getFurniture, selectFurnitureState } from 'features/furniture/furnitureSlice';
import FurnituresDTO from 'models/furnitures-dto';
import SpotFurnitureDTO from 'models/spot/spot-furniture-dto';

export const DashboardFurnitureList = ({ selectedItems, handler }: FurnitureListDashboardProps): JSX.Element => {
  const { furnitureList } = useAppSelector(selectFurnitureState);
  const dispatch = useAppDispatch();
  const initialState = selectedItems ? selectedItems : ([] as Array<SpotFurnitureDTO>);
  const [spotFurniture, setSpotFurniture] = useState<Array<SpotFurnitureDTO>>(initialState);

  const changeCountOfFurnitureHandler = (e: any) => {
    const parsedName = parseInt(e.target.name);
    const parsedValue = parseInt(e.target.value);

    setSpotFurniture((existingSpotFurnitureItems) => {
      const itemIndex = existingSpotFurnitureItems.findIndex((item) => item.furnitureId === parsedName);
      return itemIndex !== -1
        ? [
            ...existingSpotFurnitureItems.slice(0, itemIndex),
            {
              ...existingSpotFurnitureItems[itemIndex],
              count: parsedValue,
            },
            ...existingSpotFurnitureItems.slice(itemIndex + 1),
          ]
        : [...existingSpotFurnitureItems, { furnitureId: parsedName, count: parsedValue }];
    });
  };

  const handleCheckboxChange = (e: any, furnitureItem: FurnituresDTO) => {
    const selectedSpotFurnitureItem = spotFurniture.find(
      (spotFurnitureItem) => spotFurnitureItem.furnitureId === furnitureItem.id,
    );

    if (e.target.checked) {
      if (selectedSpotFurnitureItem) {
        handler([...spotFurniture, selectedSpotFurnitureItem]);
      } else {
        handler([...spotFurniture, { furnitureId: furnitureItem.id, count: 1 }]);
      }
    } else {
      const itemIndex = spotFurniture.findIndex((item) => item.furnitureId === furnitureItem.id);

      handler([...spotFurniture.slice(0, itemIndex), ...spotFurniture.slice(itemIndex + 1)]);
    }
  };

  useEffect(() => {
    !furnitureList.length && dispatch(getFurniture());
  }, []);

  useEffect(() => {
    selectedItems ? setSpotFurniture(selectedItems) : setSpotFurniture(initialState);
  }, [selectedItems]);

  return (
    <Grid item container spacing={10}>
      {furnitureList?.map((furnitureItem: FurnituresDTO, index: number) => {
        const selectedSpotFurniture = spotFurniture?.find(
          (spotFurnitureItem) => spotFurnitureItem.furnitureId === furnitureItem.id,
        );

        return (
          <Grid
            item
            xs={7}
            md={4}
            key={index}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Checkbox
              formGroupStyles={{ flexShrink: '0' }}
              checked={!!selectedSpotFurniture}
              label={
                <Typography
                  sx={{
                    typography: {
                      xs: 'body2',
                      md: 'body1',
                    },
                  }}
                >
                  {furnitureItem.name}
                </Typography>
              }
              changeHandler={(e: any) => {
                handleCheckboxChange(e, furnitureItem);
              }}
            />

            <BasicInput
              value={''}
              formControlProps={{ sx: { maxWidth: '70px', marginRight: '10px' } }}
              inputProps={{
                value: selectedSpotFurniture?.count ? selectedSpotFurniture.count : '',
                name: furnitureItem.id,
                type: 'number',
                placeholder: 'Count',
                onChange: changeCountOfFurnitureHandler,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

interface FurnitureListDashboardProps {
  selectedItems: SpotFurnitureDTO[];
  handler: (spotFurniture: SpotFurnitureDTO[]) => void;
}
