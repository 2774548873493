import React from 'react';

import { ContainerProps } from '@mui/material';

import { StyledDashboardBasicContainer, StyledDashboardBasicContainerProps } from './styles';

export const DashboardBasicContainer = ({
  maxWidthInPixels = '1170px',
  children,
  disableGutters = true,
}: ContainerProps & StyledDashboardBasicContainerProps): JSX.Element => {
  return (
    <StyledDashboardBasicContainer maxWidthInPixels={maxWidthInPixels} disableGutters={disableGutters}>
      {children}
    </StyledDashboardBasicContainer>
  );
};
