import { camelCase, isPlainObject } from 'lodash';

export const camelizeKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const replaceUnicodeSpaceCharacters = (value: string) => {
  // eslint-disable-next-line no-irregular-whitespace
  return value.replace(/[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000\u2028]/g, '');
};

export const isNotEmptyObject = (obj: Record<any, any>): boolean => {
  return !(obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype);
};

export const isNotUndefinedOrNull = (element: string | number | undefined | null): boolean => {
  return element !== undefined && element !== 'undefined' && element !== null && element !== 'null';
};

export const isString = (element: any): boolean => {
  return typeof element === 'string';
};

export const isStringWithNumbers = (value: string) => {
  return value.match(/^\d+$/);
};
