import React from 'react';

import { DashboardMainTemplate } from 'common/components/dashboard/MainTemplate';
import { NotFound } from 'common/components/NotFound';
import { Billing } from 'features/manager-dashboard/billing';
import { BookingsPage } from 'features/manager-dashboard/bookings';
import { Brands } from 'features/manager-dashboard/brands';
import { CreatePersonalizedBooking } from 'features/manager-dashboard/create-personalized-booking';
import { EditSpot } from 'features/manager-dashboard/edit-spot';
import { EditStore } from 'features/manager-dashboard/edit-store';
import { Home } from 'features/manager-dashboard/home';
import { NewSpot } from 'features/manager-dashboard/new-spot';
import { NewStore } from 'features/manager-dashboard/new-store';
import { Profile } from 'features/manager-dashboard/profile';
import { Route, Routes } from 'react-router-dom';

export const ManagerDashboard = (): JSX.Element => {
  return (
    <DashboardMainTemplate>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="personalized-booking" element={<CreatePersonalizedBooking />} />
        <Route path="new-store" element={<NewStore />} />
        <Route path="new-spot" element={<NewSpot />} />
        <Route path="edit-spot/:storeId/:spotId" element={<EditSpot />} />
        <Route path="edit-store/:storeId" element={<EditStore />} />
        <Route path="bookings" element={<BookingsPage />} />
        <Route path="brands" element={<Brands />} />
        <Route path="billing" element={<Billing />} />
        <Route path="profile" element={<Profile />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </DashboardMainTemplate>
  );
};
