import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface StyledBookNowFixed {
  isClosedBookNowFixedBlock: boolean;
}

interface StyledRangeBoxProps {
  isShowCalendarBlock: boolean;
}

export const StyledBookNowFixed = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isClosedBookNowFixedBlock',
})<StyledBookNowFixed>(({ theme, isClosedBookNowFixedBlock }) => ({
  display: isClosedBookNowFixedBlock ? 'none' : 'block',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: '999',
  width: '100%',
  padding: '16px',
  backgroundColor: theme.palette.common.white,
  borderTop: '2px solid black',
}));

export const StyledRangeBox = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isShowCalendarBlock',
})<StyledRangeBoxProps>(({ theme, isShowCalendarBlock }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 14px',
  transition: '500ms',
  border: `solid 1px ${theme.palette.common.white}`,
  borderRadius: isShowCalendarBlock ? 'none' : '20px',

  [theme.breakpoints.up('sm')]: {
    padding: '8px 50px',
  },
}));
