import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { AUTH_TOKEN_KEY } from '../../constants';
import { CURRENCY, getDefault as getDefaultCurrency } from '../../constants/enums/currency';
import UserAuthDTO from '../../models/user/user-auth-dto';
import UserEditDTO from '../../models/user/user-edit-dto';
import UserExternalSignInDTO from '../../models/user/user-external-sign-in-dto';
import UserLogInDTO from '../../models/user/user-log-in-dto';
import UserRecoveryDTO from '../../models/user/user-recovery-dto';
import UserRecoveryRequestDTO from '../../models/user/user-recovery-request-dto';
import UserRegisterAsBrandDTO from '../../models/user/user-register-as-brand';
import AuthService from '../../services/auth';
import UserService from '../../services/user';

interface initialAuthStateTypes {
  authorizedUser: UserAuthDTO;
  redirectAfterLogOut: boolean;
}

const initialAuthState = {
  authorizedUser: {
    currency: getDefaultCurrency(),
  },
  redirectAfterLogOut: false,
} as initialAuthStateTypes;

const authService: AuthService = new AuthService();
const userService: UserService = new UserService();

export const userLogIn = createAsyncThunk('userLogIn', async (logInUser: UserLogInDTO) => {
  return await authService.userLogIn(logInUser).execute();
});

export const changeUserCurrency = createAsyncThunk('changeUserCurrency', async (currency: CURRENCY) => {
  return await userService.changeCurrency(currency).execute();
});

export const createUserAsBrand = createAsyncThunk('createUserAsBrand', async (registerUser: UserRegisterAsBrandDTO) => {
  return await authService.createUserAsBrand(registerUser).execute();
});

export const passwordRecoveryRequest = createAsyncThunk(
  'passwordRecoveryRequest',
  async (email: UserRecoveryRequestDTO) => {
    return await authService.passwordRecoveryRequest(email).execute();
  },
);

export const passwordRecovery = createAsyncThunk('passwordRecovery', async (userRecovery: UserRecoveryDTO) => {
  return await authService.passwordRecovery(userRecovery).execute();
});

export const externalSignIn = createAsyncThunk('externalSignIn', async (userExternalSignIn: UserExternalSignInDTO) => {
  return await authService.externalSignIn(userExternalSignIn).execute();
});

export const emailVerify = createAsyncThunk(
  'emailVerify',
  async ({ userId, token }: { userId: string; token: string }) => {
    return await authService.emailVerify(userId, token).execute();
  },
);

export const getCurrentUserByToken = createAsyncThunk('getCurrentUserByToken', async () => {
  return await userService.getCurrentUserByToken().execute();
});

export const updateCurrentUser = createAsyncThunk('updateCurrentUser', async (userEditData: UserEditDTO) => {
  return await userService.updateCurrentUser(userEditData).execute();
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    logOut: (state) => {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      state.redirectAfterLogOut = true;
      state.authorizedUser = initialAuthState.authorizedUser;
    },
  },
  extraReducers(builder) {
    builder.addCase(updateCurrentUser.fulfilled, (state, action) => {
      state.authorizedUser = {
        ...state.authorizedUser,
        ...action.payload,
      };
    });
    builder.addCase(changeUserCurrency.fulfilled, (state, action) => {
      state.authorizedUser.currency = action.payload.currency;
    });
    builder.addCase(getCurrentUserByToken.fulfilled, (state, action) => {
      const token: string | null = localStorage.getItem(AUTH_TOKEN_KEY) || null;

      if (action.payload && token) {
        state.authorizedUser = {
          ...action.payload,
          token: token,
        };
      }
    });
    builder.addCase(getCurrentUserByToken.rejected, (state) => {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      state.authorizedUser = initialAuthState.authorizedUser;
    });
    builder.addMatcher(
      isAnyOf(
        userLogIn.fulfilled,
        createUserAsBrand.fulfilled,
        passwordRecovery.fulfilled,
        externalSignIn.fulfilled,
        emailVerify.fulfilled,
      ),
      (state, action) => {
        if (action.payload) {
          state.redirectAfterLogOut = false;
          localStorage.setItem(AUTH_TOKEN_KEY, action.payload.token);
          state.authorizedUser = action.payload;
        }
      },
    );
  },
});

export const selectAuthState = function (state: RootState): any {
  return state.auth;
};

export const { logOut } = authSlice.actions;

export default authSlice.reducer;
