import React from 'react';

import { Box, SvgIcon } from '@mui/material';

import { ReactComponent as InfoIcon12px } from '../../../assets/icons/info12px.svg';
import { ReactComponent as InfoIcon16px } from '../../../assets/icons/info16px.svg';
import { Tooltip } from '../Tooltip';

export const TooltipInfoIcon = ({
  children,
  tooltipTitle,
  iconSize16px = false,
  containerStyles = {},
  tooltipStyles = {},
  boxClassName,
}: TooltipInfoIconProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const isDeviceWithTouchScreen = 'ontouchstart' in window;

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const infoIcon = (
    <SvgIcon
      sx={{
        width: iconSize16px ? '16px' : '12px',
        height: iconSize16px ? '16px' : '12px',
        marginLeft: 2,
        marginTop: iconSize16px ? -7 : -2,
        color: 'transparent',
      }}
      component={iconSize16px ? InfoIcon16px : InfoIcon12px}
      viewBox={`0 0 ${iconSize16px ? '16 16' : '12 12'}`}
    />
  );

  return (
    <Box
      className={boxClassName}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
        },
        containerStyles,
      ]}
    >
      {children}

      <Tooltip
        {...(isDeviceWithTouchScreen && { open: open })}
        {...(isDeviceWithTouchScreen && { disableHoverListener: true })}
        disableFocusListener
        disableTouchListener
        onClose={handleTooltipClose}
        onClick={isDeviceWithTouchScreen ? () => setOpen(!open) : handleTooltipOpen}
        placement="bottom-start"
        sx={[{ maxWidth: '245px' }, tooltipStyles]}
        title={tooltipTitle}
      >
        {infoIcon}
      </Tooltip>
    </Box>
  );
};

interface TooltipInfoIconProps {
  children: string | JSX.Element | JSX.Element[];
  tooltipTitle: string | JSX.Element | JSX.Element[];
  iconSize16px?: boolean;
  containerStyles?: object;
  tooltipStyles?: object;
  boxClassName?: string;
}
