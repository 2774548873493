import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import BrandDTO from '../models/brand/brand-dto';
import BrandEditDTO from '../models/brand/brand-edit-dto';

export class BrandService {
  private readonly basePath: string = 'brand';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getCurrentBrand(): ApiRequestWrapper<BrandDTO> {
    return this.apiUrlProvider.get(this.basePath + '/current');
  }

  public updateCurrentBrand(brandEditData: BrandEditDTO): ApiRequestWrapper<BrandDTO> {
    return this.apiUrlProvider.put(this.basePath + '/current', brandEditData);
  }
}
