import React, { useState } from 'react';

import { Avatar, Box, Grid, Link, SvgIcon, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import managerAvatarUrl from '../../../assets/images/manager-avatar.jpeg';
import { BasicButton } from '../../../common/components/basic/Button';
import { BoxWithBackground } from '../../../common/components/BoxWithBackground';
import { CampaignCancellationDialog } from '../../../common/components/Dialogs/CampaignCancellationDialog/CampaignCancellationDialog';
import { FurnitureList } from '../../../common/components/FurnitureList';
import { GoogleMap } from '../../../common/components/GoogleMap';
import { GoogleMapPin } from '../../../common/components/GoogleMapPin';
import { findPartInAddressObjByType } from '../../../common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { CampaignStatus } from '../../../constants/enums/campaign/campaign-status';
import CampaignDTO from '../../../models/campaign/campaign-dto';
import SpotDTO from '../../../models/spot/spot-dto';
import StoreDTO from '../../../models/store/store-dto';
import { hideRightNavDrawer } from '../../drawer/drawerSlice';
import { GuidelineList } from '../../manager-dashboard/create-personalized-booking/GuidelineList';
import { canselCampaignById, selectBrandDashboardState } from '../brandDashboardSlice';

export const CampaignAdditionalInfo = ({ selectedCampaign }: CampaignAdditionalInfoProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isShowCampaignCancellationDialog, setIsShowCampaignCancellationDialog] = useState(false);
  const { allSpots, allStores } = useAppSelector(selectBrandDashboardState);
  const selectedSpot = allSpots?.find((spot: SpotDTO) => spot.id === selectedCampaign?.spotId);
  const selectedStore = allStores?.find((store: StoreDTO) => store.id === selectedSpot?.storeId);
  const beforeCheckInGuidelineList = JSON.stringify([
    'Be sure to have all your stuff ready for your Pop-up',
    '24hs prior to the Pop-up, you’ll get the Code for the Key of the spot',
  ]);
  const duringCheckOutGuidelineList = JSON.stringify([
    'Make sure everything is in good condition',
    'Contact our support team for any inquiries',
    '24hs before ending the Pop-up, you’ll get the instructions to leave the spot',
  ]);
  const spotCoordinates =
    selectedStore?.address?.lat && selectedStore?.address?.lng
      ? {
          lat: selectedStore.address.lat,
          lng: selectedStore.address.lng,
        }
      : undefined;
  const spotCityName = selectedStore?.address?.parts
    ? findPartInAddressObjByType(selectedStore.address.parts, 'locality')?.name
    : '';
  const isUpcomingCampaign =
    selectedCampaign &&
    [
      CampaignStatus.Pending,
      CampaignStatus.Approved,
      CampaignStatus.WaitingForPaid,
      CampaignStatus.ReadyToStart,
      CampaignStatus.Modified,
    ].includes(selectedCampaign?.status);

  const cancellationHandler = () => {
    if (selectedCampaign?.id) {
      dispatch(canselCampaignById(selectedCampaign.id));
      dispatch(hideRightNavDrawer());
    }
  };

  const refuseCancellationHandler = () => {
    setIsShowCampaignCancellationDialog(false);
    dispatch(hideRightNavDrawer());
  };

  const clickOnCancelBooking = () => {
    setIsShowCampaignCancellationDialog(true);
  };

  return (
    <Box sx={{ marginTop: 15 }}>
      <Grid container alignItems="center" rowSpacing={{ xs: 5, sm: 0 }} sx={{ marginBottom: 15 }}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
            {selectedSpot?.name}
          </Typography>
          {spotCityName && <Typography variant="subtitle2">{spotCityName}</Typography>}
        </Grid>

        <Grid item xs={12} sm={4}>
          <Link
            component="button"
            type="button"
            variant="body1"
            color="inherit"
            underline="always"
            onClick={() => {
              navigate(`/booking/spots/${selectedSpot.id}`);
              dispatch(hideRightNavDrawer());
            }}
          >
            Go to Pop-up page
          </Link>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 5, paddingBottom: { xs: 7, md: 5 } }}>
          <Typography variant="h3" sx={{ typography: { xs: 'h5', md: 'h3' }, mr: 4 }}>
            Check in:
          </Typography>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            {selectedCampaign?.startDate && format(new Date(selectedCampaign.startDate), 'dd/MM/yyyy')}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: { xs: 12, md: 5 } }}>
          <Typography variant="h3" sx={{ typography: { xs: 'h5', md: 'h3' }, mr: 4 }}>
            Check out:
          </Typography>
          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            {selectedCampaign?.endDate && format(new Date(selectedCampaign.endDate), 'dd/MM/yyyy')}{' '}
          </Typography>
        </Box>
      </Box>

      {selectedSpot?.furnitures.length ? (
        <Box sx={{ marginBottom: 15 }}>
          <FurnitureList spotFurnitureItems={selectedSpot?.furnitures} gridSpacing={5} />
        </Box>
      ) : null}

      {spotCoordinates?.lat && spotCoordinates?.lng ? (
        <>
          <Typography variant="h3" sx={{ marginBottom: 5 }}>
            Location
          </Typography>

          <Box sx={{ width: '100%', height: '210px', borderRadius: '10px', overflow: 'hidden' }}>
            <GoogleMap center={spotCoordinates} defaultZoom={14}>
              <GoogleMapPin lat={spotCoordinates.lat} lng={spotCoordinates.lng} />
            </GoogleMap>
          </Box>
        </>
      ) : null}

      <BoxWithBackground
        className="full-width"
        sx={{ paddingX: 15, paddingY: 10, marginTop: 15, marginBottom: 15, textAlign: 'center' }}
      >
        <Typography variant="h5" sx={{ marginBottom: 5 }}>
          Contact our community manager of {selectedSpot?.name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Avatar
            sx={{ width: '52px', height: '52px', marginRight: '10px', border: 'solid 2px white' }}
            src={managerAvatarUrl}
            alt="manager avatars"
          />
          <Typography sx={{ typography: { xs: 'h3', md: 'h5' }, marginRight: '28px' }}>
            {selectedStore?.contactName}
          </Typography>
          <Link
            href={`https://wa.me/${selectedStore?.contactPhoneNumber && selectedStore.contactPhoneNumber}`}
            target="_blank"
            rel="noreferrer"
          >
            <SvgIcon
              viewBox={'0 0 40 40'}
              component={PhoneIcon}
              sx={{ marginRight: 13, width: '36px', height: '36px' }}
            />
          </Link>
          <Link href={`mailto:${selectedStore?.contactEmail && selectedStore.contactEmail}`}>
            <SvgIcon viewBox={'0 0 51 40'} component={MailIcon} sx={{ width: '45px', height: '36px' }} />
          </Link>
        </Box>
      </BoxWithBackground>

      <GuidelineList
        sx={{ '.title': { fontSize: '20px' } }}
        title="Before your Pop-up - Check in"
        guidelines={beforeCheckInGuidelineList}
        guidelineInputProps={{
          id: 'before-check-in',
          name: 'beforeCheckIn',
          placeholder: 'Add a guideline',
        }}
      />

      <GuidelineList
        sx={{ '.title': { fontSize: '20px' }, marginBottom: 25 }}
        title="During your Pop-up - Check out"
        guidelines={duringCheckOutGuidelineList}
        guidelineInputProps={{
          id: 'during-check-out',
          name: 'duringCheckOut',
          placeholder: 'Add a guideline',
        }}
      ></GuidelineList>

      {isUpcomingCampaign ? (
        <Box sx={{ textAlign: 'center' }}>
          <BasicButton
            className="btn-large"
            sx={{ minWidth: '165px', marginBottom: 5 }}
            variant="outlined"
            type="button"
            onClick={clickOnCancelBooking}
          >
            Cancel this REPOP
          </BasicButton>
          <Typography variant="body2" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            *Conditions may apply
          </Typography>
        </Box>
      ) : null}

      <CampaignCancellationDialog
        dialogProps={{
          open: isShowCampaignCancellationDialog,
          onClose: () => {
            setIsShowCampaignCancellationDialog(false);
          },
          fullWidth: true,
          PaperProps: { sx: { maxWidth: '800px' } },
        }}
        campaignStartStr={selectedCampaign?.startDate}
        refuseCancellationHandler={refuseCancellationHandler}
        cancellationHandler={cancellationHandler}
      />
    </Box>
  );
};

interface CampaignAdditionalInfoProps {
  selectedCampaign: CampaignDTO | undefined;
}
