import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { DashboardLeftNavDrawer } from 'common/components/dashboard/LeftNavDrawer';
import { Header } from 'common/components/Header';

export const DashboardMainTemplate = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <DashboardLeftNavDrawer />
        <StyledMainTag>{children}</StyledMainTag>
      </Box>
    </>
  );
};

const StyledMainTag = styled('main')(({ theme }) => ({
  width: '100%',
  minHeight: 'calc(100vh)',

  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(20)} ${theme.spacing(8)} ${theme.spacing(5)}`,
  },

  [theme.breakpoints.up('sm')]: {
    minHeight: `calc(100vh - ${theme.spacing(6)})`,
    paddingTop: `${theme.spacing(15)}`,
    paddingBottom: `${theme.spacing(15)}`,
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
  },
}));
