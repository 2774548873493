import React from 'react';

import { Button, ButtonProps } from '@mui/material';

import { basicButtonStyles } from './styles';

export const BasicButton = ({
  sx = {},
  buttonRef,
  className = '',
  variant = 'outlined',
  type = 'button',
  children,
  disabled = false,
  onClick,
  fullWidth,
}: BasicButtonProps): JSX.Element => {
  return (
    <Button
      ref={buttonRef}
      disabled={disabled}
      className={className}
      sx={[basicButtonStyles, { ...sx }]}
      variant={variant}
      type={type}
      onClick={onClick}
      fullWidth={fullWidth}
    >
      {children && children}
    </Button>
  );
};

interface BasicButtonProps extends ButtonProps {
  sx?: object;
  className?: string;
  buttonRef?: any;
}
