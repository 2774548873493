import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import CampaignDTO from '../models/campaign/campaign-dto';
import CampaignEditDTO from '../models/campaign/campaign-edit-dto';

export class CampaignBrandService {
  private readonly basePath: string = 'brand/campaigns';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public createCampaign(campaignEditDto: CampaignEditDTO): ApiRequestWrapper<CampaignDTO> {
    return this.apiUrlProvider.post(this.basePath, campaignEditDto);
  }

  public getCampaigns(): ApiRequestWrapper<CampaignDTO[]> {
    return this.apiUrlProvider.get(this.basePath);
  }

  public canselCampaignById(id: number): ApiRequestWrapper<CampaignDTO> {
    return this.apiUrlProvider.delete(this.basePath + `/${id}/cancle`);
  }
}
