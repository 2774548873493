import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import AmenitiesDTO from 'models/amenities-dto';
import { AmenitiesService } from 'services/amenities';

interface initialAuthStateTypes {
  amenitiesList: AmenitiesDTO[];
}

const initialAuthState = {
  amenitiesList: [],
} as initialAuthStateTypes;

const amenitiesService: AmenitiesService = new AmenitiesService();

export const getAmenities = createAsyncThunk('getAmenities', async () => {
  return await amenitiesService.getAmenities().execute();
});

export const amenitiesSlice = createSlice({
  name: 'amenities',
  initialState: initialAuthState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAmenities.fulfilled, (state, action) => {
      state.amenitiesList = action.payload;
    });
  },
});

export const selectAmenitiesState = function (state: RootState): any {
  return state.amenities;
};

export default amenitiesSlice.reducer;
