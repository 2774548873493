import React, { useEffect, useState } from 'react';

import { Box, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';

import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { BasicButton } from '../../../common/components/basic/Button';
import { DashboardBasicContainer } from '../../../common/components/dashboard/BasicContainer';
import { DashboardCaption } from '../../../common/components/dashboard/Caption';
import { getFormattedDatesRange, getFormattedPrice } from '../../../common/helpers/formatters';
import { findPartInAddressObjByType } from '../../../common/helpers/google-heplers';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { getById as getCurrencyById } from '../../../constants/enums/currency';
import { getAllPaymentTransactionStatuses } from '../../../constants/enums/payment-transaction-status';
import CampaignDTO from '../../../models/campaign/campaign-dto';
import PaymentTransactionDTO from '../../../models/payment-transactions/payment-transaction-dto';
import SpotDTO from '../../../models/spot/spot-dto';
import StoreDTO from '../../../models/store/store-dto';
import theme from '../../../theme-config/theme';
import { getPaymentTransactionsByFilterBrand, selectBrandDashboardState } from '../brandDashboardSlice';
import { setInitialStateForPaymentTransactionsFilterResult } from '../brandDashboardSlice';

export const Billing = (): JSX.Element => {
  const initialPageState = {
    campaignId: null,
    paymentMethodId: null,
    brandId: null,
    text: '',
    itemsOnPage: 10,
    page: 1,
  };
  const [pageFilterState, setFilterPageState] = useState(initialPageState);
  const dispatch = useAppDispatch();
  const { paymentTransactionsFilterResult } = useAppSelector(selectBrandDashboardState);
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));

  const clickOnViewMoreBtnHandler = () => {
    const nextPage = pageFilterState.page + 1;
    setFilterPageState({ ...pageFilterState, page: nextPage });
  };

  useEffect(() => {
    return () => {
      dispatch(setInitialStateForPaymentTransactionsFilterResult());
    };
  }, []);

  useEffect(() => {
    dispatch(getPaymentTransactionsByFilterBrand(pageFilterState));
  }, [pageFilterState]);

  return (
    <>
      <DashboardCaption>Billing</DashboardCaption>

      <DashboardBasicContainer>
        <Grid container rowSpacing={2} columnSpacing={15} sx={{ marginBottom: 20 }}>
          {paymentTransactionsFilterResult?.searchPage?.items.map(
            (paymentTransaction: PaymentTransactionDTO, index: number) => {
              const currency = getCurrencyById(paymentTransaction.currency);
              const parsedStatus = getAllPaymentTransactionStatuses().find((x) => x.id == paymentTransaction?.status);
              const campaign = paymentTransactionsFilterResult?.campaigns.find(
                (campaign: CampaignDTO) => campaign.id === paymentTransaction?.campaignId,
              );
              const spot = paymentTransactionsFilterResult?.spots.find((spot: SpotDTO) => spot.id == campaign?.spotId);
              const store = paymentTransactionsFilterResult?.stores.find(
                (store: StoreDTO) => store.id == spot?.storeId,
              );
              const spotCityName = store?.address?.parts
                ? findPartInAddressObjByType(store.address.parts, 'locality')?.name
                : '';

              return (
                <Grid key={index} item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: { xs: '12px 10px', md: '15px 20px' },
                      border: 'solid 1px black',
                      borderRadius: '20px',
                    }}
                  >
                    <Grid container columns={16} columnSpacing={{ xs: 10, md: 6 }} rowSpacing={5} alignItems="center">
                      <Grid item xs={8} md={2} sx={{ flexDirection: 'column', textAlign: 'center' }}>
                        <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                          Date
                        </Typography>
                        <Typography variant="body1">
                          {format(new Date(paymentTransaction.createdAt), 'dd/MM/yyyy')}
                        </Typography>
                      </Grid>

                      <Grid item xs={8} md={3} sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                          Total payment
                        </Typography>
                        <Typography variant="body1">
                          {getFormattedPrice(paymentTransaction.cost, currency.symbol, true)}
                        </Typography>
                      </Grid>

                      <Grid item xs={8} md={2} sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                          Type
                        </Typography>
                        <Typography variant="body1">Credit card</Typography>
                      </Grid>

                      <Grid item xs={8} md={3} sx={{ flexDirection: 'column', textAlign: 'center' }}>
                        <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                          Rental period
                        </Typography>
                        <Typography variant="body1">
                          {getFormattedDatesRange(new Date(campaign.startDate), new Date(campaign.endDate))}
                        </Typography>
                      </Grid>

                      <Grid item xs={8} md={3} sx={{ flexDirection: 'column', textAlign: 'center' }}>
                        <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                          Store
                        </Typography>
                        <Typography variant="body1">
                          {spot?.name}
                          {spotCityName && ', ' + spotCityName}
                        </Typography>
                      </Grid>

                      <Grid item xs={8} md={2} sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
                          Status
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box
                            sx={{
                              width: '12px',
                              height: '12px',
                              flexShrink: '0',
                              marginRight: 5,
                              borderRadius: '20px',
                              backgroundColor: parsedStatus?.color,
                            }}
                          ></Box>

                          <Typography variant="body1">{parsedStatus?.name}</Typography>
                        </Box>
                      </Grid>

                      {isUpMdBreakPoint && (
                        <Grid item xs={8} md={1} sx={{ textAlign: 'right' }}>
                          {paymentTransaction?.invoiceUrl && (
                            <Link
                              underline="none"
                              href={`${paymentTransaction.invoiceUrl}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <DownloadIcon />
                            </Link>
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {!isUpMdBreakPoint && paymentTransaction?.invoiceUrl && (
                      <Link
                        title={`Green invoice ID: ${paymentTransaction?.greenInvoiceId}`}
                        sx={{ marginLeft: 10, alignSelf: 'center' }}
                        underline="none"
                        href={`${paymentTransaction.invoiceUrl}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownloadIcon />
                      </Link>
                    )}
                  </Box>
                </Grid>
              );
            },
          )}
        </Grid>

        <Grid container>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <BasicButton
              className="btn-large btn-yellow"
              variant="contained"
              onClick={clickOnViewMoreBtnHandler}
              disabled={
                paymentTransactionsFilterResult?.searchPage?.items
                  ? paymentTransactionsFilterResult.searchPage.items.length >=
                    paymentTransactionsFilterResult.searchPage.totalCount
                  : true
              }
            >
              <Typography variant="body1">View more</Typography>
            </BasicButton>
          </Grid>
        </Grid>
      </DashboardBasicContainer>
    </>
  );
};
