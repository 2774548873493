import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';
import { DashboardBasicContainer } from 'common/components/dashboard/BasicContainer';
import { DashboardCaption } from 'common/components/dashboard/Caption';
import { DashboardSpotCard } from 'common/components/dashboard/SpotCard';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { getSpotsByFilter, selectManagerDashboardState } from 'features/manager-dashboard/managerDashboardSlice';
import SpotItemDTO from 'models/spot/spot-item-dto';

export const Home = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { spotsFilterResult } = useAppSelector(selectManagerDashboardState);
  const filter = {
    location: '',
    itemsOnPage: 100,
    page: 1,
  };

  useEffect(() => {
    dispatch(getSpotsByFilter(filter));
  }, []);

  return (
    <Box>
      <DashboardCaption>Home</DashboardCaption>

      <DashboardBasicContainer>
        <Grid container spacing={15} mb={30}>
          {spotsFilterResult?.items?.length &&
            spotsFilterResult.items.map((item: SpotItemDTO, index: number) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <DashboardSpotCard
                    name={item.storeName}
                    spotId={item.spotId}
                    storeId={item.storeId}
                    shortAddress={item.shortAddress}
                    imageId={item.storeImage}
                  />
                </Grid>
              );
            })}
        </Grid>
      </DashboardBasicContainer>
    </Box>
  );
};
