import React from 'react';

import { Grid, Typography } from '@mui/material';

import { getNameByDay, week } from '../../../constants/enums/days-of-week';
import StoreWorkingDayDTO from '../../../models/store/store-working-day-dto';
import { Checkbox } from '../basic/Checkbox';

export const WorkingDayCheckboxes = ({
  workingDaysState,
  changeWorkingDayHandler,
}: WorkingDayCheckboxesProps): JSX.Element => {
  const checkboxes = week().map((value, index) => {
    let isChecked = !!workingDaysState?.find((workingDay) => workingDay.day === value);

    return (
      <Checkbox
        name={`${getNameByDay(value)}`}
        key={index}
        checked={isChecked}
        label={<Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>{getNameByDay(value)}</Typography>}
        changeHandler={changeWorkingDayHandler}
      />
    );
  });

  return (
    <Grid
      item
      sx={{
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat(2, 200px)',
      }}
    >
      {checkboxes}
    </Grid>
  );
};

interface WorkingDayCheckboxesProps {
  workingDaysState: Array<StoreWorkingDayDTO> | [];
  changeWorkingDayHandler: (e: any) => void;
}
