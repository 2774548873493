import React, { useState } from 'react';

import { Box, Dialog, DialogContent, Link, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ReactComponent as CheckMarkCircleIcon } from '../../../../assets/icons/check-mark-circle.svg';
import { BasicButton } from '../../../../common/components/basic/Button';
import { BasicInput } from '../../../../common/components/basic/Input';
import { scrollToTop } from '../../../../common/helpers/scroll-to-top';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/redux-hooks';
import { PreCampaignManagerService } from '../../../../services/pre-campaign-manager';
import { enqueueNotification as enqueueSnackbarAction } from '../../../notifier/notifierSlice';
import { resetPreCampaignEdit } from '../personalizedBookingSlice';
import { selectPersonalizedBookingState } from '../personalizedBookingSlice';
import { bookingRequestReadyDialogStyles } from './styles';

export const BookingRequestReadyDialog = ({
  dialogProps = { open: false },
}: BookingRequestReadyDialogProps): JSX.Element => {
  const { preCampaignState } = useAppSelector(selectPersonalizedBookingState);
  const preBookingUrl = preCampaignState?.id
    ? location.origin + '/pre-booking?precampaign_id=' + preCampaignState.id
    : '';

  const [isCopied, setCopy] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const preCampaignManagerService: PreCampaignManagerService = new PreCampaignManagerService();
  const dispatch = useAppDispatch();

  const clickOnCopyHandler = () => {
    setCopy(true);
  };

  const closeTooltipHandler = () => {
    setCopy(false);
  };

  const changeEmailAddressFieldHandler = (e: any) => {
    setEmailAddress(e.target.value);
  };

  const clickOnMakeAnotherBookingHandler = () => {
    dialogProps?.onClose && dialogProps.onClose();
    dispatch(resetPreCampaignEdit());
    scrollToTop();
  };

  const submitEmailForm = (e: any) => {
    e.preventDefault();

    if (preCampaignState?.id && emailAddress) {
      preCampaignManagerService
        .sendBookingUrlToEmail(preCampaignState?.id, emailAddress)
        .execute()
        .then(() => {
          dispatch(
            enqueueSnackbarAction({
              message: 'Email sent successfully',
              options: { variant: 'success' },
            }),
          );
        });
    }
  };

  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <Stack sx={bookingRequestReadyDialogStyles} alignItems="center">
          <SvgIcon className="check-mark-icon" viewBox={'0 0 20 20'} component={CheckMarkCircleIcon}></SvgIcon>
          <Typography sx={{ pt: 15, pb: 5 }} variant="subtitle1">
            Booking request ready
          </Typography>
          <Typography sx={{ pb: 25 }} variant="body1">
            The booking request is completed, how do you want to <br /> share it?
          </Typography>

          <Box className="row-with-action-btn" sx={{ pb: 18 }}>
            <Typography variant="body1" sx={{ textAlign: 'left', pr: 15 }}>
              Booking URL: {preBookingUrl}
            </Typography>
            <CopyToClipboard text={preBookingUrl} onCopy={clickOnCopyHandler}>
              <Tooltip open={isCopied} placement="top" leaveDelay={500} onClose={closeTooltipHandler} title="Copied">
                <div>
                  <BasicButton variant="outlined">Copy</BasicButton>
                </div>
              </Tooltip>
            </CopyToClipboard>
          </Box>

          <Typography sx={{ pb: 15 }} variant="body1">
            Or send an email
          </Typography>

          <form className="email-form">
            <Box className="row-with-action-btn" sx={{ pb: 20 }}>
              <BasicInput
                id="email"
                value={emailAddress || ''}
                fullWidth
                formControlProps={{ sx: { pr: 15 } }}
                inputProps={{
                  name: 'emailAddress',
                  type: 'email',
                  placeholder: 'Email address',
                  onChange: changeEmailAddressFieldHandler,
                }}
              />
              <BasicButton className="btn-black" variant="contained" onClick={submitEmailForm}>
                Send
              </BasicButton>
            </Box>
          </form>

          <Box sx={{ pb: 10 }}>
            <Link color="inherit" sx={{ pr: 38 }}>
              Go to proposals
            </Link>
            <Link
              component="button"
              type="button"
              variant="body1"
              color="inherit"
              underline="always"
              onClick={clickOnMakeAnotherBookingHandler}
            >
              Make another booking
            </Link>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

interface BookingRequestReadyDialogProps {
  dialogProps: {
    open: boolean;
    onClose?: () => void;
    fullWidth?: boolean;
    PaperProps?: object;
  };
}
