import PreCampaignDTO from '../../models/pre-campaign/pre-campaign-dto';

export const getActionMenuItemsForPreCampaign = (
  preCampaign: PreCampaignDTO,
  redirectToPersonalizedBookingHandler: (preCampaign: PreCampaignDTO) => void,
  clickOnDeleteHandler: (preCampaign: PreCampaignDTO) => void,
  clickOnApproveHandler: (preCampaign: PreCampaignDTO) => void,
  clickOnCopyBookingURLHandler: (preCampaign: PreCampaignDTO) => void,
  clickOnRejectHandler: (preCampaign: PreCampaignDTO) => void,
  isPreBookingWithBankAccount: boolean,
  isWaitingBankAccountConfirmationStatus: boolean,
) => {
  const actionMenuItems = [
    {
      title: 'Copy and delete',
      action: () => {
        redirectToPersonalizedBookingHandler(preCampaign);
        setTimeout(() => clickOnDeleteHandler(preCampaign), 1000);
      },
    },
    {
      title: 'Copy and keep',
      action: () => {
        redirectToPersonalizedBookingHandler(preCampaign);
      },
    },
    {
      title: 'Copy booking URL',
      action: () => {
        clickOnCopyBookingURLHandler(preCampaign);
      },
    },
    {
      title: 'Delete',
      action: () => {
        clickOnDeleteHandler(preCampaign);
      },
    },
  ];

  //Add approve action in case with not verified bank account
  isPreBookingWithBankAccount &&
    isWaitingBankAccountConfirmationStatus &&
    actionMenuItems.splice(
      0,
      0,
      ...[
        {
          title: 'Reject',
          action: () => {
            clickOnRejectHandler(preCampaign);
          },
        },
        {
          title: 'Approve',
          action: () => {
            clickOnApproveHandler(preCampaign);
          },
        },
      ],
    );

  return actionMenuItems;
};
