import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { CampaignStatus } from '../../constants/enums/campaign/campaign-status';
import CampaignDTO from '../../models/campaign/campaign-dto';
import BrandPaymentTransactionsResultDTO from '../../models/payment-transactions/brand-payment-transactions-result-dto';
import PaymentTransactionsFilterDTO from '../../models/payment-transactions/payment-transactions-filter';
import SpotDTO from '../../models/spot/spot-dto';
import SpotItemDTO from '../../models/spot/spot-item-dto';
import SpotsFilterDTO from '../../models/spot/spots-filter-dto';
import StoreDTO from '../../models/store/store-dto';
import { CampaignBrandService } from '../../services/campaigns-brand';
import { PaymentTransactionsBrandService } from '../../services/payment-transactions-brand';
import { SpotsService } from '../../services/spots';
import { StoresService } from '../../services/stores';

interface initialStateTypes {
  upcomingCampaigns: CampaignDTO[];
  activeCampaigns: CampaignDTO[];
  pastCampaigns: CampaignDTO[];
  spotsByFiler: SpotItemDTO[];
  allStores: StoreDTO[];
  allSpots: SpotDTO[];
  newestSpots: SpotItemDTO[];
  paymentTransactionsFilterResult: BrandPaymentTransactionsResultDTO;
}

const initialState = {
  upcomingCampaigns: [],
  activeCampaigns: [],
  pastCampaigns: [],
  spotsByFiler: [],
  allStores: [],
  allSpots: [],
  newestSpots: [],
  paymentTransactionsFilterResult: {} as BrandPaymentTransactionsResultDTO,
} as initialStateTypes;

const campaignBrandService: CampaignBrandService = new CampaignBrandService();
const paymentTransactionsBrandService: PaymentTransactionsBrandService = new PaymentTransactionsBrandService();
const spotsService: SpotsService = new SpotsService();
const storesService: StoresService = new StoresService();

export const getBrandCampaigns = createAsyncThunk('getBrandCampaigns', async () => {
  return await campaignBrandService.getCampaigns().execute();
});

export const canselCampaignById = createAsyncThunk('canselCampaignById', async (id: number) => {
  return await campaignBrandService.canselCampaignById(id).execute();
});

export const getAllSpots = createAsyncThunk('getAllSpots', async () => {
  return await spotsService.getAllSpots().execute();
});

export const getAllStores = createAsyncThunk('getAllStores', async () => {
  return await storesService.getAllStores().execute();
});

export const getSpotsByFilter = createAsyncThunk('getSpotsByFilter', async (filer: SpotsFilterDTO) => {
  return await spotsService.getSpotsByFilter(filer).execute();
});

export const getNewestSpots = createAsyncThunk('getNewestSpots', async (count: number) => {
  return await spotsService.getNewestSpots(count).execute();
});

export const getPaymentTransactionsByFilterBrand = createAsyncThunk(
  'getPaymentTransactionsByFilterBrand',
  async (filter: PaymentTransactionsFilterDTO) => {
    return await paymentTransactionsBrandService.getPaymentTransactionsByFilterBrand(filter).execute();
  },
);

export const brandDashboardSlice = createSlice({
  name: 'brandDashboard',
  initialState: initialState,
  reducers: {
    setInitialStateForPaymentTransactionsFilterResult: (state) => {
      state.paymentTransactionsFilterResult = {} as BrandPaymentTransactionsResultDTO;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllStores.fulfilled, (state, action) => {
      state.allStores = action.payload;
    });
    builder.addCase(getAllSpots.fulfilled, (state, action) => {
      state.allSpots = action.payload;
    });
    builder.addCase(getSpotsByFilter.fulfilled, (state, action) => {
      state.spotsByFiler = action.payload.items;
    });
    builder.addCase(getNewestSpots.fulfilled, (state, action) => {
      state.newestSpots = action.payload.items;
    });
    builder.addCase(getBrandCampaigns.fulfilled, (state, action) => {
      const upcomingCampaigns = action.payload.filter((campaign) =>
        [
          CampaignStatus.Pending,
          CampaignStatus.Approved,
          CampaignStatus.WaitingForPaid,
          CampaignStatus.ReadyToStart,
          CampaignStatus.Modified,
        ].includes(campaign.status),
      );
      const activeCampaigns = action.payload.filter((campaign) => campaign.status === CampaignStatus.Started);
      const pastCampaigns = action.payload.filter((campaign) =>
        [CampaignStatus.Ended, CampaignStatus.Refused, CampaignStatus.Cancel].includes(campaign.status),
      );

      state.upcomingCampaigns = upcomingCampaigns;
      state.activeCampaigns = activeCampaigns;
      state.pastCampaigns = pastCampaigns;
    });
    builder.addCase(canselCampaignById.fulfilled, (state, action) => {
      const indexOfCanceledCampaign = state.upcomingCampaigns.findIndex((item) => item.id === action.payload.id);

      state.upcomingCampaigns.splice(indexOfCanceledCampaign, 1);
      state.pastCampaigns.push(action.payload);
    });
    builder.addCase(getPaymentTransactionsByFilterBrand.fulfilled, (state, action) => {
      const previousPaymentTransactions = state.paymentTransactionsFilterResult?.searchPage?.items
        ? state.paymentTransactionsFilterResult.searchPage.items
        : [];

      const { preCampaigns = [], campaigns = [], spots = [], stores = [] } = state.paymentTransactionsFilterResult;

      state.paymentTransactionsFilterResult = {
        searchPage: {
          items: [...previousPaymentTransactions, ...action.payload.searchPage.items],
          totalCount: action.payload.searchPage.totalCount,
        },
        preCampaigns: [...preCampaigns, ...action.payload.preCampaigns],
        campaigns: [...campaigns, ...action.payload.campaigns],
        spots: [...spots, ...action.payload.spots],
        stores: [...stores, ...action.payload.stores],
      };
    });
  },
});

export const selectBrandDashboardState = function (state: RootState): any {
  return state.brandDashboard;
};

export const { setInitialStateForPaymentTransactionsFilterResult } = brandDashboardSlice.actions;

export default brandDashboardSlice.reducer;
