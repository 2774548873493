import React from 'react';

import { Box, SvgIcon, Typography } from '@mui/material';

import { ReactComponent as DailyTrafficIcon } from '../../../assets/icons/daily-traffic.svg';
import { getById as getCurrencyById } from '../../../constants/enums/currency';
import SpotDTO from '../../../models/spot/spot-dto';
import StoreDTO from '../../../models/store/store-dto';
import { getFormattedPrice } from '../../helpers/formatters';
import { useCurrencyConverter } from '../../hooks/use-currency-converter';
import { BoxWithBackground } from '../BoxWithBackground';
import { CircleProgressBar } from '../CircleProgressBar';
import { TooltipInfoIcon } from '../TooltipInfoIcon';
import { StyledSpotInfoBlock } from './styles';

export const SpotInfoBox = ({
  className,
  selectedSpot,
  selectedStore,
}: {
  className?: string;
  selectedSpot: SpotDTO;
  selectedStore: StoreDTO;
}): JSX.Element => {
  const selectedSpotCurrency = getCurrencyById(selectedSpot?.currency);
  const { currencySymbol = '', convertedPrice: convertedDailySalesPrice = 0 } = useCurrencyConverter(
    selectedSpotCurrency,
    selectedSpot?.sales,
  );

  const matchRate = 100;
  const formattedDailySalesPrice = getFormattedPrice(convertedDailySalesPrice, currencySymbol, true);
  const spotAdditionalInfoData = [
    {
      title: 'Size',
      value: selectedSpot?.sizeInMeters,
      text: selectedSpot?.sizeInMeters + ' sq/m',
    },
    {
      title: 'Est. Daily Sales',
      value: convertedDailySalesPrice,
      text: formattedDailySalesPrice,
      tooltipText:
        'This is the estimated volume of sales a seller can make during a one-day pop-up at this spot. The estimation relies on past performance in an 100% match rate.',
    },
    {
      title: 'Est. Daily Traffic',
      value: selectedStore?.audiencePerDay,
      text: selectedStore?.audiencePerDay,
      tooltipText:
        'This is the estimated number of people that can be exposed to this spot on an average day. This number is given by the retailer.',
      withTrafficIcon: true,
    },
    {
      title: 'Match Rate',
      value: matchRate,
      text: matchRate + '%',
      tooltipText:
        'The secret sauce at Repop. Match rate takes in consideration your profile, the spots profile, the past performance in the spot and additional data - to make a match percentage that will predict your success.',
      withProgressbar: true,
    },
  ];

  return (
    <BoxWithBackground className={className} sx={{ paddingX: 20, paddingY: 15, marginBottom: 15 }}>
      <StyledSpotInfoBlock>
        {spotAdditionalInfoData.map((item, key) => {
          if (item.value) {
            const Title = (
              <Typography variant="h5" sx={{ typography: { xs: 'h6', md: 'h5' } }}>
                {item.title}
              </Typography>
            );
            return (
              <Box key={key}>
                {item?.tooltipText ? (
                  <TooltipInfoIcon tooltipTitle={`${item.tooltipText}`}>{Title}</TooltipInfoIcon>
                ) : (
                  Title
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                  {item?.withProgressbar && (
                    <CircleProgressBar
                      variant="determinate"
                      value={matchRate}
                      size={25}
                      thickness={4}
                      sx={{ marginRight: 4 }}
                    />
                  )}
                  {item?.withTrafficIcon && (
                    <SvgIcon viewBox={'0 0 25 25'} component={DailyTrafficIcon} sx={{ marginRight: 5 }} />
                  )}
                  <Typography variant="subtitle2" sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
                    {item.text}
                  </Typography>
                </Box>
              </Box>
            );
          }
        })}
      </StyledSpotInfoBlock>
    </BoxWithBackground>
  );
};
