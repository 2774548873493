import React from 'react';

import { Box, Step, StepIconProps, StepLabel } from '@mui/material';

import theme from '../../../theme-config/theme';
import { StepIconRoot, StyledHorizontalStepper } from './styles';

function StepIcon(props: StepIconProps) {
  const { active, completed, icon } = props;

  return (
    <StepIconRoot className="StepIcon-root" ownerState={{ active, completed }}>
      <div className="StepIcon">{icon}</div>
    </StepIconRoot>
  );
}

export const HorizontalStepper = ({
  steps,
  activeStep = 0,
  colorPalette = theme.palette.common.black,
  sx = {},
}: HorizontalStepperPropTypes): JSX.Element => {
  const [currentStep] = React.useState(activeStep);

  return (
    <Box sx={[{ width: '100%' }, sx]}>
      <StyledHorizontalStepper alternativeLabel activeStep={currentStep} colorPalette={colorPalette}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
            </Step>
          );
        })}
      </StyledHorizontalStepper>
    </Box>
  );
};

interface HorizontalStepperPropTypes {
  steps: Array<string>;
  activeStep?: number;
  colorPalette?: string;
  sx?: object;
}
