import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { AddNewCreditCardBlock } from '../../../common/components/AddNewCreditCardBlock';
import { DefaultCreditCardBlock } from '../../../common/components/DefaultCreditCardBlock';
import { camelizeKeys, isJsonString } from '../../../common/helpers/utils';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import PaymentMethodDTO from '../../../models/payment/payment-method-dto';
import {
  addNewCreditCard,
  getAvailablePaymentMethods,
  getPayPlusIframeUrl,
  selectBrandPaymentsState,
  setPayPlusIframeShowStatus,
} from '../brandPaymentsSlice';

export const PayPlus = ({
  depositAmount,
  totalAmount,
}: {
  depositAmount?: string;
  totalAmount?: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { availablePaymentMethods, shownPayPlusIframe, payPlusIframeUrl } = useAppSelector(selectBrandPaymentsState);
  const defaultCreditCard = availablePaymentMethods?.find((paymentMethod: PaymentMethodDTO) => paymentMethod.isDefault);

  const messageHandler = (event: any) => {
    const paymentMethod = isJsonString(event.data) && camelizeKeys(JSON.parse(event.data).paymentMethod);

    if (paymentMethod && paymentMethod?.id) {
      dispatch(setPayPlusIframeShowStatus(false));
      dispatch(addNewCreditCard(paymentMethod));
    }
  };

  useEffect(() => {
    !availablePaymentMethods?.length && dispatch(getAvailablePaymentMethods());
    !payPlusIframeUrl && dispatch(getPayPlusIframeUrl());
    dispatch(setPayPlusIframeShowStatus(false));

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  return (
    <Grid item container sx={{ marginBottom: shownPayPlusIframe ? 0 : 20 }}>
      <Grid item xs={12}>
        {(depositAmount || totalAmount) && (
          <Box
            sx={{
              width: '100%',
              maxWidth: '370px',
              marginTop: 8,
              marginBottom: 20,
              marginX: 'auto',
              paddingX: 15,
              paddingY: 8,
              borderRadius: '10px',
              backgroundColor: (theme: Theme) => theme.palette.common.black,
              color: (theme: Theme) => theme.palette.common.white,
            }}
          >
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              <Typography variant="caption" sx={{ typography: { xs: 'h5', md: 'h3' } }}>
                {depositAmount && 'Pay now'}
                {totalAmount && 'Total Price:'}
              </Typography>{' '}
              {depositAmount && (
                <Typography variant="caption" sx={{ typography: { xs: 'body1', md: 'subtitle2' } }}>
                  (deposit fee):
                </Typography>
              )}{' '}
              <Typography variant="caption" sx={{ typography: { xs: 'h5', md: 'h3' } }}>
                {depositAmount && depositAmount}
                {totalAmount && totalAmount}
              </Typography>
            </Typography>
          </Box>
        )}
      </Grid>

      <Grid item container spacing={10}>
        {defaultCreditCard?.id && (
          <Grid item container xs={12} md={6}>
            <DefaultCreditCardBlock
              type={defaultCreditCard?.stripeData?.type || ''}
              lastDigit={defaultCreditCard?.stripeData?.lastDigit || defaultCreditCard?.payPlusData?.lastDigit}
              expiredDate={defaultCreditCard?.stripeData?.expriedAt || defaultCreditCard?.payPlusData?.expriedAt}
            />
          </Grid>
        )}
        {!shownPayPlusIframe && (
          <Grid item container xs={12} md={6}>
            <AddNewCreditCardBlock
              title={'Add new PayPlus credit card'}
              showIframeAction={() => {
                dispatch(setPayPlusIframeShowStatus(true));
              }}
            />
          </Grid>
        )}
      </Grid>

      {shownPayPlusIframe && (
        <Grid item xs={12} sx={{ marginTop: 10 }}>
          <iframe
            className="payplus-iframe"
            src={payPlusIframeUrl}
            style={{ border: 'none', width: '100%', height: '600px' }}
          ></iframe>
        </Grid>
      )}
    </Grid>
  );
};
