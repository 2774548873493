import budapestImg from '../assets/images/cities/budapest.png';
import jerusalemImg from '../assets/images/cities/jerusalem.png';
import telAvivImg from '../assets/images/cities/tel-aviv.png';
import theme from '../theme-config/theme';

export const MOBILE_HEADER_BREAKPOINT = 1025;
export const MOBILE_HEADER_BREAKPOINT_DASHBOARD = theme.breakpoints.values.sm;

export const CITIES_FILTER_DATA = [
  {
    name: 'Tel Aviv',
    imgSrc: telAvivImg,
    searchParameter: 'tel_aviv',
  },
  {
    name: 'Budapest',
    imgSrc: budapestImg,
    searchParameter: 'budapest',
  },
  {
    name: 'Jerusalem',
    imgSrc: jerusalemImg,
    searchParameter: 'jerusalem',
  },
  {
    name: 'Givatayim',
    imgSrc: jerusalemImg,
    searchParameter: 'givatayim',
  },
];
export const DURATION_FILTER_DATA = [
  {
    name: '2 Weeks - 4 Weeks',
    discountPercentage: 0,
    searchParameter: '2-4',
  },
  {
    name: '1 Month - 6 Months',
    discountPercentage: 10,
    searchParameter: '4-24',
  },
  {
    name: '+ 6 Months',
    discountPercentage: 20,
    searchParameter: '24-52',
  },
];
