import React, { useEffect, useState } from 'react';

import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

import { ReactComponent as ULetter } from '../../../assets/icons/letters/u-letter.svg';
import { BoxWithBackground } from '../../../common/components/BoxWithBackground';
import { DashboardBasicContainer } from '../../../common/components/dashboard/BasicContainer';
import { DashboardCaption } from '../../../common/components/dashboard/Caption';
import { DashboardRightDrawer } from '../../../common/components/dashboard/RightDrawer';
import { getDaysBetweenTwoDates } from '../../../common/helpers/date-heplers';
import { getFormattedDatesRange } from '../../../common/helpers/formatters';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import CampaignDTO from '../../../models/campaign/campaign-dto';
import SpotItemDTO from '../../../models/spot/spot-item-dto';
import { ImgService } from '../../../services/img-service';
import { openRightNavDrawer } from '../../drawer/drawerSlice';
import {
  getAllSpots,
  getAllStores,
  getBrandCampaigns,
  getSpotsByFilter,
  selectBrandDashboardState,
} from '../brandDashboardSlice';
import { CampaignAdditionalInfo } from '../campaign-additional-info';
import { PastSpotAdditionalInfo } from '../past-spot-additional-info';

export const PopUps = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { upcomingCampaigns, activeCampaigns, pastCampaigns, spotsByFiler } = useAppSelector(selectBrandDashboardState);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignDTO>();

  const imgService: ImgService = new ImgService();

  const clickOnCampaignHandler = (selectedCampaign: CampaignDTO) => {
    setSelectedCampaign(selectedCampaign);
    dispatch(openRightNavDrawer('CampaignAdditionalInfo'));
  };

  const clickOnPastSpotHandler = (campaign: CampaignDTO) => {
    setSelectedCampaign(campaign);
    dispatch(openRightNavDrawer('PastSpotAdditionalInfo'));
  };

  useEffect(() => {
    dispatch(getBrandCampaigns());
    dispatch(getAllSpots());
    dispatch(getAllStores());
    dispatch(
      getSpotsByFilter({
        location: '',
        itemsOnPage: 100,
        page: 1,
      }),
    );
  }, []);

  return (
    <>
      <DashboardCaption>
        POP-
        <ULetter />
        Ps
      </DashboardCaption>

      <DashboardBasicContainer>
        {upcomingCampaigns?.length ? (
          <>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              UPCOMING
            </Typography>
            <Grid container rowSpacing={10} columnSpacing={{ xs: 10, md: 15 }} sx={{ marginBottom: 10 }}>
              {upcomingCampaigns?.map((campaign: CampaignDTO, index: number) => {
                const daysToStart = getDaysBetweenTwoDates(new Date(), new Date(campaign.startDate));

                return (
                  <Grid key={index} item xs={12} md={6}>
                    <BoxWithBackground
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderRadius: '20px',
                        padding: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => clickOnCampaignHandler(campaign)}
                    >
                      <Box>
                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          REPOP at
                        </Typography>
                        <Typography variant="subtitle2" sx={{ marginBottom: 15 }}>
                          {campaign.name}
                        </Typography>

                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          Dates
                        </Typography>
                        <Typography variant="subtitle2">
                          {getFormattedDatesRange(new Date(campaign.startDate), new Date(campaign.endDate))}
                        </Typography>
                      </Box>

                      {daysToStart >= 1 ? (
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography variant="h5" sx={{ color: '#808080' }}>
                            Countdown
                          </Typography>
                          <Typography variant="subtitle1">{daysToStart}</Typography>
                          <Typography variant="h5" sx={{ color: '#808080' }}>
                            days
                          </Typography>
                        </Box>
                      ) : null}
                    </BoxWithBackground>
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}

        {activeCampaigns?.length ? (
          <>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              ACTIVE
            </Typography>
            <Grid container rowSpacing={10} columnSpacing={{ xs: 10, md: 15 }} sx={{ marginBottom: 10 }}>
              {activeCampaigns?.map((campaign: CampaignDTO, index: number) => {
                const leftDays = getDaysBetweenTwoDates(new Date(), new Date(campaign.endDate));

                return (
                  <Grid key={index} item xs={12} md={6}>
                    <BoxWithBackground
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderRadius: '20px',
                        padding: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => clickOnCampaignHandler(campaign)}
                    >
                      <Box>
                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          REPOP at
                        </Typography>
                        <Typography variant="subtitle2" sx={{ marginBottom: 15 }}>
                          {campaign.name}
                        </Typography>

                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          Dates
                        </Typography>
                        <Typography variant="subtitle2">
                          {getFormattedDatesRange(new Date(campaign.startDate), new Date(campaign.endDate))}
                        </Typography>
                      </Box>

                      {leftDays >= 1 ? (
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography variant="h5" sx={{ color: '#808080' }}>
                            Left
                          </Typography>
                          <Typography variant="subtitle1">{leftDays}</Typography>
                          <Typography variant="h5" sx={{ color: '#808080' }}>
                            days
                          </Typography>
                        </Box>
                      ) : null}
                    </BoxWithBackground>
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}

        {pastCampaigns?.length ? (
          <>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              PAST
            </Typography>
            <Grid container rowSpacing={10} columnSpacing={{ xs: 10, md: 15 }} sx={{ marginBottom: 10 }}>
              {pastCampaigns?.map((campaign: CampaignDTO, index: number) => {
                return (
                  <Grid key={index} item xs={12} md={6}>
                    <BoxWithBackground
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderRadius: '20px',
                        padding: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => clickOnCampaignHandler(campaign)}
                    >
                      <Box>
                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          REPOP at
                        </Typography>
                        <Typography variant="subtitle2" sx={{ marginBottom: 15 }}>
                          {campaign.name}
                        </Typography>

                        <Typography variant="h5" sx={{ color: '#808080' }}>
                          Dates
                        </Typography>
                        <Typography variant="subtitle2">
                          {getFormattedDatesRange(new Date(campaign.startDate), new Date(campaign.endDate))}
                        </Typography>
                      </Box>
                    </BoxWithBackground>
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}

        {pastCampaigns?.length ? (
          <>
            <Typography variant="h3" sx={{ marginBottom: 5 }}>
              PAST POP-UPS
            </Typography>
            <Grid container rowSpacing={10} columnSpacing={{ xs: 10, md: 15 }} sx={{ marginBottom: 10 }}>
              {pastCampaigns.map((campaign: CampaignDTO, index: number) => {
                const pastSpot = spotsByFiler?.find((spot: SpotItemDTO) => spot.spotId === campaign.spotId);

                if (pastSpot) {
                  return (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                      <Card
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                          borderRadius: '20px',
                          boxShadow: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        <CardMedia
                          sx={{ height: '100%' }}
                          component="img"
                          image={imgService.getImgPreviewUrl(pastSpot.storeImage, { width: 600, height: 600 })}
                          onClick={() => {
                            clickOnPastSpotHandler(campaign);
                          }}
                        />
                        <CardContent sx={{ backgroundColor: 'black', padding: '20px 10px 10px 20px !important' }}>
                          <Typography
                            variant="h3"
                            sx={{ display: 'inline-flex', color: 'white', margin: '0 10px 10px 0' }}
                          >
                            {pastSpot.spotName}, {pastSpot.shortAddress}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ display: 'inline-flex', color: '#808080', marginBottom: '10px' }}
                          >
                            {getFormattedDatesRange(new Date(campaign.startDate), new Date(campaign.endDate))}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </>
        ) : null}
      </DashboardBasicContainer>

      <DashboardRightDrawer
        contentElements={[
          <CampaignAdditionalInfo data-name={'CampaignAdditionalInfo'} selectedCampaign={selectedCampaign} />,
          <PastSpotAdditionalInfo data-name={'PastSpotAdditionalInfo'} campaign={selectedCampaign} />,
        ]}
      />
    </>
  );
};
