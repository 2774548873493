import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import ExchangeRatesDTO from '../models/exchange-rate/exchange-rate-dto';

export class ExchangeRatesService {
  private readonly basePath: string = 'ExchangeRates';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getCurrencyRates(baseCurrency: string): ApiRequestWrapper<ExchangeRatesDTO> {
    return this.apiUrlProvider.get(this.basePath + `?b=${baseCurrency}`);
  }
}