import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import StoreDTO from '../models/store/store-dto';

export class StoresService {
  private readonly basePath: string = 'stores';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getAllStores(): ApiRequestWrapper<StoreDTO[]> {
    return this.apiUrlProvider.get(this.basePath);
  }

  public getStoreById(id: number): ApiRequestWrapper<StoreDTO> {
    return this.apiUrlProvider.get(this.basePath + `/${id}`);
  }
}
