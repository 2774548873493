import React, { useEffect } from 'react';

import { Step, StepLabel, Typography } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { format } from 'date-fns';

import { ReactComponent as CheckedMark } from '../../../assets/icons/checked-mark.svg';
import { StepIconRoot, StyledRefundDepositBlock } from './styles';

function StepIcon(props: StepIconProps) {
  const { active, completed } = props;

  return (
    <StepIconRoot ownerState={{ active, completed }} className="StepIcon-root">
      {active ? (
        <div className="StepIcon-activeIcon">
          <CheckedMark />
        </div>
      ) : (
        <div className="StepIcon-circle" />
      )}
    </StepIconRoot>
  );
}

export const RefundDeposit = ({ startDateOfBooking }: { startDateOfBooking: Date }): JSX.Element => {
  //up to 45 days prior to the start date of the booking
  const freePeriodLastDate = new Date(startDateOfBooking).setDate(startDateOfBooking.getDate() - 45);
  //14 days prior to booking start date
  const nonRefundPeriodStartDate = new Date(startDateOfBooking).setDate(startDateOfBooking.getDate() - 14);
  const steps = [
    { firstLabel: `Until ${format(freePeriodLastDate, 'dd/MM/yyyy')}`, secondLabel: 'Free' },
    { firstLabel: `From ${format(freePeriodLastDate, 'dd/MM/yyyy')}`, secondLabel: 'Deposit fee' },
    { firstLabel: `After ${format(nonRefundPeriodStartDate, 'dd/MM/yyyy')}`, secondLabel: 'Non-refundable' },
  ];
  const [currentStep, setCurrentStep] = React.useState(0);

  useEffect(() => {
    const currentDate = new Date().getTime();

    if (currentDate < freePeriodLastDate) {
      setCurrentStep(0);
    } else if (currentDate >= freePeriodLastDate && currentDate < nonRefundPeriodStartDate) {
      setCurrentStep(1);
    } else if (currentDate >= nonRefundPeriodStartDate) {
      setCurrentStep(2);
    }
  }, [freePeriodLastDate, nonRefundPeriodStartDate]);

  return (
    <StyledRefundDepositBlock alternativeLabel activeStep={currentStep}>
      {steps.map((stepData, index) => {
        return (
          <Step key={index}>
            <StepLabel StepIconComponent={StepIcon}>
              <Typography sx={{ opacity: '0.5' }}>{stepData.firstLabel}</Typography>
              <Typography sx={{ fontWeight: '700' }}>{stepData.secondLabel}</Typography>
            </StepLabel>
          </Step>
        );
      })}
    </StyledRefundDepositBlock>
  );
};
