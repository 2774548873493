import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from './app/store'; //store should be located before App import
/* eslint-disable */
import App from './app/App';
import './index.scss';
import { Notifier } from './features/notifier';
import theme from './theme-config/theme';

const snackbarProviderSettings = {
  classes: {
    containerRoot: 'custom-snackbar',
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  maxSnack: 5,
} as SnackbarProviderProps;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <SnackbarProvider {...snackbarProviderSettings}>
          <ThemeProvider theme={theme}>
            <Notifier>
              <App />
            </Notifier>
          </ThemeProvider>
        </SnackbarProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
