import React, { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Link, Typography } from '@mui/material';
import wavingHandIcon from 'assets/icons/emoji/waving-hand.png';
import columnBackground from 'assets/images/repop-backgrounds/column-backgound1-1x.png';
import repopBackground from 'assets/images/repop-backgrounds/repop-backgound2-1x.png';
import { BasicButton } from 'common/components/basic/Button';
import { BasicInput } from 'common/components/basic/Input';
import { GridWithRepopBackground } from 'common/components/GridWithRepopBackground';
import { RePopLogoBlock } from 'common/components/RePopLogoBlock';
import { TitleWithEmojiIcon } from 'common/components/TitleWithEmojiIcon';
import { createNewWindowInCenter } from 'common/helpers/create-new-window';
import { receiveMessageHandler } from 'common/helpers/receive-massage-from-social-auth-view';
import { getLinkForFacebookLogIn, getLinkForGoogleLogIn } from 'common/helpers/social-auth-link-generator';
import { signUpSchema } from 'common/helpers/validation';
import { useAppDispatch } from 'common/hooks/redux-hooks';
import { createUserAsBrand, externalSignIn } from 'features/authentication/authSlice';
import { enqueueNotification as enqueueSnackbarAction } from 'features/notifier/notifierSlice';
import UserRegisterAsBrandDTO from 'models/user/user-register-as-brand';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const SignUp = (): JSX.Element => {
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserRegisterAsBrandDTO>({ resolver: yupResolver(signUpSchema) });
  let externalSignInWindow: any;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const submitHandler = (data: UserRegisterAsBrandDTO) => {
    const { firstName, lastName } = data;
    const combinedBrandName = firstName && lastName ? firstName + ' ' + lastName : firstName + lastName;

    dispatch(createUserAsBrand({ ...data, brandName: combinedBrandName })).then((data) => {
      if (!data.payload) return;

      dispatch(
        enqueueSnackbarAction({
          message: 'We have sent a confirmation to your email',
          options: { variant: 'success' },
        }),
      );

      reset();
    });
  };

  const clickOnSocialSignInHandler = (link: string) => {
    externalSignInWindow = createNewWindowInCenter(link, '', 600, 600);
  };

  const redirectToPage = (url: string) => {
    navigate(url, { replace: true });
  };

  const messageHandler = useCallback(
    (e: any) => {
      receiveMessageHandler(dispatch, externalSignIn, e, externalSignInWindow);
    },
    [dispatch, externalSignInWindow],
  );

  useEffect(() => {
    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  return (
    <GridWithRepopBackground
      columnBackground={`top / cover no-repeat url(${columnBackground})`}
      repopBackground={repopBackground}
      sx={{ textAlign: 'center' }}
    >
      <Grid container sx={{ justifyContent: { sm: 'center', md: 'flex-start' } }}>
        <Grid item xs={12} sm={10} md={12} lg={11}>
          <RePopLogoBlock sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '8.5vh' }} />
        </Grid>

        <Grid item xs={12} sm={10} md={12} lg={11}>
          <TitleWithEmojiIcon
            title="Hi there!"
            sx={{ paddingTop: { xs: 25, md: 'initial' }, paddingBottom: { xs: 33, md: '7.5vh' } }}
          >
            <img src={wavingHandIcon} className="emoji-icon" alt="waving-hand-emoji" />
          </TitleWithEmojiIcon>
        </Grid>

        <Grid item xs={12} sm={10} md={12} lg={11}>
          <Typography variant="subtitle2" sx={{ paddingBottom: 6 }}>
            Sign up your brand and book your next REPOP shop at one of our prime locations
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={12} lg={11}>
          <Typography variant="body1" sx={{ paddingBottom: 6 }}>
            Already have an account?
            <Link
              component="button"
              type="button"
              sx={{ ml: 2 }}
              variant="h5"
              color="inherit"
              underline="always"
              onClick={() => {
                redirectToPage('/log-in');
              }}
            >
              Log In
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={12} lg={11} sx={{ paddingBottom: 12 }}>
          <BasicButton
            className="btn-large btn-with-icon btn-with-icon--google"
            sx={{ minWidth: { sm: '170px' }, mr: { xs: 10, sm: 15 } }}
            variant="outlined"
            type="button"
            onClick={() => {
              clickOnSocialSignInHandler(getLinkForGoogleLogIn());
            }}
          >
            Sign up
          </BasicButton>
          <BasicButton
            className="btn-blue btn-large btn-with-icon btn-with-icon--facebook"
            sx={{ minWidth: { sm: '170px' } }}
            variant="contained"
            type="button"
            onClick={() => {
              clickOnSocialSignInHandler(getLinkForFacebookLogIn());
            }}
          >
            Sign up
          </BasicButton>
        </Grid>

        <Grid item xs={12} sm={10} md={12} lg={11}>
          <Typography variant="body1" sx={{ paddingBottom: 12 }}>
            or
          </Typography>
        </Grid>
      </Grid>

      <Box component="form" autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
        <Grid container spacing={10} sx={{ justifyContent: { sm: 'center', md: 'flex-start' } }}>
          <Grid item xs={12} sm={10} md={6} lg={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BasicInput
                  value={value || ''}
                  fullWidth
                  className="input-rounded"
                  controlError={errors?.firstName ? errors.firstName.message : ''}
                  inputProps={{
                    placeholder: 'First name',
                    autoComplete: 'given-name',
                    onChange: onChange,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={10} md={6} lg={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BasicInput
                  value={value || ''}
                  fullWidth
                  className="input-rounded"
                  controlError={errors?.lastName ? errors.lastName.message : ''}
                  inputProps={{
                    placeholder: 'Last name',
                    autoComplete: 'family-name',
                    onChange: onChange,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={10} md={6}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BasicInput
                  value={value || ''}
                  fullWidth
                  className="input-rounded"
                  controlError={errors?.email ? errors.email.message : ''}
                  inputProps={{
                    placeholder: 'Email',
                    autoComplete: 'email',
                    onChange: onChange,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={10} md={6}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BasicInput
                  value={value || ''}
                  fullWidth
                  className="input-rounded"
                  controlError={errors?.phoneNumber ? errors.phoneNumber.message : ''}
                  inputProps={{
                    type: 'tel',
                    placeholder: 'Phone (with country code)',
                    autoComplete: 'tel',
                    onChange: onChange,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={10} md={6}>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BasicInput
                  value={value || ''}
                  fullWidth
                  className="input-rounded"
                  controlError={errors?.password ? errors.password.message : ''}
                  inputProps={{
                    type: 'password',
                    placeholder: 'Password',
                    autoComplete: 'new-password',
                    onChange: onChange,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={10} md={6} sx={{ paddingBottom: 10 }}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BasicInput
                  value={value || ''}
                  fullWidth
                  className="input-rounded"
                  controlError={errors?.confirmPassword ? errors.confirmPassword.message : ''}
                  inputProps={{
                    type: 'password',
                    placeholder: 'Confirm password',
                    autoComplete: 'new-password',
                    onChange: onChange,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={10} md={12}>
            <BasicButton
              className="btn-large btn-yellow fs-20px"
              sx={{ minWidth: '170px' }}
              variant="contained"
              type="submit"
            >
              Sign up
            </BasicButton>
          </Grid>
        </Grid>
      </Box>
    </GridWithRepopBackground>
  );
};
