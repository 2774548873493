import { styled } from '@mui/system';

export const StyledDropzone = styled('section')(({ theme }) => ({
  textAlign: 'center',
  border: `1px dashed ${theme.palette.common.black}`,
  borderRadius: '47px',
  cursor: 'pointer',

  '.dropzone': {
    padding: theme.spacing(15),
  },
}));
