import React, { useEffect, useState } from 'react';

import { Box, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ReactComponent as CheckMarkCircleIcon } from '../../../assets/icons/check-mark-circle.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/icons/credit-card.svg';
import { ReactComponent as StripeIcon } from '../../../assets/icons/stripe.svg';
import { BasicButton } from '../../../common/components/basic/Button';
import { Checkbox } from '../../../common/components/basic/Checkbox';
import { BasicContainer } from '../../../common/components/BasicContainer';
import { TermAndConditionsDialog } from '../../../common/components/Dialogs/TermAndConditions';
import { RefundDeposit } from '../../../common/components/RefundDeposit';
import { SignUpLogInBox } from '../../../common/components/SignUpLogInBox';
import { SummaryTable } from '../../../common/components/SummaryTable';
import { TabsComponent } from '../../../common/components/TabsComponent';
import { fixedOrderDate } from '../../../common/helpers/date-heplers';
import { getFormattedDatesRange } from '../../../common/helpers/formatters';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/redux-hooks';
import { useBookingData } from '../../../common/hooks/use-booking-data';
import { useUserRole } from '../../../common/hooks/use-user-role';
import { USER_ROLES } from '../../../constants/enums/user-roles';
import SpotDTO from '../../../models/spot/spot-dto';
import { CampaignBrandService } from '../../../services/campaigns-brand';
import { PaymentsBrandService } from '../../../services/payments-brand';
import theme from '../../../theme-config/theme';
import { selectAuthState } from '../../authentication/authSlice';
import { PayPlus } from '../../brand-payments/PaymentTabs/PayPlus';
import { Stripe } from '../../brand-payments/PaymentTabs/Stripe';
import { enqueueNotification } from '../../notifier/notifierSlice';
import { getSpotById, getStoreById, selectBookingState } from '../bookingSlice';

export const Checkout = (): JSX.Element => {
  const { selectedSpot, selectedStore } = useAppSelector(selectBookingState);
  const { authorizedUser } = useAppSelector(selectAuthState);
  const { token, role } = authorizedUser;
  const isBrandRole = useUserRole(USER_ROLES.Brand);
  const userHasRequiredRole = [USER_ROLES.Brand, undefined].includes(role);
  const { spotId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [isShowTermsAndConditionsDialog, showTermsAndConditionsDialog] = useState(false);
  const [isDisabledBookNowBtn, setDisabledBookNowBtn] = useState(true);
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const [stripeKey, setStripeKey] = useState<string | null>(null);

  const campaignBrandService = new CampaignBrandService();
  const paymentsBrandService: PaymentsBrandService = new PaymentsBrandService();

  const startDateStrFromUrl = searchParams.get('start_date') || '';
  const endDateStrFromUrl = searchParams.get('end_date') || '';
  const startDateObj = startDateStrFromUrl && new Date(startDateStrFromUrl);
  const endDateObj = endDateStrFromUrl && new Date(endDateStrFromUrl);

  const { workingDaysDuration, formattedTotalAmount, formattedPricePerWeek, spotCityName } = useBookingData(
    selectedSpot,
    selectedStore,
    startDateStrFromUrl,
    endDateStrFromUrl,
  );
  const formattedDateRange = startDateObj && endDateObj && getFormattedDatesRange(startDateObj, endDateObj);

  const checkInOutDates = [
    { label: 'Check in', date: startDateObj },
    { label: 'Check out', date: endDateObj },
  ];

  const summaryTableData = [
    {
      headTitle: 'Dates',
      rowValue: formattedDateRange && formattedDateRange,
    },
    {
      headTitle: 'Total working days',
      rowValue: workingDaysDuration && workingDaysDuration,
      isCollapsed: true,
    },
    {
      headTitle: 'Spot',
      rowValue: formattedPricePerWeek + '/Week',
      isCollapsed: true,
    },
    {
      headTitle: 'Total Amount',
      rowValue: formattedTotalAmount && formattedTotalAmount,
    },
    {
      headTitle: 'VAT',
      rowValue: selectedSpot?.vat && selectedSpot.vat + '%',
      isShowDivider: true,
    },
    {
      headTitle: 'Pay at Check in',
      rowValue: formattedTotalAmount && formattedTotalAmount,
      isHighlightedTitle: true,
      isHighlightedValue: true,
    },
  ];

  const paymentTabs = [
    {
      label: 'Credit card',
      icon: <CreditCardIcon />,
      tabPanelComponent: <PayPlus totalAmount={formattedTotalAmount} />,
    },
  ];

  stripeKey &&
    paymentTabs.splice(1, 0, {
      label: 'Stripe',
      icon: <StripeIcon />,
      tabPanelComponent: <Stripe stripeKey={stripeKey} totalAmount={formattedTotalAmount} />,
    });

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const clickOnTermsAndConditionsLinkHandler = () => {
    showTermsAndConditionsDialog(true);
  };

  const changeTermsAndConditionsCheckboxHandler = (e: any) => {
    e.target.checked ? setDisabledBookNowBtn(false) : setDisabledBookNowBtn(true);
  };

  const clickOnBookNowBtnHandler = () => {
    const isAvailableData = !!(selectedSpot?.id && selectedSpot?.name && startDateStrFromUrl && endDateStrFromUrl);

    if (isAvailableData) {
      campaignBrandService
        .createCampaign({
          spotId: selectedSpot.id,
          name: selectedSpot.name,
          startDate: fixedOrderDate(new Date(startDateStrFromUrl)).toISOString(),
          endDate: fixedOrderDate(new Date(endDateStrFromUrl)).toISOString(),
        })
        .execute()
        .then(() => {
          navigate('/dashboard/brand');
        });
    }
  };

  useEffect(() => {
    if (!(selectedSpot.id || selectedStore.id) && spotId) {
      dispatch(getSpotById(parseInt(spotId)))
        .unwrap()
        .then((spotData: SpotDTO) => {
          dispatch(getStoreById(spotData?.storeId));
        });
    }
  }, [spotId]);

  useEffect(() => {
    if (!userHasRequiredRole) {
      dispatch(
        enqueueNotification({
          options: { variant: 'error' },
          message: "You don't have permission for this action",
        }),
      );
      navigate('/log-in');
    }
  }, [userHasRequiredRole]);

  useEffect(() => {
    isBrandRole &&
      paymentsBrandService
        .getStripeKey()
        .execute()
        .then((key) => {
          setStripeKey(key.PublishableKey);
        });
  }, [isBrandRole]);

  return (
    <>
      <BasicContainer sx={{ paddingY: { xs: 20, md: 25 } }}>
        <Box sx={{ textAlign: { xs: 'center', md: 'left' }, marginBottom: { xs: 20, md: 25 } }}>
          <Typography variant="h1" sx={{ typography: { xs: 'h3', md: 'h1' }, marginBottom: { xs: 6, md: 2 } }}>
            REQUEST TO BOOK THIS STORE
          </Typography>

          <Typography variant="h2" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            Add your payment method for booking this store, you will be charged once the store manager approves your
            booking request
          </Typography>
        </Box>

        <Grid
          container
          flexDirection={{ xs: userHasRequiredRole && token ? 'column-reverse' : 'initial', md: 'initial' }}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          spacing={10}
        >
          <Grid item xs={12} md={6}>
            {userHasRequiredRole && token ? (
              <>
                {!isUpMdBreakPoint && (
                  <Typography variant={'h3'} sx={{ marginTop: 10, marginBottom: 6 }}>
                    Payment info
                  </Typography>
                )}

                <Typography
                  variant="body1"
                  sx={{
                    typography: { xs: 'body2', md: 'body1' },
                    textAlign: 'left',
                    paddingBottom: 10,
                  }}
                >
                  This information will be used to book your future Pop-Ups, pay for your spot, request additions and
                  more
                </Typography>

                <TabsComponent
                  value={activeTab}
                  data={paymentTabs}
                  handleChangeTab={handleChangeTab}
                  tabsStyles={{ marginBottom: 12, width: '100%' }}
                  ariaLabel="payment-tab"
                ></TabsComponent>

                <Typography sx={{ typography: { xs: 'h6', md: 'h3' }, textAlign: 'center', paddingBottom: 10 }}>
                  Free cancellation and deposit refund until{' '}
                  {startDateObj && format(new Date(startDateObj).setDate(startDateObj.getDate() - 45), 'dd/MM/yyyy')}
                </Typography>

                <Box sx={{ paddingBottom: 15 }}>
                  {startDateObj && <RefundDeposit startDateOfBooking={startDateObj} />}
                </Box>

                <Box sx={{ paddingBottom: 15 }}>
                  <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                    *The rest of the payment will be charged on the first day of the Pop-up period
                  </Typography>
                </Box>

                <Box sx={{ paddingBottom: 10 }}>
                  <Checkbox
                    changeHandler={changeTermsAndConditionsCheckboxHandler}
                    label={
                      <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                        By clicking here you are accepting the{' '}
                        <Link
                          component="button"
                          type="button"
                          sx={{ typography: { xs: 'body2', md: 'h5' } }}
                          variant="body1"
                          color="inherit"
                          underline="always"
                          onClick={clickOnTermsAndConditionsLinkHandler}
                        >
                          Terms and Conditions
                        </Link>
                      </Typography>
                    }
                  ></Checkbox>
                </Box>

                <Grid item xs={12} sx={{ textAlign: { xs: 'center', md: 'right', marginTop: 20 } }}>
                  <BasicButton
                    className="btn-large"
                    sx={{ minWidth: '165px' }}
                    variant="outlined"
                    type="button"
                    disabled={isDisabledBookNowBtn}
                    onClick={clickOnBookNowBtnHandler}
                  >
                    Book now
                  </BasicButton>
                </Grid>
              </>
            ) : (
              <SignUpLogInBox />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                maxWidth: '400px',
                margin: { xs: '0 auto', md: '0 0 0 auto' },
              }}
            >
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.common.black}`,
                  borderRadius: '10px',
                  marginBottom: { xs: 10, md: 20 },
                  padding: 15,
                }}
              >
                <Typography variant="h3" sx={{ paddingBottom: 5 }}>
                  {selectedSpot?.name}, {spotCityName}
                </Typography>
                <SummaryTable
                  tableData={summaryTableData}
                  styles={{
                    '&': { fontSize: { xs: '12px', md: '14px' } },
                    '> div:not(:last-of-type) .table-row:not(.table-row-highlighted)': { marginBottom: 10 },
                    '.table-row.table-row-highlighted': { marginY: 10 },
                  }}
                ></SummaryTable>
              </Box>

              {checkInOutDates.map((item, index) => {
                return (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      textAlign: { xs: 'center', md: 'left' },
                      typography: { xs: 'body2', md: 'body1' },
                      marginBottom: 5,
                    }}
                  >
                    <CheckMarkCircleIcon style={{ flexShrink: '0', marginRight: '10px' }} />
                    {item.label}: {format(new Date(item.date), 'dd/MM/yyyy')}
                  </Typography>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </BasicContainer>
      <TermAndConditionsDialog
        dialogProps={{
          open: isShowTermsAndConditionsDialog,
          onClose: () => {
            showTermsAndConditionsDialog(false);
          },
          fullWidth: true,
          withCloseIcon: true,
          PaperProps: { sx: { maxWidth: '970px' } },
        }}
      />
    </>
  );
};
