import React from 'react';

import { Box, Link, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ActionMenu } from '../../../../common/components/ActionMenu';
import { BasicButton } from '../../../../common/components/basic/Button';
import { fileDownloader } from '../../../../common/helpers/file-downaloder';
import { getFormattedPrice } from '../../../../common/helpers/formatters';
import { getActionMenuItemsForPreCampaign } from '../../../../common/helpers/get-action-menu-items-for-pre-campaign';
import { getPreBookingStatus } from '../../../../common/helpers/get-pre-campaign-status';
import { getRelatedDataToPreCampaign } from '../../../../common/helpers/get-related-data-to-pre-campaign';
import { findPartInAddressObjByType } from '../../../../common/helpers/google-heplers';
import { useAppSelector } from '../../../../common/hooks/redux-hooks';
import { useCurrencyConverter } from '../../../../common/hooks/use-currency-converter';
import { getById as getCurrencyById } from '../../../../constants/enums/currency';
import PreCampaignBundleDTO from '../../../../models/pre-campaign/pre-campaign-bundle-dto';
import PreCampaignDTO from '../../../../models/pre-campaign/pre-campaign-dto';
import { ImgService } from '../../../../services/img-service';
import { selectBookingsManagerDashboardState } from '../bookingsManagerDashboardSlice';

export const PreBookingAdditionalInfo = ({
  clickOnApproveHandler,
  clickOnDeleteHandler,
  clickOnCopyBookingURLHandler,
  clickOnRejectHandler,
  redirectToPersonalizedBookingHandler,
}: PreBookingAdditionalInfoProps): JSX.Element => {
  const {
    preBookingsBundleData,
    selectedPreBookingId,
  }: { preBookingsBundleData: PreCampaignBundleDTO; selectedPreBookingId: string } = useAppSelector(
    selectBookingsManagerDashboardState,
  );
  const { preCampaign, store, spot, campaign } = getRelatedDataToPreCampaign(
    selectedPreBookingId,
    preBookingsBundleData,
  );

  const imgService: ImgService = new ImgService();

  const createdAtDate = preCampaign?.createdAt && format(new Date(preCampaign.createdAt), 'dd/MM/yyyy');
  const startDate = preCampaign?.startDate && format(new Date(preCampaign.startDate), 'dd/MM/yyyy');
  const endDate = preCampaign?.endDate && format(new Date(preCampaign.endDate), 'dd/MM/yyyy');
  const spotCityName = store?.address?.parts ? findPartInAddressObjByType(store.address.parts, 'locality')?.name : '';
  const currencyObj = getCurrencyById(preCampaign?.currency);
  const { convertedPrice: convertedAmount = 0, currencySymbol = '' } = useCurrencyConverter(
    currencyObj,
    preCampaign?.amount,
  );
  const { convertedPrice: convertedDepositAmount = 0 } = useCurrencyConverter(currencyObj, preCampaign?.depositAmount);
  const formattedDepositAmount = getFormattedPrice(convertedDepositAmount, currencySymbol, true);
  const formattedAmount = getFormattedPrice(convertedAmount, currencySymbol, true);
  const formattedAmountAtCheckIn = getFormattedPrice(convertedAmount - convertedDepositAmount, currencySymbol, true);
  const isPreBookingWithBankAccount = Boolean(preCampaign?.bankAccount && preCampaign?.bankAccountId);
  const isVerifiedBankAccount = isPreBookingWithBankAccount && preCampaign.bankAccount.isVerified;
  const isAvailableRelatedCampaign = Boolean(campaign?.id);
  const preCampaignStatus = getPreBookingStatus(
    isVerifiedBankAccount,
    isPreBookingWithBankAccount,
    isAvailableRelatedCampaign,
  );
  const isWaitingBankAccountConfirmationStatus = preCampaignStatus && preCampaignStatus.id === 4;
  const bankAccountImgUrl =
    isPreBookingWithBankAccount &&
    imgService.getImgPreviewUrl(preCampaign.bankAccount.image, {
      width: 1000,
      height: 1000,
    });
  const originalBankAccountImgUrl =
    isPreBookingWithBankAccount && imgService.getOriginalUrlImg(preCampaign.bankAccount.image);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 15 }}>
        <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
          {preCampaign?.brandName}
        </Typography>
        <ActionMenu
          items={getActionMenuItemsForPreCampaign(
            preCampaign,
            redirectToPersonalizedBookingHandler,
            clickOnDeleteHandler,
            clickOnApproveHandler,
            clickOnCopyBookingURLHandler,
            clickOnRejectHandler,
            isPreBookingWithBankAccount,
            isWaitingBankAccountConfirmationStatus,
          )}
        />
      </Box>
      <Typography variant="subtitle2" sx={{ marginBottom: 6 }}>
        Online Booking
      </Typography>
      <Typography variant="body1" sx={{ color: '#808080', marginBottom: 20 }}>
        {createdAtDate && `Created on:  ${createdAtDate}`}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
        {startDate && (
          <Typography variant="h3" sx={{ marginRight: 10 }}>
            From:
            <Typography variant="caption" sx={{ typography: { xs: 'body2', md: 'body1', marginLeft: '10px' } }}>
              {startDate}
            </Typography>
          </Typography>
        )}

        {endDate && (
          <Typography variant="h3">
            To:
            <Typography variant="caption" sx={{ typography: { xs: 'body2', md: 'body1' }, marginLeft: '10px' }}>
              {endDate}
            </Typography>
          </Typography>
        )}
      </Box>

      <Box sx={{ marginBottom: 20 }}>
        <Typography variant="h3" sx={{ marginBottom: 6 }}>
          Store
        </Typography>
        <Typography variant="body1">
          {spot?.name}
          {spotCityName && ', ' + spotCityName}
        </Typography>
      </Box>

      <Box sx={{ marginBottom: 50 }}>
        <Typography variant="h3" sx={{ marginBottom: 6 }}>
          Status
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Box
            sx={{
              width: '12px',
              height: '12px',
              flexShrink: '0',
              marginRight: 5,
              borderRadius: '20px',
              backgroundColor: preCampaignStatus.color,
            }}
          ></Box>

          <Typography variant="subtitle2">{preCampaignStatus.name}</Typography>
        </Box>

        {preCampaignStatus?.name.toLowerCase() != 'pending' && (
          <Typography variant="body1" sx={{ marginBottom: 10 }}>
            Payment method: {preCampaign?.bankAccountId ? 'Bank transfer' : 'Credit card'}
          </Typography>
        )}

        <Typography variant="body1" sx={{ marginBottom: 10 }}>
          Total payment: {formattedAmount}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 10 }}>
          Deposit amount: {formattedDepositAmount}
        </Typography>
        <Typography variant="body1">Total returned: {formattedAmountAtCheckIn}</Typography>

        {isPreBookingWithBankAccount && bankAccountImgUrl && (
          <Box sx={{ display: 'flex', marginTop: 10 }}>
            <Typography variant="h5">Uploaded File:</Typography>

            <Link
              href={bankAccountImgUrl}
              target="_blank"
              rel="noreferrer"
              variant="body1"
              color="inherit"
              underline="always"
              sx={{ marginX: 10 }}
            >
              View
            </Link>

            <Link
              component="button"
              type="button"
              variant="body1"
              color="inherit"
              underline="always"
              onClick={() => fileDownloader(originalBankAccountImgUrl, `${preCampaign?.brandName}-bank-transfer.png`)}
            >
              Download
            </Link>
          </Box>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', flexWrap: 'wrap' }}>
        {isWaitingBankAccountConfirmationStatus && (
          <>
            <BasicButton
              className="btn-large"
              sx={{
                minWidth: { xs: '150', md: '165px' },
                marginRight: { xs: 7, md: 15 },
                marginBottom: { xs: 7, md: 15 },
              }}
              variant="outlined"
              onClick={() => clickOnApproveHandler(preCampaign)}
            >
              Approve
            </BasicButton>

            <BasicButton
              className="btn-large"
              sx={{
                minWidth: { xs: '150', md: '165px' },
                marginRight: { xs: 7, md: 15 },
                marginBottom: { xs: 7, md: 15 },
              }}
              variant="outlined"
              onClick={() => clickOnRejectHandler(preCampaign)}
            >
              Reject
            </BasicButton>
          </>
        )}

        <BasicButton
          className="btn-large"
          sx={{ minWidth: { xs: '150', md: '165px' } }}
          variant="outlined"
          onClick={() => clickOnDeleteHandler(preCampaign)}
        >
          Delete
        </BasicButton>
      </Box>
    </Box>
  );
};

interface PreBookingAdditionalInfoProps {
  clickOnApproveHandler: (preCampaign: PreCampaignDTO) => void;
  clickOnDeleteHandler: (preCampaign: PreCampaignDTO) => void;
  clickOnCopyBookingURLHandler: (preCampaign: PreCampaignDTO) => void;
  clickOnRejectHandler: (preCampaign: PreCampaignDTO) => void;
  redirectToPersonalizedBookingHandler: (preCampaign: PreCampaignDTO) => void;
}
