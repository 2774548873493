import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import { API_URL } from '../constants';
import UserAuthDTO from '../models/user/user-auth-dto';
import UserExternalSignInDTO from '../models/user/user-external-sign-in-dto';
import UserLogInDTO from '../models/user/user-log-in-dto';
import UserRecoveryDTO from '../models/user/user-recovery-dto';
import UserRecoveryRequestDTO from '../models/user/user-recovery-request-dto';
import UserRegisterAsBrandDTO from '../models/user/user-register-as-brand';

export default class AuthService {
  baseURL: string;

  constructor() {
    this.baseURL = `${API_URL}/auth`;
  }

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public createUserAsBrand(brandRegisterData: UserRegisterAsBrandDTO): ApiRequestWrapper<UserAuthDTO> {
    return this.apiUrlProvider.post(this.baseURL + '/register-as-brand', brandRegisterData);
  }

  public userLogIn(logInUser: UserLogInDTO): ApiRequestWrapper<UserAuthDTO> {
    return this.apiUrlProvider.post(this.baseURL + '/sign-in', { ...logInUser });
  }

  public passwordRecoveryRequest(email: UserRecoveryRequestDTO): ApiRequestWrapper<UserAuthDTO> {
    return this.apiUrlProvider.post(this.baseURL + '/recovery-request', email);
  }

  public passwordRecovery(userRecovery: UserRecoveryDTO): ApiRequestWrapper<UserAuthDTO> {
    return this.apiUrlProvider.post(this.baseURL + '/recovery', userRecovery);
  }

  public externalSignIn(userExternalSignIn: UserExternalSignInDTO): ApiRequestWrapper<UserAuthDTO> {
    return this.apiUrlProvider.post(this.baseURL + '/external-sign-in', userExternalSignIn);
  }

  public emailVerificationRequest(email: string) {
    return this.apiUrlProvider.post(this.baseURL + `/email-verification-request?email=${email}`, {});
  }

  public emailVerify(userId: string, token: string): ApiRequestWrapper<UserAuthDTO> {
    return this.apiUrlProvider.post(this.baseURL + `/email-verify?userId=${userId}&token=${token}`, {});
  }
}
