import { USER_ROLES } from 'constants/enums/user-roles';

import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { MainTemplate } from 'common/components/MainTemplate';
import { NotFound } from 'common/components/NotFound';
import { PrivateRoute } from 'common/components/PrivateRout';
import { ScrollToTopWrapper } from 'common/components/ScrollToTop';
import { useRedirectByUserRole } from 'common/hooks/use-redirect-by-user-role';
import { useSetCurrencyRatesByUserCurrency } from 'common/hooks/use-set-currency-rates-by-user-currency';
import { Checkout as BookingCheckout } from 'features/booking/Checkout';
import { Spot } from 'features/booking/Spot';
import { SpotsList } from 'features/booking/SpotsList';
import { Wizard } from 'features/booking/Wizard';
import { BrandDashboard } from 'features/brand-dashboard';
import { ForgotPassword } from 'features/forgot-password';
import { Loader } from 'features/loader';
import { LogIn } from 'features/log-in';
import { ManagerDashboard } from 'features/manager-dashboard';
import { PreBooking } from 'features/pre-booking';
import { Checkout as PreBookingCheckout } from 'features/pre-booking/Checkout';
import { Proposal } from 'features/pre-booking/Proposal';
import { Welcome } from 'features/pre-booking/Welcome';
import { RecoveryPassword } from 'features/recovery-password';
import { SignUp } from 'features/sign-up';
import { withSnackbar } from 'notistack';
import TagManager from 'react-gtm-module';
import { Route, Routes } from 'react-router-dom';

import { GeneralStyles } from './styles';

declare global {
  interface Window {
    spaSettings: SpaSettings;
  }
}

interface SpaSettings {
  googleMapApiKey: string;
  googleClientId: string;
  facebookClientId: string;
  gtmContainerId: string;
}

const GTM_CONTAINER_ID = window.spaSettings.gtmContainerId;

const tagManagerArgs = {
  gtmId: GTM_CONTAINER_ID ? GTM_CONTAINER_ID : '',
};

TagManager.initialize(tagManagerArgs);

const App = (): JSX.Element => {
  useRedirectByUserRole();
  useSetCurrencyRatesByUserCurrency();
  return (
    <GeneralStyles>
      <CssBaseline />
      <Loader />
      <ScrollToTopWrapper>
        <Routes>
          <Route path="/" element={<MainTemplate />}>
            <Route path="sign-up" element={<SignUp />} />
            <Route path="log-in" element={<LogIn />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="recovery" element={<RecoveryPassword />} />
          </Route>
          <Route
            path="dashboard/manager/*"
            element={
              <PrivateRoute userRoles={[USER_ROLES.Admin, USER_ROLES.Retail]}>
                <ManagerDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="dashboard/brand/*"
            element={
              <PrivateRoute userRoles={[USER_ROLES.Brand]}>
                <BrandDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="pre-booking"
            element={
              <PrivateRoute userRoles={[USER_ROLES.Brand]}>
                <PreBooking />
              </PrivateRoute>
            }
          >
            <Route path="welcome" element={<Welcome />} />
            <Route path="proposal" element={<Proposal />} />
            <Route path="checkout" element={<PreBookingCheckout />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path="booking/wizard" element={<Wizard />} />
            <Route path="booking/spots" element={<SpotsList />} />
            <Route path="booking/spots/:spotId" element={<Spot />} />
            <Route path="booking/checkout/:spotId" element={<BookingCheckout />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTopWrapper>
    </GeneralStyles>
  );
};

export default withSnackbar(App);
