import CurrencyDTO from '../../models/currency-dto';

export enum CURRENCY {
  USD = 0,
  ILS = 1,
  JPY = 2,
  EUR = 3,
}

export const getAllCurrencies = (): Array<CurrencyDTO> => {
  return [
    {
      id: CURRENCY.USD,
      name: 'USD',
      symbol: '$',
    },
    {
      id: CURRENCY.ILS,
      name: 'ILS',
      symbol: '₪',
    },
    {
      id: CURRENCY.JPY,
      name: 'JPY',
      symbol: '¥',
    },
    {
      id: CURRENCY.EUR,
      name: 'EUR',
      symbol: '€',
    },
  ];
};

export const getDefault = () => {
  let value = getAllCurrencies().find((x) => x.id === CURRENCY.ILS);

  if (value === undefined) {
    throw new TypeError('Cant find default currency');
  }

  return value;
};

export const getById = (currency: CURRENCY | undefined): CurrencyDTO => {
  return getAllCurrencies().find((x: any) => x.id == currency) || getDefault();
};

export const getByName = (currencyName: string | undefined): CurrencyDTO => {
  return getAllCurrencies().find((x: any) => x.name == currencyName) || getDefault();
};