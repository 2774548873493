export enum PaymentTransactionStatus {
  Successed,
  Processing,
  Failure,
}

export const getAllPaymentTransactionStatuses = () => {
  return [
    {
      id: PaymentTransactionStatus.Successed,
      name: 'Succeeded',
      color: '#79C26A',
    },
    {
      id: PaymentTransactionStatus.Processing,
      name: 'Processing',
      color: '#ffa726',
    },
    {
      id: PaymentTransactionStatus.Failure,
      name: 'Failure',
      color: '#DD4D47',
    },
  ];
};

export const getById = (status: PaymentTransactionStatus) => {
  return getAllPaymentTransactionStatuses().find((x) => x.id == status);
};
