import { Card } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSpotCardDashboard = styled(Card)(({ theme }) => ({
  '&.MuiCard-root': {
    position: 'relative',
    height: '370px',
    borderRadius: '20px',
    boxShadow: 'none',
  },

  '&:hover': {
    '.MuiCardContent-root': {
      opacity: '1',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },

  '.MuiCardMedia-img': {
    height: '100%',
  },

  '.MuiCardContent-root': {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
    padding: '10px',
    color: theme.palette.common.white,
    opacity: '0',
    transition: '.7s',

    '.navigations': {
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '.address': {
      position: 'absolute',
      bottom: '20px',
    },
  },
}));
