import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { useDropzone } from 'react-dropzone';

import { hideLoader, showLoader } from '../../../features/loader/loaderSlice';
import { enqueueNotification as enqueueSnackbarAction } from '../../../features/notifier/notifierSlice';
import ImageEditDTO from '../../../models/image-edit-dto';
import { ImgService } from '../../../services/img-service';
import { fileUploaderHelper } from '../../helpers/file-uploader-helper';
import { createImageDto } from '../../helpers/img-helper';
import { isNotUndefinedOrNull } from '../../helpers/utils';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { BasicButton } from '../basic/Button';
import { StyledDropzone, StyledUploadedItem } from './styles';

export const PhotoUploader = ({
  children,
  selectedFiles,
  alreadySavedFiles,
  setSelectedFiles,
  maxFiles,
  allowedImgExtension = '.jpg, .jpeg, .png, .pdf',
  uploaderBoxStyles = [],
}: PhotoUploaderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const isAvailableMultipleFiles = maxFiles > 1;
  const isArraySelectedFiles = Array.isArray(selectedFiles);
  const isArrayAlreadySavedFiles = Array.isArray(alreadySavedFiles);
  const [indexOfSelectedFileToChange, setIndexOfSelectedFileToChange] = useState<number | undefined>(undefined);
  const [indexOfSavedFileToChange, setIndexOfSavedFileToChange] = useState<number | undefined>(undefined);

  const imgService: ImgService = new ImgService();

  const onDropRejected = useCallback((fileRejections: any) => {
    const err = fileRejections[0].errors[0].code;

    const message = () => {
      if (err === 'file-invalid-type') return 'File should be in the following formats: .jpg, .jpeg, .png, .pdf';
      else if (err === 'too-many-files') return `Number of files cannot be more than ${maxFiles}`;
    };

    dispatch(
      enqueueSnackbarAction({
        message: message(),
        options: { variant: 'error' },
      }),
    );
  }, []);

  const onDropAccepted = useCallback(
    async (files: Array<any>) => {
      const uploadedFilesInBase64 = await fileUploaderHelper(files);
      const imgObjectsArray = uploadedFilesInBase64.map((file) => createImageDto(file, false));

      //need to save previous images in case with uploading multiple files
      const newArrayOfSelectedFiles =
        isAvailableMultipleFiles && isArraySelectedFiles ? [...selectedFiles, ...imgObjectsArray] : imgObjectsArray;

      if (isNotUndefinedOrNull(indexOfSelectedFileToChange) && isArraySelectedFiles && selectedFiles?.length) {
        //change uploaded images

        const changedArray = selectedFiles.map((imgObject, index) => {
          if (index !== indexOfSelectedFileToChange) {
            return imgObject;
          } else {
            return imgObjectsArray[0];
          }
        });
        setIndexOfSelectedFileToChange(undefined);
        setSelectedFiles(changedArray, alreadySavedFiles);
      } else if (
        indexOfSavedFileToChange !== undefined &&
        isNotUndefinedOrNull(indexOfSavedFileToChange) &&
        isArrayAlreadySavedFiles &&
        alreadySavedFiles?.length
      ) {
        //change saved images from the server and upload new images

        setIndexOfSelectedFileToChange(undefined);
        setSelectedFiles(newArrayOfSelectedFiles, [
          ...alreadySavedFiles.slice(0, indexOfSavedFileToChange),
          ...alreadySavedFiles.slice(indexOfSavedFileToChange + 1),
        ]);
      } else {
        //default upload images for the first time
        setSelectedFiles(newArrayOfSelectedFiles, alreadySavedFiles);
      }

      dispatch(hideLoader());
    },
    [selectedFiles, setSelectedFiles, indexOfSavedFileToChange, indexOfSelectedFileToChange],
  );

  const onDrop = useCallback(() => {
    dispatch(showLoader());
  }, []);

  const onFileDialogCancel = useCallback(() => {
    setIndexOfSelectedFileToChange(undefined);
    setIndexOfSavedFileToChange(undefined);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: maxFiles,
    accept: allowedImgExtension,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onDrop,
  });

  const clickOnUploadedItemHandler = (key: number) => {
    setIndexOfSelectedFileToChange(key);
  };

  const clickOnSavedItemHandler = (key: number) => {
    setIndexOfSavedFileToChange(key);
  };

  const getPreviewForSingleFile = () => {
    if (!isAvailableMultipleFiles && !isArraySelectedFiles && selectedFiles?.base64) {
      return selectedFiles.base64;
    } else if (!isAvailableMultipleFiles && !isArrayAlreadySavedFiles && alreadySavedFiles) {
      return imgService.getImgPreviewUrl(alreadySavedFiles, {
        width: 300,
        height: 300,
      });
    }
  };

  useEffect(() => {
    if (isNotUndefinedOrNull(indexOfSelectedFileToChange)) {
      open();
    }
  }, [indexOfSelectedFileToChange]);

  useEffect(() => {
    if (isNotUndefinedOrNull(indexOfSavedFileToChange)) {
      open();
    }
  }, [indexOfSavedFileToChange]);

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexWrap: 'wrap',
          height: '100%',
        },
        ...(Array.isArray(uploaderBoxStyles) ? uploaderBoxStyles : [uploaderBoxStyles]),
      ]}
    >
      {isAvailableMultipleFiles && isArrayAlreadySavedFiles
        ? alreadySavedFiles?.map((imgId, index) => {
            const imgUrl = imgService.getImgPreviewUrl(imgId, {
              width: 300,
              height: 300,
            });

            return (
              <StyledUploadedItem key={index} previewImg={imgUrl}>
                <Box className="btn-block" onClick={() => clickOnSavedItemHandler(index)}>
                  <BasicButton className="transparent transparent--white" sx={{ minWidth: '100px' }} variant="outlined">
                    Change
                  </BasicButton>
                </Box>
              </StyledUploadedItem>
            );
          })
        : null}

      {isAvailableMultipleFiles && isArraySelectedFiles
        ? selectedFiles.map((file: any, index: number) => {
            return (
              <StyledUploadedItem key={index} previewImg={file.base64}>
                <Box className="btn-block" onClick={() => clickOnUploadedItemHandler(index)}>
                  <BasicButton className="transparent transparent--white" sx={{ minWidth: '100px' }} variant="outlined">
                    Change
                  </BasicButton>
                </Box>
              </StyledUploadedItem>
            );
          })
        : null}

      <StyledDropzone {...getRootProps({ className: 'dropzone' })} previewImg={getPreviewForSingleFile()}>
        <input {...getInputProps()} />
        {!isAvailableMultipleFiles &&
        !(isArraySelectedFiles || isArrayAlreadySavedFiles) &&
        (selectedFiles || alreadySavedFiles) ? (
          <Box className="btn-block">
            <BasicButton className="transparent transparent--white" sx={{ minWidth: '100px' }} variant="outlined">
              Change
            </BasicButton>
          </Box>
        ) : (
          children
        )}
      </StyledDropzone>
    </Box>
  );
};

interface PhotoUploaderProps {
  children?: React.ReactNode;
  maxFiles: number;
  allowedImgExtension?: string;
  selectedFiles: ImageEditDTO[] | ImageEditDTO | null;
  alreadySavedFiles?: string[] | string | null;
  setSelectedFiles: any;
  uploaderBoxStyles?: SxProps<Theme>;
}
