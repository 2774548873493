import ApiRequestWrapper from '../api/api-request-wrapper';
import ApiUrlProvider from '../api/api-url-provider';
import BrandsSearchDTO from '../models/brand/brands-search-dto';
import BrandsSearchFilterDTO from '../models/brand/brands-search-filter-dto';

export class BrandsManagerService {
  private readonly basePath: string = 'manager/brands';

  private readonly apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();

  public getBrandsByFilter(filter: BrandsSearchFilterDTO): ApiRequestWrapper<BrandsSearchDTO> {
    return this.apiUrlProvider.post(this.basePath + '/filter', filter);
  }
}
