import React from 'react';

import { Box, Grid, Link, Typography } from '@mui/material';
import { format, parseJSON } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { BasicButton } from '../../../common/components/basic/Button';
import { HorizontalStepper } from '../../../common/components/HorizontalStepper';
import { RePopLogoBlock } from '../../../common/components/RePopLogoBlock';
import { SummaryTable } from '../../../common/components/SummaryTable';
import { TooltipInfoIcon } from '../../../common/components/TooltipInfoIcon';
import { useAppSelector } from '../../../common/hooks/redux-hooks';
import { useOnBeforeUnload } from '../../../common/hooks/use-on-before-unload';
import theme from '../../../theme-config/theme';
import { GuidelineList } from '../../manager-dashboard/create-personalized-booking/GuidelineList';
import { selectPreBookingState } from '../preBookingSlice';

export const Proposal = (): JSX.Element => {
  const navigate = useNavigate();
  const steps = ['Welcome', 'Proposal', 'Checkout'];
  const { preCampaignState, formattedSummaryData } = useAppSelector(selectPreBookingState);
  const { startDate, endDate, discountInPercentage, vatInPercentage, beforeCheckIn, duringCheckOut } = preCampaignState;
  const {
    formattedDateRange,
    daysDuration,
    formattedPricePerWeek,
    formattedTotalAmount,
    formattedDepositAmount,
    spotNameWithStoreAddress,
  } = formattedSummaryData;
  const parsedStartDate = startDate && parseJSON(startDate);
  const parsedEndDate = endDate && parseJSON(endDate);

  const tooltipContent = (
    <Typography variant="body1">
      This guides will help you with your Spot, before, during and after your Pop-up time.
      <br></br>
      <br></br>
      Don’t hesitate to contact our team for any enquieries
    </Typography>
  );

  const summaryTableData = [
    {
      headTitle: spotNameWithStoreAddress,
      rowValue: formattedPricePerWeek && formattedPricePerWeek + '/Week',
    },
    {
      headTitle: 'Dates',
      rowValue: formattedDateRange && formattedDateRange,
    },
    {
      headTitle: 'Total days',
      rowValue: daysDuration && daysDuration,
    },
    {
      headTitle: 'Total Amount',
      rowValue: formattedTotalAmount && formattedTotalAmount,
      isHighlightedTitle: true,
      isHighlightedValue: true,
    },
    {
      headTitle: 'VAT',
      rowValue: vatInPercentage && vatInPercentage + '%',
    },
    {
      headTitle: 'Deposit',
      rowValue: formattedDepositAmount && formattedDepositAmount,
    },
  ];

  //add discount row only in case if discount is available
  discountInPercentage &&
    summaryTableData.splice(1, 0, {
      headTitle: 'Discount applied',
      rowValue: discountInPercentage + '%',
    });

  useOnBeforeUnload();

  return (
    <Grid container sx={{ minHeight: { md: '100vh' }, alignItems: { md: 'stretch' } }}>
      <Grid item container sm={12} md={6} lg={6} sx={{ justifyContent: 'flex-end' }}>
        <Grid
          item
          sm={12}
          md={12}
          lg={10}
          sx={{ width: '100%', maxWidth: { md: '630px' }, padding: { xs: 8, md: 15 } }}
        >
          <RePopLogoBlock sx={{ margin: { xs: '0 auto', md: 'initial' }, marginBottom: { xs: 13, md: '8.5vh' } }} />

          <HorizontalStepper
            steps={steps}
            activeStep={1}
            sx={{ display: { xs: 'block', md: 'none' }, paddingBottom: 15 }}
          ></HorizontalStepper>
          <Box sx={{ paddingBottom: { xs: 15, md: '7.5vh' } }}>
            <Typography variant="h1" sx={{ typography: { xs: 'h3', md: 'h1' }, textTransform: { md: 'uppercase' } }}>
              Proposal
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', paddingBottom: 8 }}>
            <Typography
              variant="h4"
              sx={{ typography: { xs: 'subtitle2', md: 'h3' }, marginRight: 5, paddingBottom: 7 }}
            >
              {spotNameWithStoreAddress}
            </Typography>
            <Link
              component="button"
              type="button"
              sx={{ display: spotNameWithStoreAddress ? 'block' : 'none', paddingBottom: 7 }}
              variant="body1"
              color="inherit"
              underline="always"
            >
              Go to spot page
            </Link>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 5, paddingBottom: { xs: 7, md: 5 } }}>
              <Typography variant="h5" sx={{ typography: { xs: 'h6', md: 'h5' }, mr: 4 }}>
                Check in:
              </Typography>
              <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                {parsedStartDate && format(parsedStartDate, 'dd/MM/yyyy - ha')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'c', paddingBottom: { xs: 12, md: 5 } }}>
              <Typography variant="h5" sx={{ typography: { xs: 'h6', md: 'h5' }, mr: 4 }}>
                Check out:
              </Typography>
              <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                {parsedEndDate && format(parsedEndDate, 'dd/MM/yyyy - ha')}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.common.black}`,
              borderRadius: '10px',
              padding: 10,
              marginBottom: 10,
            }}
          >
            <SummaryTable
              tableData={summaryTableData}
              styles={{
                '&': { typography: { xs: 'body2', md: 'subtitle2' } },
                '> div:not(:last-of-type) .table-row:not(.table-row-highlighted)': { marginBottom: 10 },
                '.table-row.table-row-highlighted': { marginTop: 5, marginBottom: 15 },
              }}
            ></SummaryTable>
          </Box>

          <Typography
            variant="h5"
            sx={{
              typography: { xs: 'h6', md: 'h5' },
              paddingBottom: { xs: 15, md: 25 },
            }}
          >
            Free Cancelation{' '}
            <Typography variant="caption" sx={{ typography: { xs: 'body2', md: 'body1' } }}>
              until 25/12/2021. View cancelation conditions at checkout
            </Typography>
          </Typography>

          <Typography variant="body1" sx={{ typography: { xs: 'body2', md: 'body1' }, paddingBottom: 10 }}>
            For any questions regarding this proposal, contact our community managers via email{' '}
            <Link
              href="mailto:hello@repop.shop"
              variant="body1"
              sx={{ typography: { xs: 'body2', md: 'body1' } }}
              color="inherit"
              underline="always"
            >
              hello@repop.shop
            </Link>{' '}
            or WhatsApp{' '}
            <Link
              href="https://wa.me/+972548200202"
              target="_blank"
              variant="body1"
              sx={{ typography: { xs: 'body2', md: 'body1' } }}
              color="inherit"
              underline="always"
            >
              +972-54-8200-202
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid item container sm={12} md={6} lg={6} sx={{ backgroundColor: 'black' }}>
        <Grid
          item
          container
          alignContent="space-between"
          sm={12}
          md={12}
          lg={10}
          sx={{ width: '100%', maxWidth: { md: '630px' }, padding: { xs: 8, md: 15 } }}
        >
          <Grid item xs={12}>
            <HorizontalStepper
              steps={steps}
              activeStep={1}
              colorPalette={theme.palette.common.white}
              sx={{
                maxWidth: '300px',
                display: { xs: 'none', md: 'block' },
                marginLeft: 'auto',
                paddingTop: '11vh',
                paddingBottom: '12.5vh',
              }}
            ></HorizontalStepper>

            <Box sx={{ paddingBottom: { xs: 15, md: 35 } }}>
              <TooltipInfoIcon
                iconSize16px={true}
                tooltipTitle={tooltipContent}
                containerStyles={{ paddingTop: { xs: 8, md: 'none' }, paddingBottom: { xs: 10, md: 15 } }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: (theme) => theme.palette.common.white,
                  }}
                >
                  Guidelines
                </Typography>
              </TooltipInfoIcon>

              <GuidelineList
                title="Before your Pop-up - Check in"
                colorPalette={theme.palette.common.white}
                guidelines={beforeCheckIn}
                sx={{ paddingBottom: { md: 25 }, '.title': { typography: { xs: 'body2', md: 'h3' } } }}
                guidelineInputProps={{
                  id: 'during-check-out',
                  name: 'beforeCheckIn',
                  placeholder: 'Add a guideline',
                }}
              ></GuidelineList>
              <GuidelineList
                title="During your Pop-up - Check out"
                colorPalette={theme.palette.common.white}
                guidelines={duringCheckOut}
                sx={{ '.title': { typography: { xs: 'body2', md: 'h3' } } }}
                guidelineInputProps={{
                  id: 'during-check-out',
                  name: 'duringCheckOut',
                  placeholder: 'Add a guideline',
                }}
              ></GuidelineList>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-end' },
                paddingBottom: { xs: 17, md: 40 },
              }}
            >
              <BasicButton
                className="btn-large btn-outlined-black-with-white-text"
                sx={{ minWidth: '165px', marginRight: { xs: 6, md: 15 } }}
                variant="outlined"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </BasicButton>
              <BasicButton
                className="btn-large btn-yellow"
                sx={{ minWidth: '165px' }}
                variant="contained"
                type="button"
                onClick={() => {
                  navigate('/pre-booking/checkout');
                }}
              >
                Next
              </BasicButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
