import React, { useState } from 'react';

import { Avatar, Box, Menu, MenuItem, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { BasicButton } from 'common/components/basic/Button';
import { useAppDispatch } from 'common/hooks/redux-hooks';
import { useMatchRoutes } from 'common/hooks/use-match-routes';
import { logOut } from 'features/authentication/authSlice';
import { useNavigate } from 'react-router-dom';
import theme from 'theme-config/theme';

interface DashboardCaptionProps {
  children: string | React.ReactNode | React.ReactNode[];
}

export const DashboardCaption = ({ children }: DashboardCaptionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isOnlyXsBreakPoint = useMediaQuery(theme.breakpoints.only('xs'));
  const isOnlySmBreakPoint = useMediaQuery(theme.breakpoints.only('sm'));
  const isUpSmBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const isManagerHomePage = useMatchRoutes(['/dashboard/manager']);
  const isManagerBookingsPage = useMatchRoutes(['/dashboard/manager/bookings']);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickOnLogOut = () => {
    dispatch(logOut());
    handleCloseUserMenu();
  };

  const getAdditionalButtons = () => {
    if (isManagerHomePage) {
      return (
        <>
          <BasicButton
            sx={{ minWidth: { xs: '150', md: '170px' }, display: 'block', marginRight: 10 }}
            variant="outlined"
            onClick={() => navigate('/dashboard/manager/new-spot')}
          >
            <Typography variant="body1">New spot</Typography>
          </BasicButton>

          <BasicButton
            sx={{ minWidth: { xs: '150', md: '170px' }, display: 'block', marginRight: 30 }}
            variant="outlined"
            onClick={() => navigate('/dashboard/manager/new-store')}
          >
            <Typography variant="body1">New store</Typography>
          </BasicButton>
        </>
      );
    } else if (isManagerBookingsPage) {
      return (
        <BasicButton
          sx={{ minWidth: '170px', display: 'block', marginRight: 30 }}
          variant="outlined"
          onClick={() => navigate('/dashboard/manager/personalized-booking')}
        >
          <Typography variant="body1">New booking</Typography>
        </BasicButton>
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 6, sm: 25 } }}>
      {isOnlyXsBreakPoint && <Box sx={{ display: 'flex', marginBottom: 15 }}>{getAdditionalButtons()}</Box>}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h1" sx={{ textTransform: 'uppercase', marginRight: 30 }}>
          {children}
        </Typography>

        {isUpSmBreakPoint && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isUpMdBreakPoint && getAdditionalButtons()}

            <SvgIcon
              sx={{
                marginRight: 30,
                fill: 'transparent',
              }}
              viewBox={'0 0 23 28'}
              component={BellIcon}
            />
            <Box sx={{ flexGrow: 0 }}>
              <Avatar alt="user avatar" />

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleClickOnLogOut}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        )}
      </Box>

      {isOnlySmBreakPoint && <Box sx={{ display: 'flex', marginTop: 10 }}>{getAdditionalButtons()}</Box>}
    </Box>
  );
};
