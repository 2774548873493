import React from 'react';

import { Box, FormControl, FormHelperText, FormLabel, RadioGroup } from '@mui/material';

import { radioBtnGroupStyles } from './styles';

export const BasicRadioBtnGroup = ({
  label,
  children,
  radioGroupProps = {},
  error = '',
}: BasicRadioBtnGroupProps): JSX.Element => {
  return (
    <FormControl error={!!error} sx={radioBtnGroupStyles}>
      <Box className="radio-group-box">
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <RadioGroup {...radioGroupProps}>{children}</RadioGroup>
      </Box>

      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

interface BasicRadioBtnGroupProps {
  label?: string;
  children: JSX.Element | JSX.Element[];
  radioGroupProps: object;
  error?: string;
}
