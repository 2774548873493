import React, { useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import BlatantOtf from '../../../assets/fonts/blatant/Blatant.otf';
import { PaymentsBrandService } from '../../../services/payments-brand';
import { StripePaymentForm } from '../StripePaymentForm';

const configOptions = {
  fonts: [
    {
      family: 'Blatant',
      weight: '400',
      src: `local('Blatant'), local('Blatant'), url(https://${location.hostname}${BlatantOtf})`,
    },
  ],
};

export const StripeElement = ({ stripeKey }: { stripeKey: string | null }): JSX.Element => {
  return (
    <>
      {stripeKey && (
        <Elements stripe={stripeKey ? loadStripe(stripeKey) : null} options={configOptions}>
          <StripePaymentForm />
        </Elements>
      )}
    </>
  );
};
