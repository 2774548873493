import { Theme } from '@mui/material/styles';

export const basicContainerStyles = (theme: Theme) =>
  ({
    '&.MuiContainer-root': {
      maxWidth: '1170px',

      [theme.breakpoints.down('lg')]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
  } as const);
