export enum CampaignStatus {
  // Prepared statuses
  Pending = 0,

  Approved = 1,
  Refused = 2,

  // Modification statuses
  Modified = 10,

  //Refused = 20,
  //Brand or Retail can be cancle campaign after Approved
  Cancel = 20,

  // Waiting statuses
  WaitingForPaid = 40,

  ReadyToStart = 45,

  Started = 50,
  Ended = 100,
}

export const getAllCampaignStatuses = () => {
  return [
    {
      id: CampaignStatus.Pending,
      name: 'Pending',
      color: '#ffa726',
    },
    {
      id: CampaignStatus.Approved,
      name: 'Approved',
      color: '#79C26A',
    },
    {
      id: CampaignStatus.Refused,
      name: 'Refused',
      color: '#DD4D47',
    },
    {
      id: CampaignStatus.Modified,
      name: 'Modified',
      color: '#ffa726',
    },
    {
      id: CampaignStatus.WaitingForPaid,
      name: 'Payment Request',
      color: '#ffa726',
    },
    {
      id: CampaignStatus.ReadyToStart,
      name: 'Ready to start',
      color: '#79C26A',
    },
    {
      id: CampaignStatus.Started,
      name: 'On-going',
      color: '#79C26A',
    },
    {
      id: CampaignStatus.Ended,
      name: 'Ended',
      color: '#79C26A',
    },
    {
      id: CampaignStatus.Cancel,
      name: 'Cancel',
      color: '#DD4D47',
    },
  ];
};
