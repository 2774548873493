import React from 'react';

import { Tooltip as MaterialTooltip, styled, tooltipClasses, TooltipProps } from '@mui/material';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: `solid 1px ${theme.palette.common.black}`,
    borderRadius: '10px',
    padding: '10px',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,

    [`&.${tooltipClasses.tooltipPlacementBottom}`]: {
      marginTop: 6,
    },
  },
}));
